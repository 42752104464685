.feature-card-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.ac-head-wrap {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
}

.matrix-key,
.matrix-val {
  display: block;
}

.matrix-key {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.total-amount,
.matrix-val {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.ac-head-wrap h4 span {
  font-size: 14px;
  font-weight: 300;
  margin-left: 5px;
  text-transform: capitalize;
}

.action-item {
  margin-bottom: 40px;
  font-family: "Roboto";
}

.action-item h4,
.ac-statics h4,
.perf-statics h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.action-item button {
  width: 40%;
  margin-right: 20px;
  background: #fff;
  padding: 10px;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  font-family: "Roboto";
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  position: relative;
}

.action-item button i {
  font-size: 18px;
  margin-right: 6px;
  vertical-align: middle;
}

.action-item button .fa-angle-right {
  position: absolute;
  top: 7px;
  right: 15px;
  font-size: 24px;
}

.ac-statics {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 23px;
  padding-bottom: 15px;
  font-family: "Roboto";
}

.perf-statics {
  font-family: "Roboto";
}

.info-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 13px;
  flex-wrap: wrap;
}

.matrix-block.info-wrap div {
  width: 33%;
  margin-bottom: 23px;
}

.info-wrap p {
  margin-bottom: 10px;
}

.profile-wrapper .article-head {
  display: block;
  text-align: center;
}

.profile-wrapper .article-head figure {
  margin: 0 auto 20px;
}

.profile-wrapper .profile-name {
  font-size: 24px;
  display: block;
  margin-bottom: 15px;
  text-transform: capitalize;
  text-align: center;
}

.left-item-list i {
  display: inline-block;
  margin-right: 10px;
  width: 22px;
  font-weight: normal;
  font-size: 18px;
}

.left-item-list .active-tab i {}

.profile-wrapper .tags a {
  width: 17%;
}

.profile-wrapper .tags .active-tab {
  color: #F5C139;
  opacity: 1;
  position: relative;
}

.profile-wrapper .tags .active-tab:after {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #F5C139;
  content: "";
  left: 0;
}

.profile-wrapper .tags-wrap {
  margin-bottom: 30px;
}

.blogtags-sec .container {
  padding: 0 2%;
}

.profile-details {
  background: #f7f5f6;
}

.profile-details .detail-row {
  display: flex;
  justify-content: space-between;
}

.profile-details .detail-from {
  padding: 70px 0 90px;
  border-bottom: 3px solid rgba(112, 112, 112, 0.11);
}

.profile-details .detail-from:last-child {
  border: 0;
}

.profile-details .detail-colmn {
  width: 49%;
}

.detail-from input {
  font-size: 18px;
  color: #4a4a4a;
  height: 53px;
  border: 3px solid #d9d9d9;
  padding: 5px 15px;
  width: 100%;
  background: #e5e5e5;
}

.group-wrap.edit-profile .form-input select {
  font-size: 18px;
  color: #4a4a4a;
  height: 53px;
  border: 3px solid #d9d9d9;
}

.detail-from .edit-profile input {
  background: #fff;
}

.detail-from .edit-profile input::placeholder {
  opacity: 1;
}

.form-input span {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 7px;
  display: block;
  text-align: left;
}

.detail-from input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.2;
}

.detail-from input::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.2;
}

.detail-from input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.2;
}

.detail-from input:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.2;
}

.detail-from .form-title {
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 40px;
  color: #4a4a4a;
  font-weight: 600;
  text-transform: capitalize;
}

.detail-from .form-input {
  margin-bottom: 30px;
  position: relative;
}

.detail-from .group-wrap {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}

.detail-from .group-wrap .form-input {
  width: 48%;
}

.detail-from button {
  /* width: auto; */
  text-transform: uppercase;
  margin-left: 0;
}

.signup-form .detail-from button.shop-now {
  padding: 7px 50px;
}

.detail-from button.search-btn {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  height: 72px;
  background: #a292b3;
  width: 133px;
  border: 1px solid #a292b3;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-align: center;
  padding: 7px;
}

.detail-from button.search-btn:hover {
  background: #fff;
  color: #a292b3;
}

.detail-from .search-field {
  display: flex;
}

.detail-from .search-field input {
  width: calc(100% - 133px);
}

.profile-details.contact-details {
  padding: 60px 0 100px;
}

.contact-details .btn-wrap {
  text-align: center;
  padding: 140px 0 20px;
}

.profile-details.contact-details .detail-from {
  max-width: 552px;
  width: 100%;
  padding: 40px 3.5%;
  background: #fff;
  border: 1px solid #707070;
}

.wardrobe-card {
  text-align: center;
  min-height: 525px;
}

.wardrobe-card .add-icn {
  width: 66px;
  height: 66px;
  display: inline-block;
}

.wardrobe-wrap {
  display: flex;
  justify-content: space-between;
}

.wardrobe-wrap .pause-btn {
  width: auto;
  min-width: 359px;
}

/* Profile four start */
.profile-account {
  display: flex;
  align-items: center;
  margin-bottom: 115px;
  position: relative;
}

.manage-profile .profile-account {
  display: flex;
  flex-wrap: wrap;
}

.manage-profile .profile-account .acc-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.profile-account .account-desc {
  text-align: center;
  width: 25%;
}

.profile-account .account-desc.acc-desc {
  width: 26.05%;
  text-align: left;
}

.profile-account .acc-name {
  font-size: 48px;
  letter-spacing: 1.1px;
  font-weight: normal;
  font-family: "Ogg";
  color: #4a4a4a;
  text-align: left;
  margin-bottom: 25px;
}

.profile-account .verify-btn {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #d16d38;
  border: 1px solid #d16d38;
  transition: all 0.3s ease-in-out;
  max-width: 339px;
  width: 100%;
  padding: 5px 10px;
  letter-spacing: -0.7px;
}

.profile-account .verify-btn:hover {
  background: none;
  color: #d16d38;
}

.profile-account .address-txt {
  font-size: 20px;
  font-family: "Roboto";
  color: #4a4a4a;
  text-align: left;
}

.profile-account .total-txt {
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto";
  color: #4a4a4a;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.profile-account .total-amount {
  font-size: 44px;
  font-weight: 600;
  color: #d16d38;
}

.profile-account .profile-img {
  height: 150px;
  width: 150px;
  padding: 10px;
  background-size: 100% 100%;
  margin: 0 auto -92px;
  background-color: #ffffff;
  border-radius: 50%;
}

.profile-account .profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}

.profile-account .acc-info {
  width: 60%;
}

.profile-account .info-wrap {
  display: flex;
  justify-content: space-between;
  /* width: 58.8%; */
  width: 68.3%;
  padding-top: 11px;
}

.profile-calendar {
  padding: 60px 0;
  display: flex;
  justify-content: space-between;
}

.profile-calendar .calendar-title {
  font-size: 26px;
  font-weight: 600;
  color: #4a4a4a;
}

.profile-calendar .landing-calendar,
.profile-calendar .rental-calendar {
  width: 48%;
  min-height: 300px;
}

.invite-profilewrap {
  border-bottom: 1px solid #cecece;
}

.invite-list {
  margin-bottom: 75px;
}

.invite-list li {
  display: inline-block;
  text-align: center;
  padding-bottom: 40px;
  margin-right: 8%;
}

.invite-list figure:after {
  position: absolute;
  width: 30px;
  height: 6px;
  background: #a292b3;
  top: 50%;
  transform: translateY(-50%);
  right: -40%;
  content: "";
}

.invite-list li:last-child figure:after {
  display: none;
}

.invite-list button {
  font-size: 14px;
  font-weight: 600;
  color: #d16d38;
  background: #f7d9b7;
  border: 1px solid #f7d9b7;
  transition: all 0.3s ease-in-out;
  padding: 8px 26px;
  text-transform: uppercase;
}

.invite-list button:hover {
  background: none;
  border-color: #d16d38;
}

.invite-list figure {
  margin-bottom: 30px;
  position: relative;
}

.invite-profile {
  padding: 40px 40px 130px;
  border: 1px solid #707070;
  margin-bottom: 95px;
}

.invite-profile .invite-title {
  font-size: 44px;
  font-weight: normal;
  font-family: "Ogg";
  color: #4a4a4a;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.invite-profile .invite-desc {
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto";
  line-height: 1.5;
  margin-bottom: 60px;
}

.invite-form {
  display: flex;
}

.invite-form .search-field {
  width: 43.5%;
  margin: 0 5% 0 0;
}

.profile-wrapper .react-calendar button {
  padding: 0.75em 0.5em;
}

.profile-wrapper .react-calendar {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  transform: translateY(3px);
}

.profile-settings .setting-icn {
  width: 32px;
  position: relative;
}

.profile-settings .notification-icn {
  width: 30px;
}

.profile-wrapper .plp-header {
  align-items: center;
  margin-bottom: 63px;
}

.profile-wrapper .plp-product-screen {
  width: 100%;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.profile-wrapper .addmore-card,
.profile-wrapper .plp-costume-box {
  width: 25%;
  margin-right: 0;
  padding: 0 15x;
}

.profile-wrapper .addmore-card {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-wrapper .plp-costume-action {
  top: 2px;
  left: 0;
  width: 100%;
}

.profile-wrapper .plp-costume-action span {
  padding: 10px 5px;
  margin: 0 3px;
  float: right;
}

.profile-wrapper .plp-costume-action span.new {
  float: left;
  padding: 10px;
}

.addmore-card .add-icn {
  display: block;
  width: 136px;
  height: 136px;
  margin: 0 auto 30px;
  cursor: pointer;
}

.addmore-card .add-txt {
  font-size: 40px;
  font-weight: normal;
  font-family: "Ogg";
  margin-bottom: 40px;
  color: #4a4a4a;
}

.profile-rating {
  display: inline-block;
}

.profile-rating .star-icn {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 15px;
  vertical-align: middle;
}

.profile-rating .rate {
  display: inline-block;
  font-size: 20px;
  font-weight: normal;
  text-decoration: underline;
  font-family: "Roboto";
  padding: 0px 0 0 10px;
}

.form-title.billing-title {
  padding-top: 40px;
  border-top: 3px solid rgba(112, 112, 112, 0.11);
}

.billing-checked {
  display: block;
  position: relative;
  margin-bottom: 30px;
}

.billing-checked span {
  padding-left: 30px;
  display: inline-block;
  font-size: 14px;
  font-family: "Roboto";
}

.billing-checked input[type="checkbox"] {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}

.profile-wrapper .select-opt__input input {
  height: auto !important;
}

.plp-product-screen .plp-costumes {
  /* height: 278px;
  padding: 15px; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plp-product-screen .plp-costumes img {
  max-height: 100%;
}

.seller-profile-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profile-account-wrap {
  width: 25.5%;
}

.profile-account-wrap .profile-account {
  border: none;
  display: block;
  padding: 55px 50px 42px;
  text-align: center;
  background-color: #faefe9;
}

.profile-account-wrap .profile-account .profile-img {
  margin: 0 auto 50px;
  width: 300px;
  height: 300px;
}

.profile-account-wrap .profile-account .acc-name {
  font-size: 50px;
  text-align: center;
  margin-bottom: 14px;
}

.profile-account-wrap .profile-account .acc-info {
  width: 100%;
}

.profile-account-wrap .profile-account .verify-btn {
  margin-bottom: 55px;
}

.profile-account-wrap .profile-account .info-wrap {
  flex-wrap: wrap;
}

.profile-account-wrap .profile-account .account-desc {
  width: 50%;
  margin-bottom: 58px;
}

.profile-account-wrap .follow-user {
  padding: 0 18%;
}

.profile-account-wrap .follow-user .verify-btn {
  padding: 21px 20px;
  border-radius: 50px;
  border-width: 3px;
  margin-bottom: 30px;
}

.profile-account-wrap .follow-user .verify-btn:last-child {
  margin-bottom: 0;
}

.rating-popup {
  width: 790px;
  padding: 40px 60px;
}

.rating-popup .icon-close {
  top: 16px;
  right: 18px;
}

.rating-popup h2 {
  font-weight: normal;
  font-family: "Ogg";
  font-size: 43px;
  color: #d16d38;
  margin-bottom: 48px;
}

.rating-popup p {
  font-family: "Roboto";
  font-size: 24px;
  color: #4a4a4a;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 36px;
}

.rating-popup .verify-btn {
  padding: 23px 45px;
  position: relative;
  display: block;
  width: 82%;
  margin: 0 auto;
  background: #d16d38;
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}

.rating-popup .verify-btn::after {
  content: "";
  background: url(/assets/images/sprites.png) -266px -17px no-repeat;
  width: 23px;
  height: 19px;
  position: absolute;
  right: 28px;
  top: 28px;
}

.rating {
  height: auto;
  text-align: left;
  margin-bottom: 20px;
}

.rating input {
  position: absolute;
}

.rating__item {
  display: inline;
  font-size: 0;
}

.rating__item::before {
  font-size: 50px;
  content: "★";
  color: #b7b7b7;
}

.rating__input {
  position: absolute;
  visibility: hidden;
}

/* .rating__item:hover:before {
  color: #d16d38;
} */
.rating:hover .rating__item::before {
  color: #f86202;
}

.rating__item:hover~.rating__item::before {
  color: #b7b7b7;
}

.rating__item--selected::before {
  color: #f86202;
}

.seller-prod-tags .tags-wrap {
  margin-bottom: 50px;
}

.seller-prod-tags .seller-prods {
  margin: 0 -16px;
}

.seller-prod-tags .plp-product-screen {
  padding: 0;
  justify-content: flex-start;
}

.seller-prod-tags .plp-costume-block {
  border: 1px solid #d16d38;
}

.seller-prod-tags .plp-costume-box {
  width: 33.33%;
  padding: 0 16px;
  margin-right: 0;
}

.seller-prod-tags {
  width: 72%;
}

.product-head {
  width: 100%;
  text-align: center;
}

.product-head h6 {
  font-size: 16px;
  color: #d16e39;
  margin-top: 20px;
}

.product-head .product-img {
  width: 200px;
  margin: 0 auto;
}

.breadcrumb-wrap li {
  padding: 0 8px;
}

.breadcrumb-wrap li:first-child {
  padding-left: 0;
}

.manage-profile .blogtags-sec {
  margin: 0;
}

.manage-profile .plp-header {
  padding: 0px 60px;
  margin: 0 auto 63px;
  max-width: 1920px;
}

.blogtags-sec .breadcrumb-wrap {
  padding: 0;
}

.manage-profile .profile-settings .setting-icn {
  margin: 0;
}

.manage-profile .plp-product-screen {
  padding: 0;
  font-family: "Roboto";
}

.edit-maintain figure {
  max-width: 500px;
  max-height: 500px;
}

.edit-maintain.article-head figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.change-reset-file {
  margin-right: 0;
}

.blogtags-sec .edit-maintain h2 {
  margin-bottom: 35px;
}

/* body {
  background: url(/assets/images/bg1.jpg) center top no-repeat;
} */
/* Responsive css Start */
.profile-data {
  padding-top: 115px;
  background: #622bc5;

}

.profile-settings .setting-icn .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
}

.profile-headTitle {
  font-size: 62px;
  color: #4a4a4a;
  padding: 8px 40px 0 0;
}

.profile-wrapper {
  /* font-family: "Roboto"; */
  padding-bottom: 50px;
}

.custom-reports {
  margin-bottom: 40px;
}

.custom-sales {
  max-width: 760px;
  margin: 0 auto;
}

.Sales-page {
  padding: 30px;
  background: #fff;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}

.cal-fld {
  width: 40%;
}

.rel-art h4 {
  font-size: 28px;
  text-align: left;
  font-weight: 500;
  margin-bottom: 30px;
}

.related-articles {
  margin-bottom: 30px;
  margin-top: 30px;
}

.related-articles h2 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
}

.cal-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.cal-fld span {
  display: block;
  text-align: left;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  color: #6a6a6a;
  font-weight: 600;
}

.cal-fld input {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
}

.profile-account .acc-name {
  display: flex;
  align-items: center;
}

.profile-rating {
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.acc-desc .profile-rating .star-icn {
  height: auto;
}

.acc-desc .profile-rating .star-icn img {
  display: block;
}

/* ###########  seler  profile  start  ################################## */

/* ###################### seler  profile end  ############################################## */

.feature-card {
  width: 32%;
  border: 1px solid #cecece;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.orange {
  background: #f2e7e0;
}

.purple {
  background: #eadcee;
}

.green {
  background: #dfeed9;
}

.feature-card h4 {
  margin-bottom: 15px;
}

.feature-card h6 {
  padding-bottom: 0;
}

.feature-card span {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
}

.feature-buttton {
  background: cornflowerblue;
}

#article-tbl {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#article-tbl td,
#article-tbl th {
  border: 1px solid #ddd;
  padding: 15px 10px;
  font-size: 15px;
}

#article-tbl tr:nth-child(odd) td {
  background: #fff;
}

#article-tbl tr:nth-child(even) td {
  background: #ede5f0;
}

#article-tbl th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: none;
  color: #4c4c4c;
  border: 0;
  padding: 10px 0;
}

#article-tbl td button {
  color: #f86202;
  font-size: 15px;
  transition: all 0.5s;
}

#article-tbl td button:hover {
  opacity: 0.7;
}

#article-tbl td button i {
  margin-right: 5px;
}

.report-downlaod-btn {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  position: relative;
  transition: all 0.5s ease;
  padding: 10px 23px;
  background: #f86202;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #f86202;
  transition: all 0.5s ease 0s;
}

.report-downlaod-btn:hover {
  background: transparent;
  color: #f86202;
}

.popCalendar {
  position: relative;
}

.popCalendar i {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #b4b4b4;
  font-size: 20px;
}

.selldetails-wrap div {
  position: relative;
}

.selldetails-wrap span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 20px;
  width: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding-bottom: 1px;
  background: #c0c0c0;
  border-radius: 40px;
  text-align: center;
}

.selldetails-wrap .active-tab span {
  background: #f86203;
}

.profile-div .profile-img {
  height: 108px;
  width: 108px;
  background-size: 100% 100%;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 6px;
}

.profile-div .profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
}

.mob-prof-nav-icn {
  display: none;
}

.mob-prof-nav-icn i,
.profile-div .profile-img i {
  position: absolute;
  bottom: 4px;
  right: -4px;
  font-size: 25px;
  color: #622bc5;
  background: #fff;
  border-radius: 40px;
  border: 2px solid #fff;
  cursor: pointer;
}

.edit-prof {
  background: #eaeaea;
  padding-top: 50px;
}

.profile-main-div {
  display: flex;
  justify-content: space-between;
}

.prof-nav {
  background: #fff;
  padding: 0 20px;
  width: 20%;
  margin-right: 2%;
  padding-bottom: 15px;
  border-radius: 11px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
}

@media all and (min-width: 1700px) {
  .prof-nav {
    width: 18%;
  }
}

.right-prof-sec {
  background: #fff;
  width: 78%;
  padding: 30px 0;
  border-radius: 11px;
  box-shadow: 0 4px 10px 6px rgba(0, 0, 0, 0.03);
}

@media all and (min-width: 1700px) {
  .right-prof-sec {
    width: 80%;
  }
}

.right-prof-sec .container {
  padding: 0 2%;
  height: 100%;
}

.profile-left-panel .profile-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  padding-top: 20px;
}

.user-detail-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.left-item-list .active-tab {
  color: #F5C139;
  opacity: 1;
  position: relative;
}

.left-item-list .active-tab ::after {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #F5C139;
  content: "";
  left: 0;
}

.left-item-list>div,
.left-item-list>a {
  text-transform: capitalize;
  padding: 10px 0;
  transition: all 0.3s ease-in-out;
  margin-bottom: 8px;
}

@media only screen and (max-width: 1699px) {

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 36px;
  }

  .profile-account .address-txt {
    font-size: 17px;
  }

  .profile-calendar .calendar-title {
    font-size: 22px;
  }

  .invite-profile .invite-desc {
    font-size: 17px;
  }

  .invite-list li {
    width: 12%;
  }

  .detail-from input {
    font-size: 18px;
    height: 53px;
  }

  .detail-from .form-input {
    margin-bottom: 24px;
  }

  .detail-from .form-title {
    font-size: 17px;
    margin-bottom: 30px;
  }

  .detail-from button.search-btn {
    font-size: 19px;
    height: 60px;
  }

  .wardrobe-card {
    min-height: 450px;
  }

  .wardrobe-card .add-icn {
    width: 50px;
    height: 50px;
  }

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 36px;
  }

  .profile-account .address-txt {
    font-size: 17px;
  }

  .profile-calendar .calendar-title {
    font-size: 22px;
  }

  .invite-profile .invite-desc {
    font-size: 17px;
  }

  .invite-list li {
    width: 12%;
  }

  .addmore-card .add-icn {
    width: 100px;
    height: 100px;
  }

  .addmore-card .add-txt {
    font-size: 32px;
  }

  .profile-settings .setting-icn {
    width: 26px;
    position: relative;
  }

  .profile-settings .setting-icn:hover .tooltiptext {
    visibility: visible;
  }

  .profile-settings .setting-icn .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  .profile-settings .setting-icn .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 110%;
  }

  .profile-settings .notification-icn {
    width: 24px;
  }

  .profile-wrapper .select-opt__control {
    height: 50px;
    padding-left: 10px;
    font-size: 19px;
  }

  .profile-account-wrap .profile-account .profile-img {
    width: 250px;
    height: 250px;
  }

  .profile-account-wrap .follow-user {
    padding: 0;
  }
}

@media only screen and (max-width: 1499px) {
  .profile-div .profile-img {
    height: 100px;
    width: 100px;
  }

  .profile-wrapper .profile-name {
    font-size: 20px;
  }

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 32px;
  }

  .invite-profile .invite-desc {
    font-size: 16px;
  }

  .invite-list figure:after {
    width: 20px;
    height: 4px;
  }

  .invite-profile {
    padding: 40px 20px 70px;
  }

  .invite-list button {
    font-size: 13px;
  }

  .detail-from input {
    font-size: 16px;
    height: 45px;
    border-width: 1px;
  }

  .group-wrap.edit-profile .form-input select {
    font-size: 16px;
    height: 45px;
    border-width: 1px;
  }

  .detail-from button.search-btn {
    font-size: 17px;
    height: 52px;
  }

  .wardrobe-card {
    min-height: 350px;
  }

  .profile-details .wardrobe-wrap .detail-from {
    max-width: 400px;
  }

  .wardrobe-card .add-icn {
    width: 45px;
    height: 45px;
  }

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 32px;
  }

  .invite-profile .invite-desc {
    font-size: 16px;
  }

  .invite-list figure:after {
    width: 20px;
    height: 4px;
  }

  .invite-profile {
    padding: 40px 20px 70px;
  }

  .invite-list button {
    font-size: 13px;
  }

  .profile-account .acc-info {
    width: 65%;
  }

  .profile-settings .setting-icn {
    width: 24px;
    position: relative;
  }

  .profile-settings .setting-icn:hover .tooltiptext {
    visibility: visible;
  }

  .profile-settings .setting-icn .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  .profile-settings .setting-icn .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 110%;
  }

  .profile-settings .notification-icn {
    width: 22px;
  }

  .profile-account .account-desc.acc-desc {
    margin-bottom: 40px;
  }

  .addmore-card .add-icn {
    width: 80px;
    height: 80px;
  }

  .addmore-card .add-txt {
    font-size: 28px;
  }

  /* .profile-rating .rate {
    font-size: 18px;
  } */
  .profile-rating .star-icn {
    width: 22px;
    height: 22px;
  }

  .profile-wrapper .select-opt__control {
    height: 50px;
    font-size: 17px;
  }

  .profile-account-wrap .profile-account {
    padding: 30px 20px;
  }

  .profile-account-wrap .profile-account .profile-img {
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
  }

  .profile-account-wrap .profile-account .acc-name {
    font-size: 30px;
  }

  .profile-account-wrap .profile-account .verify-btn {
    margin-bottom: 25px;
  }

  .profile-account-wrap .profile-account .account-desc {
    margin-bottom: 30px;
  }

  .profile-account-wrap .follow-user .verify-btn {
    padding: 15px 20px;
  }

  .profile-data {
    padding-top: 50px;
  }

  .custom-reports h5 {
    font-size: 22px;
  }

  .custom-reports span {
    font-size: 15px;
    line-height: 20px;
  }

  .related-articles h2 {
    font-size: 22px;
  }

  /* Changes */
  /* .prof-nav{
    width: 19%;
  }
  .right-prof-sec{
    width: 76%;
  } */
}

@media only screen and (max-width: 1199px) {
  .order-bottom-detail {
    flex-direction: column;
  }

  .detail-from input {
    font-size: 16px;
  }

  .wardrobe-card .add-icn {
    width: 40px;
    height: 40px;
  }

  .wardrobe-wrap .pause-btn {
    min-width: 300px;
  }

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 28px;
  }

  .profile-account .total-txt {
    font-size: 15px;
  }

  .profile-account .address-txt {
    font-size: 16px;
  }

  .profile-calendar .calendar-title {
    font-size: 20px;
  }

  .invite-list button {
    padding: 8px 15px;
  }

  .invite-profile {
    padding: 40px 20px 50px;
    margin-bottom: 50px;
  }

  .profile-account .profile-img {
    width: 175px;
    height: 175px;
    padding: 15px;
  }

  .addmore-card .add-icn {
    width: 60px;
    height: 60px;
  }

  .addmore-card .add-txt {
    font-size: 24px;
  }

  .profile-wrapper .select-opt__control {
    font-size: 16px;
  }

  .profile-account-wrap .profile-account .profile-img {
    width: 180px;
    height: 180px;
  }

  .manage-profile .plp-header,
  .manage-profile .container {
    padding: 0 14px;
  }

  .plp-costume-box {
    padding: 0 5px;
  }

  .manage-profile .plp-costume-box {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .action-item button {
    width: 48%;
    margin-right: 7px;
  }

  .ac-head-wrap {
    flex-direction: column;
  }

  .profile-wrapper .plp-header {
    padding-top: 30px;
  }

  .profile-wrapper .profile-name {
    font-size: 16px;
  }

  .profile-details .wardrobe-wrap .detail-from {
    max-width: 340px;
  }

  .wardrobe-card .add-icn {
    width: 35px;
    height: 35px;
  }

  .wardrobe-wrap .pause-btn {
    min-width: 240px;
  }

  .invite-list li {
    width: 25%;
  }

  .invite-list figure:after {
    right: -22%;
  }

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 21px;
  }

  .invite-profile .invite-desc,
  .profile-account .total-txt {
    font-size: 14px;
  }

  .profile-calendar .calendar-title {
    font-size: 18px;
  }

  .profile-wrapper .article-head {
    padding-top: 30px;
  }

  .profile-account {
    margin-bottom: 40px;
  }

  .profile-wrapper .tags {
    display: flex;
  }

  .profile-account .profile-img {
    width: 85px;
    height: 85px;
    padding: 6px;
    margin-top: -103px;
  }

  .profile-account .account-desc.acc-desc {
    margin-bottom: 20px;
  }

  .addmore-card .add-icn {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .addmore-card .add-txt {
    font-size: 22px;
  }

  .profile-account-wrap,
  .seller-prod-tags {
    width: 100%;
  }

  .profile-data {
    padding-top: 75px;
  }

  .profile-account .total-txt {
    font-size: 12.5px;
  }

  .profile-account .info-wrap {
    width: 73.3%;
    padding-top: 5px;
  }

  .manage-profile .tags-wrap {
    padding: 0 20px;
    margin-bottom: 40px;
  }

  .manage-profile .plp-costume-box {
    margin-bottom: 45px !important;
  }

  .profile-headTitle {
    font-size: 40px;
  }

  /* Changes */
  .prof-nav {
    width: 26%;
  }

  .right-prof-sec {
    width: 72%;
  }
}

@media only screen and (max-width: 767px) {
  .timline {
    flex-direction: column;
  }

  .timline li {
    width: auto;
    height: 75px;
  }

  .timline li::after {
    transform: rotate(90deg);
    width: 75px;
    left: -26px;
    right: auto;
    top: 43px;
  }

  .timeline-text {
    bottom: auto;
    left: 31px;
    top: 3px;
  }

  .features-wrapper {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .action-item h4,
  .ac-statics h4,
  .perf-statics h4 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .sort-drop {
    width: 100%;
  }

  .action-item button {
    width: 100%;
    margin-bottom: 10px;
  }

  .ac-head-wrap h4 span {
    display: block;
    margin-top: 10px;
  }

  .ac-head-wrap {
    flex-direction: column;
  }

  .prof-nav {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .right-prof-sec {
    width: 100%;
    padding: 20px 0;
  }

  .profile-main-div {
    flex-direction: column;
  }

  .profile-details .detail-row {
    display: block;
  }

  .profile-details .detail-colmn {
    width: 100%;
  }

  .detail-from input {
    font-size: 15px;
    height: 42px;
    font-family: 'Tw Cen MT Std';
  }

  .group-wrap.edit-profile .form-input select {
    font-size: 15px;
    height: 42px;
  }


  .signup-form .detail-from button.shop-now {
    padding: 7px 25px;
  }

  .detail-from .group-wrap .form-input {
    width: 100%;
  }

  .detail-from .form-input {
    margin-bottom: 23px;
  }

  .detail-from button.search-btn {
    font-size: 15px;
    height: 42px;
  }

  .profile-details .detail-from {
    padding: 40px 0 50px;
  }

  .profile-details.contact-details {
    padding: 40px 0 60px;
  }

  .contact-details .btn-wrap {
    text-align: center;
    padding: 40px 0 20px;
  }

  .wardrobe-wrap {
    display: block;
    text-align: center;
  }

  .profile-details .wardrobe-wrap .detail-from {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .profile-account {
    display: block;
    padding: 15px 15px 0px;
  }

  .profile-calendar {
    display: block;
  }

  .profile-account .account-desc {
    width: 50%;
    margin-bottom: 25px;
  }

  .profile-account .address-txt,
  .profile-account .acc-name {
    text-align: center;
  }

  .profile-account .total-txt {
    margin-bottom: 10px;
  }

  .invite-list li {
    width: 50%;
    margin: 0;
    padding: 15px 30px;
  }

  .invite-list figure:after {
    display: none;
  }

  .invite-form {
    display: block;
  }

  .invite-form .search-field {
    width: 100%;
    margin-right: 0;
  }

  .invite-list {
    margin-bottom: 25px;
  }

  .profile-calendar .landing-calendar,
  .profile-calendar .rental-calendar {
    width: 100%;
  }

  .profile-account .profile-img {
    /* margin: -107px auto 20px; */
    width: 137px;
    height: 137px;
    padding: 17px;
  }

  .profile-account .acc-info {
    width: 100%;
  }

  .profile-account .account-desc.acc-desc {
    text-align: center;
  }

  .profile-wrapper .addmore-card,
  .profile-wrapper .plp-costume-box {
    width: 48%;
  }

  .profile-account .info-wrap {
    flex-flow: wrap;
  }

  .profile-wrapper .blogtags-sec .tags {
    display: block;
  }

  .profile-wrapper .blogtags-sec {
    padding: 0;
  }

  .profile-wrapper .tags-wrap {
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .profile-wrapper .plp-header {
    display: flex;
    padding: 0 15px 0 15px;
    margin: 0;
    flex-wrap: wrap;
  }

  .profile-wrapper .select-opt__control {
    font-size: 15px;
    height: 42px;
  }

  .profile-data {
    padding-top: 110px;
  }

  .profile-edithead {
    font-size: 36px;
  }

  .feature-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .feature-card-row {
    flex-direction: column;
  }

  .right-prof-sec .blogtags-sec>.container {
    padding: 0;
  }

  .mob-prof-nav-icn {
    display: inline-block;
    margin-bottom: 10px;
  }

  .mob-prof-nav-icn i {
    position: static;
  }

  .prof-nav.hidden {
    display: none;
  }

  .prof-nav.show {
    display: block;
  }
}

@media only screen and (max-width: 1023px) {
  .chat-fld-wrap .message {
    width: 100%;
    margin: 0;
    max-height: none;
  }

  .item-img {
    margin: 0 auto 20px;
    width: 200px;
  }

  .chat-fld-wrap .contacts-chat {
    width: 100%;
    margin: 30px 0;
    max-height: none;
  }

  .chat-fld-wrap .full-item-detail {
    width: 100%;
    max-height: none;
    margin: 0;
  }

  .btns-download {
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .btns-download~.shop-now.msg-btn {
    margin-left: 0;
  }

  .ret-ref .switch-wrapper.action-btn .header-sell {
    width: 100%;
  }

  .switch-wrapper.action-btn {
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .switch-wrapper.action-btn .header-sell {
    margin-right: 0;
    width: auto;
  }

  .shop-now.msg-btn {
    margin: 10px auto 0;
  }

  .main-reasons {
    margin-top: 0;
  }

  .timeline-timings {
    bottom: auto;
    left: 32px;
    top: 19px;
    white-space: nowrap;
  }

  .order-left {
    width: 100%;
    margin-bottom: 20px;
  }

  .order-top {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .order-bottom-right {
    width: 100%;
  }

  .order-bottom-left {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .order-prod-detail {
    padding: 0;
  }

  .features-wrapper .dual-features {
    width: 100%;
  }

  .quantity-price {
    width: 100%;
  }

  .features-wrapper {
    flex-direction: column;
  }

  .features-wrapper .dual-features .color-quant {
    padding-right: 0;
  }

  .timline {
    flex-direction: column;
    padding-bottom: 0;
  }

  .timline li {
    width: auto;
    height: 75px;
  }

  .timline li::after {
    transform: rotate(90deg);
    width: 75px;
    left: -26px;
    right: auto;
    top: 43px;
  }

  .timeline-text {
    bottom: auto;
    left: 31px;
    top: 3px;
  }

  .down-btn {
    margin-top: 10px;
  }

  .order-timeline .products-details li {
    display: flex;
    width: 100%;
  }

  .order-timeline .products-details li span {
    margin-right: 10px;
  }

  .profile-wrapper .tags-wrap .container .tags {
    width: auto;
    margin: 0 -15px;
    justify-content: space-between;
  }

  .prod-toggle {
    width: 100%;
    margin: 0 auto 23px;
  }

  .buy-prod {
    display: block;
  }

  .prod-detail.product-orderinfo .prod-link {
    padding: 0;
    display: block;
    text-align: center;
  }

  .prod-title.prod-toggle {
    text-align: center;
  }

  .rating-msg {
    justify-content: center;
    padding: 0;
  }

  .rating-msg .shop-now.msg-btn {
    margin: 0;
  }

  .prod-title.prod-toggle .del-details .status-details {
    justify-content: center !important;
  }

  .prod-detail.product-orderinfo .prod-link .product-switch {
    padding: 0;
  }

  .prod-detail.product-orderinfo .prod-link>div {
    padding: 0;
  }

  .prod-detail.product-orderinfo .prod-link .home-detail {
    justify-content: center;
    padding: 0;
  }

  .prod-detail.product-orderinfo {
    width: 100%;
    padding-left: 0;
  }

  .est-delivery {
    bottom: 7px;
    left: 0;
    text-align: center;
    right: 0;
  }

  .blogtags-sec .prod-img,
  .prod-img a {
    margin-left: auto;
    margin-right: auto;
  }

  .est-deliveries {
    position: static;
  }

  .buy-prod {
    position: relative;
  }

  .feature-card-row {
    flex-direction: column;
  }

  .feature-card {
    width: 100%;
    margin-bottom: 10px;
  }

  .table-values {
    flex-direction: column;
    align-items: center;
  }

  .table-values .name-value {
    width: 100%;
    text-align: center;
  }

  .table-values .image-value {
    margin-bottom: 12px;
  }

  .table-values .redeem-value {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }

  .table-values .percent-value {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }

  .table-values .valid-value {
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .table-values .valid-value .text {
    margin: 0 5px;
  }

  .table-values .valid-value .d-none {
    width: 100%;
    margin: 0 0 3px 0;
  }

  .table-values .btn-value {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .table-values .btn-value .create-btn {
    width: auto;
    min-width: 90px;
  }

  .table-values .d-none {
    font-weight: 500;
    margin-right: 7px;
  }
}

@media only screen and (max-width: 575px) {
  .invite-list li {
    width: 100%;
  }

  .profile-wrapper .addmore-card,
  .profile-wrapper .plp-costume-box {
    width: 100%;
  }

  .manage-profile .container {
    padding: 0 15px;
  }

  .profile-headTitle {
    font-size: 35px;
    padding: 0 30px 0 0;
  }

  .profile-account .profile-img {
    width: 91px;
    height: 91px;
    padding: 5px;
  }

  .profile-account .account-desc.acc-desc {
    width: 100%;
    text-align: left;
  }

  .profile-account .info-wrap {
    width: 100%;
  }

  .profile-edithead {
    font-size: 30px;
    width: 45%;
  }

  .edit-breadcrumb {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}

.detail-from .flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-item-list>div,
.left-item-list>a {
  /* cursor: pointer; */
}

.left-item-list {
  display: flex;
  flex-direction: column;
}

.notification-card .notification-img {
  top: 50%;
  transform: translateY(-50%);
  left: 9px;
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.notification-card .msg {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2em;
  line-height: 1.5;
  font-family: "Roboto";
}

.notification-card .title {
  display: inline-block;
  padding: 5px 10px;
  background: rgba(193, 193, 193, 0.45);
  border-radius: 7px;
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto";
  letter-spacing: 0.5px;
  margin-top: 13px;
}

.prod-name-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.reason-checkbox-list label {
  text-align: left;
  font-size: 18px;
  font-family: "Roboto";
  padding-left: 30px;
  display: block;
  position: relative;
}

.reason-checkbox-list label input {
  position: absolute;
  top: 7px;
  left: 0;
}

textarea {
  width: 100%;
  border: 1px solid #e5e5e5;
}

.loader-style {
  position: inherit;
  top: 200px;
}


.vibration {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.red {
  color: red;
}

.zip-input {
  border: none;
  font-size: 18px;
  color: rgb(76, 76, 76);
}

.zip-input:focus {
  outline: none;
}

.slider-input {
  width: 100%;
}

.distance p {
  text-align: right;
  color: #733381;
  font-size: 17px;
  margin-top: 6px;
}

.slider-input.slider::-webkit-slider-thumb {
  border-radius: 50%;
  background: #733381;
  box-shadow: 0 4px 10px 6px rgb(0, 0, 0, 0.07);
}

.slider-input.slider::-moz-range-thum {
  background: #733381;
  border-radius: 50%;
  box-shadow: 0 4px 10px 6px rgb(0, 0, 0, 0.03);
}

.slider-input.slider {
  height: 3px;
  -webkit-appearance: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  background: #733381;
  opacity: 0.8;
}

.range-marks {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 4px;
}

.range-marks li {
  width: 2px;
  height: 9px;
  display: block;
  background: rgba(128, 128, 128, 0.18);
}

.forget-block {
  flex-grow: 1;
}

.right-prof-sec.right-prof-sec.profile-right-content .detail-from .form-title {
  margin-bottom: 15px;
  font-family: 'Inter';
}

.right-prof-sec.right-prof-sec.profile-right-content .form-input span {
  font-family: 'Inter';
}

.detail-from input {
  border-radius: 6px;
}

.group-wrap.edit-profile.change-passwrd-my-profile .form-wrapp {
  position: relative;
}

.group-wrap.edit-profile.change-passwrd-my-profile i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.header-sell.change-passwrd-sell {
  text-transform: capitalize;
}