body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.useLocation {
  background: #622bc5;
  color: #fff;
  padding: 13px 10px;
  font-size: 18px;
  margin-bottom: 20px;
  transition: all 0.5s;
  border-radius: 7px;
  border: 1px solid #622bc5;
}

.useLocation:hover {
  color: #622bc5;
  background: transparent;
}

.popup-body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  align-content: space-around;
  padding: 60px 0 30px;
}

.cont-shopping {
  background: rgb(248, 98, 2);
  color: #fff;
  padding: 13px 10px;
  font-size: 18px;
  transition: all 0.5s;
  border-radius: 7px;
  border: 1px solid rgb(248, 98, 2);
}

.zip-code {
  height: 49px;
  font-size: 15px;
  margin-bottom: 20px;
  padding: 0 15px;
  border-radius: 7px;
  text-align: center;
  border-width: 1px;
  font-family: "Roboto";
}

.make-offer-popup {
  font-family: "Roboto";
}

.popup-body h3 {
  font-family: "Roboto";
}

.useLocation {
  font-family: "Roboto";
}

.cont-shopping {
  font-family: "Roboto";
}

.popup-body .loc-img {
  margin: 0 0 24px 0;
}

.cont-shopping:hover {
  background: transparent;
  color: rgb(248, 98, 2);
}


/* Slider.css */

.slider {
  margin-bottom: 40px;
}

.slider label {
  color: #888;
  font-size: 0.8rem;
  margin-bottom: 20px;
  display: block;
  text-transform: capitalize;
}

.input-range__slider {
  background: #e27a3f;
  border: none;
}

.input-range__track {
  background: #ddd;
}

.input-range__track--active {
  background: #e27a3f;
}

.input-range__label--value .input-range__label-container {
  background: #e27a3f;
  color: white;
  font-size: 0.65rem;
  padding: 2px 5px;
  border-radius: 2px;
}

.input-range__label--min .input-range__label-container,
.input-range__label--max .input-range__label-container {
  font-size: 0.65rem;
  color: #888;
  left: 0;
}

.input-range__label--max .input-range__label-container {
  left: 25%;
}
/* W3.CSS 4.15 December 2020 by Jan Egil and Borge Refsnes */
/* html {
    box-sizing: border-box
}

*,
*:before,
*:after {
    box-sizing: inherit
} */

/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
/* html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
    display: block
}

summary {
    display: list-item
}

audio,
canvas,
progress,
video {
    display: inline-block
}

progress {
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],
template {
    display: none
}

a {
    background-color: transparent
}

a:active,
a:hover {
    outline-width: 0
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: bolder
}

dfn {
    font-style: italic
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

figure {
    margin: 1em 40px
}

img {
    border-style: none
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

button,
input,
select,
textarea,
optgroup {
    font: inherit;
    margin: 0
}

optgroup {
    font-weight: bold
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    border-style: none;
    padding: 0
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
    outline: 1px dotted ButtonText
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: .35em .625em .75em
}

legend {
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

textarea {
    overflow: auto
}

[type=checkbox],
[type=radio] {
    padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}


html,
body {
    font-family: Verdana, sans-serif;
    font-size: 15px;
    line-height: 1.5
}

html {
    overflow-x: hidden
}

h1 {
    font-size: 36px
}

h2 {
    font-size: 30px
}

h3 {
    font-size: 24px
}

h4 {
    font-size: 20px
}

h5 {
    font-size: 18px
}

h6 {
    font-size: 16px
} */

/* .w3-serif {
    font-family: serif
}

.w3-sans-serif {
    font-family: sans-serif
}

.w3-cursive {
    font-family: cursive
}

.w3-monospace {
    font-family: monospace
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Segoe UI", Arial, sans-serif;
    font-weight: 400;
    margin: 10px 0
}

.w3-wide {
    letter-spacing: 4px
}

hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0
}

.w3-image {
    max-width: 100%;
    height: auto
}

img {
    vertical-align: middle
}

a {
    color: inherit
} */

.w3-table,
.w3-table-all {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    display: table
}

.w3-table-all {
    border: 1px solid #ccc
}

.w3-bordered tr,
.w3-table-all tr {
    border-bottom: 1px solid #ddd
}

.w3-striped tbody tr:nth-child(even) {
    background-color: #f1f1f1
}

.w3-table-all tr:nth-child(odd) {
    background-color: #fff
}

.w3-table-all tr:nth-child(even) {
    background-color: #f1f1f1
}

.w3-hoverable tbody tr:hover,
.w3-ul.w3-hoverable li:hover {
    background-color: #ccc
}

.w3-centered tr th,
.w3-centered tr td {
    text-align: center
}

.w3-table td,
.w3-table th,
.w3-table-all td,
.w3-table-all th {
    padding: 8px 8px;
    display: table-cell;
    text-align: left;
    vertical-align: top
}

.w3-table th:first-child,
.w3-table td:first-child,
.w3-table-all th:first-child,
.w3-table-all td:first-child {
    padding-left: 16px
}

.w3-btn,
.w3-button {
    border: none;
    display: inline-block;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap
}

.w3-btn:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.w3-btn,
.w3-button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none
}

.w3-disabled,
.w3-btn:disabled,
.w3-button:disabled {
    cursor: not-allowed;
    opacity: 0.3
}

.w3-disabled *,
:disabled * {
    pointer-events: none
}

.w3-btn.w3-disabled:hover,
.w3-btn:disabled:hover {
    box-shadow: none
}

.w3-badge,
.w3-tag {
    background-color: #000;
    color: #fff;
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center
}

.w3-badge {
    border-radius: 50%
}

.w3-ul {
    list-style-type: none;
    padding: 0;
    margin: 0
}

.w3-ul li {
    padding: 8px 16px;
    border-bottom: 1px solid #ddd
}

.w3-ul li:last-child {
    border-bottom: none
}

.w3-tooltip,
.w3-display-container {
    position: relative
}

.w3-tooltip .w3-text {
    display: none
}

.w3-tooltip:hover .w3-text {
    display: inline-block
}

.w3-ripple:active {
    opacity: 0.5
}

.w3-ripple {
    transition: opacity 0s
}

.w3-input {
    padding: 8px;
    display: block;
    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%
}

.w3-select {
    padding: 9px 0;
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc
}

.w3-dropdown-click,
.w3-dropdown-hover {
    position: relative;
    display: inline-block;
    cursor: pointer
}

.w3-dropdown-hover:hover .w3-dropdown-content {
    display: block
}

.w3-dropdown-hover:first-child,
.w3-dropdown-click:hover {
    background-color: #ccc;
    color: #000
}

.w3-dropdown-hover:hover>.w3-button:first-child,
.w3-dropdown-click:hover>.w3-button:first-child {
    background-color: #ccc;
    color: #000
}

.w3-dropdown-content {
    cursor: auto;
    color: #000;
    background-color: #fff;
    display: none;
    position: absolute;
    min-width: 160px;
    margin: 0;
    padding: 0;
    z-index: 1
}

.w3-check,
.w3-radio {
    width: 24px;
    height: 24px;
    position: relative;
    top: 6px
}

.w3-sidebar {
    height: 100%;
    width: 200px;
    background-color: #fff;
    position: fixed !important;
    z-index: 1;
    overflow: auto
}

.w3-bar-block .w3-dropdown-hover,
.w3-bar-block .w3-dropdown-click {
    width: 100%
}

.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,
.w3-bar-block .w3-dropdown-click .w3-dropdown-content {
    min-width: 100%
}

.w3-bar-block .w3-dropdown-hover .w3-button,
.w3-bar-block .w3-dropdown-click .w3-button {
    width: 100%;
    text-align: left;
    padding: 8px 16px
}

.w3-main,
#main {
    transition: margin-left .4s
}

.w3-modal {
    z-index: 3;
    display: none;
    padding-top: 100px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4)
}

.w3-modal-content {
    margin: auto;
    background-color: #fff;
    position: relative;
    padding: 0;
    outline: 0;
    width: 600px
}

.w3-bar {
    width: 100%;
    overflow: hidden
}

.w3-center .w3-bar {
    display: inline-block;
    width: auto
}

.w3-bar .w3-bar-item {
    padding: 8px 16px;
    float: left;
    width: auto;
    border: none;
    display: block;
    outline: 0
}

.w3-bar .w3-dropdown-hover,
.w3-bar .w3-dropdown-click {
    position: static;
    float: left
}

.w3-bar .w3-button {
    white-space: normal
}

.w3-bar-block .w3-bar-item {
    width: 100%;
    display: block;
    padding: 8px 16px;
    text-align: left;
    border: none;
    white-space: normal;
    float: none;
    outline: 0
}

.w3-bar-block.w3-center .w3-bar-item {
    text-align: center
}

.w3-block {
    display: block;
    width: 100%
}

.w3-responsive {
    display: block;
    overflow-x: auto
}

.w3-container:after,
.w3-container:before,
.w3-panel:after,
.w3-panel:before,
.w3-row:after,
.w3-row:before,
.w3-row-padding:after,
.w3-row-padding:before,
.w3-cell-row:before,
.w3-cell-row:after,
.w3-clear:after,
.w3-clear:before,
.w3-bar:before,
.w3-bar:after {
    content: "";
    display: table;
    clear: both
}

.w3-col,
.w3-half,
.w3-third,
.w3-twothird,
.w3-threequarter,
.w3-quarter {
    float: left;
    width: 100%
}

.w3-col.s1 {
    width: 8.33333%
}

.w3-col.s2 {
    width: 16.66666%
}

.w3-col.s3 {
    width: 24.99999%
}

.w3-col.s4 {
    width: 33.33333%
}

.w3-col.s5 {
    width: 41.66666%
}

.w3-col.s6 {
    width: 49.99999%
}

.w3-col.s7 {
    width: 58.33333%
}

.w3-col.s8 {
    width: 66.66666%
}

.w3-col.s9 {
    width: 74.99999%
}

.w3-col.s10 {
    width: 83.33333%
}

.w3-col.s11 {
    width: 91.66666%
}

.w3-col.s12 {
    width: 99.99999%
}

@media (min-width:601px) {
    .w3-col.m1 {
        width: 8.33333%
    }

    .w3-col.m2 {
        width: 16.66666%
    }

    .w3-col.m3,
    .w3-quarter {
        width: 24.99999%
    }

    .w3-col.m4,
    .w3-third {
        width: 33.33333%
    }

    .w3-col.m5 {
        width: 41.66666%
    }

    .w3-col.m6,
    .w3-half {
        width: 49.99999%
    }

    .w3-col.m7 {
        width: 58.33333%
    }

    .w3-col.m8,
    .w3-twothird {
        width: 66.66666%
    }

    .w3-col.m9,
    .w3-threequarter {
        width: 74.99999%
    }

    .w3-col.m10 {
        width: 83.33333%
    }

    .w3-col.m11 {
        width: 91.66666%
    }

    .w3-col.m12 {
        width: 99.99999%
    }
}

@media (min-width:993px) {
    .w3-col.l1 {
        width: 8.33333%
    }

    .w3-col.l2 {
        width: 16.66666%
    }

    .w3-col.l3 {
        width: 24.99999%
    }

    .w3-col.l4 {
        width: 33.33333%
    }

    .w3-col.l5 {
        width: 41.66666%
    }

    .w3-col.l6 {
        width: 49.99999%
    }

    .w3-col.l7 {
        width: 58.33333%
    }

    .w3-col.l8 {
        width: 66.66666%
    }

    .w3-col.l9 {
        width: 74.99999%
    }

    .w3-col.l10 {
        width: 83.33333%
    }

    .w3-col.l11 {
        width: 91.66666%
    }

    .w3-col.l12 {
        width: 99.99999%
    }
}

.w3-rest {
    overflow: hidden
}

.w3-stretch {
    margin-left: -16px;
    margin-right: -16px
}

.w3-content,
.w3-auto {
    margin-left: auto;
    margin-right: auto
}

.w3-content {
    max-width: 980px
}

.w3-auto {
    max-width: 1140px
}

.w3-cell-row {
    display: table;
    width: 100%
}

.w3-cell {
    display: table-cell
}

.w3-cell-top {
    vertical-align: top
}

.w3-cell-middle {
    vertical-align: middle
}

.w3-cell-bottom {
    vertical-align: bottom
}

.w3-hide {
    display: none !important
}

.w3-show-block,
.w3-show {
    display: block !important
}

.w3-show-inline-block {
    display: inline-block !important
}

@media (max-width:1205px) {
    .w3-auto {
        max-width: 95%
    }
}

@media (max-width:600px) {
    .w3-modal-content {
        margin: 0 10px;
        width: auto !important
    }

    .w3-modal {
        padding-top: 30px
    }

    .w3-dropdown-hover.w3-mobile .w3-dropdown-content,
    .w3-dropdown-click.w3-mobile .w3-dropdown-content {
        position: relative
    }

    .w3-hide-small {
        display: none !important
    }

    .w3-mobile {
        display: block;
        width: 100% !important
    }

    .w3-bar-item.w3-mobile,
    .w3-dropdown-hover.w3-mobile,
    .w3-dropdown-click.w3-mobile {
        text-align: center
    }

    .w3-dropdown-hover.w3-mobile,
    .w3-dropdown-hover.w3-mobile .w3-btn,
    .w3-dropdown-hover.w3-mobile .w3-button,
    .w3-dropdown-click.w3-mobile,
    .w3-dropdown-click.w3-mobile .w3-btn,
    .w3-dropdown-click.w3-mobile .w3-button {
        width: 100%
    }
}

@media (max-width:768px) {
    .w3-modal-content {
        width: 500px
    }

    .w3-modal {
        padding-top: 50px
    }
}

@media (min-width:993px) {
    .w3-modal-content {
        width: 900px
    }

    .w3-hide-large {
        display: none !important
    }

    .w3-sidebar.w3-collapse {
        display: block !important
    }
}

@media (max-width:992px) and (min-width:601px) {
    .w3-hide-medium {
        display: none !important
    }
}

@media (max-width:992px) {
    .w3-sidebar.w3-collapse {
        display: none
    }

    .w3-main {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .w3-auto {
        max-width: 100%
    }
}

.w3-top,
.w3-bottom {
    position: fixed;
    width: 100%;
    z-index: 1
}

.w3-top {
    top: 0
}

.w3-bottom {
    bottom: 0
}

.w3-overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2
}

.w3-display-topleft {
    position: absolute;
    left: 0;
    top: 0
}

.w3-display-topright {
    position: absolute;
    right: 0;
    top: 0
}

.w3-display-bottomleft {
    position: absolute;
    left: 0;
    bottom: 0
}

.w3-display-bottomright {
    position: absolute;
    right: 0;
    bottom: 0
}

.w3-display-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
}

.w3-display-left {
    position: absolute;
    top: 50%;
    left: 0%;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    -ms-transform: translate(-0%, -50%)
}

.w3-display-right {
    position: absolute;
    top: 50%;
    right: 0%;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%)
}

.w3-display-topmiddle {
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%)
}

.w3-display-bottommiddle {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%)
}

.w3-display-container:hover .w3-display-hover {
    display: block
}

.w3-display-container:hover span.w3-display-hover {
    display: inline-block
}

.w3-display-hover {
    display: none
}

.w3-display-position {
    position: absolute
}

.w3-circle {
    border-radius: 50%
}

.w3-round-small {
    border-radius: 2px
}

.w3-round,
.w3-round-medium {
    border-radius: 4px
}

.w3-round-large {
    border-radius: 8px
}

.w3-round-xlarge {
    border-radius: 16px
}

.w3-round-xxlarge {
    border-radius: 32px
}

.w3-row-padding,
.w3-row-padding>.w3-half,
.w3-row-padding>.w3-third,
.w3-row-padding>.w3-twothird,
.w3-row-padding>.w3-threequarter,
.w3-row-padding>.w3-quarter,
.w3-row-padding>.w3-col {
    padding: 0 8px
}

.w3-container,
.w3-panel {
    padding: 0.01em 16px
}

.w3-panel {
    margin-top: 16px;
    margin-bottom: 16px
}

.w3-code,
.w3-codespan {
    font-family: Consolas, "courier new";
    font-size: 16px
}

.w3-code {
    width: auto;
    background-color: #fff;
    padding: 8px 12px;
    border-left: 4px solid #4CAF50;
    word-wrap: break-word
}

.w3-codespan {
    color: crimson;
    background-color: #f1f1f1;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 110%
}

.w3-card,
.w3-card-2 {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
}

.w3-card-4,
.w3-hover-shadow:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19)
}

.w3-spin {
    -webkit-animation: w3-spin 2s infinite linear;
            animation: w3-spin 2s infinite linear
}

@-webkit-keyframes w3-spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg)
    }
}

@keyframes w3-spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg)
    }
}

.w3-animate-fading {
    -webkit-animation: fading 10s infinite;
            animation: fading 10s infinite
}

@-webkit-keyframes fading {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes fading {
    0% {
        opacity: 0
    }

    50% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.w3-animate-opacity {
    -webkit-animation: opac 0.8s;
            animation: opac 0.8s
}

@-webkit-keyframes opac {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes opac {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.w3-animate-top {
    position: relative;
    -webkit-animation: animatetop 0.4s;
            animation: animatetop 0.4s
}

@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.w3-animate-left {
    position: relative;
    -webkit-animation: animateleft 0.4s;
            animation: animateleft 0.4s
}

@-webkit-keyframes animateleft {
    from {
        left: -300px;
        opacity: 0
    }

    to {
        left: 0;
        opacity: 1
    }
}

@keyframes animateleft {
    from {
        left: -300px;
        opacity: 0
    }

    to {
        left: 0;
        opacity: 1
    }
}

.w3-animate-right {
    position: relative;
    -webkit-animation: animateright 0.4s;
            animation: animateright 0.4s
}

@-webkit-keyframes animateright {
    from {
        right: -300px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

@keyframes animateright {
    from {
        right: -300px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

.w3-animate-bottom {
    position: relative;
    -webkit-animation: animatebottom 0.4s;
            animation: animatebottom 0.4s
}

@-webkit-keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

.w3-animate-zoom {
    -webkit-animation: animatezoom 0.6s;
            animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
    from {
        -webkit-transform: scale(0);
                transform: scale(0)
    }

    to {
        -webkit-transform: scale(1);
                transform: scale(1)
    }
}

@keyframes animatezoom {
    from {
        -webkit-transform: scale(0);
                transform: scale(0)
    }

    to {
        -webkit-transform: scale(1);
                transform: scale(1)
    }
}

.w3-animate-input {
    transition: width 0.4s ease-in-out
}

.w3-animate-input:focus {
    width: 100% !important
}

.w3-opacity,
.w3-hover-opacity:hover {
    opacity: 0.60
}

.w3-opacity-off,
.w3-hover-opacity-off:hover {
    opacity: 1
}

.w3-opacity-max {
    opacity: 0.25
}

.w3-opacity-min {
    opacity: 0.75
}

.w3-greyscale-max,
.w3-grayscale-max,
.w3-hover-greyscale:hover,
.w3-hover-grayscale:hover {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%)
}

.w3-greyscale,
.w3-grayscale {
    -webkit-filter: grayscale(75%);
            filter: grayscale(75%)
}

.w3-greyscale-min,
.w3-grayscale-min {
    -webkit-filter: grayscale(50%);
            filter: grayscale(50%)
}

.w3-sepia {
    -webkit-filter: sepia(75%);
            filter: sepia(75%)
}

.w3-sepia-max,
.w3-hover-sepia:hover {
    -webkit-filter: sepia(100%);
            filter: sepia(100%)
}

.w3-sepia-min {
    -webkit-filter: sepia(50%);
            filter: sepia(50%)
}

.w3-tiny {
    font-size: 10px !important
}

.w3-small {
    font-size: 12px !important
}

.w3-medium {
    font-size: 15px !important
}

.w3-large {
    font-size: 18px !important
}

.w3-xlarge {
    font-size: 24px !important
}

.w3-xxlarge {
    font-size: 36px !important
}

.w3-xxxlarge {
    font-size: 48px !important
}

.w3-jumbo {
    font-size: 64px !important
}

.w3-left-align {
    text-align: left !important
}

.w3-right-align {
    text-align: right !important
}

.w3-justify {
    text-align: justify !important
}

.w3-center {
    text-align: center !important
}

.w3-border-0 {
    border: 0 !important
}

.w3-border {
    border: 1px solid #ccc !important
}

.w3-border-top {
    border-top: 1px solid #ccc !important
}

.w3-border-bottom {
    border-bottom: 1px solid #ccc !important
}

.w3-border-left {
    border-left: 1px solid #ccc !important
}

.w3-border-right {
    border-right: 1px solid #ccc !important
}

.w3-topbar {
    border-top: 6px solid #ccc !important
}

.w3-bottombar {
    border-bottom: 6px solid #ccc !important
}

.w3-leftbar {
    border-left: 6px solid #ccc !important
}

.w3-rightbar {
    border-right: 6px solid #ccc !important
}

.w3-section,
.w3-code {
    margin-top: 16px !important;
    margin-bottom: 16px !important
}

.w3-margin {
    margin: 16px !important
}

.w3-margin-top {
    margin-top: 16px !important
}

.w3-margin-bottom {
    margin-bottom: 16px !important
}

.w3-margin-left {
    margin-left: 16px !important
}

.w3-margin-right {
    margin-right: 16px !important
}

.w3-padding-small {
    padding: 4px 8px !important
}

.w3-padding {
    padding: 8px 16px !important
}

.w3-padding-large {
    padding: 12px 24px !important
}

.w3-padding-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important
}

.w3-padding-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important
}

.w3-padding-32 {
    padding-top: 32px !important;
    padding-bottom: 32px !important
}

.w3-padding-48 {
    padding-top: 48px !important;
    padding-bottom: 48px !important
}

.w3-padding-64 {
    padding-top: 64px !important;
    padding-bottom: 64px !important
}

.w3-padding-top-64 {
    padding-top: 64px !important
}

.w3-padding-top-48 {
    padding-top: 48px !important
}

.w3-padding-top-32 {
    padding-top: 32px !important
}

.w3-padding-top-24 {
    padding-top: 24px !important
}

.w3-left {
    float: left !important
}

.w3-right {
    float: right !important
}

.w3-button:hover {
    color: #000 !important;
    background-color: #ccc !important
}

.w3-transparent,
.w3-hover-none:hover {
    background-color: transparent !important
}

.w3-hover-none:hover {
    box-shadow: none !important
}

/* Colors */
.w3-amber,
.w3-hover-amber:hover {
    color: #000 !important;
    background-color: #ffc107 !important
}

.w3-aqua,
.w3-hover-aqua:hover {
    color: #000 !important;
    background-color: #00ffff !important
}

.w3-blue,
.w3-hover-blue:hover {
    color: #fff !important;
    background-color: #2196F3 !important
}

.w3-light-blue,
.w3-hover-light-blue:hover {
    color: #000 !important;
    background-color: #87CEEB !important
}

.w3-brown,
.w3-hover-brown:hover {
    color: #fff !important;
    background-color: #795548 !important
}

.w3-cyan,
.w3-hover-cyan:hover {
    color: #000 !important;
    background-color: #00bcd4 !important
}

.w3-blue-grey,
.w3-hover-blue-grey:hover,
.w3-blue-gray,
.w3-hover-blue-gray:hover {
    color: #fff !important;
    background-color: #607d8b !important
}

.w3-green,
.w3-hover-green:hover {
    color: #fff !important;
    background-color: #4CAF50 !important
}

.w3-light-green,
.w3-hover-light-green:hover {
    color: #000 !important;
    background-color: #8bc34a !important
}

.w3-indigo,
.w3-hover-indigo:hover {
    color: #fff !important;
    background-color: #3f51b5 !important
}

.w3-khaki,
.w3-hover-khaki:hover {
    color: #000 !important;
    background-color: #f0e68c !important
}

.w3-lime,
.w3-hover-lime:hover {
    color: #000 !important;
    background-color: #cddc39 !important
}

.w3-orange,
.w3-hover-orange:hover {
    color: #000 !important;
    background-color: #ff9800 !important
}

.w3-deep-orange,
.w3-hover-deep-orange:hover {
    color: #fff !important;
    background-color: #ff5722 !important
}

.w3-pink,
.w3-hover-pink:hover {
    color: #fff !important;
    background-color: #e91e63 !important
}

.w3-purple,
.w3-hover-purple:hover {
    color: #fff !important;
    background-color: #9c27b0 !important
}

.w3-deep-purple,
.w3-hover-deep-purple:hover {
    color: #fff !important;
    background-color: #673ab7 !important
}

.w3-red,
.w3-hover-red:hover {
    color: #fff !important;
    background-color: #f44336 !important
}

.w3-sand,
.w3-hover-sand:hover {
    color: #000 !important;
    background-color: #fdf5e6 !important
}

.w3-teal,
.w3-hover-teal:hover {
    color: #fff !important;
    background-color: #009688 !important
}

.w3-yellow,
.w3-hover-yellow:hover {
    color: #000 !important;
    background-color: #ffeb3b !important
}

.w3-white,
.w3-hover-white:hover {
    color: #000 !important;
    background-color: #fff !important
}

.w3-black,
.w3-hover-black:hover {
    color: #fff !important;
    background-color: #000 !important
}

.w3-grey,
.w3-hover-grey:hover,
.w3-gray,
.w3-hover-gray:hover {
    color: #000 !important;
    background-color: #9e9e9e !important
}

.w3-light-grey,
.w3-hover-light-grey:hover,
.w3-light-gray,
.w3-hover-light-gray:hover {
    color: #000 !important;
    background-color: #f1f1f1 !important
}

.w3-dark-grey,
.w3-hover-dark-grey:hover,
.w3-dark-gray,
.w3-hover-dark-gray:hover {
    color: #fff !important;
    background-color: #616161 !important
}

.w3-pale-red,
.w3-hover-pale-red:hover {
    color: #000 !important;
    background-color: #ffdddd !important
}

.w3-pale-green,
.w3-hover-pale-green:hover {
    color: #000 !important;
    background-color: #ddffdd !important
}

.w3-pale-yellow,
.w3-hover-pale-yellow:hover {
    color: #000 !important;
    background-color: #ffffcc !important
}

.w3-pale-blue,
.w3-hover-pale-blue:hover {
    color: #000 !important;
    background-color: #ddffff !important
}

.w3-text-amber,
.w3-hover-text-amber:hover {
    color: #ffc107 !important
}

.w3-text-aqua,
.w3-hover-text-aqua:hover {
    color: #00ffff !important
}

.w3-text-blue,
.w3-hover-text-blue:hover {
    color: #2196F3 !important
}

.w3-text-light-blue,
.w3-hover-text-light-blue:hover {
    color: #87CEEB !important
}

.w3-text-brown,
.w3-hover-text-brown:hover {
    color: #795548 !important
}

.w3-text-cyan,
.w3-hover-text-cyan:hover {
    color: #00bcd4 !important
}

.w3-text-blue-grey,
.w3-hover-text-blue-grey:hover,
.w3-text-blue-gray,
.w3-hover-text-blue-gray:hover {
    color: #607d8b !important
}

.w3-text-green,
.w3-hover-text-green:hover {
    color: #4CAF50 !important
}

.w3-text-light-green,
.w3-hover-text-light-green:hover {
    color: #8bc34a !important
}

.w3-text-indigo,
.w3-hover-text-indigo:hover {
    color: #3f51b5 !important
}

.w3-text-khaki,
.w3-hover-text-khaki:hover {
    color: #b4aa50 !important
}

.w3-text-lime,
.w3-hover-text-lime:hover {
    color: #cddc39 !important
}

.w3-text-orange,
.w3-hover-text-orange:hover {
    color: #ff9800 !important
}

.w3-text-deep-orange,
.w3-hover-text-deep-orange:hover {
    color: #ff5722 !important
}

.w3-text-pink,
.w3-hover-text-pink:hover {
    color: #e91e63 !important
}

.w3-text-purple,
.w3-hover-text-purple:hover {
    color: #9c27b0 !important
}

.w3-text-deep-purple,
.w3-hover-text-deep-purple:hover {
    color: #673ab7 !important
}

.w3-text-red,
.w3-hover-text-red:hover {
    color: #f44336 !important
}

.w3-text-sand,
.w3-hover-text-sand:hover {
    color: #fdf5e6 !important
}

.w3-text-teal,
.w3-hover-text-teal:hover {
    color: #009688 !important
}

.w3-text-yellow,
.w3-hover-text-yellow:hover {
    color: #d2be0e !important
}

.w3-text-white,
.w3-hover-text-white:hover {
    color: #fff !important
}

.w3-text-black,
.w3-hover-text-black:hover {
    color: #000 !important
}

.w3-text-grey,
.w3-hover-text-grey:hover,
.w3-text-gray,
.w3-hover-text-gray:hover {
    color: #757575 !important
}

.w3-text-light-grey,
.w3-hover-text-light-grey:hover,
.w3-text-light-gray,
.w3-hover-text-light-gray:hover {
    color: #f1f1f1 !important
}

.w3-text-dark-grey,
.w3-hover-text-dark-grey:hover,
.w3-text-dark-gray,
.w3-hover-text-dark-gray:hover {
    color: #3a3a3a !important
}

.w3-border-amber,
.w3-hover-border-amber:hover {
    border-color: #ffc107 !important
}

.w3-border-aqua,
.w3-hover-border-aqua:hover {
    border-color: #00ffff !important
}

.w3-border-blue,
.w3-hover-border-blue:hover {
    border-color: #2196F3 !important
}

.w3-border-light-blue,
.w3-hover-border-light-blue:hover {
    border-color: #87CEEB !important
}

.w3-border-brown,
.w3-hover-border-brown:hover {
    border-color: #795548 !important
}

.w3-border-cyan,
.w3-hover-border-cyan:hover {
    border-color: #00bcd4 !important
}

.w3-border-blue-grey,
.w3-hover-border-blue-grey:hover,
.w3-border-blue-gray,
.w3-hover-border-blue-gray:hover {
    border-color: #607d8b !important
}

.w3-border-green,
.w3-hover-border-green:hover {
    border-color: #4CAF50 !important
}

.w3-border-light-green,
.w3-hover-border-light-green:hover {
    border-color: #8bc34a !important
}

.w3-border-indigo,
.w3-hover-border-indigo:hover {
    border-color: #3f51b5 !important
}

.w3-border-khaki,
.w3-hover-border-khaki:hover {
    border-color: #f0e68c !important
}

.w3-border-lime,
.w3-hover-border-lime:hover {
    border-color: #cddc39 !important
}

.w3-border-orange,
.w3-hover-border-orange:hover {
    border-color: #ff9800 !important
}

.w3-border-deep-orange,
.w3-hover-border-deep-orange:hover {
    border-color: #ff5722 !important
}

.w3-border-pink,
.w3-hover-border-pink:hover {
    border-color: #e91e63 !important
}

.w3-border-purple,
.w3-hover-border-purple:hover {
    border-color: #9c27b0 !important
}

.w3-border-deep-purple,
.w3-hover-border-deep-purple:hover {
    border-color: #673ab7 !important
}

.w3-border-red,
.w3-hover-border-red:hover {
    border-color: #f44336 !important
}

.w3-border-sand,
.w3-hover-border-sand:hover {
    border-color: #fdf5e6 !important
}

.w3-border-teal,
.w3-hover-border-teal:hover {
    border-color: #009688 !important
}

.w3-border-yellow,
.w3-hover-border-yellow:hover {
    border-color: #ffeb3b !important
}

.w3-border-white,
.w3-hover-border-white:hover {
    border-color: #fff !important
}

.w3-border-black,
.w3-hover-border-black:hover {
    border-color: #000 !important
}

.w3-border-grey,
.w3-hover-border-grey:hover,
.w3-border-gray,
.w3-hover-border-gray:hover {
    border-color: #9e9e9e !important
}

.w3-border-light-grey,
.w3-hover-border-light-grey:hover,
.w3-border-light-gray,
.w3-hover-border-light-gray:hover {
    border-color: #f1f1f1 !important
}

.w3-border-dark-grey,
.w3-hover-border-dark-grey:hover,
.w3-border-dark-gray,
.w3-hover-border-dark-gray:hover {
    border-color: #616161 !important
}

.w3-border-pale-red,
.w3-hover-border-pale-red:hover {
    border-color: #ffe7e7 !important
}

.w3-border-pale-green,
.w3-hover-border-pale-green:hover {
    border-color: #e7ffe7 !important
}

.w3-border-pale-yellow,
.w3-hover-border-pale-yellow:hover {
    border-color: #ffffcc !important
}

.w3-border-pale-blue,
.w3-hover-border-pale-blue:hover {
    border-color: #e7ffff !important
}
.primary-header {
    /* background: #FDEFC1; */
    padding: 0;
    position: relative;
}

.primary-header .container-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.primary-header .logo {
    margin: 0;
    padding: 15px 0;
    display: block;
    width: 90px;
}

.primary-header .logo img {
    width: 100%;
    display: block;
}

.primary-header .navbar-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    background: #fff;
    z-index: 9;
    width: 100%;
    text-align: center;
    padding: 10px 20px;
}

.navbar-wrapper.active {
    display: block;
}

.header-content-wrap.active .navbar-wrapper {
    display: block;
    padding: 15px 0;
}

.container-main {
    max-width: 1384px;
    padding: 0 20px;
    margin: 0 auto;
}

/* 
.navbar {
    margin-bottom: 20px;
} */

.nav-menu a {
    font-family: 'Inter',
        sans-serif;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    padding: 15px;
    display: block;
    transition: all 0.5s;
}

.nav-menu .active a {
    color: #EF7F1A;
}

.nav-menu a:hover {
    color: #EF7F1A;
}

.nav-menu a:active {
    color: #EF7F1A;
}

.nav-menu a:active {
    color: #EF7F1A;
}

.latest-news {
    display: flex;
    align-items: center;
    background-color: #000;
}

.latest-news .ltl-news {
    padding: 8px 20px;
}

.ticker-wrapper {
    padding: 11px 20px;
    height: 41px;
    width: 100%;
}

.header-msg .container-main {
    padding: 0;
}

.latest-news h3 {
    font-size: 14px;
    color: #fff;
    line-height: 0;
}



.header-msg {
    background: #EEA12A;
    /* padding: 12px 0; */
}

.news-logo {
    margin-right: 10px;
    width: 28px;
}

.news-logo img {
    width: 100%;
    display: block;
}


.header-msg p {
    font-family: 'Inter', sans-serif;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
}

.search-wrapper {
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 6px;
    max-width: 318px;
    position: relative;
}

.search-wrapper .search-icon {
    background: url(/assets/images/search-icon.svg) no-repeat;
    display: block;
    height: 18px;
    width: 18px;
    background-size: 18px auto;
}

.search-wrapper .search-btn {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0 10px;
}

.astrisk-sign{
    color: #ff0000;
}
.error-msg-detail{
    color: #ff0000;
    display: block;
    margin: -5px 0 5px 0;
}

.search-wrapper .form-control {
    color: #666666;
    width: 100%;
    border: 0;
    background-color: transparent;
    padding: 12px 20px 12px 40px;
}

.signup-login-wrapper {
    display: flex;
    align-items: center;
}

.signup-login-wrapper .main-btn {
    color: #808080;
    font-weight: 500;
    font-size: 13px;
    font-family: 'Inter',
        sans-serif;
    text-transform: capitalize;
    padding: 0;
    transition: all 0.5s;
}

.signup-login-wrapper .main-btn:hover {
    opacity: 0.7;
}

.signup-login-wrapper .login {
    display: block;
}

.mob-menu {
    width: 20px;
    background: transparent;
    display: block;
    border: 0;
    padding: 0;
}

.mob-menu .menu-bar {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-bottom: 3px;
    transition: all 0.2s;
}

.mob-menu .menu-bar:last-child {
    margin-bottom: 0;
}

.mob-menu.active .menu-bar:first-child {
    -webkit-transform: rotate(45deg) translateY(1px) translateX(4px);
            transform: rotate(45deg) translateY(1px) translateX(4px);
}

.mob-menu.active .menu-bar:first-child {
    display: none;
}

.mob-menu.active .menu-bar:first-child {
    -webkit-transform: rotate(-45deg) translateY(-2px) translateX(4px);
            transform: rotate(-45deg) translateY(-2px) translateX(4px);
}

/* Banner */
.grow-business-wrap {
    padding: 30px 0;
    background-color: #fff;
}

.box-wrap {
    border: 2px solid #F9BE07;
    border-radius: 10px;
}

.grow-business-wrap .btn-wrapper {
    display: flex;
}

.grow-business-wrap .btn-wrapper .box-btn {
    background: #EF7F1A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    text-transform: capitalize;
    padding: 16px 20px;
    max-width: 160px;
    width: 100%;
    font-weight: 700;
    border: 1px solid transparent;
    transition: all 0.5s;
}

.grow-business-wrap .btn-wrapper .box-btn:hover {
    background-color: transparent;
    color: #EF7F1A;
    border: 1px solid #EF7F1A;
}

.grow-business-wrap .btn-wrapper .login-btn {
    margin-right: 15px;
}

.grow-business-wrap h2 {
    font-size: 22px;
    margin-bottom: 20px;
}

.grow-business-wrap p {
    font-size: 13px;
    margin-bottom: 20px;
}

.grow-business-wrap .box-wrap {
    padding: 30px 20px;
}

.grow-business-wrap .ltl-box {
    /* margin-bottom: 30px; */
    order: 2;
}

.grow-business-wrap .rtl-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    order: 1;
}

/* platform-wrapper */
.platform-wrapper {
    padding: 30px 0;
    background-color: #fff;
}

.platform-wrapper h3 {
    font-weight: 400;
    font-size: 25px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #333333;
}

.logo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    gap: 2rem;
}

.company-logo {
    height: 96px;
    padding: 20px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #F9BE07;
}

.company-logo img {
    width: 100%;
    object-fit: contain;
    object-position: top;
    height: 100%;
}

.company-logo .logo-text {
    color: #CC4B4C;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Inter',
        sans-serif;
}

/* working-process */
.working-process {
    padding: 30px 0;
    background-color: #fff;
}

.working-process h4 {
    font-weight: 500;
    font-size: 24px;
    font-family: 'Inter';
    font-style: normal;
    color: #333333;
}

/* working-process */
.working-process h3 {
    font-weight: 400;
    font-size: 25px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    color: #333333;
}

.working-process h4 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 25px;
    text-align: center;
}

.working-process .work-img-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    /* border: 2px solid #F9BE07; */
    border-radius: 12px;
    /* padding: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 125px;
    height: 125px; */
    /* margin: 0 auto 30px; */
    padding: 0 30px;
}

.working-process .work-img-wrp img {
    border-radius: 12px;
}

.working-process .ltl-work {
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 6px;
    padding: 30px 15px;
    margin-bottom: 30px;
}

.working-process .rtl-work {
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 6px;
    padding: 30px 15px;
}

.working-process .container-main {
    max-width: 1200px;
}

.working-process .img-wrap {
    /* width: 61px; */
}

.working-process .img-wrap img {
    width: 100%;
    display: block;
}

/* our-customer */
.our-customer .box-wrap {
    justify-content: center;
}

.our-customer h3 {
    font-size: 23px;
    font-weight: 500;
    font-family: 'Inter',
        sans-serif;
    margin-bottom: 40px;
    text-align: center;
}

.our-customer p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    color: #808080;
}

.customer-img {
    width: 96px;
    margin: 0 auto 18px;
}

.customer-img img {
    width: 100%;
    display: block;
}

/* product-offering */
.product-offering {
    padding: 30px 0;
}

.product-offering h3 {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter',
        sans-serif;
    margin-bottom: 8px;
}

.product-offering p {
    font-family: 'Inter',
        sans-serif;
    color: #808080;
    font-size: 14px;
}

.product-desc {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 6px;
    padding: 12px;
}

.product-desc:last-child {
    margin-bottom: 0;
}

.product-offering .work-img-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border: 2px solid #F9BE07;
    border-radius: 12px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 85px;
    margin: 0;
}

.product-offering .img-wrap {
    width: 45px;
    flex-shrink: 0;
}

.product-offering .product-rtl {
    width: calc(100% - 100px);
}

/* subscribe-wrapper */
.subscription .subscribe-wrapper {
    padding: 30px 20px;
    border: 2px solid #F9BE07;
    border-radius: 10px;
}

.subscribe-wrapper .img-wrap {
    display: flex;
    justify-content: center;
    max-width: 280px;
    margin: 0 auto 35px;
}

.subscribe-wrapper .img-wrap img {
    border-radius: 12px;
    width: 100%;
}

.subscription h3 {
    font-size: 25px;
    font-weight: 500;
    font-family: 'Inter',
        sans-serif;
    margin-bottom: 18px;
    text-align: center;
}

.subscription p {
    font-family: 'Inter',
        sans-serif;
    color: #808080;
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
}

.search-form .form-control {
    padding: 14px 24px;
    border: 0;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.2), rgba(128, 128, 128, 0.2)),
        #FFFFFF;
    border: 2px solid rgba(128, 128, 128, 0.25);
    border-radius: 12px;
    color: #000;
    font-size: 14px;
    width: 100%;
    text-transform: capitalize;
    font-family: 'Inter',
        sans-serif;
}

.search-form .form-control::-webkit-input-placeholder {
    color: rgba(51, 51, 51, 0.5);
}

.search-form .form-control::placeholder {
    color: rgba(51, 51, 51, 0.5);
}

.subscription .subscription-search-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.subscription .submit-btn {
    background: #EF7F1A;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    text-transform: capitalize;
    padding: 16px 20px;
    width: 100%;
    font-weight: 700;
    border: 1px solid transparent;
    transition: all 0.5s;
}

.subscription .submit-btn:hover {
    background-color: transparent;
    color: #EF7F1A;
    border: 1px solid #EF7F1A;
}

.main-form-wrap {
    max-width: 600px;
    margin: 0 auto;
}


.news-content-wrap {
    display: flex;
    align-items: center;
    width: 100%;
}

.latest-news .ltl-news {
    display: flex;
    align-items: center;
    width: 100%;
}

.box-wrap {
    display: flex;
    flex-direction: column;
}

.grow-business-wrap .rtl-box img {
    cursor: pointer;
    width: 100%;
    border-radius: 12px;
    display: block;
}


.grow-business-wrap.our-customer .slick-next {
    background-color: #EEA12A !important;
}

.grow-business-wrap.our-customer .slick-prev {
    background-color: #EEA12A !important;
}

/* .grow-business-wrap.our-customer .slick-prev:hover,
.slick-next:hover {
    background-color: #f86202 !important;
} */

.grow-business-wrap.our-customer .customer-img {
    width: 160px;
    margin: 0 auto 18px;
    height: 160px;
}

.grow-business-wrap.our-customer .customer-img img {
    width: 100%;
    display: block;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}

.our-customer h3 {
    font-size: 23px;
    font-weight: 500;
    font-family: 'Inter',
        sans-serif;
    margin-bottom: 40px;
    text-align: center;
    line-height: 1.0;
    text-transform: capitalize;
}

.popular-arrival .slick-arrow.slick-next {
    border: 2px solid rgba(128, 128, 128, 0.5);
    background: #fff url(/assets/images/arrival-slider-arrow.svg) no-repeat center !important;
    height: 20px !important;
    width: 20px !important;
    background-size: 16px auto !important;
    padding: 20px;
}

.popular-arrival .slick-arrow.slick-next:hover {
    border: 2px solid #ED9829;
}

.popular-arrival .slick-arrow.slick-prev {
    border: 2px solid rgba(128, 128, 128, 0.5);
    background: #fff url(/assets/images/arrival-slider-arrow.svg) no-repeat center !important;
    height: 20px !important;
    width: 20px !important;
    background-size: 16px auto !important;
    padding: 20px;
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
}

.popular-arrival .slick-arrow.slick-prev:hover {
    border: 2px solid #ED9829;
}

/* .grow-business-wrap h2{
    
} */

@media all and (min-width:768px) {
    /* .grow-business-wrap .rtl-box .img-metal {
        width: 200px;
        margin: 0 auto;
    } */

    .logo-wrapper {
        justify-content: flex-start;
    }

    .primary-header .container-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navbar {
        margin-bottom: 0;
    }

    .news-header {
        display: flex;
    }

    .nav-menu a {
        padding: 0;
    }

    .primary-header .nav-menu {
        display: flex;
        margin-bottom: 0;
    }

    .mob-menu {
        display: none;
    }

    .nav-menu a {
        font-size: 13px;
    }

    .signup-login-wrapper .main-btn {
        font-size: 13px;
    }

    .header-msg p {
        font-size: 13px;
    }

    .latest-news h3 {
        font-size: 16px;
    }

    .primary-header .nav-menu li {
        margin-right: 12px;
    }

    .primary-header .logo {
        width: 120px;
    }

    .primary-header .navbar-wrapper {
        display: flex;
        align-items: center;
        position: static;
        background-color: transparent;
        border: 0;
        width: auto;
        padding: 0;
    }

    .grow-business-wrap .box-wrap {
        padding: 40px 30px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .box-wrap {
        border-radius: 24px;
    }

    .grow-business-wrap h2 {
        font-size: 30px;
    }

    .grow-business-wrap .ltl-box {
        width: 50%;
        padding-right: 30px;
        margin-bottom: 0;
        order: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .work-wrapper p {
        text-align: center;
    }

    .grow-business-wrap .rtl-box {
        width: 50%;
        order: 2;
        margin-bottom: 0;
        max-width: 400px;
        margin: 0 auto;
        padding-left: 30px;

    }

    .grow-business-wrap .btn-wrapper .box-btn {
        font-size: 15px;
    }

    .grow-business-wrap {
        padding: 50px 0;
    }

    /* platform-wrapper */
    .platform-wrapper h3 {
        font-size: 30px;
        margin-bottom: 40px;
    }

    .logo-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 2rem;
        gap: 2rem;
    }

    .company-logo .logo-text {
        font-size: 15px;
    }

    /*work-wrapper */
    .work-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .working-process .ltl-work {
        border-radius: 12px;
        width: 48%;
        max-width: 464px;
        margin-bottom: 0;
    }

    .working-process .rtl-work {
        border-radius: 12px;
        width: 48%;
        max-width: 464px;
    }

    .working-process h3 {
        margin-bottom: 50px;
    }

    /* our-customer */
    .our-customer .box-wrap {
        justify-content: center;
        padding: 30px;
        flex-direction: column;
    }

    .our-customer h3 {
        margin-bottom: 20px;
    }

    /* product-offering */
    .product-offering .product-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .product-desc {
        width: 49%;
    }

    /* subscribe-wrapper */
    .subscription .subscribe-wrapper {
        border-radius: 20px;
        padding: 50px;
    }

    .subscription .subscription-search-btn {
        display: block;
        margin-top: 0;
        max-width: 160px;
        width: 100%;
    }

    .subscription .search-form {
        display: flex;
        justify-content: center;
        max-width: 600px;
        width: 100%;
    }

    .subscription .form-block {
        margin-right: 24px;
        max-width: 380px;
        width: 100%;
    }

    /* .latest-news {
        position: relative;
        width: 24%;
    } */

    .news-header {
        position: relative;
    }

    .latest-news {
        width: 30%;
        height: 41px;
        /* position: relative; */
        /* z-index: 1;
        top: 0;
        left: 0; */
    }

    /* .latest-news ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
    } */


    .header-msg {
        height: 41px;
    }

    .news-content-wrap {
        justify-content: flex-end;
    }

    .latest-news .ltl-news {
        justify-content: flex-end;
        position: relative;
    }

    .latest-news .ltl-news::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-left: 7px solid #000;
        border-bottom: 7px solid transparent;
        position: absolute;
        left: 100%;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
    }

    .header-msg .container-main {
        display: flex;
        width: 100%;
    }

    .ticker-wrapper {
        padding-left: 30px;
    }

    .header-msg {
        position: relative;
    }

    .header-msg::after {
        content: "";
        position: absolute;
        background-color: #000;
        left: 0;
        width: 100vw;
    }

    .grow-business-wrap p {
        max-width: 550px;
        margin-bottom: 30px;
    }

    .grow-business-wrap.our-customer p {
        max-width: none;
    }

    .working-process .work-img-wrp {
        padding: 0 50px;
    }
}

@media all and (max-width:767px) {
    .grow-business-wrap h2 {
        text-align: center;
    }

    .grow-business-wrap p {
        text-align: center;
    }

    .grow-business-wrap .btn-wrapper {
        justify-content: center;
    }
}

@media all and (min-width:1024px) {
    .our-customer h3 {
        font-size: 25px;
    }

    .work-wrapper {
        padding: 0 70px;
    }

    .working-process .ltl-work {
        padding: 20px 40px;
    }

    .working-process .rtl-work {
        padding: 25px 40px;
    }

    .working-process h3 {
        /* margin-bottom: 100px; */
        font-size: 30px;
    }

    .product-desc {
        border-radius: 12px;
    }

    /* .grow-business-wrap .box-wrap {
        padding: 70px 30px;
    } */
}

@media all and (min-width:1200px) {
    .logo-wrapper {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 2rem;
        gap: 2rem;
    }

    /* 
    .company-logo:nth-child(8n) {
        margin-right: 0;
    } */

    .latest-news {
        width: calc(50% - 512px);
        min-width: 200px;
    }


    .nav-menu a {
        font-size: 18px;
    }

    /* .primary-header .navbar-wrapper {
        margin-left: 150px;
    } */

    .signup-login-wrapper .main-btn {
        font-size: 18px;
    }

    .header-msg p {
        font-size: 14px;
    }

    /* .header-msg {
        padding: 12px 5px 12px 5px;
    } */

    .primary-header .logo {
        width: 171px;
    }

    .navbar {
        margin-right: 30px;
    }

    .grow-business-wrap h2 {
        font-size: 36px;
    }

    .grow-business-wrap .btn-wrapper .box-btn {
        font-size: 15px;
    }

    .grow-business-wrap {
        padding: 50px 0;
    }

    /* 
    .grow-business-wrap .box-wrap {
        padding: 103px 74px;
    } */

    .company-logo {
        padding: 30px 20px;
    }

    .company-logo .logo-text {
        font-size: 18px;
    }

    .platform-wrapper h3 {
        font-size: 36px;
    }

    /* working-process */
    /* .working-process .work-img-wrp {
        width: 148px;
        height: 148px;
    } */

    .work-wrapper {
        padding: 0;
    }

    /* our-customer */
    .our-customer .box-wrap {
        justify-content: center;
        padding: 50px;
    }

    .our-customer h3 {
        margin-bottom: 30px;
    }


    .our-customer p {
        font-size: 20px;
    }

    /* product-offering */
    .product-desc {
        width: 32%;
        margin-bottom: 0;
    }

    .product-offering .work-img-wrp {
        width: 145px;
        height: 145px;
    }

    .product-offering .img-wrap {
        width: 61px;
        flex-shrink: 0;
    }

    .product-offering .product-rtl {
        width: calc(100% - 160px);
    }

    /* subscribe-wrapper */
    .subscription .subscribe-wrapper {
        border-radius: 24px;
    }

    .subscription p {
        font-size: 18px;
        margin-bottom: 48px;
    }

    /* subscribe-wrapper */
    /* .subscription .subscribe-wrapper {
        padding: 70px;
    } */

    /* .working-process h3 {
        font-size: 33px;
    } */
}

@media all and (min-width:1400px) {
    /* .primary-header .navbar-wrapper {
        margin-left: 200px;
    } */

    .our-customer h3 {
        margin-bottom: 40px;
    }

}


.platform-wrapper .company-logo {
    height: 80px;
    padding: 20px 15px;
}

.platform-wrapper .company-logo img {
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: 100%;
    border-radius: 6px;
}

.grow-business-wrap.our-customer p {
    text-align: center;
    margin: 0 auto;
}

@media all and (max-width:767px) {
    .nav-menu a {
        text-align: left;
        padding: 15px 0;
        border-bottom: 1px solid #80808033;
    }

    .nav-menu li:last-child a {
        border-bottom: 0;
    }

    .primary-header .navbar-wrapper {
        padding: 15px;
    }
}
.item-seller-info {
  display: flex;
  padding: 13px 85px 50px 75px;
  align-items: flex-start;
  max-width: 1920px;
  margin: 0 auto;
}

.option-container {
  display: flex;
  width: 95px;
  margin-right: 1.3%;
  /* height: 400px; */
  height: 402px;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

.option-container .slick-list {
  height: 408px !important;
}

.product-view {
  width: 100%;
  padding: 0;
  border: 1px solid #e6e6e6;
  position: relative;
}

/* .product-view .main-item div , .product-view .main-item{
  height: 100% !important;
} */
/* .product-view .main-item img{
  max-height: 485px;
} */
.sales-details {
  width: 100%;
  margin-right: 2.5%;
  font-family: "Roboto";
}

/* .option-container > .div {
} */
.option-container-item {
  margin-bottom: 0;
  width: 115px;
  height: 160px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
}

.option-container-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.main-item {
  width: 100%;
  height: auto;
}

.main-item>div>div>img {
  max-width: unset;
}

.item-name-detail {
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  color: #4a4a4a;
  margin-bottom: 15px;
  margin-top: 21px;
}

.item-name-detail .it-dtlsopen-collapse {
  margin-bottom: 11px;
  display: block;
}

.main-item img {
  width: 100%;
}

.product-name {
  font-size: 32px;
  color: #4a4a4a;
  font-weight: 300;
  margin-bottom: 10px;
}

.buy-offer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  flex-direction: column;
  padding: 15px 0;
  margin-bottom: 15px;
}

.buy-offer span {
  color: #d16d38;
  display: block;
  margin: 15px 2px;
  font-weight: 600;
  font-size: 18px;
}

.buy-now-btn {
  border: 2px solid #000000;
  background: #ebddfe;
  border-radius: 40px;
  top: 0;
  left: 0;
  transition: all 0.15s linear 0s;
  position: relative;
  display: inline-block;
  color: #000000;
  box-shadow: 6px 6px 0 #707070;
  font-size: 15px;
  height: 54px;
}

.buy-now-btn:after,
.make-offer-btn:after {
  content: "";
  position: absolute;
  right: -9px;
  bottom: -10px;
  border: 2px solid #000000;
  padding: 23px 50.5% 29px;
  border-radius: 40px;
  z-index: -1;
}

.buy-now-btn:hover,
.make-offer-btn:hover {
  top: 3px;
  left: -3px;
  box-shadow: 0px 0px 0 #707070;
}

.buy-now-btn:hover:after,
.make-offer-btn:hover:after {
  display: none;
}

/* .buy-now-btn.disabled{
  opacity: 0.5;
  cursor: not-allowed;
} */
.make-offer-btn {
  width: 49%;
  height: 71px;
  border: 2px solid #000000;
  background: #ebddfe;
  border-radius: 40px;
  top: 0;
  left: 0;
  transition: all 0.15s linear 0s;
  position: relative;
  display: inline-block;
  color: #000000;
  box-shadow: 6px 6px 0 #707070;
  font-size: 15px;
  height: 54px;
}

/* .make-offer-btn.disabled{
  opacity: 0.5;
  cursor: not-allowed;
}
.make-offer-btn:hover {
  background: none;
  color: #d16d38;
  border-color: #d16d38;
} */
.product-verification-btn {
  margin-right: 10px;
  font-size: 18px;
  text-transform: capitalize;
  color: #4a4a4a;
  font-family: "Open Sans", sans-serif;
}

.reference-link {
  text-align: left;
  text-decoration: underline;
  font-weight: 500;
  letter-spacing: 0px;
  color: #a292b3;
  font-size: 20px;
  font-family: "poppins", sans-serif;
  text-transform: uppercase;
}

.seller-info .info-title h4 {
  padding-bottom: 15px;
}

.seller-info .vrify-icn {
  display: inline-block;
  vertical-align: top;
  margin-right: 7px;
}

.seller-info .trend-gallery {
  margin: 0 auto 15px;
}

.seller-info .info-wrap {
  padding-top: 1px;
  padding-bottom: 20px;
}

.seller-text {
  color: #828282;
  font-size: 22px;
  margin-bottom: 15px;
  display: inline-block;
  position: relative;
}

.seller-text span {
  display: none;
  position: absolute;
  top: -18px;
  left: 0;
  font-size: 10px;
  white-space: nowrap;
  color: #fff;
  background-color: #4a4a4a;
  padding: 3px 7px;
  border-radius: 15px;
}

.seller-text:hover span {
  display: block;
}

.seller-text2 {
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.rating-list {
  display: flex;
}

.rating-list li {
  margin-right: 6px;
}

.icon-star {
  width: 36px;
  height: 36px;
  background-position: -55px -248px;
}

.icon-star.active {
  background-position: -10px -248px;
  background-color: transparent;
}

.verify-title,
.similar-product-caption h2 {
  font-size: 64px;
  font-family: "Ogg";
  color: #d2703c;
  font-weight: 500;
  line-height: 1.2;
}

.details-dropdown {
  margin-top: 15px;
  border-top: 1px solid #cecece;
}

.last-chance .similar-product-caption {
  width: 22%;
}

/* verify-product start */
.verify-product {
  padding: 46px 0;
  border-top: 1px solid #cecece;
  background: #fff5f5;
}

.verify-title {
  margin-bottom: 50px;
  line-height: 1.3;
}

.verify-product .info {
  width: 49%;
  padding: 90px 82px;
  text-align: center;
}

.verify-product .flip-box {
  background: #707070;
  border: 2px solid #000000;
  padding-bottom: 15px;
  border-radius: 20px;
  position: relative;
  min-height: 400px;
  max-width: 375px;
  margin: 0 auto 20px auto;
  transition: 0.8s;
}

.flip-box-inner {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 98%;
  height: 99%;
  text-align: center;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  height: 99%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}

.flip-box-back {
  background-color: #ebddfe;
  color: white;
  width: 100%;
  height: 100%;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  border-radius: 18px;
  overflow: auto;
}

.flip-back-wrap .post-ques-btn {
  margin-bottom: 20px;
  padding: 8px 12px;
  font-size: 13px;
}

.flip-back-wrap .search-box {
  font-size: 13px;
}

.flip-back-wrap .error-msg {
  color: #141414;
  font-weight: 600;
}

.flip-back-wrap .ques-cards {
  color: #141414;
  margin-bottom: 10px;
}

.flip-back-wrap .cards-wrap {
  padding-right: 0;
  text-align: left;
}

.verify-product .flip-box-front {
  background: #ebddfe;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  border: 2px solid #000000;
  border-radius: 20px;
}

.flip-box-front h2 {
  color: #e57235;
  font-size: 36px;
  text-align: center;
  font-family: "Ogg";
  font-weight: "roman";
  text-decoration: underline;
}

.question-info:first-child:after {
  content: "";
  width: 2px;
  height: 45%;
  position: absolute;
  bottom: 0;
  top: -28px;
  left: 0;
  right: 0;
  margin: auto;
  background: #d16d38;
}

.verify-product .info figure {
  background: url(/assets/images/ipad-frame.svg) no-repeat;
  background-size: 100% 147%;
  background-position: center center;
  width: 700px;
  height: 473px;
  padding: 30px 67px 30px 81px;
  margin: 0 auto;
}

.verify-product .info:first-child {
  background: #f7d9b7;
}

.verify-product .info:last-child {
  background: #f7f5f5;
  padding: 60px 82px;
}

.verify-product .verify-wrap {
  display: flex;
  justify-content: space-between;
}

.verify-product .explore-membership {
  position: static;
}

.verify-product h3 {
  font-size: 40px;
  color: #4a4a4a;
  font-family: "Ogg";
  margin: 0 0 30px;
  font-weight: 300;
}

/* verify-product end */
/* Breadcrumb start */
.breadcrumb-wrap {
  padding: 20px 0 20px;
  /* border-top: 1px solid #cecece; */
  max-width: 1920px;
  margin: 0 auto;
}

.breadcrumb-wrap li,
.breadcrumb-wrap a {
  font-size: 16px;
  color: #999999;
  text-decoration: none;
  font-family: "Roboto";
}

.breadcrumb-wrap li {
  color: #4c4c4c;
}

.breadcrumb-wrap a:hover {
  color: #d16d38;
}

.breadcrumb-wrap li {
  display: inline-block;
  padding: 0 10px;
  position: relative;
  text-decoration: underline;
}

.breadcrumb-wrap li:after {
  position: absolute;
  right: -3px;
  content: "/";
  color: #4a4a4a;
  top: 0;
}

.breadcrumb-wrap li:last-child:after {
  display: none;
}

.breadcrumb-wrap .back-arrow {
  quotes: 8px;
  height: 14px;
}

/* Breadcrumb end */
.product-clr {
  color: #a3a5a7;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.product-clr .product-verification-btn {
  margin: 0 0 0 10px;
  width: auto;
  min-width: 52px;
  color: grey;
  font-weight: 600;
}

.product-clr .product-verification-btn.active {
  border-color: #4a4a4a;
}

.item-seller-info .verify-txt {
  color: #828282;
  font-size: 20px;
  font-weight: 500;
  font-family: "poppins", sans-serif;
  margin-bottom: 20px;
}

.verification-btn-wrap {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #cecece;
}

.details-dropdown .droplink {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  font-family: "poppins", sans-serif;
  color: #707070;
  border-bottom: 1px solid #cecece;
  padding: 25px 0;
}

.details-dropdown i {
  position: relative;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  height: 14px;
  width: 14px;
}

.details-dropdown .open-collapse i {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.ReactCollapse--content {
  padding: 25px 0;
  font-size: 16px;
  font-family: "poppins", sans-serif;
  line-height: 1.4;
  color: #707070;
  border-bottom: 1px solid #cecece;
}

.size-wrap .popup-wrap {
  position: absolute;
}

.size-popup .popup-sec {
  height: 100%;
}

.size-popup .popup-block {
  height: auto;
  overflow: visible;
}

.size-chart h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.size-chart img {
  width: 100%;
}

/* Make an offer popup start */

.make-offer-popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 1;
  background: rgba(0, 0, 0, 0.32);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow-x: auto;
}

.make-offer-popup .offer-content {
  width: 100%;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 9px;
  padding: 15px;
  position: relative;
  text-align: center;
}

.make-offer-popup .offer-content .explore-membership {
  position: static;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  min-width: 220px;
  display: block;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

.make-offer-popup.custom-popup .offer-content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.make-offer-popup .offer-content .explore-membership:hover {
  background: transparent;
  color: #d16d39;
  border: 1px solid #d16d39;
}

.modal-closeBtn {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background-color: transparent;
  color: #c2c1c1;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
  outline: transparent solid 1px;
  cursor: pointer;
  z-index: 800;
  font-size: 25px;
  width: 25px;
  height: 33px;
}

.make-offer-popup.report-popup .modal-closeBtn {
  top: 7px;
}

.closeBtn-crossLeft,
.closeBtn-crossRight {
  display: block;
  position: absolute;
  left: 50%;
  top: 43%;
  width: 1px;
  height: 17px;
  border: none;
  background-color: #ababab;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
}

.closeBtn-crossLeft {
  -webkit-transform: rotate(45deg) translate3d(-50%, -50%, 0);
          transform: rotate(45deg) translate3d(-50%, -50%, 0);
  left: calc(50% - 3px);
  top: 36%;
}

.closeBtn-crossRight {
  -webkit-transform: rotate(-45deg) translate3d(-50%, -50%, 0);
          transform: rotate(-45deg) translate3d(-50%, -50%, 0);
  left: calc(53% + 8px);
}

.make-offer-popup.seller-product-wrapper .closeBtn-crossRight {
  /* transform: rotate(-45deg) translate3d(-50%, -50%, 0); */
  left: calc(53% + 9px);
  top: 34%;
}

.make-offer-popup .offer-title {
  display: block;
  padding: 0 0 15px;
  margin-bottom: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #333333;
}

.offer-title span {
  display: block;
  margin-bottom: 20px;
}

.offer-title .character-text {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 7px;
  margin-top: -12px;
  text-align: left;
}

.make-offer-popup .offer-desc {
  line-height: 21px;
  margin-bottom: 40px;
  font-size: 14px;
  font-family: "poppins", sans-serif;
  font-weight: 500;
}

.make-offer-popup .offer-price>span {
  color: #f47900;
  letter-spacing: 1.3px;
}

.make-offer-popup .offer-info {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.make-offer-popup .offer-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 193px;
  height: 193px;
  padding: 20px;
  margin-right: 26px;
  /* background: #f5f6f8; */
  object-fit: cover;
  padding: 0;
  border-radius: 13px;
}

.make-offer-popup .offer-txt {
  color: #282828;
  margin-left: calc(180px + 26px);
  font-size: 15px;
  font-family: "poppins", sans-serif;
  line-height: 1.4;
  text-align: left;
  font-weight: 500;
}

.make-offer-popup .offer-txts {
  font-weight: 700;
  font-size: 20px;
}

.make-offer-popup .offer-price {
  margin-left: calc(180px + 26px);
  overflow: hidden;
  position: relative;
  padding-top: 5px;
}

.offer-input-box label {
  width: 100%;
  text-transform: capitalize;
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
  font-family: "poppins", sans-serif;
}

.make-offer-popup .offer-price:before {
  display: block;
  /* content: ""; */
  position: absolute;
  height: 1px;
  background: #ebebeb;
  top: 0;
  left: 0;
  width: 110px;
  right: auto;
}

.shipping-inner-wrap {
  margin-top: 23px;
}

.shipping-inner-wrap>span {
  font-family: "poppins", sans-serif;
  font-size: 17px;
  display: block;
  margin-bottom: 20px;
}

.make-offer-popup .offer-input {
  margin-bottom: 7px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  border: 1.5px solid rgba(128, 128, 128, 0.5);
  border-radius: 4px;
  max-width: 150px;
  padding: 0 10px;
}

.make-offer-popup .range-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ED9829;
}

.make-offer-popup .input-group {
  padding: 10px 0;
  display: block;
  position: relative;
  flex: auto;
  text-align: left;
  padding-left: 10px;
}

.make-offer-popup .input-group input {
  background: 0 0;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #666666;
}

.make-offer-popup .offer-sign {
  white-space: nowrap;
  position: relative;
  vertical-align: middle;
  font-family: 'Inter';
  font-style: normal;
  /* font-weight: 700; */
  font-size: 20px;
  color: #666666;
}

.offer-inserted-btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  white-space: nowrap;
  transition: all 0.3s ease;
  font-family: "poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
  /* background: #ebebeb;
  border-color: #ebebeb;
  color: #ababab; */
  width: 100%;
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  border: 1px solid #f47900;
  background: #f47900;
  color: #fff;
  transition: all 0.5s ease-in-out;
  border-radius: 8px;
  margin-top: 25px;
}

/* .offer-inserted-btn:hover {
  border: 1px solid #d16d38;
  background: transparent;
  color: #d16d38;
} */

.offer-btn-diable {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  white-space: nowrap;
  transition: all 0.3s ease;
  font-family: "poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  color: #f47900;
  /* border-color: #f47900; */
  border: 1px solid #f47900;
  width: 100%;
  height: 48px;
  padding: 0 24px;
  cursor: default;
  margin-top: 25px;
}

/* .offer-btn-diable:hover {
  background: #f47900;
  border: 1px solid #f47900;
  color: #fff;
} */

.seller-space {
  margin-bottom: 30px;
}

.seller-spacewrap {
  padding: 0 20px;
}

.verify-product .container {
  padding: 0 45px 0 45px;
}

.product-sellerwrap .last-chance .container {
  padding: 0 45px 0 45px;
}

.product-view .main-item div {
  margin: 0 auto;
  text-align: center;
}

/* .partition-line {
  border-top: 2px solid #e6e6e6;
  display: block;
  margin-bottom: 5px;
} */
/* .productfit-imagewrap{
  min-width: 505px!important;
  min-height: 745px!important;
  margin: 0 auto!important;
  max-width: 510px!important;
  width: auto!important;
  height: auto!important;
}
.productfit-imagewrap img{
  width: 100%!important;
  height: 100%!important;
  object-fit: contain;
} */
.availability-info {
  position: absolute;
  top: 0;
  left: 0;
  text-transform: uppercase;
  font-size: 20px;
  padding: 15px 35px;
  color: #a292b3;
  font-weight: 700;
  background: #f7f5f5;
}

.season-wrap {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.season-info {
  display: block;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #707070;
}

.icon-season {
  background-position: -10px -305px;
  background-size: 450px 350px;
  width: 39px;
  height: 9px;
}

.season-wrap i {
  width: 39px;
  height: 9px;
  margin-left: 10px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.product-details-name .free-shipping {
  margin-left: 5px;
}

.rating-date {
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
}

.item-seller-info .seller {
  width: 25%;
}

.item-seller-info .seller-reviews {
  width: 75%;
  display: flex;
  flex-direction: row;
}

.item-seller-info .seller-reviews .seller-review-card {
  border: 1px solid #b6b6d7;
  display: "flex";
  flex-direction: "column";
  width: 28%;
}

.product-name {
  font-weight: 600;
  font-size: 29px;
  font-family: "Roboto";
}

.item-name-detail .desc {
  font-size: 16px;
  font-family: "Roboto";
}

.item-name-detail .desc.desc-open {
  height: auto;
}

.item-name-detail i {
  position: relative;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  height: 14px;
  width: 14px;
}

.item-name-detail .open-collapse i {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  position: relative;
  height: 14px;
  width: 14px;
}

.item-name-detail .droplink {
  cursor: pointer;
}

.seller-prof-img {
  width: 90px;
  height: 90px;
  border-radius: 90px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.16);
}

.complete-purchase.disablebutton {
  border: 0;
}

.product-sellerwrap .costume-box .costume-info {
  padding: 17px 10px;
}

.product-sellerwrap .costume-box .costumes {
  height: 157px;
}

.fontSmall {
  font-size: 14px;
}

.share-icon-group i {
  color: #c8c6c6;
}

.updated-bar {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.updated-time,
.reports {
  color: #999999;
  font-size: 18px;
  text-transform: capitalize;
  font-family: "Roboto";
}

.reports {
  display: flex;
  align-items: center;
}

.report-btns {
  display: flex;
  align-items: center;
}

.report-btns img {
  max-width: 19px;
}

.report {
  margin-top: 3px;
  margin-left: 3px;
}

.slide-box-wrap {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-right: 3.5%;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  z-index: 1;
}

.des-wrap {
  display: flex;
  width: 51%;
  padding-right: 60px;
  position: relative;
}

.seller-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  position: relative;
}

.about-seller h4 {
  margin-bottom: 30px;
  font-family: "Roboto";
  font-weight: 500;
}

.about-seller h4.info-title {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 5px;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.5s;
}

.about-seller h4.info-title :hover {
  color: #f86202;
}

.tittle-wrap {
  padding-left: 15px;
}

.media-seller li {
  cursor: pointer;
  display: flex;
  background: #9983a0;
  height: 30px;
  width: 30px;
  border-radius: 40px;
  padding: 5px 0;
  text-align: center;
  color: #fff;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
}

/* By Sujay(1-Sept-2021) 
Purpose - Change in Hover Color of Seller Facebook , 
Mail and Contact Icons*/
.media-seller li:hover {
  background: #d60e96;
}

/* .fab {
color: #fff;
}
.fas {
  color: #fff;
  } */
/*End*/
.confirmed {
  /* display: flex; */
  display: none;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.confirmed span {
  font-size: 18px;
}

.sell-items span,
.rating-review-wrap span {
  font-size: 16px;
  font-family: "Roboto";
}

.sell-items strong {
  font-weight: 600;
}

.media-seller {
  display: flex;
}

.rating-review-wrap {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  align-items: center;
}

.view-seller-wrap .rating-review-wrap {
  justify-content: flex-start;
}

.ratings span {
  font-size: 16px;
  cursor: pointer;
}

.rating-review-wrap span b {
  font-weight: 600;
}

.rating-review-wrap span {
  margin-left: 10px;
}

.rating-review-wrap span:first-of-type {
  margin-left: 0 !important;
}

.price {
  display: flex;
}

.it-dtls {
  font-weight: bold;
  font-size: 21px;
  margin-top: 13px;
  line-height: 46px;
  display: block;
  margin-bottom: 4px;
}

.buy-now {
  display: flex;
  align-items: center;
  background: #efefef;
  padding: 5px 20px;
  border-radius: 5px;
  margin-bottom: 25px;
  justify-content: center;
}

.buy-now span {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
}

.shop-now {
  font-family: "Roboto";
  letter-spacing: 0.4px;
}

.buy-now a {
  margin-left: 10px;
}

.like-item {
  padding-left: 50px;
  position: relative;
  font-family: "Roboto";
}

.like-item i {
  position: absolute;
  top: 4px;
  left: 0;
}

.like-item strong,
.like-item span {
  font-size: 15px;
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
}

.like-item span {
  font-size: 14px;
  font-weight: 300;
}

/* Make an offer popup End */
.location-text {
  display: flex;
  flex-wrap: wrap;
}

.header-address .deliver-text {
  width: 100%;
  font-size: 10px;
}

.location-mark i {
  font-weight: 500;
  margin-right: 5px;
}

@media all and (min-width: 1700px) {
  .buy-now-btn:after {
    padding: 23px 50% 29px;
  }

  .option-container-item {
    height: 96px;
    width: 95px;
  }
}

@media only screen and (max-width: 1699px) {
  .product-name {
    font-size: 26px;
  }

  .details-dropdown .droplink,
  .item-seller-info .verify-txt,
  .product-clr,
  .item-name-detail {
    font-size: 16px;
  }

  .make-offer-btn,
  .buy-now-btn {
    font-size: 15px;
    height: 54px;
  }

  .product-verification-btn {
    font-size: 16px;
    border-width: 2px;
  }

  .reference-link {
    font-size: 14px;
  }

  .seller-info .info-title h4 {
    font-size: 20px;
  }

  .seller-text {
    font-size: 18px;
    text-align: left;
    line-height: 1.4;
  }

  .seller-spacewrap {
    padding: 0 15px;
  }

  .verify-title,
  .similar-product-caption h2 {
    font-size: 40px;
  }

  .verify-title {
    font-size: 42px;
  }

  .verify-product .info figure {
    width: 600px;
    height: 407px;
    padding: 25px 57px 22px 67px;
  }

  .rating-list li {
    margin-right: 1px;
  }

  /* .productfit-imagewrap{
    min-height:545px!important;
    max-width: 410px!important;
    min-width: 410px!important;
  } */
}

@media only screen and (max-width: 1499px) {
  /* .breadcrumb-wrap {
    padding: 20px 4% 20px 2%;
  } */

  .product-name,
  .it-dtls,
  .about-seller h4 {
    font-size: 18px;
  }

  .confirmed span,
  .product-clr,
  .product-verification-btn,
  .item-name-detail .desc,
  .updated-time,
  .reports {
    font-size: 14px;
  }

  .about-seller h4.info-title {
    font-size: 16px;
  }

  .verify-product .info:last-child {
    background: #f7f5f5;
    padding: 60px 30px;
  }

  .verify-product .info figure {
    width: 513px;
    height: 348px;
    padding: 20px 48px 20px 56px;
  }

  /* 10 nov */


  .sales-details {
    width: 100%;
  }

  .seller-info {
    /* width: 22%; */
    width: auto;
  }

  .rating-list li {
    margin-right: 6px;
  }

  .product-sellerwrap .last-chance {
    padding-bottom: 50px;
  }

  .verify-product .info {
    padding: 90px 40px;
  }

  /* .productfit-imagewrap {
    min-height: 305px!important;
    max-width: 295px!important;
    min-width: 295px!important;
  } */
  .option-container-item {
    width: 101% !important;
  }

  .popup-block h3 {
    margin-bottom: 25px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 1365px) {

  /* .breadcrumb-wrap {
    padding: 20px 35px 20px 35px;
  } */
  .item-seller-info {
    padding: 13px 4% 60px 4%;
  }
}

@media only screen and (max-width: 1240px) {

  /* .breadcrumb-wrap {
    padding: 20px 25px 20px 25px;
  } */
  .item-seller-info {
    padding: 13px 4% 50px 4%;
  }
}

@media only screen and (max-width: 1199px) {
  .seller-info .info-title h4 {
    font-size: 18px;
  }

  .seller-text {
    font-size: 16px;
  }

  .seller-info .ratting-img {
    max-width: 90%;
  }

  .seller-text2 {
    font-size: 16px;
  }

  .verify-product .info:last-child,
  .verify-product .info {
    padding: 60px 5%;
  }

  .verify-title,
  .similar-product-caption h2 {
    font-size: 32px;
  }

  .verify-product h3 {
    font-size: 32px;
  }

  .ReactCollapse--content {
    font-size: 14px;
  }

  .verify-product .info figure {
    width: 388px;
    height: 264px;
    padding: 15px 37px 15px 40px;
  }

  /* 10 nov */
  .item-seller-info {
    padding: 13px 4% 25px;
  }

  /* .breadcrumb-wrap {
    padding: 20px 85px 20px 75px;
  } */

  .productfit-imagewrap {
    min-width: 175px !important;
  }

  .rating-list li {
    margin-right: 2px;
  }

  .verify-product .container,
  .ques-ans-wrapper,
  .product-sellerwrap .last-chance .container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .product-fitimage {
    width: 305px !important;
    min-width: 305px !important;
    object-fit: contain !important;
  }
}

@media only screen and (max-width: 991px) {
  .item-seller-info {
    flex-flow: wrap;
  }

  .product-view {
    width: 75%;
    margin-bottom: 80px;
    margin-right: 0;
  }

  .verify-title,
  .similar-product-caption h2 {
    font-size: 26px;
  }

  .verify-product .info figure {
    width: 300px;
    height: 205px;
    padding: 15px 28px 15px 34px;
  }

  .productfit-imagewrap {
    min-height: 210px !important;
  }

  .seller-info {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .make-offer-popup .offer-input {
    max-width: none;
  }

  .mobile-header-address.header-address {
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 110px;
    border-radius: 0;
    padding: 8px 17px;
    background: #622bc5;
    color: #fff;
    position: static;
    margin-top: 49px;
  }

  .location-mark {
    min-height: auto;
    width: 100%;
  }

  .location-text {
    width: 100%;
    flex-wrap: initial;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1.1px;
  }

  .header-address .deliver-text {
    width: auto;
    margin-right: 3px;
    font-size: 13px;
  }

  .location-mark i {
    font-size: 20px;
    margin-right: 9px;
  }

  .header-address .delivery-text {
    max-width: none;
  }

  .deliver-zip {
    margin-left: 3px;
  }

  .option-container .slick-list {
    height: auto !important;
  }

  .multiple-items .summary-multi-bill .bill-price-list {
    text-align: center;
    margin-bottom: 15px;
  }

  .multiple-items .order-summary-multi h3 {
    text-align: left;
  }

  .multiple-items .order-summary-multi h6 {
    text-align: left;
  }

  .multiple-items .summary-multi-bill .bill-heading {
    width: 60%;
    font-size: 14px;
    text-align: left;
  }

  .multiple-items .items-table-wrap {
    padding: 0;
  }

  .multiple-items .summary-multi-bill .bill-price {
    margin-top: 7px;
    width: 40%;
    text-align: center;
    font-size: 14px;
  }

  .slide-box-wrap {
    position: static;
    z-index: unset;
  }

  .option-container-item {
    width: 70px;
    height: 100px;
  }

  .sales-details,
  .seller-info {
    width: 100%;
  }

  .verify-product .verify-wrap {
    display: block;
  }

  .verify-product .info {
    width: 100%;
    margin-bottom: 20px;
  }

  .last-chance .similar-product-caption {
    width: 100%;
    text-align: center;
  }

  .make-offer-popup .offer-content {
    height: 100%;
    min-height: auto;
    height: auto;
    padding: 20px 16px;
    width: 100%;
  }

  .right-prof-sec .make-offer-popup .offer-content {
    width: 94%;
  }


  .register-popup form {
    padding-bottom: 28px;
  }

  /* .make-offer-popup .offer-title {
    padding: 29px 0 34px;
  } */
  .make-offer-popup .offer-desc {
    line-height: 16px;
    margin-bottom: 16px;
    font-size: 13px;
  }

  .make-offer-popup .offer-img {
    width: 60px;
    height: 60px;
    margin-right: 18px;
    background: transparent;
    border-radius: 7px;
  }



  .offer-input-box {
    margin-top: 8px;
  }

  .make-offer-popup .offer-txt {
    font-size: 13px;
    line-height: 20px;
    margin-left: calc(66px + 7px);
  }

  .make-offer-popup .offer-price {
    margin-left: 0;
  }

  .make-offer-popup .offer-price:before {
    width: 100%;
  }

  .option-container {
    margin-right: 0;
  }

  .product-view {
    width: 70%;
    margin-bottom: 0;
  }

  .slide-box-wrap,
  .des-wrap {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }

  .about-seller,
  .post-ques-btn {
    margin-bottom: 20px;
  }

  .seller-wrap {
    flex-direction: column;
  }

  .profile-wrapper .wishlist-section .plp-product-screen {
    max-width: 305px;
    margin: 0 auto;
  }

  .des-wrap {
    padding-top: 4px;
  }

  .navigation-screen .attr-elements .slct-optns {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

@media all and (min-width: 575px) {
  .make-offer-btn:after {
    right: -9px;
    padding: 23px 50% 29px;
  }
}

@media only screen and (max-width: 575px) {
  .verify-product .info figure {
    width: 260px;
    height: 180px;
    padding: 11px 24px 11px 30px;
  }

  .wrap-delivery {
    flex-flow: wrap;
  }

  .offered-popup-wrap.make-offer-popup .offer-title:after {
    width: 110%;
  }
}



/* more-btn */
.more-btn-wrap {
  text-align: center;
  padding: 15px 0;
}

.more-btn-wrap .more-btn {
  color: #d16d38;
  padding: 10px 25px;
  border-radius: 3px;
  transition: all 0.5s ease-in-out;
  font-size: 16px;
}

/* -- question-answer -- */
.ques-ans-wrapper {
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  padding: 50px 45px 50px 45px;
  margin-bottom: 40px;
}

.ques-ask-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*display: none;*/
}

.ques-ans-wrapper h3 {
  font-family: "Ogg";
  color: #d2703c;
  font-size: 40px;
  font-weight: normal;
  margin-bottom: 0;
}

.post-ques-btn {
  padding: 10px 20px;
  color: #f86202;
  font-size: 15px;
  font-weight: 500;
  font-family: "poppins", sans-serif;
  border: 1px solid #f86202;
  transition: all 0.25s ease-in-out;
  border-radius: 5px;
}

.post-ques-btn:hover {
  background-color: #f86202;
  color: #ffffff;
}

.post-ques-btn i {
  vertical-align: middle;
  margin-right: 8px;
}

/* .question-wrapper{
  border-bottom: 1px solid #cecece;
} */
.question-wrapper h3 {
  padding: 0px 0 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #cecece;
}

.ques-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.search-option {
  width: calc(100% - 325px);
  border: 1px solid #cecece;
  border-radius: 5px;
  position: relative;
}

.search-box {
  width: 100%;
  border: 0;
  padding: 15px 20px 15px 50px;
  border-radius: 5px;
  color: #4a4a4a;
  font-weight: 300;
  border: 0;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}

.search-option .icon-search {
  position: absolute;
  left: 15px;
  top: 15px;
}

.error-msg {
  width: 100%;
  padding: 25px;
  /*display: none;*/
}

.ques-cards {
  border: 1px solid #cecece;
}

.cards-wrap {
  padding: 15px;
  border-bottom: 1px solid #cecece;
  position: relative;
  padding-right: 150px;
}

.cards-wrap:last-child {
  border-bottom: none;
}

.cards-wrap .question {
  font-weight: 600;
}

.cards-wrap p {
  margin-bottom: 5px;
}

.cards-wrap p span {
  margin-right: 5px;
  display: inline-block;
  font-weight: 600;
}

.cards-wrap .user-name {
  color: #968f8f;
  font-weight: 500;
  font-size: 13px;
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 15px;
}

.other-ans {
  color: #d16d38;
  display: block;
  font-weight: 700;
}

.post-ans {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #d16d38;
  text-decoration: underline;
  font-weight: 700;
  transition: all 0.5s ease-in-out;
}

.post-ans:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* popup */
.popup-wrap {
  height: 100%;
  width: 100%;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 30;
  background: rgba(0, 0, 0, 0.75);
  overflow-y: auto;
}

.popup-sec {
  max-width: 600px;
  padding: 45px;
  margin: 70px auto 50px;
  background: #fff;
  position: relative;
  border: 1px solid #707070;
  overflow: auto;
}

.popup-cards {
  height: 100%;
  overflow: auto;
  border: 1px solid #cecece;
}

.popup-block {
  height: 100%;
  overflow: auto;
  border: none;
  padding: 0;
}

.field-wrap {
  width: 100%;
  height: 130px;
  border: 1px solid #cecece;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;
}

.field-input {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 15px 20px;
  border-radius: 5px;
  color: #4a4a4a;
  font-weight: 300;
  border: 0;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}

.post-btn-wrap {
  padding: 10px 20px;
  background-color: #d16d38;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "poppins", sans-serif;
  border: 1px solid #d16d38;
  transition: all 0.25s ease-in-out;
  float: right;
  clear: both;
  border-radius: 40px;
}

.post-btn-wrap:hover {
  background-color: transparent;
  color: #d16d38;
}

.popup-sec .close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.popup-sec .close-btn i {
  font-size: 26px;
}

.popup-sec .close-btn .icon-close {
  position: static;
}



#more-ans-popup {
  display: block;
}



/* responsive */
@media only screen and (max-width: 1199px) {
  .ques-ans-wrapper h3 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 991px) {
  .ques-ans-wrapper h3 {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .ques-ask-wrap {
    display: block;
  }

  .search-option .icon-search {
    left: 10px;
  }

  .search-option {
    width: 100%;
    margin-bottom: 15px;
  }

  .ques-block {
    display: block;
  }

  .popup-sec {
    width: 100%;
  }

  .popup-block {
    padding: 0;
    padding-top: 15px;
  }

  .popup-sec .close-btn {
    top: 0;
    right: 20px;
  }

  .popup-sec .close-btn .icon-close {
    background-position: -148px -46px;
    background-size: 511px;
  }
}

@media (max-width: 575px) {
  .ques-ans-wrapper {
    padding: 50px 25px 50px 25px;
  }

  .ques-ans-wrapper h3 {
    line-height: 1.2;
  }

  .flip-back-wrap h3 {
    font-size: 20px;
  }
}

@media all and (min-width: 475px) {
  .season-wrap {
    padding: 35px 0;
  }

  .season-wrap i {
    margin-left: 35px;
  }

  .season-wrap:hover {
    -webkit-transform: rotate(0) scale(1.1) translateZ(0);
            transform: rotate(0) scale(1.1) translateZ(0);
  }

  .flip-back-wrap h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .flip-back-wrap .search-option {
    width: 100%;
    margin-bottom: 20px;
  }

  .flip-back-wrap .ques-block {
    justify-content: center;
  }
}

@media all and (min-width: 1024px) {
  .dual-order .dual-content-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .verify-product .flip-box {
    max-width: 672px;
    width: 40%;
  }
}

@media all and (min-width: 768px) {
  .header-address .deliver-text {
    width: 100%;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .updated-bar {
    display: flex;
  }

  .flip-box {
    max-width: 672px;
    width: 46%;
  }

  .product-view {
    width: calc(100% - 110px);
  }

  .slide-box-wrap {
    /* overflow: hidden; */
    width: 100%;
    display: block;
    margin-right: 0;
  }

  .pro-slide-wrap {
    width: 45%;
    order: 1;
    float: left;
    margin-bottom: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 9999;
  }


  .updt-info-wrp {
    width: 100%;
    order: 3;
    float: left;
  }

  .slide-box-wrap .des-wrap {
    order: 2;
    float: right;
    padding-right: 0;
  }

  .product-details-name .product-name {
    padding-right: 17px;
  }

  .slide-box-wrap .complete-purchase.btn-shop {
    display: inline-block;
    width: auto;
    background: transparent;
    border: 0;
  }

  .slide-box-wrap .complete-purchase.btn-shop button {
    border-left: 1px solid #f86202 !important;
    border-right: 1px solid #f86202 !important;
  }

  .slide-box-wrap .btn-wrap button.complete-purchase {
    width: 161px;
  }
}

@media all and (min-width: 1024px) {


  .product-details-name .product-name {
    padding-right: 0;
  }

  .slide-box-wrap .des-wrap {
    padding-right: 60px;
  }

  .updt-info-wrp {
    width: 45%;
    margin-top: 30px;
  }
}

@media all and (min-width: 1920px) {
  .season-wrap i {
    background-size: 900px 700px;
    background-position: -21px -611px;
    width: 77px;
    height: 17px;
  }

  .flip-box-inner h2 {
    font-size: 80px;
  }

  .verify-product .flip-box {
    min-height: 998px;
  }

  .verify-product .flip-box-inner {
    padding: 87px;
    height: 98.5%;
  }

  .question-info:first-child:after {
    height: 51%;
  }
}

@media all and (min-width: 992px) {
  .product-view .main-item div {
    text-align: left;
  }
}

/* Changes */
.dates-selector {
  flex-wrap: wrap;
}

.dates-selector .dates-wraps {
  margin-bottom: 20px;
  width: 100%;
}

.dates-wraps {
  position: relative;
  display: flex;
  align-items: center;
}

.dates-wraps input {
  flex-grow: 1;
}

.dates-wraps label {
  width: 64px;
}

.dates-wraps span {
  width: 64px;
}

.dates-wraps .toggle-password {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 13px;
}

.popup-body.offer-making {
  padding: 27px 0 30px;
}

.popup-body.offer-making h3 {
  margin-bottom: 45px;
  font-size: 25px;
}

.popCalendar.calendar-popup {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.popCalendar.calendar-popup .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
  white-space: nowrap;
}

.popCalendar.calendar-popup .react-calendar {
  width: 310px;
}



.deliver-zip {
  margin-left: 5px;
}

.wrap-delivery {
  display: flex;
}



@media all and (min-width: 768px) {
  .header-address .delivery-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .dates-selector {
    flex-wrap: nowrap;
  }

  .dates-selector .dates-wraps {
    margin-bottom: 0;
  }

  .dates-wraps span {
    width: auto;
  }

  .popup-body.offer-making {
    padding: 16px 0 30px;
  }

  .popup-body.offer-making h3 {
    text-align: left;
    margin-bottom: 67px;
    font-size: 20px;
    width: 100%;
    padding-right: 40px;
    position: relative;
  }

  .popup-body.offer-making h3::after {
    position: absolute;
    bottom: -20px;
    left: -15px;
    width: calc(100% + 30px);
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
    content: "";
  }
}

.smart-price {
  background: #ececec;
  padding: 13px 15px 17px;
  border-radius: 5px;
  margin-top: 13px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.26);
}

.smart-price span {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.smart-price p {
  color: rgb(76, 76, 76);
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.rec-input {
  width: 100%;
  margin-top: 16px;
}

.smart-price .smart-input {
  background: #fff;
  width: 100%;
  border: 1px solid #cdced2;
  padding: 11px 15px;
  font-size: 15px;
  border-radius: 4px;
  color: #4c4c4c;
  max-width: 168px;
}

.smart-price .smart-input:disabled {
  background: #d2d2d2;
}

.smart-price .btns-status .ship-btn {
  margin-right: 0;
}

.smart-price .btns-status .ship-btn.active {
  border: 1px solid #f86202;
}

.smart-price .btns-status .ship-btn:hover {
  color: #f86202;
  background: #fff;
}

.navigation-container.both-btn .navigation-back .back-btn {
  transition: all 0.5s;
}

.navigation-container.both-btn .navigation-back .back-btn:hover {
  opacity: 0.7;
}

.dashboard .slick-initialized .slick-slide {
  height: inherit;
}

.dashboard .slick-initialized .slick-slide.slick-active.slick-current {
  z-index: 1 !important;
}

.dashboard .slick-initialized .slick-slide>div {
  height: 100%;
}

.dashboard .slick-initialized .slick-slide .costume-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff !important;
  background-color: #fff !important;
}

.dashboard .slick-initialized .slick-slide .costume-box a {
  flex-grow: 1;
}

.dashboard .slick-initialized .slick-slide .costume-info {
  height: 100%;
}

.dashboard .slick-track {
  display: flex;
}

.heart-like .fa-heart {
  color: #f47900;
  font-size: 20px;
}

.attr .label-txt.attr-select {
  font-size: 15px;
}

.checkout-cardwrap .qty-input {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.checkout-cardwrap .qty-input input {
  text-align: center;
  border-top: none;
  border-bottom: none;
  border-radius: 0;
  padding: 0;
  font-size: 11px;
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkout-cardwrap .qty-input button {
  background-image: linear-gradient(#fbfbfb, #e4e4e4);
  padding: 5px 3px;
  font-size: 10px;
}

.checkout-cardwrap .qty-input button:hover {
  background-image: linear-gradient(#e4e4e4, #fbfbfb);
}

.checkout-cardwrap .qty-input button[disabled]:hover {
  background-image: linear-gradient(#fbfbfb, #e4e4e4);
  cursor: unset;
}

.checkout-cardwrap .qty-input .qty-decrement {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.checkout-cardwrap .qty-input .qty-increment {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.applied-msg {
  margin-top: 5px;
  font-family: "Roboto";
}

.recommended-value {
  margin-top: 7px;
  font-size: 14px;
  color: #f86202;
  font-weight: 500;
}

.smart-price span {
  font-size: 12px;
}

.navigation-screen .attr-elements {
  display: flex;
  flex-flow: wrap;
}

.navigation-screen .attr-elements .select-attr,
.navigation-screen .attr-elements .label-txt {
  width: 100%;
}

.navigation-screen .attr-elements .slct-optns {
  width: 48%;
  margin-right: 2%;
}

.navigation-screen .attr-elements .slct-optns .slt-url {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navigation-screen .attr-elements .slct-optns .slt-url>div {
  width: 68%;
}

.navigation-screen .attr-elements .slct-optns .slt-url select {
  cursor: pointer;
  -webkit-appearance: none;
  border: 1px solid #cdced2;
  border-radius: 4px;
  height: 47px;
  margin-left: 8px;
  width: 31%;
  background-image: linear-gradient(45deg, transparent 50%, #bababa 50%),
    linear-gradient(135deg, #bababa 50%, transparent 50%);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) calc(0.8em + 6px),
    calc(100% - 15px) calc(0.8em + 6px), calc(100% - 2.5em) 0.5em;
  background-size: 7px 6px, 6px 7px, 0 0;
}

.navigation-screen .attr-elements .label-txt {
  font-size: 16px;
  text-transform: capitalize;
}

.navigation-screen .attr-elements .select-attr .label-txt {
  font-size: 14px;
  margin-bottom: 8px;
}

.navigation-screen .attr-elements .attr-optns .label-txt {
  margin-bottom: 16px;
}

.attr-elements .select-attr:nth-last-child(2) .slct-optns .attr-optns {
  width: auto;
}

.attr-elements .select-attr:last-child .slct-optns .attr-optns {
  width: auto;
}

.price-input-wrap {
  position: relative;
}

.rupees-icon {
  position: absolute;
  top: 14px;
  left: 15px;
}

.price-input-wrap .input-url {
  padding-left: 24px;
}

.product-review-images {
  display: block;
  width: 70px;
  height: 70px;
}

.prof-img .product-review-images img {
  width: 100%;
  margin-right: 0;
  height: 100%;
  object-fit: contain;
}

.status-details span {
  font-size: 13px;
  line-height: 1.2;
}

.del-details span {
  font-size: 13px;
}

.prod-title.prod-toggle .del-details p {
  font-size: 13px;
}

.status-details {
  font-size: 13px;
}

.del-details strong {
  font-size: 13px;
}

.make-offer-popup.cancel-return {
  font-family: "Roboto";
}

.make-offer-popup.cancel-return .offer-title.heading {
  padding: 14px 28px 0 0;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
}

.make-offer-popup.cancel-return .text-reason {
  border: 1px solid #cdced2;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 18px;
}

.make-offer-popup.cancel-return .full-width {
  border: 1px solid #cdced2;
  font-family: "Roboto";
  border-radius: 4px;
  color: rgb(51, 51, 51);
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 0 35px 0 15px;
  cursor: pointer;
}

.make-offer-popup.cancel-return .reason-selector {
  margin-bottom: 18px;
  position: relative;
}

.make-offer-popup.cancel-return .reason-selector i {
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: 14px;
  font-size: 14px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.make-offer-popup.cancel-return .full-width::-ms-expand {
  display: none;
}

.make-offer-popup.cancel-return .header-sell {
  height: auto;
}

.make-offer-popup.cancel-return .text-reason textarea {
  width: 100% !important;
  border: none;
  font-family: "Roboto";
  color: rgb(51, 51, 51);
  display: block;
}

.make-offer-popup.cancel-return .text-reason textarea::-webkit-input-placeholder {
  color: rgb(51, 51, 51);
}

.make-offer-popup.cancel-return .text-reason textarea::placeholder {
  color: rgb(51, 51, 51);
}

.make-offer-popup.cancel-return .text-reason textarea:focus {
  outline: none;
}

.make-offer-popup.cancel-return .offer-title {
  padding-bottom: 15px;
}

.make-offer-popup.cancel-return .header-sell {
  font-size: 15px;
}

.footer-social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social .fb-link {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.footer-social .fb-link:hover {
  border: 1px solid #3b5998;
}

.footer-social .twitter-link {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.footer-social .twitter-link:hover {
  border: 1px solid #1da1f2;
}

.footer-social .insta-link {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.footer-social .insta-link:hover {
  border: 1px solid #bc2a8d;
}

.withdraw-history-link {
  margin-top: 10px;
  display: block;
}

.green a {
  transition: all 0.5s;
}

.green a:hover {
  opacity: 0.7;
}

.footer-social i {
  font-size: 19px;
  color: #999999;
  transition: all 0.5s;
  margin-right: 20px;
  border: 1px solid #999999;
  ;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  text-rendering: optimizeLegibility;
}

.footer-social i:hover {
  color: #f47900;
  border-color: #f47900;
}

.footer-social .fb-link:hover i {
  color: #3b5998;
}

.footer-social .twitter-link:hover i {
  color: #1da1f2;
}

.footer-social .insta-link:hover i {
  color: #bc2a8d;
}

.withdraw-panel {
  font-family: "Roboto";
}

.withdraw-panel h4 {
  font-size: 23px;
  margin-bottom: 11px;
  color: #1d1d23;
}

.withdraw-panel p {
  color: #000000;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 1.3;
}

.withdraw-panel .italic-text {
  font-style: italic;
}

.balance-block {
  margin-bottom: 50px;
}

.balance-block .balance-number {
  color: #622bc5;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 5px;
}

.balance-block h6 {
  text-align: center;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 15px;
  padding: 0;
  margin: 0;
}

.withdraw-inputs {
  margin-bottom: 35px;
}

.withdraw-inputs .amount-block {
  margin-bottom: 20px;
}

.withdraw-inputs .label-text {
  color: #1d1d23;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 5px 3px;
  display: block;
}



.withdraw-inputs .input-amount {
  width: 100%;
  border: 1px solid #cecdcd;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
  color: #1d1d23;
  border-radius: 4px;
  padding: 20px 15px;
}

.withdraw-inputs .input-amount::-webkit-input-placeholder {
  color: rgba(29, 29, 35, 0.42);
}

.withdraw-inputs .input-amount::placeholder {
  color: rgba(29, 29, 35, 0.42);
}

.withdraw-inputs h5 {
  margin: 8px 0 0 0;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
}



.withdraw-inputs .withdraw-select {
  width: 100%;
  border: 1px solid #cecdcd;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
  color: #999999;
  border-radius: 4px;
  padding: 19px 37px 19px 15px;
  height: auto;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.withdraw-inputs select::-ms-expand {
  display: none;
}

.withdraw-inputs .withdrwa-select-wrap {
  position: relative;
}

.withdraw-inputs .withdrwa-select-wrap i {
  position: absolute;
  top: 50%;
  right: 15px;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.withdraw-inputs .add-bank-link {
  color: #f86202;
  text-decoration: underline;
  font-weight: 500;
  display: inline-block;
  margin-top: 10px;
  transition: all 0.5s;
}

.withdraw-inputs .add-bank-link:hover {
  color: #622bc5;
}

.bank-column {
  border-top: 1px solid #cecdcd;
  padding: 35px 0;
}

.bank-column .bank-balance {
  background: #eaeaea;
  display: flex;
  align-items: flex-start;
  padding: 15px 20px 15px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.bank-column h4 {
  margin-bottom: 34px;
}

.bank-column .bank-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bank-column .bank-icon {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #bababa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bank-column .bank-icon img {
  display: block;
}

.bank-column .bank-detail {
  margin-left: 12px;
  font-size: 17px;
}

.bank-column .bank-name {
  color: #1d1d23;
  font-weight: 500;
  margin-bottom: 4px;
}

.bank-column .bank-number {
  color: #bdbece;
  font-weight: 500;
  margin-bottom: 12px;
}

.bank-column .change-btn {
  color: #bdbece;
  text-decoration: underline;
  transition: all 0.5s;
  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto";
  margin-right: 15px;
}

.bank-column .change-btn i {
  margin-left: 4px;
  font-size: 12px;
}

.bank-column .change-btn:hover {
  color: rgb(58, 85, 169, 0.8);
}

.transaction-listing {
  max-width: 435px;
}

.transaction-listing li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.transaction-listing li:last-child {
  margin-bottom: 0;
}

.transaction-listing .list-heading {
  width: 61%;
  padding-right: 20px;
  font-size: 14px;
}

.transaction-listing .list-value {
  color: #1d1d23;
  font-size: 15px;
  font-weight: 700;
}

.transaction-listing .list-value.purple-value {
  color: #622bc5;
}

.withdraw-amounts h4 {
  margin-bottom: 27px;
}

.withdraw-amounts {
  margin-bottom: 35px;
}

.withdraw-btns {
  /* width: calc(100% + 60px); */
  /* margin-left: -30px; */
  /* border-top: 1px solid #d8d8d8; */
  width: 100%;
  /* padding: 27px 30px 0; */
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.withdraw-btns .withdraw-cancel {
  border: 1px solid #747477;
  font-weight: 500;
  color: #1d1d23;
  padding: 7px 12px;
  font-size: 17px;
  border-radius: 5px;
  transition: all 0.5s;
}

.withdraw-btns .withdraw-cancel:hover {
  color: #fff;
  background: #1d1d23;
}

.withdraw-btns .withdraw-continue {
  border: 1px solid #f86202;
  font-weight: 500;
  color: #fff;
  padding: 7px 20px;
  font-size: 17px;
  border-radius: 5px;
  background: #f86202;
  transition: all 0.5s;
}

.Sales-page.transaction {
  margin-bottom: 0;
}

.withdraw-btns .withdraw-continue:hover {
  color: #f86202;
  background: transparent;
}

.seller-input .MuiInputLabel-formControl {
  top: 50%;
  transition: all 0.3s;
}

.seller-input .MuiInputLabel-outlined {
  -webkit-transform: translate(14px, -50%) scale(1);
          transform: translate(14px, -50%) scale(1);
  font-size: 16px;
  font-weight: 500;
  color: #1d1d23;
}

.signup-form .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  display: none;
}

.signup-form .MuiInputLabel-outlined.MuiInputLabel-shrink {
  padding: 0 7px;
  background: #fff;
}

.review-popup.make-offer-popup.review-popup .offer-title {
  text-align: center;
}

.signup-form .detail-from .MuiFormControl-root input {
  border: none;
  font-size: 16px;
}

.signup-form .detail-from .MuiFormControl-root input::-webkit-input-placeholder {
  color: #000;
  opacity: 0.6;
}

.signup-form .detail-from .MuiFormControl-root input::placeholder {
  color: #000;
  opacity: 0.6;
}

.signup-form .detail-from .MuiFormControl-root input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
  opacity: 0.6;
}

.signup-form .detail-from .MuiFormControl-root input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
  opacity: 0.6;
}

.update-value .update-status {
  margin-top: 5px;
}

.seller-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: 1px;
}

.seller-input .MuiFormControl-root {
  width: 100%;
  height: 100%;
}

.seller-input .MuiInputBase-root {
  height: 100%;
}

.signup-form .MuiFormControl-root {
  width: 100%;
}

.seller-input .MuiInputBase-root input {
  border: none;
}

.seller-input .MuiSelect-select:focus {
  background: transparent;
  text-align: left;
}

.seller-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: #fff;
  padding: 0 5px;
}

.seller-input .MuiSelect-outlined.MuiSelect-outlined {
  text-align: left;
  min-height: auto;
  height: auto;
  padding: 18px 50px 18px 16px;
  line-height: 1.3;
  font-size: 16px;
  font-weight: 500;
}

legend span {
  margin-right: -8px;
}

.modal-dialog.modal-90w.bank .add-links .btn-purple {
  height: auto;
  width: auto;
  padding: 15px 61px;
  font-size: 19px;
}

.matrix-info {
  width: 14px;
  height: 14px;
  display: inline-block;
  cursor: pointer;
  background: url(/assets/images/icn-info-light.svg) no-repeat center;
  margin: 0 0 -2px 5px;
  position: relative;
}

.matrix-pop {
  position: absolute;
  top: 14px;
  left: 0;
  display: none;
  width: 161px;
  padding: 7px 0;
  z-index: 1;
}

.matrix-info-popup {
  width: 100%;
  display: block;
  background: rgba(0, 0, 0);
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
}

.matrix-info:hover .matrix-pop {
  display: block;
}

.matrix-info:hover {
  background: url(/assets/images/icn-info-light-hover.svg) no-repeat center;
}

.about-team {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  background: #994fb1;
  z-index: 10;
  overflow: auto;
  height: 100%;
}

.chat-fld-wrap .full-item-detail .item-img img {
  max-height: 250px;
  object-fit: contain;
}

.about-container {
  max-width: 1430px;
  margin: 0 auto;
  padding: 0 15px;
}

.team-popup-head {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 16px 0 10px 0;
  margin-bottom: 16px;
}

.team-close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 11px;
}

.team-close i {
  transition: all 0.5s;
  font-size: 33px;
  color: #fff;
}

.team-close i:hover {
  color: #f86202;
}

.team-images {
  padding: 15px;
  background: #fff;
}

.team-images img {
  width: 100%;
  display: block;
}

.team-status h4 {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 3px;
}

.team-status h6 {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: #e0b6ee;
}

.para-team {
  background: #fff;
  padding: 15px;
}

.para-team p {
  margin-bottom: 20px;
  color: #000;
  line-height: 1.5;
}

.para-team p:last-child {
  margin-bottom: 0;
}

.general-terms {
  font-family: "Roboto" !important;
}

.privacy-details h2 {
  font-family: "Roboto" !important;
}

.privacy-details p {
  font-family: "Roboto" !important;
}

.register-popup.seller-register .label-text {
  color: rgb(76, 76, 76);
  font-weight: 600;
  font-family: "Roboto";
  display: block;
  width: 100%;
  margin-bottom: 3px;
  text-align: left;
}

.new-add-form .form-input span.highlighted {
  margin-bottom: 0;
}

.transaction-tables {
  width: 100%;
  overflow: auto;
}

.withdraw-table {
  font-family: "Roboto";
  background: #fff;
  border-radius: 4px;
  padding: 0 6px 26px 3px;
  min-width: 809px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.transaction-tab h4 {
  font-size: 20px;
  font-family: "Roboto";
  margin-bottom: 12px;
}

.withdraw-table .withdraw-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(76, 76, 76);
  font-size: 15px;
  font-weight: 500;
  padding: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.withdraw-table .withdraw-values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}



.withdraw-main {
  width: 16%;
  flex-shrink: 0;
  padding: 0 5px 0 0;
}

.withdraw-id {
  width: 16%;
  flex-shrink: 0;
  padding: 0 5px;
}

.withdraw-date {
  width: 12%;
  flex-shrink: 0;
  padding: 0 5px;
}

.req-date {
  width: 16%;
  flex-shrink: 0;
  padding: 0 5px;
}

.withdraw-date.req-date {
  width: 16%;
}

.withdraw-amount {
  width: 9%;
  flex-shrink: 0;
  padding: 0 5px;
}

.withdraw-left {
  width: 18%;
  flex-shrink: 0;
  padding: 0 5px;
}

.withdraw-status {
  width: 10%;
  flex-shrink: 0;
  padding: 0 0 0 5px;
}

.transaction-tab .balance-block {
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 30px;
}

.withdraw-values.pending .withdraw-main .transaction-logo i {
  color: #f67900;
}

.withdraw-table .withdraw-values.pending .withdraw-status {
  border: 1px solid #f67900;
  background: rgba(246, 121, 0, 0.09);
}

.withdraw-values.reject .withdraw-main .transaction-logo i {
  color: #ff0000;
}

.withdraw-table .withdraw-values.reject .withdraw-status {
  border: 1px solid #ff0000;
  background: rgba(255, 0, 0, 0.09);
}

.sign-form legend>span:last-child {
  /* display: none; */
  margin-right: -19px;
}

.transaction-tab .withdraw-panel {
  margin-bottom: 30px;
}

.withdraw-values .withdraw-main {
  display: flex;
  align-items: center;
}

.withdraw-main .transaction-logo i {
  display: block;
  font-size: 29px;
  color: #0a990a;
}

.withdraw-main .transaction-logo {
  margin-right: 10px;
}



.withdraw-table .withdraw-values .withdraw-status {
  text-align: center;
  border: 1px solid #0a990a;
  border-radius: 20px;
  padding: 6px 5px;
  background: rgba(10, 153, 10, 0.09);
}

.header-address {
  position: absolute;
  top: 63px;
  z-index: 12;
  left: 15%;
  /* width: 100%; */
  width: 113px;
  display: flex;
  justify-content: center;
  color: #f86202;
  border-radius: 6px;
  font-size: 10px;
  /* font-size: 12px; */
  /* font-weight: 500; */
  font-family: "Roboto";
  background: #e8e8e8;
  /* padding: 4px 6px; */
  /* padding: 7px 15px 3px; */
  z-index: 1;
  display: none;
}

.mobile-header-address.header-address {
  display: block;
}

.product-location-wraps {
  padding: 4px;
  cursor: pointer;
  transition: all 0.5s;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 32px;
}

.product-location-wraps:hover {
  color: #622bc5;
}

.header-address .product-location {
  display: flex;
  flex-wrap: wrap;
}

.distance-mark i {
  margin-left: 5px;
}

.location-mark {
  /* min-height: 20px; */
  display: flex;
  align-items: center;
}

.transaction-center {
  width: 100%;
  text-align: center;
  margin-top: 6px;
}

.bank-info {
  margin-top: 5px;
}

.transaction-btn {
  display: inline-block;
  color: #f67900;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.5s;
}

.transaction-btn:hover {
  opacity: 0.7;
}

.transaction-tab {
  margin: 0 -12px;
  background-color: #fff;
  padding: 18px 13px;
}

.make-offer-popup.review-popup {
  font-family: "Roboto";
}

.make-offer-popup.review-popup .offer-title {
  font-weight: 500;
  padding: 13px 31px 13px 0;
  text-align: left;
  margin-bottom: 16px;
}

.make-offer-popup.review-popup .form-input span {
  font-size: 18px;
  font-weight: 500;
}

.make-offer-popup.review-popup .rating__item {
  cursor: pointer;
}

.make-offer-popup.review-popup .form-input textarea {
  width: 100% !important;
  border: 1px solid #cdced2;
  border-radius: 4px;
  font-size: 15px;
  height: 157px;
}

.make-offer-popup.review-popup .form-input textarea:focus {
  outline: none;
}

.make-offer-popup.review-popup .detail-from .form-input {
  margin-bottom: 17px;
}

.state-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  font-weight: 400;
  border: 1px solid #cdced2;
  border-radius: 4px;
  font-family: "Roboto";
  color: #4c4c4c;
  height: 42px;
  font-size: 14px;
  padding-right: 38px;
  cursor: pointer;
}

.state-select.add {
  height: 45px;
}

.state-select::-ms-expand {
  display: none;
}

.select-state i {
  pointer-events: none;
  display: block;
  font-size: 18px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 15px;
  color: rgb(76, 76, 76);
}

.select-state {
  position: relative;
}

.select-state .select-opt__control {
  height: 42px;
}

.select-state .select-opt__value-container {
  height: 42px;
}

.new-add-form .detail-from button {
  padding: 10px 50px;
  font-size: 16px;
}

.new-add-form .select-state .select-opt__control {
  height: 45px;
}

.new-add-form .select-state .select-opt__value-container {
  height: 45px;
}



.local-banner {
  padding-bottom: 48px;
  background: #622bc5;
  font-family: "Roboto";
}

.rotate-image {
  margin: 0 -15px 27px;
  width: calc(100% + 30px);
  overflow: hidden;
}

.local-bazaar .local-banner-image {
  margin: 0 -27.5% 0 0;
}

.local-bazaar .local-banner-image img {
  width: 100%;
  display: block;
}

.local-bazaar .banner-texts {
  margin-top: 6px;
}

.local-banner h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 17px;
}

.local-banner p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 52px;
}

.local-banner .get-start {
  border: 1px solid #f86202;
  background: #f86202;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  padding: 12px 38px;
  transition: all 0.5s;
}

.local-banner .get-start:hover {
  color: #f86202;
  background: #fff;
}

.selling-local {
  padding: 20px 0;
}

.selling-local h2 {
  font-size: 26px;
  text-align: center;
  font-weight: 600;
  color: #622bc5;
  padding: 0 10px;
  margin-bottom: 40px;
}

.reason-image {
  width: 90px;
  margin-bottom: 14px;
}

.reasons-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

.reasons-wrap:last-child {
  margin-bottom: 0;
}

.reasons-wrap h4 {
  margin-bottom: 14px;
}

.reasons-wrap p {
  font-size: 14px;
  line-height: 1.5;
}

.reason-image img {
  display: block;
  width: 100%;
}

.order-details .show-review-rating {
  margin-bottom: 10px;
}

.powered-delivery {
  padding: 30px 0;
  max-width: 458px;
  margin: 0 auto;
}

.local-bazaar .container {
  max-width: 1360px;
  padding: 0 15px;
}

.selling-locals {
  background: rgba(115, 51, 129, 0.1);
  padding: 0 0 25px 0;
}

.local-selling-image {
  margin-left: -21%;
  padding-right: 21%;
}

.local-selling-image img {
  display: block;
  width: 100%;
}

.selling-locals h3 {
  font-size: 26px;
  margin-bottom: 16px;
}

.selling-locals .selling-boxes-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.selling-locals h4 {
  font-size: 26px;
  margin-bottom: 18px;
}

.selling-locals p {
  font-size: 16px;
}

.selling-locals .text-wrap-selling {
  width: calc(50% - 10px);
}

.selling-locals .selling-boxes {
  display: flex;
  justify-content: space-between;
}

.selling-locals .selling-box {
  width: calc(50% - 10px);
  text-align: center;
}

.selling-locals .option-boxes {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.selling-locals .option-box {
  padding: 20px 5px;
  margin-bottom: 8px;
  background: #622bc5;
  color: #fff;
  font-size: 16px;
}

.selling-locals .option-box:last-child {
  margin-bottom: 0;
}

.selling-locals .option-box-dotted {
  border: 2px dashed #622bc5;
  text-align: center;
  color: #fff;
  padding: 20px 5px;
  color: rgb(76, 76, 76);
  font-size: 16px;
}

.selling-locals .long {
  min-height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selling-locals .option-box.white {
  background: #fff;
  color: rgb(76, 76, 76);
}

.selling-locals .long.longer {
  min-height: 229px;
  flex-grow: 1;
}



/* .selling-locals{
  
}
.selling-locals{
  
}
.selling-locals{
  
} */
.toggle-password {
  cursor: pointer;
}

.shipping-itemwrap.shipping-remade .desc-txt .edit-wrapper .select-state input {
  border: none !important;
  margin-bottom: 0 !important;
  cursor: pointer;
  padding-left: 15px !important;
}

.shipping-itemwrap.shipping-remade .desc-txt .edit-wrapper .select-state .select-opt__single-value {
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
  top: 50% !important;
  margin: 0;
  left: 15px;
  color: #4c4c4c;
  font-weight: 400;
}

.shipping-itemwrap.shipping-remade .desc-txt .edit-wrapper .select-state .select-opt__single-value~div {
  margin: 0;
}

.new-add-form .select-state input {
  border: none !important;
  margin-bottom: 0 !important;
  cursor: pointer;
  padding-left: 15px !important;
}

.new-add-form .select-state .select-opt__value-container {
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
  top: 50% !important;
  margin: 0;
  left: 15px;
  color: #4c4c4c;
  font-weight: 400;
  font-size: 14px;
  font-family: "Roboto";
}

.new-add-form .select-state .select-opt__single-value {
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
  top: 50% !important;
  margin: 0;
  left: 0;
  color: #4c4c4c;
  font-weight: 400;
  font-size: 14px;
  font-family: "Roboto";
}

.new-add-form .select-state .select-opt__placeholder {
  font-size: 14px !important;
  top: 8px;
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
}

.new-add-form .select-opt__input {
  height: 34px;
}

.new-add-form .select-opt__input input {
  padding: 0 !important;
  height: 100%;
}

.shipping-itemwrap.shipping-remade .desc-txt.edit-address .edit-wrapper .select-opt__input input {
  height: 100% !important;
}

.edit-address .select-opt__input {
  height: 38px;
}

.new-add-form .select-state .select-opt__value-container~div {
  margin: 0;
  height: 100%;
}

.new-add-form .select-state .select-opt__value-container~div .select-opt__indicator-separator {
  display: none;
}

.buy-prod.dual-order {
  padding: 0;
}

.dual-order {
  font-family: "Roboto";
  width: 100%;
  padding-bottom: 25px;
}

.dual-order .dual-order-head {
  background: rgba(208, 208, 208, 0.28);
  padding: 20px 10px;
}

.dual-order .dual-content {
  margin-bottom: 20px;
}

.dual-order .dual-content:last-child {
  margin-bottom: 0;
}

.dual-order .dual-content h4 {
  font-size: 16px;
  margin-bottom: 3px;
  color: #4c4c4c;
  text-transform: uppercase;
}

.dual-order .dual-content h5 {
  font-size: 17px;
  margin-bottom: 0;
  color: #4c4c4c;
  text-transform: uppercase;
}



.dual-order .heading-menu {
  color: #f86202;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  transition: all 0.5s;
}

.dual-order .heading-menu:hover {
  color: #622bc5;
}

.dual-order .heading-menu .menu-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dual-order .heading-menu i {
  margin-left: 10px;
  color: #4c4c4c;
}

.dual-order .dual-menu .view-order {
  color: #f86202;
  font-size: 17px;
  display: block;
  margin: 8px 0;
  transition: all 0.5s;
}

.dual-order .dual-menu .view-order:hover {
  color: #622bc5;
}

.dual-order .ordered-products {
  padding: 20px 10px;
}

.dual-order .ordered-products h3 {
  font-size: 17px;
  margin-bottom: 20px;
}



.dual-order .dual-product-image {
  margin: 0 auto 30px;
}



.multiple-items .accordion-wrapper {
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 20px;
  background: #fff;
}

.multiple-items .accordian-panel {
  /* border-radius: 7px; */
  /* padding: 15px; */
  /* margin-bottom: 20px; */
  overflow: auto;
}

.dual-order .dual-product-image img {
  display: block;
  width: 100%;
  height: 98px;
  object-fit: contain;
  margin: 0 auto;
}

.dual-order .items-text-number {
  font-weight: 700;
  font-size: 20px;
  color: #4c4c4c;
}

.need-help-box {
  margin-bottom: 20px;
  border-radius: 7px;
  background: #fff;
}

.weight-value .cancel-btn {
  font-family: "Roboto";
  border: 1px solid #f86202;
  color: #f86202;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 11px;
  transition: all 0.5s;
  margin-right: 7px;
}

.weight-value .cancel-btn:last-child {
  margin-right: 0;
}

.weight-value .cancel-btn:hover {
  background: #f86202;
  color: #fff;
}





.dual-order .view-dual-order {
  flex-shrink: 0;
}

.dual-order .view-dual-order .view-link {
  cursor: pointer;
  border: 1px solid transparent;
  color: #fff;
  background: #EF7F1A;
  border-radius: 4px;
  padding: 10px 34px;
  font-size: 16px;
  transition: all 0.5s;
}

.dual-order .view-dual-order .view-link:hover {
  background: transparent;
  border: 1px solid #EF7F1A;
  color: #EF7F1A;
}

.multiple-items {
  /* margin: 0 10px; */
  margin-top: 20px;
  padding-bottom: 20px;
  font-family: "Roboto";
}

.multiple-items .ordered-products {
  background: #fff;
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 20px;
}

.multiple-items .products-images {
  display: flex;
  flex-wrap: wrap;
}

.multiple-items .dual-product-image {
  width: 80px;
  margin: 0 20px 20px 0;
}

.multiple-items .dual-product-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}



.multiple-items .order-items h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.multiple-items .view-all-items {
  color: #f86202;
  font-size: 13px;
  transition: all 0.5s;
  cursor: pointer;
  margin-right: 5px;
}

.multiple-items .view-all-items:hover {
  color: #622bc5;
}

.multiple-items .order-summary-multi {
  background: #fff;
  border-radius: 7px;
  padding: 15px 15px 30px;
}

.multiple-items .order-summary-multi h6 {
  padding: 0;
  margin: 0;
  text-transform: none;
  font-weight: 400;
}

.multiple-items .order-summary-multi h3 {
  font-size: 16px;
  margin-bottom: 13px;
  font-weight: 500;
}

.multiple-items .order-summary-multi h4 {
  font-size: 19px;
  margin-bottom: 20px;
}

.multiple-items .order-summary-multi h5 {
  text-transform: none;
  color: #4c4c4c;
  margin-bottom: 5px;
  line-height: 1.5;
  font-weight: 500;
}

.multiple-items .summary-multi-bill {
  margin: 19px 0;
  padding-bottom: 19px;
  border-bottom: 1px solid #dadada;
}

.multiple-items .bill-price-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
}

.multiple-items .bill-price-list:last-child {
  margin-bottom: 0;
}

.multiple-items .bill-price-list.main {
  margin-top: 25px;
  font-weight: 500;
}

.multiple-items .bill-heading {
  width: 60%;
}

.multiple-items .bill-price {
  text-align: right;
  width: 35%;
}

.multiple-items .order-summary-multi h6 {
  font-size: 16px;
}

.items-table-wrap {
  width: 100%;
  overflow: auto;
  padding: 0 10px;
  background: #fff;
  font-family: "Roboto";
  border-radius: 7px;
}

.items-table-wrap .items-table {
  min-width: 650px;
  padding: 0 0 10px 0;
}

.items-table-wrap .table-head-headings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
  position: relative;
}

.items-table-wrap .table-head-headings::after {
  content: "";
  border-bottom: 1px solid #e2e2e2;
  position: absolute;
  width: 130%;
  left: 0;
  bottom: 0;
}

.items-table-wrap .image-heading {
  width: 40px;
  flex-shrink: 0;
}

.items-table-wrap .name-heading {
  flex-shrink: 0;
  width: calc(40% - 40px);
  padding: 0 15px 0 25px;
  font-weight: 500;
}

.items-table-wrap .quantity-heading {
  flex-shrink: 0;
  width: 12%;
  text-align: center;
  padding: 0 10px;
}

.items-table-wrap .weight-heading {
  flex-shrink: 0;
  text-align: center;
  width: 63%;
  padding: 0 10px;
}

.items-table-wrap .total-heading {
  flex-shrink: 0;
  width: 17%;
  padding: 0 10px;
  text-align: center;
}



.items-table-wrap .table-head-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding: 15px 10px;
}

.items-table-wrap .table-head-values:last-child {
  border: none;
}

.items-table-wrap .image-value img {
  display: block;
  width: 100%;
}

.items-table-wrap .image-value {
  width: 40px;
  flex-shrink: 0;
}

.items-table-wrap .name-value {
  flex-shrink: 0;
  width: calc(40% - 40px);
  font-size: 15px;
  padding: 0 15px 0 25px;
  font-size: 16px;
}

.items-table-wrap .quantity-value {
  flex-shrink: 0;
  width: 12%;
  text-align: center;
  font-size: 17px;
  padding: 0 10px;
}

.items-table-wrap .weight-value {
  flex-shrink: 0;
  width: 63%;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  padding: 0 10px;
  display: flex;
  justify-content: center;
}

.items-table-wrap .total-value {
  flex-shrink: 0;
  width: 17%;
  font-size: 17px;
  padding: 0 10px;
  text-align: center;
}

.items-table-wrap .weight-value span {
  font-weight: 400;
}

.items-all-list {
  margin-bottom: 20px;
  background: #fff;
  width: 100%;
  border-radius: 7px;
  /* overflow: auto; */
  /* padding-top: 17px; */
}

.multiple-items .accordion-wrapper h1 {
  color: #4c4c4c;
  font-family: "Roboto";
  margin: 0 0;
  /* padding: 0 10px; */
  font-size: 19px;
  font-weight: 500;
  text-align: left;
}

.more-msg {
  margin: auto 0;
  font-size: 15px;
  text-decoration: underline;
  transition: all 0.5s;
  display: block;
}

.more-msg:hover {
  opacity: 0.7;
}

.seller-input.dual-input .MuiSelect-outlined.MuiSelect-outlined {
  padding: 15px 50px 15px 16px;
}

.local-working {
  padding: 33px 0;
  font-family: "Roboto";
}



.local-working .working-flow h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 24px;
  color: #141414;
}



.local-working .working-block {
  margin-bottom: 40px;
}

.local-working .working-image {
  margin-bottom: 15px;
}

.local-working .working-image img {
  width: 100%;
  display: block;
}



.local-working .working-text h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 13px;
  color: #141414;
}

.local-working .working-text p {
  color: #141414;
  font-size: 13px;
  margin-bottom: 14px;
}

.local-working .working-cost {
  color: #f86202;
  display: flex;
  align-items: center;
  line-height: 1.7;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.5s;
}

.local-working .working-cost:hover {
  color: #622bc5;
}

.local-working .working-cost i {
  font-size: 20px;
  opacity: 0.7;
  margin-left: 5px;
}

.register-facebook.mail-button span {
  display: flex;
  justify-content: center;
  text-transform: none;
}

.register-google.mail-button {
  text-transform: none;
}

.video-section {
  padding: 18px 0;
}



.video-section .video-box h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 17px;
}



.video-section .video-thumbnail {
  height: 300px;
  position: relative;
}



.video-section .video-icon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.32);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 1s;
}

.video-section .video-icon:hover {
  background: rgba(0, 0, 0, 0.05);
}

.video-section .video-icon i {
  font-size: 100px;
  color: rgba(255, 255, 255, 0.7);
}

.video-section .video-thumbnail img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}

.seller-inputs .error {
  display: block;
  font-size: 12px;
  margin: -16px 0 15px 6px;
  text-align: left;
  font-family: "Roboto";
}

.need-ideas {
  font-family: "Roboto";
  padding: 38px 0;
  background: rgba(0, 0, 0, 0.05);
}



.need-ideas .ideas-wrap h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 17px;
}

.need-ideas .ideas-wrap p {
  font-size: 17px;
  text-align: center;
  color: #141414;
  margin: 0 10px 30px;
}

.need-ideas .ideas-blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.need-ideas .idea-inner {
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  background: #fff;
  padding: 19px 11px;
  border-radius: 14px;
}

.need-ideas .idea-block {
  width: 50%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.need-ideas .idea-block h5 {
  margin: 12px 0 0 0;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
}

.need-ideas .idea-product {
  width: 100%;
  margin: auto;
}

.need-ideas .idea-product img {
  width: 100%;
  display: block;
}

.need-ideas .list-btn-wrap {
  text-align: center;
  cursor: pointer;
  margin-top: 40px;
}

.need-ideas .list-btn {
  color: #fff;
  background: #f86202;
  padding: 15px 40px;
  border-radius: 7px;
  display: inline-block;
  font-size: 20px;
  border: 1px solid #f86202;
  transition: all 0.5s;
}

.need-ideas .list-btn:hover {
  color: #f86202;
  background: transparent;
}

.available-nationwide {
  padding: 30px 0;
  font-family: "Roboto";
}



.available-nationwide .nations-name h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 37px;
}

.available-nationwide .nation-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.available-nationwide .nation-list li {
  font-weight: 500;
  width: calc(50% - 8px);
  text-align: center;
  color: #141414;
  font-size: 17px;
  margin-bottom: 25px;
}

.available-nationwide .cities-btn {
  text-align: center;
}

.available-nationwide .cities-btn .list-cities {
  padding: 9px 30px;
  border-radius: 20px;
  font-size: 17px;
  border: 1px solid #f86202;
  color: #f86202;
  transition: all 0.5s;
  display: inline-block;
}

.available-nationwide .cities-btn .list-cities:hover {
  color: #fff;
  background: #f86202;
}

.local-reviews {
  padding: 40px 0;
  font-family: "Roboto";
}

.local-reviews .reviews-local h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 17px;
}

.local-reviews .reviews-local h5 {
  text-align: center;
  text-transform: none;
  font-size: 17px;
  margin-bottom: 47px;
  font-weight: 400;
}



.local-reviews .review-box {
  background: rgb(115, 51, 129, 0.17);
  margin-bottom: 20px;
  padding: 30px 20px 70px;
}

.local-reviews .quotes {
  margin-bottom: 10px;
}

.local-reviews .quotes i {
  color: rgb(115, 51, 129, 1);
  font-size: 24px;
}

.local-reviews .review-box h4 {
  font-size: 16px;
  margin: 0 0 30px 15px;
  font-weight: 500;
}

.local-reviews .review-box p {
  font-size: 15px;
  margin-left: 15px;
}

.questions-local {
  font-family: "Roboto";
  padding: 30px 0;
}

.questions-local .local-question {
  position: relative;
  padding-bottom: 90px;
}

.questions-local .local-question h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 17px;
}

.questions-local .local-question h5 {
  text-align: center;
  text-transform: none;
  font-size: 17px;
  margin-bottom: 47px;
  font-weight: 400;
}

.questions-local .view-faq {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}

.questions-local .faq-btn {
  border: 2px solid #f86202;
  border-radius: 39px;
  display: inline-block;
  padding: 14px 32px;
  color: #f86202;
  font-size: 17px;
  transition: all 0.5s;
}

.questions-local .faq-btn:hover {
  background: #f86202;
  color: #fff;
}

.register-popup.seller-register .gst-file-name {
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  pointer-events: none;
  color: #4c4c4c;
}

.dates-wraps input::-webkit-input-placeholder {
  font-family: "Roboto";
}

.dates-wraps input::placeholder {
  font-family: "Roboto";
}



.local-accordion .accordion__button {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  outline: none;
  font-weight: 500;
  font-size: 17px;
  transition: all 0.5s;
}

.local-accordion .accordion__button:hover {
  background: transparent;
  outline: none;
}

.local-accordion .accordion__button:before {
  border-bottom: 0;
  border-right: 0;
  background: url(/public/assets/images/accordion-plus.svg) no-repeat center;
  width: 20px;
  height: 20px;
  background-size: 20px auto;
}

.local-accordion .accordion__panel {
  padding: 0 20px 10px;
}

.local-accordion .accordion {
  border: none;
}

.local-accordion .accordion__item+.accordion__item {
  border: none;
}

.local-accordion p {
  font-size: 14px;
  margin-bottom: 10px;
}

.local-accordion p {
  /* font-size: 17px; */
  width: 90%;
  padding-right: 25px;
}

.local-accordion .accordion__button[aria-expanded="true"]::before {
  margin-bottom: 5px;
}

.local-accordion .accordion__button[aria-selected="true"]::before {
  margin-bottom: 5px;
}

.local-accordion .accordion__item {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 5px;
}

.detail-from.otp-screen {
  width: 100%;
  font-family: "Roboto";
}

.detail-from.otp-screen .otp-btns {
  display: flex;
  justify-content: center;
}

.detail-from.otp-screen .form-title {
  margin-bottom: 12px;
  font-size: 16px;
}

.detail-from.otp-screen .otp-timer {
  /* margin-bottom: 12px; */
  font-size: 16px;
}

.detail-from.otp-screen .btns-wrap {
  display: flex;
  flex-direction: column;
}

.signup-form.otp-open {
  max-width: 400px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.detail-from.otp-screen .otp-btn {
  color: #f86202;
  background: transparent;
  border: 1px solid #f86202;
  border-radius: 12px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: "Roboto";
  font-size: 15px;
  margin-bottom: 12px;
  padding: 12px 42px;
  transition: all 0.5s;
}

.detail-from.otp-screen .otp-btn:hover {
  color: #fff !important;
  background-color: #f86202;
}

.detail-from.otp-screen .otp-btn:disabled {
  color: #808080;
  border: 1px solid #808080;
  pointer-events: none;
}

.detail-from.otp-screen .otp-btn.submit {
  color: #fff;
  background: #622bc5;
  border: 1px solid transparent;
}

.detail-from.otp-screen .otp-btn.submit:hover {
  color: #fff;
  background: #622bc5;
  border: 1px solid transparent;
}

.detail-from.otp-screen .otp-btn.submit:disabled {
  color: #fff;
  background: #f86202;
  border: 1px solid transparent;
}

.sign-form .error {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  font-size: 13px;
  margin-top: 4px;
  text-align: left;
  font-family: 'Inter';
}

.complete-purchase.btn-shop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.complete-purchase.make-offer {
  margin-bottom: 10px;
}

.pro-btn-wrap.make-offer {
  padding-top: 0;
}



.complete-purchase.make-offer {
  background: #622bc5;
  border-color: #622bc5;
}

.complete-purchase.msg-seller {
  border: 1px solid #622bc5;
  background: #fff;
  color: #622bc5;
}

.complete-purchase.msg-seller:hover {
  color: #622bc5;
  box-shadow: 0 0 16px 0 rgba(115, 50, 129, 0.26);
}

.complete-purchase.make-offer:hover {
  color: #622bc5;
}

.complete-purchase.make-offer.disablebutton,
.complete-purchase.msg-seller.disablebutton,
.complete-purchase.disablebutton {
  opacity: 0.3;
}

.complete-purchase.disablebutton:hover {
  background-color: #f86202;
  color: #fff;
}

.complete-purchase.make-offer.disablebutton:hover {
  background-color: #622bc5;
  color: #fff;
}

.complete-purchase.msg-seller.disablebutton:hover {
  background-color: transparent;
  color: #622bc5;
}

.complete-purchase.prod-share-btn {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 90px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a9a9a9;
  border: 0;
  background: none;
}

.prod-share-btn i {
  margin-left: 10px;
}

.dual-order .dual-product-image {
  text-align: center;
}

@media only screen and (min-width: 375px) {
  .transaction-listing .list-heading {
    font-size: 15px;
  }

  .transaction-listing .list-value {
    font-size: 16px;
  }

  .header-address {
    width: 120px;
  }
}

@media only screen and (min-width: 576px) {

  .team-details {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    flex-grow: 1;
  }

  .about-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .team-images {
    width: 209px;
    flex-shrink: 0;
    margin-top: 14px;
  }

  .para-team {
    height: 100%;
    padding: 29px 15px;
  }

  .para-team p {
    margin-bottom: 30px;
  }

  .team-close {
    margin-bottom: 0;
  }

  .team-popup-head {
    flex-direction: row;
    align-items: flex-start;
  }

  .team-status {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 950px) {
  .search-brand {
    margin: 0 6px 0 17px;
  }

  .header-text i {
    font-size: 19px;
  }

  .download-app {
    padding-right: 14px;
  }

  .cart-wrap i.fa-shopping-bag {
    font-size: 19px;
  }

  .userName-container {
    margin-right: 12px;
  }

  .signin-register .profile-name {
    font-size: 18px;
    margin: 0 6px 0 0;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1023px) {
  .profile-wrapper .tags-wrap .container .tags {
    margin: 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-header-address.header-address {
    display: none;
  }

  .header-address {
    display: block;
  }

  .footer-social i {
    font-size: 16px;
  }

  .footer-social .fb-link {
    width: 33px;
    height: 33px;
  }

  .dates-wraps label {
    width: auto;
  }

  .video-section {
    padding: 18px 0 50px;
  }

  .need-ideas .idea-block {
    width: 33.33%;
    margin-bottom: 40px;
  }

  .seller-input.dual-input .MuiSelect-outlined.MuiSelect-outlined {
    padding: 12px 50px 12px 16px;
  }

  .register-popup.seller-register .seller-input .MuiInputBase-root {
    height: 44px;
  }

  .seller-input .MuiInputBase-root input {
    height: auto;
  }

  .multiple-items .order-items {
    display: flex;
    align-items: center;
  }

  .multiple-items .order-items h3 {
    margin: 0 8px 0 0;
  }

  .multiple-items .view-all-items {
    font-size: 17px;
  }

  .dual-order .ordered-products {
    text-align: center;
  }

  .rotate-image {
    margin: 0;
    width: 446px;
    overflow: initial;
  }

  .local-bazaar .container {
    padding: 0 28px;
  }

  .local-bazaar .local-banner-image {
    margin: 0;
  }

  .selling-locals .rotate-image {
    margin: 0 auto 27px;
    padding: 0;
  }

  .powered-delivery {
    padding: 50px 0;
    max-width: 1110px;
  }

  .selling-local h2 {
    font-size: 30px;
  }

  .local-bazaar .banner-content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .local-banner {
    padding-bottom: 0;
  }

  .make-offer-popup.review-popup .offer-title {
    padding: 0 31px 13px 0;
  }

  .header-address {
    position: relative;
    flex-shrink: 0;
    margin: 0 13px;
    font-size: 11px;
    width: 138px;
    z-index: 1;
  }

  .team-images {
    width: 289px;
    margin-top: 34px;
  }

  .para-team {
    padding: 47px 30px;
  }

  .para-team p {
    font-size: 16px;
  }

  .seller-input .MuiSelect-outlined.MuiSelect-outlined {
    padding: 18px 30px 18px 12px;
  }

  .withdraw-panel p {
    margin-bottom: 60px;
  }

  .footer-social {
    justify-content: flex-end;
  }

  .make-offer-popup.cancel-return .offer-content {
    padding: 15px 24px;
  }

  .make-offer-popup.cancel-return .offer-title.heading {
    padding: 0 40px 0 0;
  }

  .checkout-cardwrap .qty-input button {
    padding: 5px 8px;
  }

  .checkout-cardwrap .qty-input {
    width: 85px;
  }

  .checkout-cardwrap .qty-input input {
    font-size: 13px;
  }

  .bank-column .bank-icon {
    width: 55px;
    height: 55px;
  }

  .bank-column .bank-detail {
    margin-left: 20px;
  }

  .bank-column .bank-balance {
    padding: 15px 36px 15px 15px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .bank-column .bank-boxes {
    justify-content: initial;
  }

  .local-bazaar .banner-texts {
    margin-right: 15px;
  }

  .dual-order .dual-order-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .dual-order .dual-content {
    width: calc(50% - 10px);
  }

  .dual-order .dual-content.order {
    width: 100%;
  }

  .dual-order .products-images {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    /* align-items: center; */
  }

  .dual-order .dual-product-image {
    margin: 0 49px 25px 0;
    width: 92px;
  }

  .dual-order .view-dual-order {
    padding: 0 10px;
    text-align: center;
  }

  .dual-order .dual-menu {
    display: flex;
    align-items: center;
    margin-top: 3px;
  }

  .dual-order .dual-menu .view-order {
    margin: 0 15px 0 0;
    padding-right: 15px;
    /* border-right: 1px solid #d6d0d0; */
  }

  .local-working {
    padding: 45px 0;
  }

  .local-working .working-flow h3 {
    font-size: 39px;
    margin-bottom: 35px;
  }

  .local-working .working-text h4 {
    font-size: 29px;
    margin-bottom: 15px;
  }

  .local-working .working-text p {
    font-size: 16px;
  }

  .local-working .working-cost {
    font-size: 16px;
  }

  .local-working .working-cost i {
    font-size: 25px;
  }

  .local-working .working-block {
    margin-bottom: 50px;
  }

  .video-section .video-box {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    background: rgb(115, 51, 129, 0.24);
  }

  .video-section .video-name {
    width: 50%;
    padding: 0 30px;
  }

  .video-section .video-thumbnail {
    width: 50%;
  }

  .video-section .video-box h3 {
    margin-bottom: 0;
  }

  .available-nationwide {
    padding: 40px 0;
  }

  .available-nationwide .nation-list li {
    width: calc(33.3% - 10px);
    margin: 0 5px 30px;
  }

  .available-nationwide .nation-list {
    justify-content: flex-start;
  }

  .local-reviews .review-boxes {
    display: flex;
    flex-wrap: wrap;
  }

  .local-reviews .review-box {
    width: calc(33.3% - 10px);
    margin: 0 5px 20px;
  }

  .local-accordion .accordion__button {
    font-size: 20px;
  }

  .local-accordion p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .prod-title.prod-toggle .del-details p {
    text-align: right;
  }

  .selling-locals .rotate-image {
    margin-bottom: 27px;
  }

  .header-address .deliver-text {
    font-size: 12px;
  }

  .questions-local .view-faq {
    position: static;
    text-align: left;
  }

  .questions-local .local-question {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 0 0 50px;
  }

  .questions-local .question-text {
    width: 30%;
  }

  .local-accordion {
    width: 100%;
  }

  .questions-local {
    padding: 30px 0 50px;
  }

  .questions-local .local-question h3 {
    font-size: 36px;
    text-align: left;
  }

  .questions-local .local-question h5 {
    text-align: left;
    font-size: 19px;
  }

  .local-accordion .accordion__button {
    font-size: 23px;
  }

  .local-accordion p {
    font-size: 17px;
  }

  .questions-local .local-question {
    max-width: 970px;
    margin: 0 auto;
    padding-left: 14px;
  }

  .local-reviews .review-box {
    width: calc(33.3% - 26px);
    margin: 0 13px 20px;
    padding: 30px 20px 50px;
  }

  .local-reviews .review-boxes {
    max-width: 970px;
    margin: 0 auto;
  }

  .local-reviews .review-box h4 {
    font-size: 21px;
  }

  .local-reviews .review-box p {
    font-size: 19px;
  }

  .local-reviews .reviews-local h3 {
    font-size: 36px;
  }

  .local-reviews .reviews-local h5 {
    font-size: 24px;
  }

  .local-reviews {
    padding: 50px 0;
  }

  .need-ideas .idea-block {
    width: 16%;
    margin-bottom: 0;
  }

  .need-ideas .ideas-wrap h3 {
    font-size: 36px;
  }

  .available-nationwide .nations-name h3 {
    font-size: 36px;
  }

  .need-ideas .ideas-wrap p {
    font-size: 22px;
    margin-bottom: 50px;
  }

  .need-ideas {
    padding: 59px 0;
  }

  .video-section .video-box h3 {
    font-size: 37px;
  }

  .video-section .video-thumbnail {
    height: 360px;
  }

  .local-working .working-block {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0;
    position: relative;
  }

  .local-working .working-block::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: #141414;
    width: 1px;
    height: 50px;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }

  .local-working .working-block:last-child:after {
    display: none;
  }

  .local-working .working-block:first-child {
    align-items: flex-start;
  }

  .local-working .working-block:first-child .working-text {
    padding-top: 0;
  }

  .local-working .working-image {
    margin-bottom: 0;
    width: calc(60% - 10px);
  }

  .local-working .working-flow h3 {
    text-align: left;
    margin-bottom: 47px;
    font-size: 41px;
  }

  .local-working .working-blocks {
    padding: 0 31px;
  }

  .local-working {
    padding: 60px 0;
  }

  .local-working .working-text {
    width: calc(40% - 10px);
    padding: 24px 6px 31px 0;
  }

  .multiple-items .order-summary-multi h3 {
    font-size: 18px;
  }

  .selling-locals-wrap {
    display: flex;
  }

  .local-selling-content {
    padding-top: 40px;
  }

  .local-selling-image {
    margin-left: -14%;
    padding-right: 14%;
    max-width: 600px;
  }

  .dual-order .ordered-products {
    text-align: left;
  }

  .dual-order .view-dual-order {
    text-align: left;
  }

  .dual-order .products-images {
    justify-content: flex-start;
    max-width: 487px;
  }

  .selling-locals h3 {
    font-size: 35px;
    margin-bottom: 42px;
  }

  .selling-locals .selling-content {
    display: flex;
  }

  .selling-locals .selling-boxes-text {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 20px 0 0;
  }

  .selling-locals .selling-boxes {
    flex-direction: column;
    justify-content: flex-start;
  }

  .selling-locals .text-wrap-selling {
    width: 100%;
    min-height: 166px;
    width: 173px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .selling-locals .text-wrap-selling:last-child {
    margin-bottom: 0;
  }

  .selling-locals .selling-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .selling-locals .selling-box:last-child {
    margin-bottom: 0;
  }

  .selling-locals .option-boxes {
    flex-direction: row;
  }

  .selling-locals .option-box {
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
    width: 83px;
    text-align: center;
    justify-content: center;
  }

  .selling-locals .long {
    flex-grow: 1;
  }

  .selling-locals .long.longer {
    margin: 0;
  }

  .selling-locals .long.longer {
    min-height: 166px;
  }

  .reasons-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .reasons-wrap {
    margin-bottom: 0;
    width: 32.3%;
    padding: 0 20px;
    max-width: 380px;
    align-items: flex-start;
    text-align: left;
  }

  .reasons-wrap p {
    font-size: 16px;
  }

  .local-banner h2 {
    font-size: 43px;
  }

  .local-banner p {
    font-size: 20px;
  }

  .local-bazaar .local-banner-image {
    height: auto;
  }

  .rotate-image {
    width: 50%;
  }

  .header-address {
    top: auto;
    left: auto;
    width: auto;
    font-size: 15px;
    /* padding: 2px 12px 2px 6px; */
  }

  .product-location-wraps {
    padding: 6px 18px 6px 15px;
  }

  .team-status h4 {
    font-size: 25px;
  }

  .team-status h6 {
    font-size: 17px;
  }

  .para-team p {
    font-size: 17px;
  }

  .team-images {
    width: 359px;
  }

  .about-container {
    padding: 0 25px;
  }

  .para-team {
    padding: 48px 51px;
  }

  .team-popup-head {
    padding: 26px 0 10px 0;
    margin-bottom: 31px;
  }

  .withdraw-btns .withdraw-cancel {
    padding: 11px 24px;
    font-size: 18px;
  }

  .withdraw-btns .withdraw-continue {
    padding: 11px 44px;
    font-size: 18px;
  }

  .balance-block {
    margin-bottom: 68px;
  }

  .withdraw-inputs {
    display: flex;
    align-items: flex-start;
  }

  .withdraw-inputs .amount-block {
    width: calc(50% - 10px);
    margin: 0;
  }

  .withdraw-inputs .bank-selector {
    width: calc(50% - 32px);
    margin-left: 40px;
  }

  .withdraw-inputs .input-amount-wrap {
    display: flex;
    align-items: center;
  }

  .withdraw-inputs h5 {
    white-space: nowrap;
    margin: 0 0 0 10px;
  }

  .withdraw-panel h4 {
    font-size: 22px;
  }

  .withdraw-panel p {
    font-size: 17px;
  }

  .balance-block .balance-number {
    font-size: 32px;
  }

  .balance-block h6 {
    font-size: 17px;
  }

  .transaction-listing .list-heading {
    font-size: 16px;
  }

  .transaction-listing .list-value {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) {
  .items-table-wrap .table-head-headings::after {
    display: none;
  }

  .user-offer-table .items-table-wrap .table-head-headings::after {
    display: block;
  }

  .items-table-wrap .table-head-headings {
    border-bottom: 1px solid #e2e2e2;
  }

  .user-offer-table .items-table-wrap .table-head-headings {
    border: 0;
  }

  .weight-value .cancel-btn {
    font-size: 15px;
    padding: 7px 11px;
  }

  .local-accordion .accordion__button {
    font-size: 24px;
  }

  .questions-local .faq-btn {
    font-size: 20px;
    padding: 16px 42px;
  }

  .available-nationwide {
    padding: 50px 0;
  }

  .available-nationwide .nations-name h3 {
    margin-bottom: 47px;
  }

  .available-nationwide .nation-list li {
    text-align: left;
    width: calc(33.3% - 50px);
    margin: 0 25px 30px;
    font-size: 20px;
  }

  .available-nationwide .cities-btn .list-cities {
    padding: 11px 35px;
    border-radius: 35px;
    font-size: 19px;
  }

  .available-nationwide .nation-list {
    margin: 0 auto 40px;
    max-width: 900px;
  }

  .local-working .working-image {
    width: 50%;
  }

  .local-working .working-text {
    width: 50%;
    padding: 24px 103px 41px 0;
  }

  .local-working .working-text p {
    font-size: 17px;
  }

  .local-working .working-cost {
    font-size: 17px;
  }

  .local-working .working-block::after {
    height: 56px;
  }

  .video-section .video-box h3 {
    font-size: 44px;
  }

  .video-section .video-name {
    padding: 0 60px;
  }

  .video-section .video-thumbnail {
    height: 380px;
  }

  .need-ideas .idea-inner {
    width: 166px;
    padding: 29px 17px;
  }

  .need-ideas .list-btn-wrap {
    margin-top: 70px;
  }

  .items-table-wrap .name-value {
    font-size: 17px;
    padding: 0 15px 0 30px;
    width: calc(40% - 132px);
  }

  .items-table-wrap .name-heading {
    padding: 0 15px 0 30px;
    width: calc(40% - 132px);
  }

  .items-table-wrap .table-head-headings {
    padding: 13px 10px;
    font-size: 18px;
  }

  .items-table-wrap .weight-value,
  .items-table-wrap .weight-heading {
    /* font-size: 18px; */
    width: 47%;
    padding: 0;
  }

  .multiple-items .summary-multi-bill {
    max-width: 555px;
  }

  .dual-order .view-dual-order {
    padding: 0;
  }

  .dual-order .dual-content {
    width: calc(20% - 10px);
  }

  .dual-order .dual-content-order {
    padding: 25px 10px;
  }

  .dual-order {
    padding-bottom: 0;
  }

  .dual-order .ordered-products {
    padding: 0;
    margin-top: 5px;
  }

  .local-selling-content {
    padding-top: 79px;
  }

  .local-bazaar .container {
    padding: 0 48px;
  }

  .local-selling-image {
    margin-left: -17px;
    padding-right: 50px;
    max-width: 600px;
  }

  .blogs-content {
    justify-content: flex-start;
    margin-right: -24px;
  }

  .blogcard-quad {
    margin-right: 24px;
  }

  .blogcard-dual {
    margin-right: 24px;
    width: calc(50% - 24px);
  }

  .powered-delivery {
    padding: 50px 0 60px;
  }

  .selling-local h2 {
    margin-bottom: 60px;
  }

  .selling-local {
    padding: 40px 0;
  }

  .selling-local h2 {
    font-size: 33px;
  }

  .local-banner h2 {
    font-size: 54px;
  }

  .local-banner p {
    font-size: 21px;
  }

  .header-address {
    margin: 0 17px 0 0;
    width: auto;
    /* font-size: 16px; */
    /* padding: 3px 6px 3px 8px; */
  }

  .header-address .product-location {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .product-location-wraps {
    padding: 7px 18px 7px 14px;
  }

  .location-mark i {
    margin: -1px 8px 0 0;
  }

  .location-mark {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .header-address .distance-mark {
    margin-left: 5px;
    white-space: nowrap;
  }

  .withdraw-table {
    min-width: auto;
  }

  .transaction-tab h4 {
    font-size: 22px;
  }

  .team-popup-head {
    padding: 37px 0 10px 0;
    margin-bottom: 35px;
  }

  .about-container {
    padding: 0 57px;
  }

  .para-team {
    position: relative;
  }

  .para-team::after {
    top: 34px;
    right: 100%;
    width: 15px;
    height: calc(100% - 34px);
    content: "";
    background: #ad72c1;
    position: absolute;
  }

  .withdraw-amounts {
    margin-bottom: 55px;
  }

  .transaction-listing li {
    margin-bottom: 20px;
  }

  .transaction-listing .list-heading {
    font-size: 17px;
  }

  .transaction-listing .list-value {
    font-size: 18px;
  }

  .withdraw-inputs .add-bank-link {
    font-size: 18px;
    margin-top: 13px;
  }

  .withdraw-inputs .label-text {
    font-size: 18px;
  }

  .withdraw-inputs .input-amount {
    font-size: 18px;
  }

  .withdraw-inputs .input-amount::-webkit-input-placeholder {
    font-size: 18px;
  }

  .withdraw-inputs .input-amount::placeholder {
    font-size: 18px;
  }

  .withdraw-inputs h5 {
    font-size: 15px;
  }

  .bank-column .bank-balance {
    padding: 20px 49px 20px 20px;
  }

  .bank-column {
    padding: 40px 0 44px;
  }

  .bank-column h4 {
    margin-bottom: 40px;
  }

  .withdraw-inputs {
    margin-bottom: 40px;
  }

  .bank-column .change-btn {
    font-size: 16px;
  }

  .bank-column .bank-detail {
    font-size: 18px;
  }



  .make-offer-popup.cancel-return .full-width {
    font-size: 14px;
  }

  .make-offer-popup.cancel-return .text-reason textarea {
    font-size: 14px;
  }

  .prod-toggle {
    max-width: 281px;
  }

  .status-details span {
    font-size: 14px;
  }

  .del-details span {
    font-size: 14px;
  }

  .prod-title.prod-toggle .del-details p {
    font-size: 14px;
  }

  .status-details {
    font-size: 14px;
  }

  .del-details strong {
    font-size: 14px;
  }

  .checkout-cardwrap .qty-input {
    width: 93px;
  }

  .checkout-cardwrap .qty-input input {
    font-size: 15px;
  }

  .checkout-cardwrap .qty-input button {
    padding: 7px 8px;
    font-size: 12px;
  }

  .attr .label-txt.attr-select {
    font-size: 16px;
  }

  .navigation-screen .attr-elements .select-attr .label-txt {
    font-size: 14px;
  }

  .team-images {
    width: 373px;
  }
}

@media only screen and (min-width: 1280px) {
  .dual-order .dual-content.order {
    width: calc(28% - 10px);
    max-width: 235px;
  }

  .dual-order .ordered-products h3 {
    font-size: 17px;
  }

  .dual-order .items-text-number {
    font-size: 18px;
  }

  .dual-order .dual-content {
    width: calc(15% - 10px);
    margin-bottom: 0;
  }

  .dual-order .dual-content h4 {
    font-size: 14px;
  }

  .dual-order .dual-content h5 {
    font-size: 15px;
  }

  .dual-order .heading-menu {
    font-size: 15px;
  }

  .dual-order .dual-menu .view-order {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1300px) {
  .dual-order .products-images {
    max-width: 690px;
  }

  /* .blogcard-dual{
    margin-right: 17px;
  } */
  .items-table-wrap .weight-value,
  .items-table-wrap .weight-heading {
    width: 43%;
  }
}

@media only screen and (min-width: 1350px) {
  .items-table-wrap {
    padding: 0 18px;
  }



  .multiple-items .order-summary-multi {
    padding: 15px 18px 30px;
  }

  .available-nationwide .nation-list li {
    width: calc(33.3% - 20px);
    margin: 0 10px 35px;
    font-size: 20px;
    font-size: 23px;
  }

  .available-nationwide .nation-list {
    max-width: 1000px;
  }

  .local-working .working-text {
    padding: 24px 103px 50px 0;
  }

  .local-working .working-block::after {
    height: 72px;
  }

  .local-working .working-text {
    padding: 24px 103px 58px 0;
  }

  .local-working .working-block::after {
    height: 85px;
  }

  .dual-order .dual-order-head {
    padding: 20px;
  }

  .dual-order .dual-content-order {
    padding: 9px 20px;
  }



  .team-popup-head {
    padding: 45px 0 10px 0;
  }

  .about-container {
    padding: 0 65px;
  }

  .team-images {
    width: 389px;
  }

  .team-close i {
    font-size: 38px;
  }
}

@media only screen and (min-width: 1367px) {
  .product-review-images {
    width: 90px;
    height: 90px;
  }
}

@media only screen and (min-width: 1500px) {
  .slide-box-wrap .btn-wrap button.complete-purchase {
    width: 216px;
  }

  .footer-social i {
    font-size: 19px;
  }

  .footer-social .fb-link {
    width: 36px;
    height: 36px;
  }

  .product-location-wraps {
    padding: 8px 18px 8px 14px;
  }

  .complete-purchase.make-offer {
    width: calc(50% - 5px);
  }

  .signup-form.otp-open {
    max-width: 500px;
  }

  .detail-from.otp-screen .otp-btn {
    padding: 13px 42px;
    font-size: 17px;
  }

  .header-address {
    margin: 0 22px 0 0;
  }

  .dual-order .ordered-products h3 {
    font-size: 17px;
  }

  .dual-order .items-text-number {
    font-size: 20px;
  }

  .dual-order .dual-content h4 {
    font-size: 16px;
  }

  .dual-order .dual-content h5 {
    font-size: 15px;
  }

  .dual-order .heading-menu {
    font-size: 17px;
  }

  .dual-order .dual-menu .view-order {
    font-size: 17px;
  }

  .local-working {
    padding: 80px 0;
  }

  .local-working .working-flow h3 {
    margin-bottom: 54px;
  }

  .need-ideas .idea-inner {
    width: 180px;
  }

  .need-ideas .list-btn-wrap {
    margin-top: 90px;
  }

  .dual-order .dual-content {
    width: calc(17% - 10px);
    margin-bottom: 0;
  }

  .dual-order .view-dual-order .view-link {
    font-size: 20px;
  }

  .dual-order .dual-content.order {
    width: 29%;
    max-width: 272px;
  }

  .selling-local {
    padding: 60px 0;
  }

  .sign-form .MuiInputLabel-outlined.MuiInputLabel-shrink {
    -webkit-transform: translate(14px, -8px) scale(0.75);
            transform: translate(14px, -8px) scale(0.75);
  }

  .selling-local h2 {
    font-size: 40px;
  }

  .local-banner h2 {
    font-size: 56px;
  }

  .local-banner p {
    font-size: 23px;
  }

  .signup-form .MuiInputLabel-outlined {
    top: 4px;
  }

  .para-team::after {
    height: calc(100% - 49px);
    top: 49px;
    width: 20px;
  }

  .team-status h4 {
    font-size: 28px;
  }

  .team-status h6 {
    font-size: 20px;
  }

  .para-team p {
    font-size: 20px;
  }

  .team-images {
    width: 462px;
    margin-top: 49px;
  }

  .para-team {
    padding: 68px 79px;
  }

  .withdraw-btns .withdraw-cancel {
    padding: 14px 50px;
    font-size: 22px;
  }

  .withdraw-btns .withdraw-continue {
    padding: 14px 54px;
    font-size: 22px;
  }

  .balance-block .balance-number {
    font-size: 33px;
  }

  .balance-block h6 {
    font-size: 18px;
  }

  .attr .label-txt.attr-select {
    font-size: 18px;
  }

  .navigation-screen .attr-elements .label-txt {
    font-size: 18px;
  }

  .navigation-screen .attr-elements .select-attr .label-txt {
    font-size: 14px;
  }

  .description-container .label-txt {
    font-size: 16px !important;
  }

  /* .discount {
    display: block;
  } */
}

@media only screen and (min-width: 1700px) {

  .items-table-wrap .weight-value,
  .items-table-wrap .weight-heading {
    width: 35%;
  }

  .attr .label-txt.attr-select {
    font-size: 20px;
  }

  .rupees-icon {
    top: 16px;
  }



  .team-close i {
    font-size: 50px;
  }

  .blog-article-one.blog-articles .article-head.blog-expandimage figure img {
    max-height: 656px;
  }
}

/* @media only screen and (min-width: 1800px) {
  .blogcard-dual {
    margin-right: 20px;
}
} */
@media only screen and (min-width: 1900px) {


  .local-banner h2 {
    font-size: 58px;
  }

  .local-banner p {
    font-size: 25px;
  }

  .local-banner .get-start {
    font-size: 21px;
  }
}

@media only screen and (max-width: 991px) {
  .local-selling-image {
    margin: 0;
    padding: 0 12px;
  }
}

/* styling */
@media all and (max-width:767px) {
  .make-offer-popup .offer-content {
    width: 100%;
  }

  .make-offer-popup.offered-popup-wrap.offer-popup-wrap .offer-title {
    font-size: 20px;
    margin: 0 -15px 10px;
    padding-bottom: 10px;
  }

  /* .item-seller-info .offered-popup-wrap.make-offer-popup .offer-title:after {
    width: 100%;
    left: 0;
  } */

  .make-offer-popup .offer-info {
    margin-bottom: 5px;
  }

  .offer-input-box label {
    font-size: 14px;
  }

  .offer-price h3 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    margin: 15px 0 12px;
  }

  .offer-btn-diable {
    margin-top: 15px;
    height: 40px;
  }

  .offer-inserted-btn {
    margin-top: 15px;
    height: 40px;
  }
}

@media all and (min-width:430px) {
  .make-offer-popup .offer-content {
    width: 75%;
  }
}
.cart-caption {
  padding-top: 28px;
}

.cart-caption h2 {
  padding-bottom: 12px;
  font-size: 30px;
}

.cart-caption h6 {
  text-transform: none;
}

.check-out-prod-card .cartmenu.cart-itemlist.cart-list-item {
  margin-bottom: 0px !important;
}

.check-out-prod-card .desc-txt {
  align-self: flex-end;
}

.check-out-prod-card {
  margin-bottom: 20px;
}


.cartmenu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 30px 20px;
  position: relative;
  /* box-shadow: 0 4px 10px 6px rgb(0, 0, 0, 0.04); */
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 2px rgb(0 0 0 / 6%);
}

.checkout-right .cartmenu {
  box-shadow: none;
}

.delete {
  position: absolute;
  top: 20px;
  right: 0;
}

.cart-des {
  margin-left: 50px;
  width: 80%;
}

.price {
  font-size: 26px;
  font-family: "Roboto";
  font-weight: 600;
  padding: 10px 0;
}

.cartmenu .cart-qty input,
.cartmenu li {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
  color: #4a4a4a;
  padding: 15px 0;
  font-family: "Poppins", sans-serif;
}

.cartmenu .cart-product {
  width: 134px;
  height: 134px;
  border-radius: 5px;
  overflow: hidden;
}

.cartmenu-title .cart-product {
  height: auto;
  text-align: center;
}

.cartmenu .cart-desc {
  padding-right: 40px;
  line-height: 1.4;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2em;
}

.cartmenu .cart-size {
  width: 14.8%;
}

.cartmenu .cart-qty {
  width: 14.8%;
}

.cartmenu .cart-price {
  width: 10%;
}

.cart-itemlist .cart-price {
  padding-left: 20px;
  position: relative;
}

.cart-itemlist {
  /* border-top: 1px solid #bebebe;
  padding: 20px 0; */
}

.cart-itemlist .cart-price:before {
  position: absolute;
  content: "\20B9";
  top: 50%;
  left: 7px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.cartmenu .cart-qty input {
  text-align: center;
  border: 3px solid #d9d9d9;
  width: 94px;
  height: 52px;
  padding: 5px;
}

.cart-product figure {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #e6e6e6; */
  position: relative;
}

.cart-product figure img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  object-fit: cover;
  object-position: top;
}

.cartsection .cart-total {
  padding: 0 0 10px 0;
  font-size: 28px;
  font-weight: normal;
  color: #4a4a4a;
  text-transform: none;
  margin-bottom: 15px;
  font-weight: 600;
}

.product-name {
  text-transform: capitalize;
}

.cartsection {
  padding: 30px 0;
}

.cartsection .cart-bottom {
  text-align: right;
  /* padding-bottom: 60px; */

}

.cartsection .buy-now-btn {
  width: 449px;
}

.cartsection .buy-now-btn a {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}

.cartsection .buy-now-btn:hover a {
  color: #d16d38;
}

.delete-product {
  width: 70px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  margin-bottom: 10px;
}

.delete-product img {
  width: 25px;
}

.cart-des span {
  display: block;
}

.price span {
  display: inline-block;
}

.pay-wrap {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.pay-wrap .buy-now {
  margin-bottom: 0;
  margin-left: 15px;
}

.pay-wrap .shop-now {
  background: #622bc5;
  border: 1px solid #622bc5;
  margin-left: 0;
}

.pay-wrap .shop-now:hover {
  background: #ffffff;
  color: #622bc5;
}

/* Responsive css Start */
@media only screen and (max-width: 1699px) {

  .cartsection .buy-now-btn a,
  .cartmenu .cart-qty input,
  .cartmenu li {
    font-size: 18px;
  }

  .cartsection .cart-total {
    font-size: 30px;
  }

  .cartsection .buy-now-btn {
    width: 320px;
  }
}

@media only screen and (max-width: 1699px) {

  .cartsection .buy-now-btn a,
  .cartmenu .cart-qty input,
  .cartmenu li {
    font-size: 16px;
  }

  .cartmenu .cart-qty input {
    width: 80px;
    height: 40px;
  }

  .cartsection .cart-total {
    font-size: 26px;
  }

  .cartsection .buy-now-btn {
    width: 240px;
  }
}

@media only screen and (max-width: 1399px) {
  .price {
    font-size: 18px;
  }

  .cartmenu .cart-product {
    width: 100px;
    height: 100px;
  }

  .cart-des {
    margin-left: 25px;
  }

  .cart-caption h2 {
    padding-bottom: 12px;
    font-size: 24px;
  }

  .delete-product img {
    width: 20px;
  }
}

@media only screen and (max-width: 991px) {

  .cartsection .buy-now-btn a,
  .cartmenu .cart-qty input,
  .cartmenu li {
    font-size: 14px;
  }

  .cartsection .cart-total {
    font-size: 22px;
    padding: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {

  .cartsection .buy-now-btn a,
  .cartmenu .cart-qty input,
  .cartmenu li {
    font-size: 12px;
  }

  .cartsection .cart-total {
    font-size: 18px;
  }

  .cartsection .buy-now-btn {
    width: 190px;
    height: 40px;
  }

  .cart-itemlist {
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .cart-itemlist .cart-product {
    width: 30%;
  }

  .cart-itemlist .cart-desc {
    width: 70%;
  }

  .cart-itemlist .cart-qty,
  .cart-itemlist .cart-price,
  .cart-itemlist .cart-size {
    width: 33.33%;
  }

  .cart-itemlist .cart-qty {
    padding: 3px 0;
  }
}
.checkout-caption {
  padding: 25px 20px;
  /* border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece; */
}

.checkout-caption h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  font-family: 'Inter';
  font-weight: 700;
  color: #333333;
}

.checkout-headings {
  /* font-size: 27px; */
  font-weight: 600;
  text-align: left;
  color: #4a4a4a;
  margin: 0;
  display: block;
}

.complete-purchase {
  width: 100%;
  background: #EF7F1A;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  border: 1px solid #EF7F1A;
  padding: 0 15px;
  height: 55px;
  border-radius: 5px;
}

.complete-purchase:hover {
  background: #fff;
  color: #EF7F1A;
}

.complete-purchase:hover a {
  color: #EF7F1A;
}

.apply-code:hover {
  background: #fff;
  color: #EF7F1A;
}

.complete-purchase a {
  color: #fff;
}

.complete-purchase a:hover {
  color: #EF7F1A;
}

.complete-purchase.cart-btn {
  background: #59d006;
  border: 1px solid #59d006;
}

.complete-purchase.cart-btn:hover {
  background: #fff;
  color: #59d006;
}

.checkout-wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 49px;
}

.checkout-left {
  width: 45%;
  padding: 12px 14px 60px;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 4px 10px 6px rgb(0, 0, 0, 0.04);
}

.checkout-right {
  width: 53%;
  padding: 28px 3.2%;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 4px 10px 6px rgb(0, 0, 0, 0.04);
}

.shipping-itemwrap {
  padding: 20px 4% 20px 4%;
}

.shipping-itemwrap .desc-txt input,
.shipping-itemwrap .desc-txt {
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
  width: 100%;
  background: none;
  border: 0;
}

.desc-txt-wrap .desc-txt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.shipping-itemwrap .desc-txt input {
  width: 48%;
  background-color: #fff;
  height: 40px;
  margin-bottom: 5px;
  padding: 0 15px;
  border: 1px solid #c8c8c8;
}

.shipping-itemwrap .desc-txt input[type="checkbox"] {
  height: auto;
  width: auto;
  margin-bottom: 0;
}

.desc-txt-wrap .edit-wrapper {
  margin-bottom: 14px;
}

.desc-txt-wrap .desc-txt input {
  background-color: transparent;
  height: 45px;
  padding: 0 15px;
  border: 1px solid #e5e5e5;
}

.desc-txt-wrap .edit-wrapper label {
  text-align: left;
}

.checkout-left #addressForm .desc-txt-wrap .error {
  text-align: left;
  font-size: 13px;
  display: block;
  margin-top: 0;
}

.shipping-itemwrap .head-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.shipping-itemwrap .edit-link {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
}

.shipping-itemwrap .desc-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.shipping-itemwrap .desc-txt b {
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}

.shipping-itemwrap .desc-txt b:last-child {
  font-weight: 400;
}

.delivery_wrap {
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #bebebe;
}

.shipping-itemwrap .gift-desc {
  padding-top: 20px;
}

.shipping-itemwrap .express-delivery {
  background: #fff;
  padding: 15px 20px;
}

.shipping-itemwrap .gift-desc span {
  display: block;
}

.checkout-right .total-item,
.checkout-right .summary-title {
  text-transform: capitalize;
  margin-bottom: 20px;
  display: block;
  font-size: 20px;
  font-family: 'Inter';
  font-weight: 600;
  color: #333333;
}

.checkout-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
}

.checkout-total div {
  display: flex;
  justify-content: space-between;
}

.checkout-total span {
  font-weight: 400;
  font-size: 30px;
  color: #4a4a4a;
  text-transform: none;
}

.checkout-item-price span,
.delivery-chrage span {
  font-size: 20px;
}

.checkout-total span:last-child {
  color: #000;
  padding: 0;
  text-align: right;
}

.checkout-cardwrap .checkout-card {
  padding: 20px 35px 20px 20px;
  border: 1px solid #bebebe;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
}

.checkout-cardwrap .card-desc {
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  font-family: "Poppins", sans-serif;
  width: calc(100% - 125px);
}

.checkout-cardwrap .card-desc span {
  display: block;
}

.checkout-cardwrap .card-img {
  width: 65px;
}

.checkout-cardwrap .card-desc b {
  font-weight: 500;
}

.checkout-cardwrap .qty-detail {
  display: flex;
  justify-content: space-between;
}

.checkout-cardwrap .price-txt {
  margin-bottom: 10px;
}

.shipping-itemwrap .desc-in input {
  width: auto;
  text-align: right;
}

.payment-type-list {
  padding: 15px 0;
}

.payment-type-list span {
  display: inline-block;
  margin-right: 50px;
}

.new-add-form .detail-from {
  padding: 30px 5%;
  background: #fff;
}

.payment-type .shop-now {
  margin-left: 0;
}

.add-new {
  /* text-align: right; */
  padding: 10px 0;
}

.success-mark,
.error-mark {
  color: #59d006;
  font-size: 40px;
}

.error-mark {
  color: #f86202;
}

@media only screen and (max-width: 1699px) {

  .checkout-item-price span,
  .delivery-chrage span {
    font-size: 20px;
  }

  .shipping-itemwrap .desc-txt input,
  .shipping-itemwrap .desc-txt {
    font-size: 16px;
  }

  .complete-purchase {
    font-size: 20px;
  }
}

.cart-des .price {
  font-size: 18px;
  font-weight: 500;
  padding: 3px 0 0;
}

@media only screen and (max-width: 1499px) {

  .checkout-item-price span,
  .delivery-chrage span {
    font-size: 18px;
  }

  .complete-purchase {
    font-size: 15px;
    height: 40px;
  }

  .follow-unfollow.view-follow .complete-purchase {
    width: 112px;
  }

  .apply-code {
    font-size: 12px;
    height: 17px;
    max-width: 66px;
  }

  .shipping-itemwrap {
    padding: 13px 3.2%;
  }

  .checkout-caption h2 {
    font-size: 20px;

  }
}

@media only screen and (max-width: 1199px) {
  .checkout-total span {
    font-size: 18px;
  }

  .shipping-itemwrap .desc-txt input,
  .shipping-itemwrap .desc-txt {
    font-size: 14px;
  }

  .shipping-itemwrap .edit-link {
    font-size: 14px;
  }

  .complete-purchase {
    font-size: 16px;
    height: 46px;
  }

  .checkout-cardwrap .card-desc {
    font-size: 12px;
    width: calc(100% - 100px);
  }
}

@media only screen and (max-width: 991px) {
  .checkout-total span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .complete-purchase {
    width: 100%;
  }

  .checkout-wrap {
    flex-direction: column-reverse;
  }

  .checkout-right,
  .checkout-left {
    width: 100%;
  }

  .checkout-total span {
    font-size: 22px;
  }

  .checkout-cardwrap .card-img {
    width: 50px;
  }

  .checkout-cardwrap .card-desc {
    width: calc(100% - 76px);
  }

  .checkout-cardwrap .checkout-card {
    padding: 15px;
  }

  .checkout-left {
    padding: 5px 0 30px;
    margin-top: 20px;
  }
}

.select-opt__single-value.css-1uccc91-singleValue {
  -webkit-transform: none !important;
          transform: none !important;
  top: 0px !important;
  line-height: 1.8;
}

@media only screen and (min-width:1500px) {
  .select-opt__single-value.css-1uccc91-singleValue {
    line-height: 1.5;
  }

  .follow-unfollow.view-follow .complete-purchase {
    width: 136px;
  }
}
.plp-header {
  position: relative;
  z-index: 9;
  /* overflow: hidden; */
}

.cat-head .plp-filters {
  display: none;
}

.plp-filters {
  width: 240px;
  border-radius: 40px;
  position: relative;
  z-index: 9;
  flex-shrink: 0;
}

.plp-filter-option {
  width: 100%;
}

.plp-main-screen {
  display: flex;
  flex-wrap: wrap;
}

.plp-product-screen {
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.accordian-header {
  color: #4a4a4a;
  cursor: pointer;
  padding: 18px 18px 10px 0;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 21px;
  transition: 0.4s;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  font-family: Roboto, sans-serif;
  text-transform: capitalize;
}

.accordian-header:before {
  float: right;
  margin-left: 5px;
  width: 0;
  height: 0;
  position: absolute;
  right: 9px;
  font-family: "Font Awesome 5 Pro";
  transition: all 0.5s ease 0s;
  color: #f86202;
}

.accordian-header.active:before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  right: 0;
}

.accordian-header.active:after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}

.active,
.accordian-header:hover {
  background-color: #ffffff;
}

.accordian-panel {
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  line-height: 15px;
  font-size: 15px;
  margin-left: 8px;
  display: flex;
}

.category-attr-block .accordian-panel {
  display: block;
}

.accordian-panel input[type="search"] {
  height: 30px;
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 0 7px;
}

.accordian-panel input[type="search"]::-webkit-search-decoration:hover,
.accordian-panel input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.expanded {
  max-height: -webkit-max-content;
  max-height: max-content;
}

.plp-costume-box {
  width: 24.92%;
  margin-bottom: 54px;
  flex: 0 0 auto;
  overflow: hidden;
  padding: 0 10px;
}

.plp-costume-box a {
  display: block;
}

.manage-profile .plp-costume-box {
  margin-bottom: 125px;
}

.plp-costume-block {
  border: 1px solid #e6e6e6;
  position: relative;
  transition: all 0.5s ease;
}

.manage-profile .plp-costume-block {
  border: 1px solid transparent;
}

/* .plp-costume-block:hover{
  border:1px solid #d16d38;
} */
/* .red-heart.icon-wishlist{
  background-position: -13px -190px;
} */
.plp-costume-info strong {
  font-size: 23px;
  font-weight: 600;
  color: #4a4a4a;
  /* margin-top: 19px;
  margin-bottom: 10px; */
  display: block;
  position: relative;
}

.plp-costume-info {
  padding: 0px 0px 0px 20px;
}

.plp-costume-info p {
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 500;
  line-height: 23px;
}

.plp-costume-info span {
  color: #a292b3;
}

.plp-costume-info .buy-info {
  color: #4a4a4a;
}

.plp-loadMore-div {
  width: 100%;
  text-align: center;
}

.plp-loadMore-btn {
  width: 276px;
  height: 71px;
  background: #f7f5f5;
  letter-spacing: 0px;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid #f7f5f5;
  transition: all 0.3s ease-in-out;
}

.plp-loadMore-btn:hover {
  color: #d16d38;
  border-color: #d16d38;
  background: none;
}

.plp-categoryItem {
  cursor: pointer;
  line-height: 20px;
}

.plp-category-selected {
  font-weight: 600;
}

.plp-colorPallete {
  margin-left: 5px;
  margin-top: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.plp-color-swatch-container {
  display: flex;
  flex-wrap: wrap;
}

.product-listhead {
  align-items: center;
  padding: 18px 0px 23px 0;
}

.product-listhead h2 {
  font-size: 25px;
  font-weight: 300;
  color: #4a4a4a;
  margin: 0px 0 0 0;
  font-family: "Roboto";
  float: left;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.select-opt__control {
  height: 47px;
  border-color: #959595;
  font-size: 22px;
  color: #4a4a4a;
  font-weight: 600;
  padding-left: 0;
}

.select-opt__indicator-separator {
  display: none;
}

.select-opt__indicator svg {
  width: 20px !important;
  height: 20px !important;
}

.product-listhead .breadcrumb-wrap {
  padding: 0;
  border: 0;
}

.mainlisting-wrap {
  padding: 0px 0px 20px 0;
  justify-content: space-between;
  position: relative;
  /* border-bottom: 1px solid #e6e6e6; */
}

.mainlisting-wrap .plp-category {
  border-top: 1px solid #e6e6e6;

}

.mainlisting-wrap .plp-category hr {
  border-top: 1px solid #e6e6e6;
}

.mainlisting-wrap .plp-product-screen {
  margin: 0;
}

.accordian-panel .plp-categoryItem {
  color: #4a4a4a;
  cursor: pointer;
  padding: 15px 18px 15px 0;
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.accordian-panel .plp-categoryItem.plp-category-selected,
.accordian-panel .plp-categoryItem:hover {
  color: #d16d38;
}

.mainlisting-wrap .plp-costume-action {
  position: absolute;
  top: -5px;
  right: 0px;
}

.mainlisting-wrap .product-pack {
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainlisting-wrap .plp-costumes {
  /* height: 278px;
  padding: 15px; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainlisting-wrap .plp-costumes img {
  max-height: 100%;
}

.plp-costume-block>a {
  display: block;
}

.no-product-wrap {
  width: 100%;
}

.no-product {
  width: 25%;
  margin: 0 auto;
}

.no-product-wrap h4 {
  margin-top: -30px;
}

/* range slider Start */
.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  touch-action: none;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-size: 14px;
  transition: all 0.1s ease-in;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}

.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}

.rangeslider .rangeslider__handle-tooltip:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
}

.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #7cb342;
  border-radius: 10px;
  top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4),
    inset 0 -1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}

.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}

.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}

.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}

.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}

.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}

.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}

.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}

.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}

.rangeslider__labels {
  position: relative;
}

.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0);
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item:before {
  content: "";
  width: 10px;
  height: 2px;
  background: #000;
  position: absolute;
  left: -14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
}

.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0);
}

.category-filter-tag {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #f86202;
  font-size: 14px;
  cursor: pointer;
  margin: 0 10px 10px;
  transition: all 0.3s ease-in-out;
  border-radius: 40px;
  color: #f86202;
}

.category-filter-tag:hover {
  background: #f86202;
  color: #fff;
}

.category-filter-tag div {
  display: inline-block;
  padding: 0 6px 0px 0;
}

.plp-loadMore-div {
  margin-top: 25px;
}

.editmain-wrapper {
  max-width: 1920px;
  margin: 0 auto;
}

.modal-body .react-share__ShareButton {
  width: 48px;
  height: 48px;
  margin: 0 5px;
  transition: all 0.5s;
}

/* Range Slider End */
.product-listhead .cat-head-wrap h2 i {
  display: none;
}

.sort-drop img {
  display: none;
}

/* Responsive CSS start */
.search-brand .react-autosuggest__suggestions-container {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  width: calc(100% + 40px);
  max-height: 434px;
  overflow: hidden;
}

@media only screen and (max-width: 1699px) {
  .product-listhead h2 {
    font-size: 20px;
  }

  .plp-filters .select-opt__control {
    padding-left: 10px;
    font-size: 18px;
  }

  .new {
    font-size: 18px;
  }

  .accordian-header {
    font-size: 18px;
  }

  .plp-loadMore-btn {
    font-size: 18px;
    width: 240px;
    height: 60px;
  }

  .accordian-panel .plp-categoryItem {
    font-size: 18px;
  }

  .plp-costume-info strong {
    font-size: 19px;
  }
}

@media only screen and (max-width: 1499px) {
  .accordian-panel {
    margin-left: 0;
  }

  .accordian-panel .plp-categoryItem,
  .accordian-header {
    font-size: 16px;
  }

  .plp-filters .select-opt__control {
    font-size: 16px;
  }

  .new {
    font-size: 16px;
  }

  .plp-costume-info strong {
    font-size: 17px;
  }

  .plp-costume-info p {
    font-size: 14px;
  }
}

.price-panelwrap {
  max-height: none;
  margin: 0;
}

@media only screen and (max-width: 1199px) {
  .plp-costume-info strong {
    font-size: 15px;
  }

  .plp-costume-info p {
    font-size: 12px;
  }

  .plp-loadMore-btn {
    font-size: 16px;
    width: 190px;
    height: 52px;
  }

  .select-opt__indicator svg {
    width: 19px !important;
    height: 19px !important;
  }

  .product-listhead {
    padding: 30px;
  }

  .mainlisting-wrap {
    padding: 0 30px 60px;
  }

  .product-listhead h2 {
    margin: 0;
  }

  .plp-costume-box {
    width: 49.92%;
  }

  .mainlisting-wrap .product-pack {
    padding: 0 10px;
  }

  .plp-main-screen.mainlisting-wrap .costume-action {
    right: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .plp-filters {
    width: 28%;
  }

  .breadcrumb-wrap li,
  .breadcrumb-wrap a {
    font-size: 14px;
  }

  .breadcrumb-wrap .back-arrow {
    width: 6px;
    height: 10px;
  }

  .accordian-panel .plp-categoryItem,
  .accordian-header {
    font-size: 19px;
  }

  .product-listhead {
    padding: 25px;
  }

  .mainlisting-wrap {
    padding: 0 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .search-brand .react-autosuggest__suggestions-container {
    left: 0;
    right: 0;
    width: calc(100% + 23px);
  }

  .modal-body .react-share__ShareButton {
    width: 36px;
    height: 36px;
  }

  .mainlisting-wrap .plp-category {
    border-top: 0;
  }

  .product-listhead .cat-head-wrap {
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    align-items: center;
    background-color: #fff;
    -webkit-filter: drop-shadow(0.5px 2.598px 2.5px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0.5px 2.598px 2.5px rgba(0, 0, 0, 0.1));
  }

  .product-listhead .cat-head-wrap.category-filter-head.category-wrap-head h2 {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }

  .product-listhead .cat-head-wrap h2 i {
    display: inline-block;
    font-size: 14px;
  }

  .product-listhead .plp-filters .my_class.new-filter.my-new-filter {
    text-align: center;
    border: 0;
    border-left: 1px solid #e5e5e5;
    font-size: 15px;
    font-weight: 500;
    color: #4a4a4a;
    background-image: none;
    padding: 10px 46px 9px 22px;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .sort-drop img {
    display: inline-block;
    position: absolute;
    max-width: 19px;
    top: 13px;
    right: 24px;
    z-index: 1;
  }

  .plp-header {
    display: block;
  }

  .breadcrumb-wrap {
    margin-bottom: 15px;
  }

  .plp-category {
    width: 100%;
  }

  .plp-filters {
    width: auto;
  }

  .cat-head-wrap .plp-filters .sort-drop {
    width: auto;
    margin-left: 0;
  }

  .plp-category {
    margin-bottom: 25px;
  }

  .mainlisting-wrap {
    display: block;
  }

  .mainlisting-wrap .plp-product-screen {
    width: 100%;
    margin: 0;
  }

  .plp-costume-box {
    width: 48%;
  }

  .plp-costume-box a {
    display: block;
  }

  .plp-costume-info strong {
    position: relative;
  }

  .plp-filter-option {
    width: 100%;
  }

  .product-listhead {
    padding: 20px;
  }

  .mainlisting-wrap {
    padding: 0 0 40px;
  }

  .product-listhead h2 {
    margin: 0;
    text-align: left;
  }

  .product-listhead {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 575px) {
  .plp-costume-box {
    width: 100%;
  }
}

/* ////## bazaar  /// */
.price-div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
}

.price-lable {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  touch-action: manipulation;
}

.price-lable:last-child {
  margin-left: 50px;
}

.price-div .price-lable:last-child {
  margin-left: 0;
}

.price-div .price-lable {
  margin-bottom: 10px;
}

.price-input {
  margin-left: 10px;
}

.price-section:hover {
  background: #f5f5f6;
}

.price-section {
  padding: 5px 0px 5px 5px;
}

.price-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  background: #00000000;
  height: auto;
  width: auto;
}

.price-textInput {
  -webkit-appearance: none;
          appearance: none;
  border-radius: 5px;
  height: 100%;
  width: 79px;
  /* font-size: var(--type-reg); */
  line-height: 22px;
  letter-spacing: -0.24px;
  /* color: var(--color-grey-4); */
  border: 1px solid #cdced2;
  padding: 12px 6px;
}

.price-input-dash {
  width: 7px;
  height: 1px;
  background: black;
  margin: auto 5px;
}

.price-apply {
  text-align: center;
  background-color: var(--color-transparent);
  color: #1717e2;
  border: 0px;
  border-radius: var(--round-default);
  cursor: pointer;
  font-weight: var(--font-semibold);
  min-width: 64px;
  padding: 0 var(--space-4);
  display: inline-block;
  width: initial;
  font-size: var(--type-h6);
  height: 32px;
  line-height: 32px;
  position: relative;
  z-index: 0;
}

.spinner-button {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 15;
}

.modal-dialog {
  background: #fff;
  padding: 11px 17px 20px;
  width: 300px;
  border-radius: 15px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal-body {
  text-align: center;
  margin-bottom: 21px;
}

.modal-body .react-share__ShareButton:hover {
  opacity: 0.7;
}

.modal-body .react-share__ShareButton svg {
  width: 100%;
  height: 100%;
}

.modal-header .modal-title {
  font-size: 22px;
  font-weight: 600;
  font-family: "Roboto";
  color: rgb(34, 34, 34);
}

.modal-header .close>span {
  font-size: 25px;
  border-radius: 50%;
  color: rgb(194, 193, 193);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f67900;
  transition: all 0.5s;
}

.modal-header .close>span:hover {
  color: #f86202;
  border-color: #f86202;
}

.modal-header .close .sr-only {
  display: none;
}

.modal-footer button {
  color: rgb(34, 34, 34);
  font-family: "Roboto";
  font-weight: 600;
  margin-left: 10px;
  font-size: 14px;
  transition: all 0.5s;
}

.modal-footer button:hover {
  color: #f86202;
}

.modal-footer i {
  font-weight: 400;
  margin-right: 5px;
}

.forget-block .input-form .send-msg-green {
  margin-bottom: 14px;
}

.modal-dialog.modal-90w.bank .file-inputs {
  height: 50px;
}

.modal-dialog.modal-90w.bank .seller-inputs .seller-input input {
  height: auto;
}

.modal-dialog.modal-90w.bank {
  width: 90% !important;
  height: auto !important;
}

.modal-dialog.modal-90w.bank .label-text {
  text-align: left;
  width: 100%;
  display: block;
  margin-bottom: 4px;
  font-weight: 500;
  font-family: "Roboto";
}

.slick-arrow.slick-prev.slick-disabled {
  display: none !important;
}

.slick-arrow.slick-next.slick-disabled {
  display: none !important;
}

.modal-dialog.modal-90w.bank .btn-purple {
  font-size: 15px;
  text-transform: capitalize;
  height: 38px;
  background: #f86202;
  border: 1px solid #f86202;
}

.modal-dialog.modal-90w.bank .btn-purple:hover {
  background: transparent;
  color: #f86202;
}

.modal-dialog.modal-90w.bank .modal-body {
  margin-bottom: 0;
}

.cat-head-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

@media only screen and (min-width: 768px) {
  .mainlisting-wrap .plp-category {
    -webkit-filter: drop-shadow(0.5px 1.598px 2.5px rgba(0, 0, 0, 0.21));
            filter: drop-shadow(0.5px 1.598px 2.5px rgba(0, 0, 0, 0.21));
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    margin-right: 1%;
  }

  .modal-dialog.modal-90w.bank {
    width: 510px !important;
  }

  .modal-dialog {
    padding: 14px 21px 22px;
    width: 340px;
  }

  .modal-body .react-share__ShareButton {
    margin: 0 7px;
  }

  .modal-header {
    margin-bottom: 30px;
  }

  .modal-body {
    margin-bottom: 31px;
  }

  .modal-footer button {
    margin-left: 21px;
  }
}

@media only screen and (min-width: 1500px) {
  .accordian-panel {
    margin-bottom: 3px;
    margin-left: 10px;
  }
}
.price-sec.filter-border .accordian-header{
  background-color: transparent;
}
/* #root{ padding: 10px; } */
.article-desc {
    padding: 80px 10%;
    /* background: #F3EFF7; */
}
.blog-article-one {
    padding-bottom: 130px;
    border-bottom: 1px solid #CECECE;
}
.article-wrap .container {
    max-width: 1824px;
}
.article-head {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    flex-direction: column-reverse;
}
.article-head figure {
    width:100%;
    /* height: 400px; */
    /* border-radius: 50%; */
    overflow: hidden;
    order: 1;
    margin-bottom:30px;
    text-align: center;
}
.article-head figure img{   
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.article-head-wrap h4{
    margin-bottom: 15px;
}
.article-head span {
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    color:#A292B3;
    font-family: 'Poppins', sans-serif;
    margin: 0 5%;
    order: 2;
    display: contents;
}
.article-detail h2 {
    font-size: 79px;
    color:#A292B3;
    font-family: 'Ogg';
    font-weight: normal;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 60px;
}
.article-detail .desc {
    margin-bottom: 30px;
}
.article-detail p {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.5;
    color:#4A4A4A;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}
.article-slider .slick-arrow {
    width: 70px;
    height: 70px;
    z-index: 2;
    background: #F7F5F5 url(/assets/images/slide-arrow.svg) no-repeat center center;
    background-size: 12px 21px;
}
.article-slider .slick-arrow:before {
    display: none;
}
.article-slider {
    padding: 40px 0 60px;
}
.article-slider .slick-prev {
    left:0;
}
.article-slider .slick-next {
    right:0;
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
}

/* blog comment  */
.comment-wrap{
    max-width: 750px;
    margin: 0 auto 0 10%;
    padding: 20px;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15);
}
.tabbing-list{
    border-bottom: 1px solid #ccc;
}
.list-item{
    padding: 15px;
    position: relative;
    font-weight: 700;
    font-size: 18px;
    cursor: default;
}
.tabbing-list li.active a:after{
    content: "";
    position: absolute;
    bottom: -1px;
    border-bottom: 2px solid #b30000;
    left: 0;
    height: 1px;
    width: 100%;
}
.share-list{
    padding: 15px;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 250px;
    margin-bottom: 20px;
}
.share-list li{
    margin-right: 50px;
}
.share-list a{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}
.share-list li a .icon-wish {
    background-position: -44px -6px;
    background-size: 660px auto;
}
.share-list li a.recommend.active i{
    background-position: -8px -133px;
    width: 27px;
    height: 28px;
}
.share-list a i{
    margin-right: 10px;
}
.share-list a strong{
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.share-list a span.number{
    display: inline-block;
    padding: 3px 5px;
    background-color: #ccc;
    font-size: 10px;
    color: #000;
    border-radius: 3px;
    font-weight: 500;
}
.share-icon{
    width: 25px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
    padding-top: 3px;
}
.share-list li.sort-dropdown{
    position: absolute;
    right: 0;
    margin-right: 0;
}
.sort-dropdown label{
    margin-right: 5px;
    font-weight: 600;
}
.sort-dropdown select{
    padding: 5px;
}
.comment-section{
    display: flex;
    min-height: 50px;
    margin-bottom: 20px;
    box-shadow:0 0 4px 2px rgba(0, 0, 0, 0.15);
    padding: 20px;
}
.reply-field .comment-section{
    border-radius: 5px;
}
.comment-block{
    margin-bottom: 50px;
}
.comment-block .user-img{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 20px;
    height: 50px;
}
.comment-block .user-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.area-field{
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
}
.area-field textarea{
    width: calc(100% - 115px);
    font-family: serif;
    font-size: 16px;
    height: 100%;
    padding: 5px 10px;
    border: 0;
    border-radius: 5px;
    border:1px solid #ccc;
}
.comment-btn{
    display: block;
    margin: 0 auto;
    background-color: #d16d38;
    border-color: #d16d38;
    color: #fff;
    font-size: 16px;
    min-width: 78px;
    padding:0px 15px;
    height: 40px;
    line-height: 40px;
}
.comment-btn:hover{
    background-color: transparent;
    border-color: #d16d38;
    color: #d16d38;
}
.post-sec{
    height: auto;
}
.post-content{
    width: calc(100% - 70px);
}
.post-content strong{
    color: #b30000;
    font-weight: 600;
    display: inline-block;
    margin-right: 15px;
}
.post-content span{
    color: #6f6d6d;
    font-size: 12px;
    display: inline-block;
    position: relative;
}
.post-content span:after{
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ccc;
    left: -8px;
    top:5px;
}
.article-detail .post-content p{
    font-size: 13px;
    margin: 5px 0;
    color: #000;
}
.post-bottom{
    padding-left: 15px;
    margin-bottom: 10px;
}
.post-bottom li{
    display: inline-block;
    margin-right: 15px;
    position: relative;
}
.post-bottom li:after{
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #b19f9f;
    left: -8px;
    top:9px;
}
.post-bottom li a{
    font-weight: 600;
    font-size: 12px;
    padding: 5px;
}
.post-bottom li:hover a{
    color: #03a9f4;
}
.post-bottom li:hover:after{
    background-color: #03a9f4;
}
/* .reply-field{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;
} */

.reply-field .comment-btn{
    min-width: 78px;
    padding:0px 10px;
    height: 40px;
    line-height: 40px;
}
.article-detail .post-block p{
    margin-bottom: 15px;
    padding-right: 80px;
}
.post-comment{
    padding: 20px;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15);
    border-radius:5px;
}
.post-wrap{
    width: calc(100% - 80px);
}
.article-detail .post-wrap p {
    margin: 0;
}
/* Responsive css start */
@media only screen and (max-width: 1699px) {
    /* .article-head figure {
        width: 330px;
        height: 330px;
    } */
    .article-head span {
        font-size: 22px;
    }
    .article-detail h2 {
        font-size: 64px;
    }
    .article-detail p {
        font-size: 20px;
    }
}
@media only screen and (max-width: 1499px) {
    /* .article-head figure {
        width: 290px;
        height: 290px;
    } */
    .article-head span {
        font-size: 18px;
    }
    .article-detail h2 {
        font-size: 50px;
    }
    .article-detail p {
        font-size: 18px;
    }
    .article-slider .slick-arrow {
        width: 60px;
        height: 60px;
    }
}
@media only screen and (max-width: 1199px) {
    /* .article-head figure {
        width: 250px;
        height: 250px;
    } */
    .article-detail h2 {
        font-size: 44px;
    }
    .article-detail p {
        font-size: 16px;
    }
    .article-slider .slick-arrow {
        width: 50px;
        height: 50px;
    }
}
@media only screen and (max-width: 991px) {
    /* .article-head figure {
        width: 230px;
        height: 230px;
    } */
    .article-detail h2 {
        font-size: 36px;
        margin-bottom: 30px;
    }
    .article-detail p {
        font-size: 16px;
    }
    .article-slider {
        padding: 0 0 40px;
    }
    .blog-article-one {
        padding-bottom: 70px;
    }
    .article-desc {
        padding: 50px 10%;
    }
}
/* Responsive css End */
@media only screen and (max-width: 767px) {
    .article-desc {
        padding: 50px 15px;
    }
    .comment-wrap{
        max-width: 100%;
        margin: 0 15px;
    }
    .area-field textarea{
        width: 100%;
    }
}
@media only screen and (max-width: 575px) {
    .article-head {
        display: flex;
        /* text-align: center; */
    }
    .article-head figure{
        margin: 0 auto 20px;
    }
    .article-head span{
        margin-bottom: 20px;
        /* display: block; */
    }
    .article-detail h2 {
        font-size: 22px;
    }
    .article-detail .desc {
        margin-bottom: 20px;
    }
}

/* comment responsive */
@media only screen and (max-width: 700px) {
    .share-list{
        padding-right: 0;
        flex-wrap: wrap;
    }
    .share-list li{
        margin-bottom: 8px;
    }
    .share-list li.sort-dropdown{
        position: static;
        width: 100%;
        display: block;
        margin-top: 15px;
    }
    .reply-field{
        flex-wrap: wrap;
    }
    .reply-field .area-field{
        width: 100%;
        margin-bottom: 15px;
    }
    .reply-field .btn{
        min-width: 100px;
    }
    .article-detail .post-block p{
        padding-right: 0;
    }
}
.feature-card-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.ac-head-wrap {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
}

.matrix-key,
.matrix-val {
  display: block;
}

.matrix-key {
  margin-bottom: 10px;
  text-transform: capitalize;
}

.total-amount,
.matrix-val {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.ac-head-wrap h4 span {
  font-size: 14px;
  font-weight: 300;
  margin-left: 5px;
  text-transform: capitalize;
}

.action-item {
  margin-bottom: 40px;
  font-family: "Roboto";
}

.action-item h4,
.ac-statics h4,
.perf-statics h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.action-item button {
  width: 40%;
  margin-right: 20px;
  background: #fff;
  padding: 10px;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  font-family: "Roboto";
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  position: relative;
}

.action-item button i {
  font-size: 18px;
  margin-right: 6px;
  vertical-align: middle;
}

.action-item button .fa-angle-right {
  position: absolute;
  top: 7px;
  right: 15px;
  font-size: 24px;
}

.ac-statics {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 23px;
  padding-bottom: 15px;
  font-family: "Roboto";
}

.perf-statics {
  font-family: "Roboto";
}

.info-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 13px;
  flex-wrap: wrap;
}

.matrix-block.info-wrap div {
  width: 33%;
  margin-bottom: 23px;
}

.info-wrap p {
  margin-bottom: 10px;
}

.profile-wrapper .article-head {
  display: block;
  text-align: center;
}

.profile-wrapper .article-head figure {
  margin: 0 auto 20px;
}

.profile-wrapper .profile-name {
  font-size: 24px;
  display: block;
  margin-bottom: 15px;
  text-transform: capitalize;
  text-align: center;
}

.left-item-list i {
  display: inline-block;
  margin-right: 10px;
  width: 22px;
  font-weight: normal;
  font-size: 18px;
}

.left-item-list .active-tab i {}

.profile-wrapper .tags a {
  width: 17%;
}

.profile-wrapper .tags .active-tab {
  color: #F5C139;
  opacity: 1;
  position: relative;
}

.profile-wrapper .tags .active-tab:after {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #F5C139;
  content: "";
  left: 0;
}

.profile-wrapper .tags-wrap {
  margin-bottom: 30px;
}

.blogtags-sec .container {
  padding: 0 2%;
}

.profile-details {
  background: #f7f5f6;
}

.profile-details .detail-row {
  display: flex;
  justify-content: space-between;
}

.profile-details .detail-from {
  padding: 70px 0 90px;
  border-bottom: 3px solid rgba(112, 112, 112, 0.11);
}

.profile-details .detail-from:last-child {
  border: 0;
}

.profile-details .detail-colmn {
  width: 49%;
}

.detail-from input {
  font-size: 18px;
  color: #4a4a4a;
  height: 53px;
  border: 3px solid #d9d9d9;
  padding: 5px 15px;
  width: 100%;
  background: #e5e5e5;
}

.group-wrap.edit-profile .form-input select {
  font-size: 18px;
  color: #4a4a4a;
  height: 53px;
  border: 3px solid #d9d9d9;
}

.detail-from .edit-profile input {
  background: #fff;
}

.detail-from .edit-profile input::-webkit-input-placeholder {
  opacity: 1;
}

.detail-from .edit-profile input::placeholder {
  opacity: 1;
}

.form-input span {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 7px;
  display: block;
  text-align: left;
}

.detail-from input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.2;
}

.detail-from input::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.2;
}

.detail-from input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.2;
}

.detail-from input:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.2;
}

.detail-from .form-title {
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 40px;
  color: #4a4a4a;
  font-weight: 600;
  text-transform: capitalize;
}

.detail-from .form-input {
  margin-bottom: 30px;
  position: relative;
}

.detail-from .group-wrap {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}

.detail-from .group-wrap .form-input {
  width: 48%;
}

.detail-from button {
  /* width: auto; */
  text-transform: uppercase;
  margin-left: 0;
}

.signup-form .detail-from button.shop-now {
  padding: 7px 50px;
}

.detail-from button.search-btn {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  height: 72px;
  background: #a292b3;
  width: 133px;
  border: 1px solid #a292b3;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-align: center;
  padding: 7px;
}

.detail-from button.search-btn:hover {
  background: #fff;
  color: #a292b3;
}

.detail-from .search-field {
  display: flex;
}

.detail-from .search-field input {
  width: calc(100% - 133px);
}

.profile-details.contact-details {
  padding: 60px 0 100px;
}

.contact-details .btn-wrap {
  text-align: center;
  padding: 140px 0 20px;
}

.profile-details.contact-details .detail-from {
  max-width: 552px;
  width: 100%;
  padding: 40px 3.5%;
  background: #fff;
  border: 1px solid #707070;
}

.wardrobe-card {
  text-align: center;
  min-height: 525px;
}

.wardrobe-card .add-icn {
  width: 66px;
  height: 66px;
  display: inline-block;
}

.wardrobe-wrap {
  display: flex;
  justify-content: space-between;
}

.wardrobe-wrap .pause-btn {
  width: auto;
  min-width: 359px;
}

/* Profile four start */
.profile-account {
  display: flex;
  align-items: center;
  margin-bottom: 115px;
  position: relative;
}

.manage-profile .profile-account {
  display: flex;
  flex-wrap: wrap;
}

.manage-profile .profile-account .acc-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.profile-account .account-desc {
  text-align: center;
  width: 25%;
}

.profile-account .account-desc.acc-desc {
  width: 26.05%;
  text-align: left;
}

.profile-account .acc-name {
  font-size: 48px;
  letter-spacing: 1.1px;
  font-weight: normal;
  font-family: "Ogg";
  color: #4a4a4a;
  text-align: left;
  margin-bottom: 25px;
}

.profile-account .verify-btn {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #d16d38;
  border: 1px solid #d16d38;
  transition: all 0.3s ease-in-out;
  max-width: 339px;
  width: 100%;
  padding: 5px 10px;
  letter-spacing: -0.7px;
}

.profile-account .verify-btn:hover {
  background: none;
  color: #d16d38;
}

.profile-account .address-txt {
  font-size: 20px;
  font-family: "Roboto";
  color: #4a4a4a;
  text-align: left;
}

.profile-account .total-txt {
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto";
  color: #4a4a4a;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.profile-account .total-amount {
  font-size: 44px;
  font-weight: 600;
  color: #d16d38;
}

.profile-account .profile-img {
  height: 150px;
  width: 150px;
  padding: 10px;
  background-size: 100% 100%;
  margin: 0 auto -92px;
  background-color: #ffffff;
  border-radius: 50%;
}

.profile-account .profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}

.profile-account .acc-info {
  width: 60%;
}

.profile-account .info-wrap {
  display: flex;
  justify-content: space-between;
  /* width: 58.8%; */
  width: 68.3%;
  padding-top: 11px;
}

.profile-calendar {
  padding: 60px 0;
  display: flex;
  justify-content: space-between;
}

.profile-calendar .calendar-title {
  font-size: 26px;
  font-weight: 600;
  color: #4a4a4a;
}

.profile-calendar .landing-calendar,
.profile-calendar .rental-calendar {
  width: 48%;
  min-height: 300px;
}

.invite-profilewrap {
  border-bottom: 1px solid #cecece;
}

.invite-list {
  margin-bottom: 75px;
}

.invite-list li {
  display: inline-block;
  text-align: center;
  padding-bottom: 40px;
  margin-right: 8%;
}

.invite-list figure:after {
  position: absolute;
  width: 30px;
  height: 6px;
  background: #a292b3;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: -40%;
  content: "";
}

.invite-list li:last-child figure:after {
  display: none;
}

.invite-list button {
  font-size: 14px;
  font-weight: 600;
  color: #d16d38;
  background: #f7d9b7;
  border: 1px solid #f7d9b7;
  transition: all 0.3s ease-in-out;
  padding: 8px 26px;
  text-transform: uppercase;
}

.invite-list button:hover {
  background: none;
  border-color: #d16d38;
}

.invite-list figure {
  margin-bottom: 30px;
  position: relative;
}

.invite-profile {
  padding: 40px 40px 130px;
  border: 1px solid #707070;
  margin-bottom: 95px;
}

.invite-profile .invite-title {
  font-size: 44px;
  font-weight: normal;
  font-family: "Ogg";
  color: #4a4a4a;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.invite-profile .invite-desc {
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto";
  line-height: 1.5;
  margin-bottom: 60px;
}

.invite-form {
  display: flex;
}

.invite-form .search-field {
  width: 43.5%;
  margin: 0 5% 0 0;
}

.profile-wrapper .react-calendar button {
  padding: 0.75em 0.5em;
}

.profile-wrapper .react-calendar {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
}

.profile-settings .setting-icn {
  width: 32px;
  position: relative;
}

.profile-settings .notification-icn {
  width: 30px;
}

.profile-wrapper .plp-header {
  align-items: center;
  margin-bottom: 63px;
}

.profile-wrapper .plp-product-screen {
  width: 100%;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.profile-wrapper .addmore-card,
.profile-wrapper .plp-costume-box {
  width: 25%;
  margin-right: 0;
  padding: 0 15x;
}

.profile-wrapper .addmore-card {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-wrapper .plp-costume-action {
  top: 2px;
  left: 0;
  width: 100%;
}

.profile-wrapper .plp-costume-action span {
  padding: 10px 5px;
  margin: 0 3px;
  float: right;
}

.profile-wrapper .plp-costume-action span.new {
  float: left;
  padding: 10px;
}

.addmore-card .add-icn {
  display: block;
  width: 136px;
  height: 136px;
  margin: 0 auto 30px;
  cursor: pointer;
}

.addmore-card .add-txt {
  font-size: 40px;
  font-weight: normal;
  font-family: "Ogg";
  margin-bottom: 40px;
  color: #4a4a4a;
}

.profile-rating {
  display: inline-block;
}

.profile-rating .star-icn {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 15px;
  vertical-align: middle;
}

.profile-rating .rate {
  display: inline-block;
  font-size: 20px;
  font-weight: normal;
  text-decoration: underline;
  font-family: "Roboto";
  padding: 0px 0 0 10px;
}

.form-title.billing-title {
  padding-top: 40px;
  border-top: 3px solid rgba(112, 112, 112, 0.11);
}

.billing-checked {
  display: block;
  position: relative;
  margin-bottom: 30px;
}

.billing-checked span {
  padding-left: 30px;
  display: inline-block;
  font-size: 14px;
  font-family: "Roboto";
}

.billing-checked input[type="checkbox"] {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}

.profile-wrapper .select-opt__input input {
  height: auto !important;
}

.plp-product-screen .plp-costumes {
  /* height: 278px;
  padding: 15px; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plp-product-screen .plp-costumes img {
  max-height: 100%;
}

.seller-profile-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profile-account-wrap {
  width: 25.5%;
}

.profile-account-wrap .profile-account {
  border: none;
  display: block;
  padding: 55px 50px 42px;
  text-align: center;
  background-color: #faefe9;
}

.profile-account-wrap .profile-account .profile-img {
  margin: 0 auto 50px;
  width: 300px;
  height: 300px;
}

.profile-account-wrap .profile-account .acc-name {
  font-size: 50px;
  text-align: center;
  margin-bottom: 14px;
}

.profile-account-wrap .profile-account .acc-info {
  width: 100%;
}

.profile-account-wrap .profile-account .verify-btn {
  margin-bottom: 55px;
}

.profile-account-wrap .profile-account .info-wrap {
  flex-wrap: wrap;
}

.profile-account-wrap .profile-account .account-desc {
  width: 50%;
  margin-bottom: 58px;
}

.profile-account-wrap .follow-user {
  padding: 0 18%;
}

.profile-account-wrap .follow-user .verify-btn {
  padding: 21px 20px;
  border-radius: 50px;
  border-width: 3px;
  margin-bottom: 30px;
}

.profile-account-wrap .follow-user .verify-btn:last-child {
  margin-bottom: 0;
}

.rating-popup {
  width: 790px;
  padding: 40px 60px;
}

.rating-popup .icon-close {
  top: 16px;
  right: 18px;
}

.rating-popup h2 {
  font-weight: normal;
  font-family: "Ogg";
  font-size: 43px;
  color: #d16d38;
  margin-bottom: 48px;
}

.rating-popup p {
  font-family: "Roboto";
  font-size: 24px;
  color: #4a4a4a;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 36px;
}

.rating-popup .verify-btn {
  padding: 23px 45px;
  position: relative;
  display: block;
  width: 82%;
  margin: 0 auto;
  background: #d16d38;
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}

.rating-popup .verify-btn::after {
  content: "";
  background: url(/assets/images/sprites.png) -266px -17px no-repeat;
  width: 23px;
  height: 19px;
  position: absolute;
  right: 28px;
  top: 28px;
}

.rating {
  height: auto;
  text-align: left;
  margin-bottom: 20px;
}

.rating input {
  position: absolute;
}

.rating__item {
  display: inline;
  font-size: 0;
}

.rating__item::before {
  font-size: 50px;
  content: "\2605";
  color: #b7b7b7;
}

.rating__input {
  position: absolute;
  visibility: hidden;
}

/* .rating__item:hover:before {
  color: #d16d38;
} */
.rating:hover .rating__item::before {
  color: #f86202;
}

.rating__item:hover~.rating__item::before {
  color: #b7b7b7;
}

.rating__item--selected::before {
  color: #f86202;
}

.seller-prod-tags .tags-wrap {
  margin-bottom: 50px;
}

.seller-prod-tags .seller-prods {
  margin: 0 -16px;
}

.seller-prod-tags .plp-product-screen {
  padding: 0;
  justify-content: flex-start;
}

.seller-prod-tags .plp-costume-block {
  border: 1px solid #d16d38;
}

.seller-prod-tags .plp-costume-box {
  width: 33.33%;
  padding: 0 16px;
  margin-right: 0;
}

.seller-prod-tags {
  width: 72%;
}

.product-head {
  width: 100%;
  text-align: center;
}

.product-head h6 {
  font-size: 16px;
  color: #d16e39;
  margin-top: 20px;
}

.product-head .product-img {
  width: 200px;
  margin: 0 auto;
}

.breadcrumb-wrap li {
  padding: 0 8px;
}

.breadcrumb-wrap li:first-child {
  padding-left: 0;
}

.manage-profile .blogtags-sec {
  margin: 0;
}

.manage-profile .plp-header {
  padding: 0px 60px;
  margin: 0 auto 63px;
  max-width: 1920px;
}

.blogtags-sec .breadcrumb-wrap {
  padding: 0;
}

.manage-profile .profile-settings .setting-icn {
  margin: 0;
}

.manage-profile .plp-product-screen {
  padding: 0;
  font-family: "Roboto";
}

.edit-maintain figure {
  max-width: 500px;
  max-height: 500px;
}

.edit-maintain.article-head figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.change-reset-file {
  margin-right: 0;
}

.blogtags-sec .edit-maintain h2 {
  margin-bottom: 35px;
}

/* body {
  background: url(/assets/images/bg1.jpg) center top no-repeat;
} */
/* Responsive css Start */
.profile-data {
  padding-top: 115px;
  background: #622bc5;

}

.profile-settings .setting-icn .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
}

.profile-headTitle {
  font-size: 62px;
  color: #4a4a4a;
  padding: 8px 40px 0 0;
}

.profile-wrapper {
  /* font-family: "Roboto"; */
  padding-bottom: 50px;
}

.custom-reports {
  margin-bottom: 40px;
}

.custom-sales {
  max-width: 760px;
  margin: 0 auto;
}

.Sales-page {
  padding: 30px;
  background: #fff;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
}

.cal-fld {
  width: 40%;
}

.rel-art h4 {
  font-size: 28px;
  text-align: left;
  font-weight: 500;
  margin-bottom: 30px;
}

.related-articles {
  margin-bottom: 30px;
  margin-top: 30px;
}

.related-articles h2 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
}

.cal-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.cal-fld span {
  display: block;
  text-align: left;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  color: #6a6a6a;
  font-weight: 600;
}

.cal-fld input {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
}

.profile-account .acc-name {
  display: flex;
  align-items: center;
}

.profile-rating {
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.acc-desc .profile-rating .star-icn {
  height: auto;
}

.acc-desc .profile-rating .star-icn img {
  display: block;
}

/* ###########  seler  profile  start  ################################## */

/* ###################### seler  profile end  ############################################## */

.feature-card {
  width: 32%;
  border: 1px solid #cecece;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.orange {
  background: #f2e7e0;
}

.purple {
  background: #eadcee;
}

.green {
  background: #dfeed9;
}

.feature-card h4 {
  margin-bottom: 15px;
}

.feature-card h6 {
  padding-bottom: 0;
}

.feature-card span {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 15px;
  display: block;
}

.feature-buttton {
  background: cornflowerblue;
}

#article-tbl {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#article-tbl td,
#article-tbl th {
  border: 1px solid #ddd;
  padding: 15px 10px;
  font-size: 15px;
}

#article-tbl tr:nth-child(odd) td {
  background: #fff;
}

#article-tbl tr:nth-child(even) td {
  background: #ede5f0;
}

#article-tbl th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: none;
  color: #4c4c4c;
  border: 0;
  padding: 10px 0;
}

#article-tbl td button {
  color: #f86202;
  font-size: 15px;
  transition: all 0.5s;
}

#article-tbl td button:hover {
  opacity: 0.7;
}

#article-tbl td button i {
  margin-right: 5px;
}

.report-downlaod-btn {
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  position: relative;
  transition: all 0.5s ease;
  padding: 10px 23px;
  background: #f86202;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #f86202;
  transition: all 0.5s ease 0s;
}

.report-downlaod-btn:hover {
  background: transparent;
  color: #f86202;
}

.popCalendar {
  position: relative;
}

.popCalendar i {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #b4b4b4;
  font-size: 20px;
}

.selldetails-wrap div {
  position: relative;
}

.selldetails-wrap span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  height: 20px;
  width: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding-bottom: 1px;
  background: #c0c0c0;
  border-radius: 40px;
  text-align: center;
}

.selldetails-wrap .active-tab span {
  background: #f86203;
}

.profile-div .profile-img {
  height: 108px;
  width: 108px;
  background-size: 100% 100%;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 6px;
}

.profile-div .profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
}

.mob-prof-nav-icn {
  display: none;
}

.mob-prof-nav-icn i,
.profile-div .profile-img i {
  position: absolute;
  bottom: 4px;
  right: -4px;
  font-size: 25px;
  color: #622bc5;
  background: #fff;
  border-radius: 40px;
  border: 2px solid #fff;
  cursor: pointer;
}

.edit-prof {
  background: #eaeaea;
  padding-top: 50px;
}

.profile-main-div {
  display: flex;
  justify-content: space-between;
}

.prof-nav {
  background: #fff;
  padding: 0 20px;
  width: 20%;
  margin-right: 2%;
  padding-bottom: 15px;
  border-radius: 11px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
}

@media all and (min-width: 1700px) {
  .prof-nav {
    width: 18%;
  }
}

.right-prof-sec {
  background: #fff;
  width: 78%;
  padding: 30px 0;
  border-radius: 11px;
  box-shadow: 0 4px 10px 6px rgba(0, 0, 0, 0.03);
}

@media all and (min-width: 1700px) {
  .right-prof-sec {
    width: 80%;
  }
}

.right-prof-sec .container {
  padding: 0 2%;
  height: 100%;
}

.profile-left-panel .profile-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  padding-top: 20px;
}

.user-detail-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.left-item-list .active-tab {
  color: #F5C139;
  opacity: 1;
  position: relative;
}

.left-item-list .active-tab ::after {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #F5C139;
  content: "";
  left: 0;
}

.left-item-list>div,
.left-item-list>a {
  text-transform: capitalize;
  padding: 10px 0;
  transition: all 0.3s ease-in-out;
  margin-bottom: 8px;
}

@media only screen and (max-width: 1699px) {

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 36px;
  }

  .profile-account .address-txt {
    font-size: 17px;
  }

  .profile-calendar .calendar-title {
    font-size: 22px;
  }

  .invite-profile .invite-desc {
    font-size: 17px;
  }

  .invite-list li {
    width: 12%;
  }

  .detail-from input {
    font-size: 18px;
    height: 53px;
  }

  .detail-from .form-input {
    margin-bottom: 24px;
  }

  .detail-from .form-title {
    font-size: 17px;
    margin-bottom: 30px;
  }

  .detail-from button.search-btn {
    font-size: 19px;
    height: 60px;
  }

  .wardrobe-card {
    min-height: 450px;
  }

  .wardrobe-card .add-icn {
    width: 50px;
    height: 50px;
  }

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 36px;
  }

  .profile-account .address-txt {
    font-size: 17px;
  }

  .profile-calendar .calendar-title {
    font-size: 22px;
  }

  .invite-profile .invite-desc {
    font-size: 17px;
  }

  .invite-list li {
    width: 12%;
  }

  .addmore-card .add-icn {
    width: 100px;
    height: 100px;
  }

  .addmore-card .add-txt {
    font-size: 32px;
  }

  .profile-settings .setting-icn {
    width: 26px;
    position: relative;
  }

  .profile-settings .setting-icn:hover .tooltiptext {
    visibility: visible;
  }

  .profile-settings .setting-icn .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  .profile-settings .setting-icn .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 110%;
  }

  .profile-settings .notification-icn {
    width: 24px;
  }

  .profile-wrapper .select-opt__control {
    height: 50px;
    padding-left: 10px;
    font-size: 19px;
  }

  .profile-account-wrap .profile-account .profile-img {
    width: 250px;
    height: 250px;
  }

  .profile-account-wrap .follow-user {
    padding: 0;
  }
}

@media only screen and (max-width: 1499px) {
  .profile-div .profile-img {
    height: 100px;
    width: 100px;
  }

  .profile-wrapper .profile-name {
    font-size: 20px;
  }

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 32px;
  }

  .invite-profile .invite-desc {
    font-size: 16px;
  }

  .invite-list figure:after {
    width: 20px;
    height: 4px;
  }

  .invite-profile {
    padding: 40px 20px 70px;
  }

  .invite-list button {
    font-size: 13px;
  }

  .detail-from input {
    font-size: 16px;
    height: 45px;
    border-width: 1px;
  }

  .group-wrap.edit-profile .form-input select {
    font-size: 16px;
    height: 45px;
    border-width: 1px;
  }

  .detail-from button.search-btn {
    font-size: 17px;
    height: 52px;
  }

  .wardrobe-card {
    min-height: 350px;
  }

  .profile-details .wardrobe-wrap .detail-from {
    max-width: 400px;
  }

  .wardrobe-card .add-icn {
    width: 45px;
    height: 45px;
  }

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 32px;
  }

  .invite-profile .invite-desc {
    font-size: 16px;
  }

  .invite-list figure:after {
    width: 20px;
    height: 4px;
  }

  .invite-profile {
    padding: 40px 20px 70px;
  }

  .invite-list button {
    font-size: 13px;
  }

  .profile-account .acc-info {
    width: 65%;
  }

  .profile-settings .setting-icn {
    width: 24px;
    position: relative;
  }

  .profile-settings .setting-icn:hover .tooltiptext {
    visibility: visible;
  }

  .profile-settings .setting-icn .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  .profile-settings .setting-icn .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 110%;
  }

  .profile-settings .notification-icn {
    width: 22px;
  }

  .profile-account .account-desc.acc-desc {
    margin-bottom: 40px;
  }

  .addmore-card .add-icn {
    width: 80px;
    height: 80px;
  }

  .addmore-card .add-txt {
    font-size: 28px;
  }

  /* .profile-rating .rate {
    font-size: 18px;
  } */
  .profile-rating .star-icn {
    width: 22px;
    height: 22px;
  }

  .profile-wrapper .select-opt__control {
    height: 50px;
    font-size: 17px;
  }

  .profile-account-wrap .profile-account {
    padding: 30px 20px;
  }

  .profile-account-wrap .profile-account .profile-img {
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
  }

  .profile-account-wrap .profile-account .acc-name {
    font-size: 30px;
  }

  .profile-account-wrap .profile-account .verify-btn {
    margin-bottom: 25px;
  }

  .profile-account-wrap .profile-account .account-desc {
    margin-bottom: 30px;
  }

  .profile-account-wrap .follow-user .verify-btn {
    padding: 15px 20px;
  }

  .profile-data {
    padding-top: 50px;
  }

  .custom-reports h5 {
    font-size: 22px;
  }

  .custom-reports span {
    font-size: 15px;
    line-height: 20px;
  }

  .related-articles h2 {
    font-size: 22px;
  }

  /* Changes */
  /* .prof-nav{
    width: 19%;
  }
  .right-prof-sec{
    width: 76%;
  } */
}

@media only screen and (max-width: 1199px) {
  .order-bottom-detail {
    flex-direction: column;
  }

  .detail-from input {
    font-size: 16px;
  }

  .wardrobe-card .add-icn {
    width: 40px;
    height: 40px;
  }

  .wardrobe-wrap .pause-btn {
    min-width: 300px;
  }

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 28px;
  }

  .profile-account .total-txt {
    font-size: 15px;
  }

  .profile-account .address-txt {
    font-size: 16px;
  }

  .profile-calendar .calendar-title {
    font-size: 20px;
  }

  .invite-list button {
    padding: 8px 15px;
  }

  .invite-profile {
    padding: 40px 20px 50px;
    margin-bottom: 50px;
  }

  .profile-account .profile-img {
    width: 175px;
    height: 175px;
    padding: 15px;
  }

  .addmore-card .add-icn {
    width: 60px;
    height: 60px;
  }

  .addmore-card .add-txt {
    font-size: 24px;
  }

  .profile-wrapper .select-opt__control {
    font-size: 16px;
  }

  .profile-account-wrap .profile-account .profile-img {
    width: 180px;
    height: 180px;
  }

  .manage-profile .plp-header,
  .manage-profile .container {
    padding: 0 14px;
  }

  .plp-costume-box {
    padding: 0 5px;
  }

  .manage-profile .plp-costume-box {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .action-item button {
    width: 48%;
    margin-right: 7px;
  }

  .ac-head-wrap {
    flex-direction: column;
  }

  .profile-wrapper .plp-header {
    padding-top: 30px;
  }

  .profile-wrapper .profile-name {
    font-size: 16px;
  }

  .profile-details .wardrobe-wrap .detail-from {
    max-width: 340px;
  }

  .wardrobe-card .add-icn {
    width: 35px;
    height: 35px;
  }

  .wardrobe-wrap .pause-btn {
    min-width: 240px;
  }

  .invite-list li {
    width: 25%;
  }

  .invite-list figure:after {
    right: -22%;
  }

  .profile-account .total-amount,
  .invite-profile .invite-title,
  .profile-account .acc-name {
    font-size: 21px;
  }

  .invite-profile .invite-desc,
  .profile-account .total-txt {
    font-size: 14px;
  }

  .profile-calendar .calendar-title {
    font-size: 18px;
  }

  .profile-wrapper .article-head {
    padding-top: 30px;
  }

  .profile-account {
    margin-bottom: 40px;
  }

  .profile-wrapper .tags {
    display: flex;
  }

  .profile-account .profile-img {
    width: 85px;
    height: 85px;
    padding: 6px;
    margin-top: -103px;
  }

  .profile-account .account-desc.acc-desc {
    margin-bottom: 20px;
  }

  .addmore-card .add-icn {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .addmore-card .add-txt {
    font-size: 22px;
  }

  .profile-account-wrap,
  .seller-prod-tags {
    width: 100%;
  }

  .profile-data {
    padding-top: 75px;
  }

  .profile-account .total-txt {
    font-size: 12.5px;
  }

  .profile-account .info-wrap {
    width: 73.3%;
    padding-top: 5px;
  }

  .manage-profile .tags-wrap {
    padding: 0 20px;
    margin-bottom: 40px;
  }

  .manage-profile .plp-costume-box {
    margin-bottom: 45px !important;
  }

  .profile-headTitle {
    font-size: 40px;
  }

  /* Changes */
  .prof-nav {
    width: 26%;
  }

  .right-prof-sec {
    width: 72%;
  }
}

@media only screen and (max-width: 767px) {
  .timline {
    flex-direction: column;
  }

  .timline li {
    width: auto;
    height: 75px;
  }

  .timline li::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 75px;
    left: -26px;
    right: auto;
    top: 43px;
  }

  .timeline-text {
    bottom: auto;
    left: 31px;
    top: 3px;
  }

  .features-wrapper {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .action-item h4,
  .ac-statics h4,
  .perf-statics h4 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .sort-drop {
    width: 100%;
  }

  .action-item button {
    width: 100%;
    margin-bottom: 10px;
  }

  .ac-head-wrap h4 span {
    display: block;
    margin-top: 10px;
  }

  .ac-head-wrap {
    flex-direction: column;
  }

  .prof-nav {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .right-prof-sec {
    width: 100%;
    padding: 20px 0;
  }

  .profile-main-div {
    flex-direction: column;
  }

  .profile-details .detail-row {
    display: block;
  }

  .profile-details .detail-colmn {
    width: 100%;
  }

  .detail-from input {
    font-size: 15px;
    height: 42px;
    font-family: 'Tw Cen MT Std';
  }

  .group-wrap.edit-profile .form-input select {
    font-size: 15px;
    height: 42px;
  }


  .signup-form .detail-from button.shop-now {
    padding: 7px 25px;
  }

  .detail-from .group-wrap .form-input {
    width: 100%;
  }

  .detail-from .form-input {
    margin-bottom: 23px;
  }

  .detail-from button.search-btn {
    font-size: 15px;
    height: 42px;
  }

  .profile-details .detail-from {
    padding: 40px 0 50px;
  }

  .profile-details.contact-details {
    padding: 40px 0 60px;
  }

  .contact-details .btn-wrap {
    text-align: center;
    padding: 40px 0 20px;
  }

  .wardrobe-wrap {
    display: block;
    text-align: center;
  }

  .profile-details .wardrobe-wrap .detail-from {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .profile-account {
    display: block;
    padding: 15px 15px 0px;
  }

  .profile-calendar {
    display: block;
  }

  .profile-account .account-desc {
    width: 50%;
    margin-bottom: 25px;
  }

  .profile-account .address-txt,
  .profile-account .acc-name {
    text-align: center;
  }

  .profile-account .total-txt {
    margin-bottom: 10px;
  }

  .invite-list li {
    width: 50%;
    margin: 0;
    padding: 15px 30px;
  }

  .invite-list figure:after {
    display: none;
  }

  .invite-form {
    display: block;
  }

  .invite-form .search-field {
    width: 100%;
    margin-right: 0;
  }

  .invite-list {
    margin-bottom: 25px;
  }

  .profile-calendar .landing-calendar,
  .profile-calendar .rental-calendar {
    width: 100%;
  }

  .profile-account .profile-img {
    /* margin: -107px auto 20px; */
    width: 137px;
    height: 137px;
    padding: 17px;
  }

  .profile-account .acc-info {
    width: 100%;
  }

  .profile-account .account-desc.acc-desc {
    text-align: center;
  }

  .profile-wrapper .addmore-card,
  .profile-wrapper .plp-costume-box {
    width: 48%;
  }

  .profile-account .info-wrap {
    flex-flow: wrap;
  }

  .profile-wrapper .blogtags-sec .tags {
    display: block;
  }

  .profile-wrapper .blogtags-sec {
    padding: 0;
  }

  .profile-wrapper .tags-wrap {
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .profile-wrapper .plp-header {
    display: flex;
    padding: 0 15px 0 15px;
    margin: 0;
    flex-wrap: wrap;
  }

  .profile-wrapper .select-opt__control {
    font-size: 15px;
    height: 42px;
  }

  .profile-data {
    padding-top: 110px;
  }

  .profile-edithead {
    font-size: 36px;
  }

  .feature-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .feature-card-row {
    flex-direction: column;
  }

  .right-prof-sec .blogtags-sec>.container {
    padding: 0;
  }

  .mob-prof-nav-icn {
    display: inline-block;
    margin-bottom: 10px;
  }

  .mob-prof-nav-icn i {
    position: static;
  }

  .prof-nav.hidden {
    display: none;
  }

  .prof-nav.show {
    display: block;
  }
}

@media only screen and (max-width: 1023px) {
  .chat-fld-wrap .message {
    width: 100%;
    margin: 0;
    max-height: none;
  }

  .item-img {
    margin: 0 auto 20px;
    width: 200px;
  }

  .chat-fld-wrap .contacts-chat {
    width: 100%;
    margin: 30px 0;
    max-height: none;
  }

  .chat-fld-wrap .full-item-detail {
    width: 100%;
    max-height: none;
    margin: 0;
  }

  .btns-download {
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .btns-download~.shop-now.msg-btn {
    margin-left: 0;
  }

  .ret-ref .switch-wrapper.action-btn .header-sell {
    width: 100%;
  }

  .switch-wrapper.action-btn {
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .switch-wrapper.action-btn .header-sell {
    margin-right: 0;
    width: auto;
  }

  .shop-now.msg-btn {
    margin: 10px auto 0;
  }

  .main-reasons {
    margin-top: 0;
  }

  .timeline-timings {
    bottom: auto;
    left: 32px;
    top: 19px;
    white-space: nowrap;
  }

  .order-left {
    width: 100%;
    margin-bottom: 20px;
  }

  .order-top {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .order-bottom-right {
    width: 100%;
  }

  .order-bottom-left {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .order-prod-detail {
    padding: 0;
  }

  .features-wrapper .dual-features {
    width: 100%;
  }

  .quantity-price {
    width: 100%;
  }

  .features-wrapper {
    flex-direction: column;
  }

  .features-wrapper .dual-features .color-quant {
    padding-right: 0;
  }

  .timline {
    flex-direction: column;
    padding-bottom: 0;
  }

  .timline li {
    width: auto;
    height: 75px;
  }

  .timline li::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 75px;
    left: -26px;
    right: auto;
    top: 43px;
  }

  .timeline-text {
    bottom: auto;
    left: 31px;
    top: 3px;
  }

  .down-btn {
    margin-top: 10px;
  }

  .order-timeline .products-details li {
    display: flex;
    width: 100%;
  }

  .order-timeline .products-details li span {
    margin-right: 10px;
  }

  .profile-wrapper .tags-wrap .container .tags {
    width: auto;
    margin: 0 -15px;
    justify-content: space-between;
  }

  .prod-toggle {
    width: 100%;
    margin: 0 auto 23px;
  }

  .buy-prod {
    display: block;
  }

  .prod-detail.product-orderinfo .prod-link {
    padding: 0;
    display: block;
    text-align: center;
  }

  .prod-title.prod-toggle {
    text-align: center;
  }

  .rating-msg {
    justify-content: center;
    padding: 0;
  }

  .rating-msg .shop-now.msg-btn {
    margin: 0;
  }

  .prod-title.prod-toggle .del-details .status-details {
    justify-content: center !important;
  }

  .prod-detail.product-orderinfo .prod-link .product-switch {
    padding: 0;
  }

  .prod-detail.product-orderinfo .prod-link>div {
    padding: 0;
  }

  .prod-detail.product-orderinfo .prod-link .home-detail {
    justify-content: center;
    padding: 0;
  }

  .prod-detail.product-orderinfo {
    width: 100%;
    padding-left: 0;
  }

  .est-delivery {
    bottom: 7px;
    left: 0;
    text-align: center;
    right: 0;
  }

  .blogtags-sec .prod-img,
  .prod-img a {
    margin-left: auto;
    margin-right: auto;
  }

  .est-deliveries {
    position: static;
  }

  .buy-prod {
    position: relative;
  }

  .feature-card-row {
    flex-direction: column;
  }

  .feature-card {
    width: 100%;
    margin-bottom: 10px;
  }

  .table-values {
    flex-direction: column;
    align-items: center;
  }

  .table-values .name-value {
    width: 100%;
    text-align: center;
  }

  .table-values .image-value {
    margin-bottom: 12px;
  }

  .table-values .redeem-value {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }

  .table-values .percent-value {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }

  .table-values .valid-value {
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .table-values .valid-value .text {
    margin: 0 5px;
  }

  .table-values .valid-value .d-none {
    width: 100%;
    margin: 0 0 3px 0;
  }

  .table-values .btn-value {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .table-values .btn-value .create-btn {
    width: auto;
    min-width: 90px;
  }

  .table-values .d-none {
    font-weight: 500;
    margin-right: 7px;
  }
}

@media only screen and (max-width: 575px) {
  .invite-list li {
    width: 100%;
  }

  .profile-wrapper .addmore-card,
  .profile-wrapper .plp-costume-box {
    width: 100%;
  }

  .manage-profile .container {
    padding: 0 15px;
  }

  .profile-headTitle {
    font-size: 35px;
    padding: 0 30px 0 0;
  }

  .profile-account .profile-img {
    width: 91px;
    height: 91px;
    padding: 5px;
  }

  .profile-account .account-desc.acc-desc {
    width: 100%;
    text-align: left;
  }

  .profile-account .info-wrap {
    width: 100%;
  }

  .profile-edithead {
    font-size: 30px;
    width: 45%;
  }

  .edit-breadcrumb {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}

.detail-from .flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-item-list>div,
.left-item-list>a {
  /* cursor: pointer; */
}

.left-item-list {
  display: flex;
  flex-direction: column;
}

.notification-card .notification-img {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 9px;
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.notification-card .msg {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2em;
  line-height: 1.5;
  font-family: "Roboto";
}

.notification-card .title {
  display: inline-block;
  padding: 5px 10px;
  background: rgba(193, 193, 193, 0.45);
  border-radius: 7px;
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto";
  letter-spacing: 0.5px;
  margin-top: 13px;
}

.prod-name-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.reason-checkbox-list label {
  text-align: left;
  font-size: 18px;
  font-family: "Roboto";
  padding-left: 30px;
  display: block;
  position: relative;
}

.reason-checkbox-list label input {
  position: absolute;
  top: 7px;
  left: 0;
}

textarea {
  width: 100%;
  border: 1px solid #e5e5e5;
}

.loader-style {
  position: inherit;
  top: 200px;
}


.vibration {
  -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
          animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both infinite;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.red {
  color: red;
}

.zip-input {
  border: none;
  font-size: 18px;
  color: rgb(76, 76, 76);
}

.zip-input:focus {
  outline: none;
}

.slider-input {
  width: 100%;
}

.distance p {
  text-align: right;
  color: #733381;
  font-size: 17px;
  margin-top: 6px;
}

.slider-input.slider::-webkit-slider-thumb {
  border-radius: 50%;
  background: #733381;
  box-shadow: 0 4px 10px 6px rgb(0, 0, 0, 0.07);
}

.slider-input.slider::-moz-range-thum {
  background: #733381;
  border-radius: 50%;
  box-shadow: 0 4px 10px 6px rgb(0, 0, 0, 0.03);
}

.slider-input.slider {
  height: 3px;
  -webkit-appearance: none;
  transition: opacity .2s;
  background: #733381;
  opacity: 0.8;
}

.range-marks {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 4px;
}

.range-marks li {
  width: 2px;
  height: 9px;
  display: block;
  background: rgba(128, 128, 128, 0.18);
}

.forget-block {
  flex-grow: 1;
}

.right-prof-sec.right-prof-sec.profile-right-content .detail-from .form-title {
  margin-bottom: 15px;
  font-family: 'Inter';
}

.right-prof-sec.right-prof-sec.profile-right-content .form-input span {
  font-family: 'Inter';
}

.detail-from input {
  border-radius: 6px;
}

.group-wrap.edit-profile.change-passwrd-my-profile .form-wrapp {
  position: relative;
}

.group-wrap.edit-profile.change-passwrd-my-profile i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.header-sell.change-passwrd-sell {
  text-transform: capitalize;
}
.modal-dialog{
    padding: 14px 21px 22px;
    width: 41% !important;
    height: 400px !important;
}

.carrierList {
    background-color: lightgray;
    margin-top: 10px;
    border-radius: 5px;
    padding: 5px;
}
.privacy-policy {
    padding: 35px 0 180px;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
}

.privacy-wrapper {
    display: flex;
    justify-content: space-between;
}

.privacy-menu {
    width: 25%;
}

.privacy-details {
    width: 70%;
}

.privacy-menu .breadcrumb-wrap {
    padding: 20px 0 0;
    border: 0;
    margin-bottom: 60px;
}

.privacy-menu .breadcrumb-wrap li,
.privacy-menu .breadcrumb-wrap span {
    font-size: 21px;
}

.privacy-menu .breadcrumb-wrap span {
    color: #B4B4B4;
}

.privacy-menu .list-wrap span {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 600;
    color: #4A4A4A;
    display: block;
    padding: 32px 0;
    border-bottom: 1px solid #999999;
    cursor: pointer;

}

.privacy-menu .list-wrap span.activePage {
    color: #D16D38;
}

.privacy-menu .list-wrap span:hover {
    color: #D16D38;
}

.privacy-details h2 {
    font-size: 64px;
    font-family: 'Ogg';
    color: #D16D38;
    font-weight: normal;
    margin-bottom: 30px;
}

.privacy-details .desc {
    margin-bottom: 30px;
}

.privacy-details p {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #4A4A4A;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.privacy-details p strong {
    font-weight: 600;
}

/* Responsive Start */
@media only screen and (max-width: 1699px) {

    .privacy-menu .breadcrumb-wrap li,
    .privacy-menu .breadcrumb-wrap span {
        font-size: 18px;
    }

    .privacy-details h2 {
        font-size: 48px;
    }

    .privacy-menu .list-wrap span {
        font-size: 20px;
        padding: 25px 0;
    }

    .privacy-details p {
        font-size: 17px;
    }

}

@media only screen and (max-width: 1499px) {

    .privacy-menu .breadcrumb-wrap li,
    .privacy-menu .breadcrumb-wrap span {
        font-size: 16px;
    }

    .privacy-details h2 {
        font-size: 32px;
    }

    .privacy-menu .list-wrap span {
        font-size: 17px;
        padding: 20px 0;
    }

    .privacy-details p {
        font-size: 16px;
    }

}

@media only screen and (max-width: 1199px) {

    .privacy-menu .breadcrumb-wrap li,
    .privacy-menu .breadcrumb-wrap span {
        font-size: 14px;
    }

    .privacy-details h2 {
        font-size: 28px;
    }

    .privacy-menu .list-wrap span {
        font-size: 16px;
        padding: 20px 0;
    }

    .privacy-details p {
        font-size: 15px;
    }

    .privacy-menu {
        width: 30%;
    }

    .privacy-details {
        width: 67%;
    }

    .privacy-policy {
        padding: 35px 0 70px;
    }

}

@media only screen and (max-width: 991px) {
    .privacy-menu {
        width: 37%;
    }

    .privacy-details {
        width: 60%;
    }

    .privacy-details p {
        font-size: 16px;
    }

    .privacy-details h2 {
        font-size: 26px;
    }

}

@media only screen and (max-width: 767px) {
    .privacy-details p {
        font-size: 13px;
    }

    .privacy-wrapper {
        display: block;
    }

    .privacy-menu,
    .privacy-details {
        width: 100%;
    }

    .privacy-menu .breadcrumb-wrap {
        padding: 0;
        margin-bottom: 20px;
    }

    .privacy-menu .list-wrap {
        margin-bottom: 30px;
    }

    .privacy-details .desc,
    .privacy-details h2 {
        margin-bottom: 15px;
    }
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
img{
    max-width: 100%;
    height: auto;
}
.coupon-wrapper{
    max-width: 90%;
    margin: 0 auto;
    padding: 60px 15px;
}
.coupon-tab{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 40px;
}
.coupon-tab li{
    margin-right:18px;
    color: #4a4a4a;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.16);
    background: #fff;
    font-weight: 700;
    font-size: 16px;
    padding: 18px 25px;
    border-radius: 26px;
    line-height: 1.3;
    text-align: center;
    cursor: pointer;
}
.coupon-tab li:hover{
    background-color:rgba(209,109,56,0.8);
    color:#fff;
}
.coupon-tab li.active{
    background-color:rgba(209,109,56,0.8);
    color:#fff;
}
.coupon-tab li:last-child{
    margin: 0;
}
.coupon-tabcontent{
    padding: 20px;
}
.coupon-infobtn{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom:40px;
    border-bottom: 1px solid #cfcfcf;
}
.coupon-infobtn li{
    width: 30%;
}
.coupon-infobtn label{
    display: block;
    margin-bottom: 12px;
    font-size: 17px;
    color: #4a4a4a;
    font-weight: 600;
}
.coupon-infobtn input{
    width: 100%;
    border-radius: 8px;
    padding: 15px;
    border: 1px solid #cfcfcf;
}
.input-wrap{
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}
.coupon-infobtn .input-wrap input{
    padding-right: 50px;
}
.discount-label{
    position: absolute;
    top:0;
    right: 0;
    color:#fff;
    font-size: 16px;
    height: 100%;
    width:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(209,109,56,1);
    pointer-events: none;
}
.coupon-products{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cfcfcf;
}

.coupon-products li{
    color: #4a4a4a;
    font-size: 16px;
    font-weight: 600;
    padding: 20px 0;
}
.coupon-products .product-image{
    width: 150px;
    max-height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.coupon-products .product-name{
    width: 60%;
}
.coupon-products .product-price{
    width: 15%;
}
.coupon-products .product-image img{
    width:75px;
    height: 75px;
    object-fit:contain;
}
.coupon-products .product-checkmark{
    width:50px;
}
.coupon-btn{
    padding: 10px 25px;
    border-radius: 8px;
    background-color:rgba(209,109,56,1);
    color:#fff;
    font-weight: 700;
    max-width: 195px;
    margin: 30px 0 0 auto;
    display: block;
    font-size: 17px;
    border:1px solid transparent;
    transition: all 0.5s ease;
}
.coupon-btn:hover{
    background-color:#fff;
    color:#4a4a4a;
    border:1px solid rgba(209,109,56,0.7);
}
.checkbox-wrap{
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size:16px;
    -webkit-user-select: none;
    user-select: none;
}
.checkbox-wrap input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: -8px;
    left: 0;
    height:18px;
    width: 18px;
    border: 1px solid #cfcfcf;
  }
  
  .checkbox-wrap input:checked ~ .checkmark {
    background-color:#d16d38;
    border: 1px solid transparent;
  }  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .checkbox-wrap .checkmark:after {
    left: 5px;
    top: 0px;
    width: 7px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
  .checkbox-wrap input:checked ~ .checkmark:after {
    display: block;
  }
  .coupon-listcontent{
      display: block;
  }
  .product-searchwrap{
      display: flex;
      justify-content: flex-end;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;
}
  .global-search{
        position: relative;
  }
  .global-search input{
        border: 1px solid #cfcfcf;
        border-radius: 4px;
        width: 100%;
        padding: 12px 18px;
        font-size: 15px;
        color:#4a4a4a;
  }
  .global-search .icon-search{
      position: absolute;
      right: 10px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      background-size: 450px 350px;
      width: 14px;
      height: 14px;
      background-position: -104px -7px;
  }
  .remove-product{
      font-size:30px;
      color:#4a4a4a;
      cursor: pointer;
      transition: all 0.5s ease;
  }
  .remove-product:hover{
    color: #d16d38;
  }
    .coupons-wrapper{
        max-width: 100%;
    }
    .coupons-wrapper .checkmark{
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
    
  /* responsive */
  @media(max-width:1199px){
    .coupon-wrapper{
        max-width: 100%;
    }
  }
  @media(max-width:991px){
    
    .coupon-heading .product-description,.coupon-products .product-name{
        width: 55%;
    }
    .coupon-heading li{
        font-size: 15px;
    }
    .couponlist-heading .product-description{
        width: 16%;
    }
    .coupon-listing li{
        font-size: 15px;
    }
    .coupon-listing .product-name{
        width: 16%;
        margin-bottom: 0;
    }
  }
  @media(max-width:767px){
    .coupon-wrapper{
        max-width: 100%;
        padding: 40px 15px;
    }
    .coupon-tabcontent {
        padding: 15px 0;
    }
    .coupon-listing li{
        font-size: 13px;
    }
    
    .coupon-heading .product-description {
        width:40%;
    }
    .coupon-products .product-name {
        width: 40%;
        margin: 0;
    }
    .couponlist-heading .product-description{
        width:24%;
    }
    .coupon-listing .product-name {
        width:95px;
    }
    .coupon-heading li{
        font-size: 13px;
    }
    .couponlist-heading li{
        font-size: 12px;
    }
    .couponlist-heading .product-image{
        width: 95px;
    }
    .couponlist-heading .product-description{
        width: 95px;
        line-height: 1.2;
    }
    .product-price,.product-expiry{
        line-height: 1.4;
    }
    .coupon-listing .product-image{
        width: 95px;
    }
    
  }
  @media(max-width:475px){
    .coupon-tab li{
        padding: 10px;
        font-size: 14px;
    }
    .coupon-tabcontent{
        padding: 0;
    }
    .coupon-infobtn label{
        font-size: 14px;
    }
    .coupon-tab{
        justify-content: space-between;
    }
    .coupon-infobtn{
        flex-wrap: wrap;
    }
    .coupon-infobtn li{
        width: 48%;
        margin-bottom: 15px;
    }
    .coupon-infobtn li:last-child{
        margin-bottom: 0;
    }
    .coupon-infobtn input{
        padding: 10px;
    }
    .coupon-products li{
        font-size: 15px;
    }
    .checkbox-wrap{
        font-size: 15px;
    }
    .coupon-listing{
        flex-wrap: wrap;
        position: relative;
    }
    .coupon-listing li{
        width: 100%;
    }
    .coupon-listing .product-image{
        max-width: none;
    }
    
    
  }
.modal-dialog{
    padding: 14px 21px 22px;
    width: 41% !important;
    height: 400px !important;
}

.carrierList {
    background-color: lightgray;
    margin-top: 10px;
    border-radius: 5px;
    padding: 5px;
}
.menu-item-container {
  display: flex;
  padding: 50px 20px 0;
  max-width: 985px;
  margin: 0 auto;
}

.review-btn,
.next-btn {
  min-width: 255px;
  height: 55px;
  border: 1px solid #f86203;
  background: #f86203;
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  text-transform: capitalize;
  font-family: "Roboto";
}

.review-btn:hover,
.next-btn:hover {
  background: none;
  color: #d16d38;
  border-color: #d16d38;
}

.navigation-panel {
  width: 32%;
  margin-right: 3%;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.navigation-screen {
  width: 68%;
  flex-grow: 1;
  padding: 25px 15px;
  background: #fff;
  border-radius: 7px;
  /* box-shadow: 0 4px 10px 6px rgba(0, 0, 0, 0.03); */
  box-shadow: 0px 4px 5px 5px rgb(0 0 0 / 6%);
  margin-bottom: 37px;
}

.navigation-container {
  display: flex;
  justify-content: space-between;
}

.navigation-continue {
  display: flex;
  justify-content: flex-end;
}

.completed-tab h5 {
  color: pink;
}

.pending-tab {
  color: black;
}

.input-url {
  height: 37px;
  font-size: 16px;
  padding: 0 15px;
  font-family: "Roboto";
  color: rgb(76, 76, 76);
}

.input-url::-webkit-input-placeholder {
  color: rgb(76, 76, 76);
  font-family: "Roboto";
}

.input-url::placeholder {
  color: rgb(76, 76, 76);
  font-family: "Roboto";
}

.pac-target-input {
  color: rgb(76, 76, 76);
  font-family: "Roboto";
}

.pac-target-input::-webkit-input-placeholder {
  color: rgb(76, 76, 76);
  font-family: "Roboto";
}

.pac-target-input::placeholder {
  color: rgb(76, 76, 76);
  font-family: "Roboto";
}

.uploading-file-wrap {
  position: relative;
  margin-bottom: 10px;
  margin-top: 30px;
  flex: 1 1;
  display: flex;
  cursor: pointer;
}

.uploading-file-wrap button {
  position: absolute;
  bottom: 0;
  left: 10px;
  cursor: default !important;
}

.uploading-file-wrap button.remove-img_btn {
  right: 10px;
  left: auto;
}

.uploading-file-wrap button.remove-img_btn .change-reset-file {
  margin-right: 0;
}

.custom-file-upload {
  border: 1px dotted #adadad;
  padding: 24px 16px 7px;
  /* margin: 10px; */
  position: relative;
  display: block;
  /* text-align: center; */
  border-radius: 20px;
  /* width: 240px; */
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.custom-file-render {
  border: 1px dotted #adadad;
  padding: 6px 12px;
  margin: 10px;
  position: relative;
  display: block;
}

.rend-box {
  width: 250px;
  height: 300px;
  display: flex;
}

.rend-box h6 {
  text-transform: capitalize;
  text-align: center;
  color: #989792;
}

.tooltip-text {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 10px;
  white-space: nowrap;
  color: #fff;
  background-color: #4a4a4a;
  padding: 3px 7px;
  border-radius: 15px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.custom-file-upload:hover .tooltip-text {
  display: block;
}

.change-reset-file {
  margin-top: 20px;
  width: 100px;
  height: 50px;
  background-color: #d16d38;
  margin-right: 30px;
  cursor: pointer;
  padding: 10px 25px;
  color: #fff;
}

.article-head .change-reset-file span {
  color: #fff;
}

.add-more-file {
  background-color: #d16d38;
  padding: 10px;
  color: #fff;
  height: 39px;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

.display-image {
  width: 250px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  object-fit: cover;
}

.image-container {
  margin-top: 20px;
}

.input-description {
  resize: none;
  width: 100%;
  height: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.country-code-mobile-no {
  display: flex;
}

.country-code-box {
  width: 45%;
}

.mobile-number-box {
  width: 45%;
}

.ship-btn {
  width: 80px;
  height: 30px;
  border: 1px solid;
  cursor: pointer;
  transition-duration: 0.4s;
}

/* .active:hover{
    background-color: white;
} */
/* .inactive:hover{
    background-color: grey;
} */
/* .active {
  background-color: grey;
} */

.inactive {
  background-color: white;
}

.slider {
  -webkit-appearance: none;
  width: 50%;
  height: 7px;
  background: #dca9a9;
  opacity: 0.7;
}

.slider:hover {
  opacity: 1;
}

.slider::-moz-range-thumb {
  width: 45px;
  height: 15px;
  background: #dca9a9;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #dca9a9;
  cursor: pointer;
}

/* Discount Banner start */
.discount-banner {
  padding: 50px 0;
  background: #f7d9b7;
}

.discount-banner .discount {
  width: 330px;
  height: 330px;
  background: #f9e8dd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 7%;
}

.discount .discount-title {
  font-size: 55px;
  line-height: 1;
  color: #673e37;
  font-family: "Ogg";
  font-weight: normal;
  margin-bottom: 30px;
}

.discount .discount-desc {
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #673e37;
  line-height: 1.5;
}

.discount-banner .discount-wrap {
  display: flex;
  align-items: center;
}

.discount-banner .info {
  width: 48%;
  text-align: center;
}

.discount-banner .info-title {
  color: #d2703c;
  font-family: "Ogg";
  font-size: 64px;
  line-height: 1.3;
  font-weight: normal;
  margin-bottom: 30px;
}

.discount-banner .info-desc {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 500;
  color: #d16d38;
  font-family: "Poppins", sans-serif;
}

/* Discount Banner end */
.selldetails-wrap {
  padding: 32px 15px 250px 20px;
}

.navigation-screen h5,
.selldetails-wrap h5 {
  padding: 15px 0;
  color: #adadad;
  font-weight: 500;
  font-size: 22px;
  text-transform: capitalize;
}

.navigation-screen h5 {
  border: 0;
  padding: 0;
  color: #4a4a4a;
  margin-bottom: 23px;
}

.selldetails-wrap h5 {
  position: relative;
  padding: 0 29px 0 29px;
}

.selldetails-wrap .completed-tab h5:after {
  content: url(/assets/images/tick-icn.svg);
  width: 27px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.selldetails-wrap .active-tab h5 {
  color: #4a4a4a;
}

.selldetails-wrap>div:last-child h5 {
  border: 0;
}

.navigation-screen .label-txt {
  font-family: "Roboto";
  color: #4a4a4a;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 14px;
  display: block;
}

/* .slct-optns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
} */

.slct-optns .optns {
  width: calc(50% - 15px);
  margin-right: 15px;
  margin-bottom: 20px;
  position: relative;
}

.slct-optns .input-url {
  border: 1px solid #cdced2;
  border-radius: 4px;
}

.slct-optns .slt-url {
  width: 40%;
}

.slct-optns .input-url {
  width: 100%;
  height: 47px;
  font-size: 22px;
  font-weight: 400;
}

.select-opt__menu {
  margin: 0 !important;
}

.select-opt__menu .select-opt__option {
  padding: 15px;
  font-size: 14px;
  font-weight: 500;
  z-index: 9;
}

/* How it work section start */
.how-it-work .szn-img {
  width: 362px;
  height: 362px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f5f5;
  border-radius: 50%;
  margin: 0 auto;
}

.how-it-work .diaries-block {
  padding: 60px 16% 111px;
}

.how-it-work .btn-diaries {
  display: none;
}

/* How it work section End */
/* Upload image section Start */
.upload-detials label,
.uploadimg-desc {
  font-size: 22px;
  font-family: "Roboto";
  line-height: 1.7;
  color: #4a4a4a;
}

.upload-detials>label {
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

.back-btn {
  color: #767676;
  font-size: 20px;
  font-weight: 500;
  border: 1px solid #767676;
  padding: 10px 45px;
  border-radius: 5px;
  font-family: "Roboto";
}

.back-btn svg {
  width: 15px;
  height: 18px;
  margin-right: 10px;
}

.navigation-container.both-btn {
  padding-top: 70px;
  margin-bottom: 20px;
}

/* Upload image section End */
/* Description section Start */
.description-container .desc-field {
  margin-bottom: 40px;
}

.btns-status {
  margin-bottom: 15px;
  font-family: "Roboto";
}

.btns-status button {
  font-family: "Roboto";
}

.btns-status .ship-btn {
  width: 75px;
  height: 35px;
  border: 1px solid #525252;
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  margin-right: 14px;
  border-radius: 5px;
  line-height: 1.2;
}

.btns-status .ship-btn.active {
  border: 3px solid #f86203;
  background: #f86203;
  color: #fff;
}

.bidding-slider {
  margin-bottom: 500px;
}

.bidding-slider .slider {
  height: 2px;
  background: #707070;
}

/* Description section Start */
/* Seller info sec start */
.seller-infoblog .mob-desc,
.seller-infoblog .slct-optns {
  margin-bottom: 50px;
}

.seller-infoblog .input-url {
  border: 1px solid #959595;
}

/* Seller info sec End */
/* Shipping detail sec start */

/* .shipping-details {
      margin-bottom: 300px;
  } */

/* Shipping detail sec start */
/* Review detail Start */
.review-detials .review-box {
  width: 424px;
  height: 424px;
  background: #f7f5f5;
  margin-bottom: 200px;
}

.review-detials .review-control {
  text-align: right;
  margin-bottom: 70px;
}

.review-control .review-btn {
  padding: 0 40px;
}

.shipping-details .slct-optns .optns {
  position: relative;
}

.sell-item {
  /* background: #eaeaea; */
  font-family: "Roboto";
}

.custom-file-upload .upload-icn span {
  display: block;
  color: #4c4c4c;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 10px;
}

.custom-file-upload .upload-icn span.drag {
  font-weight: 300;
  font-size: 15px;
}

.sell-item-header span {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

/* Responsive CSS Start */
@media only screen and (max-width: 1699px) {

  .navigation-screen h5,
  .selldetails-wrap h5 {
    font-size: 18px;
  }

  .selldetails-wrap h5 {
    font-size: 18px;
  }

  .discount-banner .info-title {
    font-size: 54px;
  }

  .navigation-screen .label-txt,
  .discount-banner .info-desc {
    font-size: 18px;
  }

  .slct-optns .select-opt__control {
    padding: 2px 0 0 15px;
    font-size: 18px;
    height: auto;
  }

  .slct-optns .input-url,
  .navigation-screen .attr-elements .slct-optns .slt-url select {
    height: 42px;
  }

  .slct-optns .input-url,
  .select-opt__menu .select-opt__option {
    font-size: 18px;
  }

  .review-btn,
  .next-btn {
    min-width: 162px;
    height: 54px;
    font-size: 20px;
  }

  .how-it-work .szn-img {
    width: 300px;
    height: 300px;
    padding: 30px;
  }

  .how-it-work .diaries-block {
    padding: 60px 14% 111px;
  }

  .upload-detials label,
  .uploadimg-desc {
    font-size: 18px;
  }

  .back-btn {
    font-size: 20px;
  }

  .back-btn svg {
    width: 9px;
    height: 14px;
  }

  .btns-status .ship-btn {
    width: 75px;
    height: 38px;
    font-size: 18px;
  }

  .review-detials .review-box {
    width: 320px;
    height: 320px;
  }
}

@media only screen and (max-width: 1499px) {
  .how-it-work .diaries-block {
    padding: 60px 10% 111px;
  }

  .how-it-work .diaries-caption p {
    min-height: 60px;
  }

  .review-btn,
  .next-btn,
  .navigation-screen h5,
  .selldetails-wrap h5 {
    font-size: 16px;
  }

  .slct-optns .input-url,
  .select-opt__menu .select-opt__option,
  .slct-optns .select-opt__control,
  .navigation-screen .label-txt,
  .discount-banner .info-desc {
    font-size: 14px;
  }

  .discount-banner .info-title {
    font-size: 44px;
  }

  .selldetails-wrap {
    padding: 32px 15px 250px 20px;
  }

  .how-it-work .szn-img {
    width: 280px;
    height: 280px;
  }

  .upload-detials label,
  .uploadimg-desc {
    font-size: 16px;
  }

  .back-btn {
    font-size: 18px;
  }

  .custom-file-upload .upload-icn {
    width: 70px;
    height: 65px;
    text-align: center;
  }

  .custom-file-upload .upload-icn img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto 15px;
  }

  .btns-status .ship-btn {
    width: 71px;
    height: 36px;
    font-size: 16px;
  }

  .review-detials .review-box {
    width: 280px;
    height: 280px;
  }
}

@media only screen and (max-width: 1199px) {
  .navigation-screen {
    padding: 25px 15px;
  }

  .how-it-work .szn-img {
    width: 200px;
    height: 200px;
  }

  .review-btn,
  .next-btn,
  .navigation-screen h5,
  .selldetails-wrap h5 {
    font-size: 14px;
  }

  .slct-optns .input-url,
  .select-opt__menu .select-opt__option,
  .slct-optns .select-opt__control,
  .navigation-screen .label-txt,
  .discount-banner .info-desc {
    font-size: 14px;
  }

  .discount-banner .info-title {
    font-size: 40px;
  }

  .discount-banner .discount {
    width: 240px;
    height: 240px;
  }

  .discount .discount-title {
    font-size: 40px;
  }

  .discount .discount-desc {
    font-size: 14px;
  }

  .upload-detials label,
  .uploadimg-desc {
    font-size: 14px;
  }

  .back-btn {
    font-size: 18px;
  }

  .btns-status .ship-btn {
    font-size: 16px;
  }

  .review-detials .review-box {
    width: 240px;
    height: 240px;
  }
}

@media only screen and (max-width: 991px) {
  .discount-banner .info-title {
    font-size: 32px;
  }

  .slct-optns .optns {
    width: calc(50% - 15px);
  }

  .slct-optns .slt-url {
    width: 46%;
  }

  .how-it-work .szn-img {
    width: 190px;
    height: 190px;
  }

  .review-detials .review-box {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .btns-status .ship-btn {
    height: 34px;
    font-size: 13px;
    border-radius: 5px;
  }

  .slct-optns {
    margin-bottom: 0;
  }

  .next-btn {
    height: 41px;
    font-size: 15px;
  }

  .how-it-work .btn-diaries {
    display: block;
  }

  .discount-banner .discount-wrap {
    display: block;
  }

  .discount-banner .discount {
    margin: 0 auto 30px;
  }

  .discount-banner .info {
    width: 100%;
  }

  .menu-item-container {
    display: block;
  }

  .navigation-panel {
    width: 100%;
    border: 0;
    margin-bottom: 20px;
  }

  .navigation-panel .selldetails-wrap span {
    height: 18px;
    width: 18px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 1px 0;
  }

  .selldetails-wrap {
    padding: 13px 15px;
  }

  .navigation-screen {
    padding: 25px 15px;
    width: 100%;
  }

  .bidding-slider .slider {
    width: 100%;
  }

  .bidding-slider {
    margin-bottom: 50px;
  }

  .shipping-details {
    margin-bottom: 0;
  }

  .review-detials .review-box {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .slct-optns .optns {
    width: 100%;
    margin: 0 0 20px;
  }

  .slct-optns {
    display: block;
  }

  .mobile-number-box,
  .slct-optns .slt-url {
    width: 100%;
  }

  .review-btn,
  .next-btn {
    min-width: 160px;
  }
}

.condition-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: var(--border-4);
  padding: 13px;
  text-align: center;
  border-radius: 7px;
  border: 1px solid #9a9a9a;
  cursor: pointer;

}

.attr-card-button.selected .desc {
  color: #fff;
}

.condition-card.selected {
  color: #fff;
  border: 1px solid #f86203;
  background-color: #f86203;
}

.attr-card-button {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-radius: var(--border-4);
  padding: var(--space-4) var(--space-2);
  text-align: center;
}

.attr-card-button.selected {
  color: #fff;
  border: 1px solid #5e6df2;
  background-color: #5e6df2;
  border-radius: 7px;
}

.slct-optns .attr-optns {
  width: auto;
  margin-right: 3%;
}

.slct-optns.price-row .input-url {}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.price-brief>span.red {
  color: red;
}

.selldetails-wrap .completed-tab span {
  background: #f86203 !important;
}

.selldetails-wrap .completed-tab h5 {
  color: #7ab661;
}

.price-brief {
  font-family: "Roboto";
}

.price-brief .row {
  margin-bottom: 5px;
}

.slct-optns.price-section {
  align-items: flex-start;
}

.ckEditorError .cke_chrome {
  border: 1px solid red !important;
  border-color: red !important;
}
