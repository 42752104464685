.article-desc {
    padding: 80px 10%;
    /* background: #F3EFF7; */
}
.blog-article-one {
    padding-bottom: 130px;
    border-bottom: 1px solid #CECECE;
}
.article-wrap .container {
    max-width: 1824px;
}
.article-head {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    flex-direction: column-reverse;
}
.article-head figure {
    width:100%;
    /* height: 400px; */
    /* border-radius: 50%; */
    overflow: hidden;
    order: 1;
    margin-bottom:30px;
    text-align: center;
}
.article-head figure img{   
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.article-head-wrap h4{
    margin-bottom: 15px;
}
.article-head span {
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    color:#A292B3;
    font-family: 'Poppins', sans-serif;
    margin: 0 5%;
    order: 2;
    display: contents;
}
.article-detail h2 {
    font-size: 79px;
    color:#A292B3;
    font-family: 'Ogg';
    font-weight: normal;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 60px;
}
.article-detail .desc {
    margin-bottom: 30px;
}
.article-detail p {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.5;
    color:#4A4A4A;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}
.article-slider .slick-arrow {
    width: 70px;
    height: 70px;
    z-index: 2;
    background: #F7F5F5 url(/assets/images/slide-arrow.svg) no-repeat center center;
    background-size: 12px 21px;
}
.article-slider .slick-arrow:before {
    display: none;
}
.article-slider {
    padding: 40px 0 60px;
}
.article-slider .slick-prev {
    left:0;
}
.article-slider .slick-next {
    right:0;
    transform: translateY(-50%) rotate(180deg);
}

/* blog comment  */
.comment-wrap{
    max-width: 750px;
    margin: 0 auto 0 10%;
    padding: 20px;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15);
}
.tabbing-list{
    border-bottom: 1px solid #ccc;
}
.list-item{
    padding: 15px;
    position: relative;
    font-weight: 700;
    font-size: 18px;
    cursor: default;
}
.tabbing-list li.active a:after{
    content: "";
    position: absolute;
    bottom: -1px;
    border-bottom: 2px solid #b30000;
    left: 0;
    height: 1px;
    width: 100%;
}
.share-list{
    padding: 15px;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 250px;
    margin-bottom: 20px;
}
.share-list li{
    margin-right: 50px;
}
.share-list a{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}
.share-list li a .icon-wish {
    background-position: -44px -6px;
    background-size: 660px auto;
}
.share-list li a.recommend.active i{
    background-position: -8px -133px;
    width: 27px;
    height: 28px;
}
.share-list a i{
    margin-right: 10px;
}
.share-list a strong{
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.share-list a span.number{
    display: inline-block;
    padding: 3px 5px;
    background-color: #ccc;
    font-size: 10px;
    color: #000;
    border-radius: 3px;
    font-weight: 500;
}
.share-icon{
    width: 25px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 10px;
    padding-top: 3px;
}
.share-list li.sort-dropdown{
    position: absolute;
    right: 0;
    margin-right: 0;
}
.sort-dropdown label{
    margin-right: 5px;
    font-weight: 600;
}
.sort-dropdown select{
    padding: 5px;
}
.comment-section{
    display: flex;
    min-height: 50px;
    margin-bottom: 20px;
    box-shadow:0 0 4px 2px rgba(0, 0, 0, 0.15);
    padding: 20px;
}
.reply-field .comment-section{
    border-radius: 5px;
}
.comment-block{
    margin-bottom: 50px;
}
.comment-block .user-img{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 20px;
    height: 50px;
}
.comment-block .user-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.area-field{
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
}
.area-field textarea{
    width: calc(100% - 115px);
    font-family: serif;
    font-size: 16px;
    height: 100%;
    padding: 5px 10px;
    border: 0;
    border-radius: 5px;
    border:1px solid #ccc;
}
.comment-btn{
    display: block;
    margin: 0 auto;
    background-color: #d16d38;
    border-color: #d16d38;
    color: #fff;
    font-size: 16px;
    min-width: 78px;
    padding:0px 15px;
    height: 40px;
    line-height: 40px;
}
.comment-btn:hover{
    background-color: transparent;
    border-color: #d16d38;
    color: #d16d38;
}
.post-sec{
    height: auto;
}
.post-content{
    width: calc(100% - 70px);
}
.post-content strong{
    color: #b30000;
    font-weight: 600;
    display: inline-block;
    margin-right: 15px;
}
.post-content span{
    color: #6f6d6d;
    font-size: 12px;
    display: inline-block;
    position: relative;
}
.post-content span:after{
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ccc;
    left: -8px;
    top:5px;
}
.article-detail .post-content p{
    font-size: 13px;
    margin: 5px 0;
    color: #000;
}
.post-bottom{
    padding-left: 15px;
    margin-bottom: 10px;
}
.post-bottom li{
    display: inline-block;
    margin-right: 15px;
    position: relative;
}
.post-bottom li:after{
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #b19f9f;
    left: -8px;
    top:9px;
}
.post-bottom li a{
    font-weight: 600;
    font-size: 12px;
    padding: 5px;
}
.post-bottom li:hover a{
    color: #03a9f4;
}
.post-bottom li:hover:after{
    background-color: #03a9f4;
}
/* .reply-field{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;
} */

.reply-field .comment-btn{
    min-width: 78px;
    padding:0px 10px;
    height: 40px;
    line-height: 40px;
}
.article-detail .post-block p{
    margin-bottom: 15px;
    padding-right: 80px;
}
.post-comment{
    padding: 20px;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15);
    border-radius:5px;
}
.post-wrap{
    width: calc(100% - 80px);
}
.article-detail .post-wrap p {
    margin: 0;
}
/* Responsive css start */
@media only screen and (max-width: 1699px) {
    /* .article-head figure {
        width: 330px;
        height: 330px;
    } */
    .article-head span {
        font-size: 22px;
    }
    .article-detail h2 {
        font-size: 64px;
    }
    .article-detail p {
        font-size: 20px;
    }
}
@media only screen and (max-width: 1499px) {
    /* .article-head figure {
        width: 290px;
        height: 290px;
    } */
    .article-head span {
        font-size: 18px;
    }
    .article-detail h2 {
        font-size: 50px;
    }
    .article-detail p {
        font-size: 18px;
    }
    .article-slider .slick-arrow {
        width: 60px;
        height: 60px;
    }
}
@media only screen and (max-width: 1199px) {
    /* .article-head figure {
        width: 250px;
        height: 250px;
    } */
    .article-detail h2 {
        font-size: 44px;
    }
    .article-detail p {
        font-size: 16px;
    }
    .article-slider .slick-arrow {
        width: 50px;
        height: 50px;
    }
}
@media only screen and (max-width: 991px) {
    /* .article-head figure {
        width: 230px;
        height: 230px;
    } */
    .article-detail h2 {
        font-size: 36px;
        margin-bottom: 30px;
    }
    .article-detail p {
        font-size: 16px;
    }
    .article-slider {
        padding: 0 0 40px;
    }
    .blog-article-one {
        padding-bottom: 70px;
    }
    .article-desc {
        padding: 50px 10%;
    }
}
/* Responsive css End */
@media only screen and (max-width: 767px) {
    .article-desc {
        padding: 50px 15px;
    }
    .comment-wrap{
        max-width: 100%;
        margin: 0 15px;
    }
    .area-field textarea{
        width: 100%;
    }
}
@media only screen and (max-width: 575px) {
    .article-head {
        display: flex;
        /* text-align: center; */
    }
    .article-head figure{
        margin: 0 auto 20px;
    }
    .article-head span{
        margin-bottom: 20px;
        /* display: block; */
    }
    .article-detail h2 {
        font-size: 22px;
    }
    .article-detail .desc {
        margin-bottom: 20px;
    }
}

/* comment responsive */
@media only screen and (max-width: 700px) {
    .share-list{
        padding-right: 0;
        flex-wrap: wrap;
    }
    .share-list li{
        margin-bottom: 8px;
    }
    .share-list li.sort-dropdown{
        position: static;
        width: 100%;
        display: block;
        margin-top: 15px;
    }
    .reply-field{
        flex-wrap: wrap;
    }
    .reply-field .area-field{
        width: 100%;
        margin-bottom: 15px;
    }
    .reply-field .btn{
        min-width: 100px;
    }
    .article-detail .post-block p{
        padding-right: 0;
    }
}