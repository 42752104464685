.modal-dialog{
    padding: 14px 21px 22px;
    width: 41% !important;
    height: 400px !important;
}

.carrierList {
    background-color: lightgray;
    margin-top: 10px;
    border-radius: 5px;
    padding: 5px;
}