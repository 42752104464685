.checkout-caption {
  padding: 25px 20px;
  /* border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece; */
}

.checkout-caption h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  font-family: 'Inter';
  font-weight: 700;
  color: #333333;
}

.checkout-headings {
  /* font-size: 27px; */
  font-weight: 600;
  text-align: left;
  color: #4a4a4a;
  margin: 0;
  display: block;
}

.complete-purchase {
  width: 100%;
  background: #EF7F1A;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  border: 1px solid #EF7F1A;
  padding: 0 15px;
  height: 55px;
  border-radius: 5px;
}

.complete-purchase:hover {
  background: #fff;
  color: #EF7F1A;
}

.complete-purchase:hover a {
  color: #EF7F1A;
}

.apply-code:hover {
  background: #fff;
  color: #EF7F1A;
}

.complete-purchase a {
  color: #fff;
}

.complete-purchase a:hover {
  color: #EF7F1A;
}

.complete-purchase.cart-btn {
  background: #59d006;
  border: 1px solid #59d006;
}

.complete-purchase.cart-btn:hover {
  background: #fff;
  color: #59d006;
}

.checkout-wrap {
  display: flex;
  justify-content: space-between;
  padding-bottom: 49px;
}

.checkout-left {
  width: 45%;
  padding: 12px 14px 60px;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 4px 10px 6px rgb(0, 0, 0, 0.04);
}

.checkout-right {
  width: 53%;
  padding: 28px 3.2%;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 4px 10px 6px rgb(0, 0, 0, 0.04);
}

.shipping-itemwrap {
  padding: 20px 4% 20px 4%;
}

.shipping-itemwrap .desc-txt input,
.shipping-itemwrap .desc-txt {
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
  width: 100%;
  background: none;
  border: 0;
}

.desc-txt-wrap .desc-txt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.shipping-itemwrap .desc-txt input {
  width: 48%;
  background-color: #fff;
  height: 40px;
  margin-bottom: 5px;
  padding: 0 15px;
  border: 1px solid #c8c8c8;
}

.shipping-itemwrap .desc-txt input[type="checkbox"] {
  height: auto;
  width: auto;
  margin-bottom: 0;
}

.desc-txt-wrap .edit-wrapper {
  margin-bottom: 14px;
}

.desc-txt-wrap .desc-txt input {
  background-color: transparent;
  height: 45px;
  padding: 0 15px;
  border: 1px solid #e5e5e5;
}

.desc-txt-wrap .edit-wrapper label {
  text-align: left;
}

.checkout-left #addressForm .desc-txt-wrap .error {
  text-align: left;
  font-size: 13px;
  display: block;
  margin-top: 0;
}

.shipping-itemwrap .head-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.shipping-itemwrap .edit-link {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
}

.shipping-itemwrap .desc-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.shipping-itemwrap .desc-txt b {
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}

.shipping-itemwrap .desc-txt b:last-child {
  font-weight: 400;
}

.delivery_wrap {
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #bebebe;
}

.shipping-itemwrap .gift-desc {
  padding-top: 20px;
}

.shipping-itemwrap .express-delivery {
  background: #fff;
  padding: 15px 20px;
}

.shipping-itemwrap .gift-desc span {
  display: block;
}

.checkout-right .total-item,
.checkout-right .summary-title {
  text-transform: capitalize;
  margin-bottom: 20px;
  display: block;
  font-size: 20px;
  font-family: 'Inter';
  font-weight: 600;
  color: #333333;
}

.checkout-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
}

.checkout-total div {
  display: flex;
  justify-content: space-between;
}

.checkout-total span {
  font-weight: 400;
  font-size: 30px;
  color: #4a4a4a;
  text-transform: none;
}

.checkout-item-price span,
.delivery-chrage span {
  font-size: 20px;
}

.checkout-total span:last-child {
  color: #000;
  padding: 0;
  text-align: right;
}

.checkout-cardwrap .checkout-card {
  padding: 20px 35px 20px 20px;
  border: 1px solid #bebebe;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  justify-content: space-between;
}

.checkout-cardwrap .card-desc {
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  font-family: "Poppins", sans-serif;
  width: calc(100% - 125px);
}

.checkout-cardwrap .card-desc span {
  display: block;
}

.checkout-cardwrap .card-img {
  width: 65px;
}

.checkout-cardwrap .card-desc b {
  font-weight: 500;
}

.checkout-cardwrap .qty-detail {
  display: flex;
  justify-content: space-between;
}

.checkout-cardwrap .price-txt {
  margin-bottom: 10px;
}

.shipping-itemwrap .desc-in input {
  width: auto;
  text-align: right;
}

.payment-type-list {
  padding: 15px 0;
}

.payment-type-list span {
  display: inline-block;
  margin-right: 50px;
}

.new-add-form .detail-from {
  padding: 30px 5%;
  background: #fff;
}

.payment-type .shop-now {
  margin-left: 0;
}

.add-new {
  /* text-align: right; */
  padding: 10px 0;
}

.success-mark,
.error-mark {
  color: #59d006;
  font-size: 40px;
}

.error-mark {
  color: #f86202;
}

@media only screen and (max-width: 1699px) {

  .checkout-item-price span,
  .delivery-chrage span {
    font-size: 20px;
  }

  .shipping-itemwrap .desc-txt input,
  .shipping-itemwrap .desc-txt {
    font-size: 16px;
  }

  .complete-purchase {
    font-size: 20px;
  }
}

.cart-des .price {
  font-size: 18px;
  font-weight: 500;
  padding: 3px 0 0;
}

@media only screen and (max-width: 1499px) {

  .checkout-item-price span,
  .delivery-chrage span {
    font-size: 18px;
  }

  .complete-purchase {
    font-size: 15px;
    height: 40px;
  }

  .follow-unfollow.view-follow .complete-purchase {
    width: 112px;
  }

  .apply-code {
    font-size: 12px;
    height: 17px;
    max-width: 66px;
  }

  .shipping-itemwrap {
    padding: 13px 3.2%;
  }

  .checkout-caption h2 {
    font-size: 20px;

  }
}

@media only screen and (max-width: 1199px) {
  .checkout-total span {
    font-size: 18px;
  }

  .shipping-itemwrap .desc-txt input,
  .shipping-itemwrap .desc-txt {
    font-size: 14px;
  }

  .shipping-itemwrap .edit-link {
    font-size: 14px;
  }

  .complete-purchase {
    font-size: 16px;
    height: 46px;
  }

  .checkout-cardwrap .card-desc {
    font-size: 12px;
    width: calc(100% - 100px);
  }
}

@media only screen and (max-width: 991px) {
  .checkout-total span {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .complete-purchase {
    width: 100%;
  }

  .checkout-wrap {
    flex-direction: column-reverse;
  }

  .checkout-right,
  .checkout-left {
    width: 100%;
  }

  .checkout-total span {
    font-size: 22px;
  }

  .checkout-cardwrap .card-img {
    width: 50px;
  }

  .checkout-cardwrap .card-desc {
    width: calc(100% - 76px);
  }

  .checkout-cardwrap .checkout-card {
    padding: 15px;
  }

  .checkout-left {
    padding: 5px 0 30px;
    margin-top: 20px;
  }
}

.select-opt__single-value.css-1uccc91-singleValue {
  transform: none !important;
  top: 0px !important;
  line-height: 1.8;
}

@media only screen and (min-width:1500px) {
  .select-opt__single-value.css-1uccc91-singleValue {
    line-height: 1.5;
  }

  .follow-unfollow.view-follow .complete-purchase {
    width: 136px;
  }
}