.privacy-policy {
    padding: 35px 0 180px;
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
}

.privacy-wrapper {
    display: flex;
    justify-content: space-between;
}

.privacy-menu {
    width: 25%;
}

.privacy-details {
    width: 70%;
}

.privacy-menu .breadcrumb-wrap {
    padding: 20px 0 0;
    border: 0;
    margin-bottom: 60px;
}

.privacy-menu .breadcrumb-wrap li,
.privacy-menu .breadcrumb-wrap span {
    font-size: 21px;
}

.privacy-menu .breadcrumb-wrap span {
    color: #B4B4B4;
}

.privacy-menu .list-wrap span {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 600;
    color: #4A4A4A;
    display: block;
    padding: 32px 0;
    border-bottom: 1px solid #999999;
    cursor: pointer;

}

.privacy-menu .list-wrap span.activePage {
    color: #D16D38;
}

.privacy-menu .list-wrap span:hover {
    color: #D16D38;
}

.privacy-details h2 {
    font-size: 64px;
    font-family: 'Ogg';
    color: #D16D38;
    font-weight: normal;
    margin-bottom: 30px;
}

.privacy-details .desc {
    margin-bottom: 30px;
}

.privacy-details p {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #4A4A4A;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.privacy-details p strong {
    font-weight: 600;
}

/* Responsive Start */
@media only screen and (max-width: 1699px) {

    .privacy-menu .breadcrumb-wrap li,
    .privacy-menu .breadcrumb-wrap span {
        font-size: 18px;
    }

    .privacy-details h2 {
        font-size: 48px;
    }

    .privacy-menu .list-wrap span {
        font-size: 20px;
        padding: 25px 0;
    }

    .privacy-details p {
        font-size: 17px;
    }

}

@media only screen and (max-width: 1499px) {

    .privacy-menu .breadcrumb-wrap li,
    .privacy-menu .breadcrumb-wrap span {
        font-size: 16px;
    }

    .privacy-details h2 {
        font-size: 32px;
    }

    .privacy-menu .list-wrap span {
        font-size: 17px;
        padding: 20px 0;
    }

    .privacy-details p {
        font-size: 16px;
    }

}

@media only screen and (max-width: 1199px) {

    .privacy-menu .breadcrumb-wrap li,
    .privacy-menu .breadcrumb-wrap span {
        font-size: 14px;
    }

    .privacy-details h2 {
        font-size: 28px;
    }

    .privacy-menu .list-wrap span {
        font-size: 16px;
        padding: 20px 0;
    }

    .privacy-details p {
        font-size: 15px;
    }

    .privacy-menu {
        width: 30%;
    }

    .privacy-details {
        width: 67%;
    }

    .privacy-policy {
        padding: 35px 0 70px;
    }

}

@media only screen and (max-width: 991px) {
    .privacy-menu {
        width: 37%;
    }

    .privacy-details {
        width: 60%;
    }

    .privacy-details p {
        font-size: 16px;
    }

    .privacy-details h2 {
        font-size: 26px;
    }

}

@media only screen and (max-width: 767px) {
    .privacy-details p {
        font-size: 13px;
    }

    .privacy-wrapper {
        display: block;
    }

    .privacy-menu,
    .privacy-details {
        width: 100%;
    }

    .privacy-menu .breadcrumb-wrap {
        padding: 0;
        margin-bottom: 20px;
    }

    .privacy-menu .list-wrap {
        margin-bottom: 30px;
    }

    .privacy-details .desc,
    .privacy-details h2 {
        margin-bottom: 15px;
    }
}