.plp-header {
  position: relative;
  z-index: 9;
  /* overflow: hidden; */
}

.cat-head .plp-filters {
  display: none;
}

.plp-filters {
  width: 240px;
  border-radius: 40px;
  position: relative;
  z-index: 9;
  flex-shrink: 0;
}

.plp-filter-option {
  width: 100%;
}

.plp-main-screen {
  display: flex;
  flex-wrap: wrap;
}

.plp-product-screen {
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.accordian-header {
  color: #4a4a4a;
  cursor: pointer;
  padding: 18px 18px 10px 0;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 21px;
  transition: 0.4s;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  font-family: Roboto, sans-serif;
  text-transform: capitalize;
}

.accordian-header:before {
  float: right;
  margin-left: 5px;
  width: 0;
  height: 0;
  position: absolute;
  right: 9px;
  font-family: "Font Awesome 5 Pro";
  transition: all 0.5s ease 0s;
  color: #f86202;
}

.accordian-header.active:before {
  transform: rotate(90deg);
  right: 0;
}

.accordian-header.active:after {
  transform: translateY(-50%) rotate(180deg);
}

.active,
.accordian-header:hover {
  background-color: #ffffff;
}

.accordian-panel {
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  line-height: 15px;
  font-size: 15px;
  margin-left: 8px;
  display: flex;
}

.category-attr-block .accordian-panel {
  display: block;
}

.accordian-panel input[type="search"] {
  height: 30px;
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 0 7px;
}

.accordian-panel input[type="search"]::-webkit-search-decoration:hover,
.accordian-panel input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.expanded {
  max-height: max-content;
}

.plp-costume-box {
  width: 24.92%;
  margin-bottom: 54px;
  flex: 0 0 auto;
  overflow: hidden;
  padding: 0 10px;
}

.plp-costume-box a {
  display: block;
}

.manage-profile .plp-costume-box {
  margin-bottom: 125px;
}

.plp-costume-block {
  border: 1px solid #e6e6e6;
  position: relative;
  transition: all 0.5s ease;
}

.manage-profile .plp-costume-block {
  border: 1px solid transparent;
}

/* .plp-costume-block:hover{
  border:1px solid #d16d38;
} */
/* .red-heart.icon-wishlist{
  background-position: -13px -190px;
} */
.plp-costume-info strong {
  font-size: 23px;
  font-weight: 600;
  color: #4a4a4a;
  /* margin-top: 19px;
  margin-bottom: 10px; */
  display: block;
  position: relative;
}

.plp-costume-info {
  padding: 0px 0px 0px 20px;
}

.plp-costume-info p {
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 500;
  line-height: 23px;
}

.plp-costume-info span {
  color: #a292b3;
}

.plp-costume-info .buy-info {
  color: #4a4a4a;
}

.plp-loadMore-div {
  width: 100%;
  text-align: center;
}

.plp-loadMore-btn {
  width: 276px;
  height: 71px;
  background: #f7f5f5;
  letter-spacing: 0px;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  border: 1px solid #f7f5f5;
  transition: all 0.3s ease-in-out;
}

.plp-loadMore-btn:hover {
  color: #d16d38;
  border-color: #d16d38;
  background: none;
}

.plp-categoryItem {
  cursor: pointer;
  line-height: 20px;
}

.plp-category-selected {
  font-weight: 600;
}

.plp-colorPallete {
  margin-left: 5px;
  margin-top: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.plp-color-swatch-container {
  display: flex;
  flex-wrap: wrap;
}

.product-listhead {
  align-items: center;
  padding: 18px 0px 23px 0;
}

.product-listhead h2 {
  font-size: 25px;
  font-weight: 300;
  color: #4a4a4a;
  margin: 0px 0 0 0;
  font-family: "Roboto";
  float: left;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.select-opt__control {
  height: 47px;
  border-color: #959595;
  font-size: 22px;
  color: #4a4a4a;
  font-weight: 600;
  padding-left: 0;
}

.select-opt__indicator-separator {
  display: none;
}

.select-opt__indicator svg {
  width: 20px !important;
  height: 20px !important;
}

.product-listhead .breadcrumb-wrap {
  padding: 0;
  border: 0;
}

.mainlisting-wrap {
  padding: 0px 0px 20px 0;
  justify-content: space-between;
  position: relative;
  /* border-bottom: 1px solid #e6e6e6; */
}

.mainlisting-wrap .plp-category {
  border-top: 1px solid #e6e6e6;

}

.mainlisting-wrap .plp-category hr {
  border-top: 1px solid #e6e6e6;
}

.mainlisting-wrap .plp-product-screen {
  margin: 0;
}

.accordian-panel .plp-categoryItem {
  color: #4a4a4a;
  cursor: pointer;
  padding: 15px 18px 15px 0;
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.accordian-panel .plp-categoryItem.plp-category-selected,
.accordian-panel .plp-categoryItem:hover {
  color: #d16d38;
}

.mainlisting-wrap .plp-costume-action {
  position: absolute;
  top: -5px;
  right: 0px;
}

.mainlisting-wrap .product-pack {
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainlisting-wrap .plp-costumes {
  /* height: 278px;
  padding: 15px; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainlisting-wrap .plp-costumes img {
  max-height: 100%;
}

.plp-costume-block>a {
  display: block;
}

.no-product-wrap {
  width: 100%;
}

.no-product {
  width: 25%;
  margin: 0 auto;
}

.no-product-wrap h4 {
  margin-top: -30px;
}

/* range slider Start */
.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-size: 14px;
  transition: all 0.1s ease-in;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}

.rangeslider .rangeslider__handle-tooltip:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
}

.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #7cb342;
  border-radius: 10px;
  top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4),
    inset 0 -1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}

.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}

.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}

.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}

.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}

.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}

.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}

.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}

.rangeslider__labels {
  position: relative;
}

.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item:before {
  content: "";
  width: 10px;
  height: 2px;
  background: #000;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  transform: translate3d(-50%, 0, 0);
}

.category-filter-tag {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #f86202;
  font-size: 14px;
  cursor: pointer;
  margin: 0 10px 10px;
  transition: all 0.3s ease-in-out;
  border-radius: 40px;
  color: #f86202;
}

.category-filter-tag:hover {
  background: #f86202;
  color: #fff;
}

.category-filter-tag div {
  display: inline-block;
  padding: 0 6px 0px 0;
}

.plp-loadMore-div {
  margin-top: 25px;
}

.editmain-wrapper {
  max-width: 1920px;
  margin: 0 auto;
}

.modal-body .react-share__ShareButton {
  width: 48px;
  height: 48px;
  margin: 0 5px;
  transition: all 0.5s;
}

/* Range Slider End */
.product-listhead .cat-head-wrap h2 i {
  display: none;
}

.sort-drop img {
  display: none;
}

/* Responsive CSS start */
.search-brand .react-autosuggest__suggestions-container {
  transform: translateY(10px);
  width: calc(100% + 40px);
  max-height: 434px;
  overflow: hidden;
}

@media only screen and (max-width: 1699px) {
  .product-listhead h2 {
    font-size: 20px;
  }

  .plp-filters .select-opt__control {
    padding-left: 10px;
    font-size: 18px;
  }

  .new {
    font-size: 18px;
  }

  .accordian-header {
    font-size: 18px;
  }

  .plp-loadMore-btn {
    font-size: 18px;
    width: 240px;
    height: 60px;
  }

  .accordian-panel .plp-categoryItem {
    font-size: 18px;
  }

  .plp-costume-info strong {
    font-size: 19px;
  }
}

@media only screen and (max-width: 1499px) {
  .accordian-panel {
    margin-left: 0;
  }

  .accordian-panel .plp-categoryItem,
  .accordian-header {
    font-size: 16px;
  }

  .plp-filters .select-opt__control {
    font-size: 16px;
  }

  .new {
    font-size: 16px;
  }

  .plp-costume-info strong {
    font-size: 17px;
  }

  .plp-costume-info p {
    font-size: 14px;
  }
}

.price-panelwrap {
  max-height: none;
  margin: 0;
}

@media only screen and (max-width: 1199px) {
  .plp-costume-info strong {
    font-size: 15px;
  }

  .plp-costume-info p {
    font-size: 12px;
  }

  .plp-loadMore-btn {
    font-size: 16px;
    width: 190px;
    height: 52px;
  }

  .select-opt__indicator svg {
    width: 19px !important;
    height: 19px !important;
  }

  .product-listhead {
    padding: 30px;
  }

  .mainlisting-wrap {
    padding: 0 30px 60px;
  }

  .product-listhead h2 {
    margin: 0;
  }

  .plp-costume-box {
    width: 49.92%;
  }

  .mainlisting-wrap .product-pack {
    padding: 0 10px;
  }

  .plp-main-screen.mainlisting-wrap .costume-action {
    right: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .plp-filters {
    width: 28%;
  }

  .breadcrumb-wrap li,
  .breadcrumb-wrap a {
    font-size: 14px;
  }

  .breadcrumb-wrap .back-arrow {
    width: 6px;
    height: 10px;
  }

  .accordian-panel .plp-categoryItem,
  .accordian-header {
    font-size: 19px;
  }

  .product-listhead {
    padding: 25px;
  }

  .mainlisting-wrap {
    padding: 0 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .search-brand .react-autosuggest__suggestions-container {
    left: 0;
    right: 0;
    width: calc(100% + 23px);
  }

  .modal-body .react-share__ShareButton {
    width: 36px;
    height: 36px;
  }

  .mainlisting-wrap .plp-category {
    border-top: 0;
  }

  .product-listhead .cat-head-wrap {
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    align-items: center;
    background-color: #fff;
    filter: drop-shadow(0.5px 2.598px 2.5px rgba(0, 0, 0, 0.1));
  }

  .product-listhead .cat-head-wrap.category-filter-head.category-wrap-head h2 {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }

  .product-listhead .cat-head-wrap h2 i {
    display: inline-block;
    font-size: 14px;
  }

  .product-listhead .plp-filters .my_class.new-filter.my-new-filter {
    text-align: center;
    border: 0;
    border-left: 1px solid #e5e5e5;
    font-size: 15px;
    font-weight: 500;
    color: #4a4a4a;
    background-image: none;
    padding: 10px 46px 9px 22px;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .sort-drop img {
    display: inline-block;
    position: absolute;
    max-width: 19px;
    top: 13px;
    right: 24px;
    z-index: 1;
  }

  .plp-header {
    display: block;
  }

  .breadcrumb-wrap {
    margin-bottom: 15px;
  }

  .plp-category {
    width: 100%;
  }

  .plp-filters {
    width: auto;
  }

  .cat-head-wrap .plp-filters .sort-drop {
    width: auto;
    margin-left: 0;
  }

  .plp-category {
    margin-bottom: 25px;
  }

  .mainlisting-wrap {
    display: block;
  }

  .mainlisting-wrap .plp-product-screen {
    width: 100%;
    margin: 0;
  }

  .plp-costume-box {
    width: 48%;
  }

  .plp-costume-box a {
    display: block;
  }

  .plp-costume-info strong {
    position: relative;
  }

  .plp-filter-option {
    width: 100%;
  }

  .product-listhead {
    padding: 20px;
  }

  .mainlisting-wrap {
    padding: 0 0 40px;
  }

  .product-listhead h2 {
    margin: 0;
    text-align: left;
  }

  .product-listhead {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 575px) {
  .plp-costume-box {
    width: 100%;
  }
}

/* ////## bazaar  /// */
.price-div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
}

.price-lable {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
}

.price-lable:last-child {
  margin-left: 50px;
}

.price-div .price-lable:last-child {
  margin-left: 0;
}

.price-div .price-lable {
  margin-bottom: 10px;
}

.price-input {
  margin-left: 10px;
}

.price-section:hover {
  background: #f5f5f6;
}

.price-section {
  padding: 5px 0px 5px 5px;
}

.price-input-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex: 1 1 auto;
  background: #00000000;
  height: auto;
  width: auto;
}

.price-textInput {
  appearance: none;
  border-radius: 5px;
  height: 100%;
  width: 79px;
  /* font-size: var(--type-reg); */
  line-height: 22px;
  letter-spacing: -0.24px;
  /* color: var(--color-grey-4); */
  border: 1px solid #cdced2;
  padding: 12px 6px;
}

.price-input-dash {
  width: 7px;
  height: 1px;
  background: black;
  margin: auto 5px;
}

.price-apply {
  text-align: center;
  background-color: var(--color-transparent);
  color: #1717e2;
  border: 0px;
  border-radius: var(--round-default);
  cursor: pointer;
  font-weight: var(--font-semibold);
  min-width: 64px;
  padding: 0 var(--space-4);
  display: inline-block;
  width: initial;
  font-size: var(--type-h6);
  height: 32px;
  line-height: 32px;
  position: relative;
  z-index: 0;
}

.spinner-button {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 15;
}

.modal-dialog {
  background: #fff;
  padding: 11px 17px 20px;
  width: 300px;
  border-radius: 15px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal-body {
  text-align: center;
  margin-bottom: 21px;
}

.modal-body .react-share__ShareButton:hover {
  opacity: 0.7;
}

.modal-body .react-share__ShareButton svg {
  width: 100%;
  height: 100%;
}

.modal-header .modal-title {
  font-size: 22px;
  font-weight: 600;
  font-family: "Roboto";
  color: rgb(34, 34, 34);
}

.modal-header .close>span {
  font-size: 25px;
  border-radius: 50%;
  color: rgb(194, 193, 193);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f67900;
  transition: all 0.5s;
}

.modal-header .close>span:hover {
  color: #f86202;
  border-color: #f86202;
}

.modal-header .close .sr-only {
  display: none;
}

.modal-footer button {
  color: rgb(34, 34, 34);
  font-family: "Roboto";
  font-weight: 600;
  margin-left: 10px;
  font-size: 14px;
  transition: all 0.5s;
}

.modal-footer button:hover {
  color: #f86202;
}

.modal-footer i {
  font-weight: 400;
  margin-right: 5px;
}

.forget-block .input-form .send-msg-green {
  margin-bottom: 14px;
}

.modal-dialog.modal-90w.bank .file-inputs {
  height: 50px;
}

.modal-dialog.modal-90w.bank .seller-inputs .seller-input input {
  height: auto;
}

.modal-dialog.modal-90w.bank {
  width: 90% !important;
  height: auto !important;
}

.modal-dialog.modal-90w.bank .label-text {
  text-align: left;
  width: 100%;
  display: block;
  margin-bottom: 4px;
  font-weight: 500;
  font-family: "Roboto";
}

.slick-arrow.slick-prev.slick-disabled {
  display: none !important;
}

.slick-arrow.slick-next.slick-disabled {
  display: none !important;
}

.modal-dialog.modal-90w.bank .btn-purple {
  font-size: 15px;
  text-transform: capitalize;
  height: 38px;
  background: #f86202;
  border: 1px solid #f86202;
}

.modal-dialog.modal-90w.bank .btn-purple:hover {
  background: transparent;
  color: #f86202;
}

.modal-dialog.modal-90w.bank .modal-body {
  margin-bottom: 0;
}

.cat-head-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

@media only screen and (min-width: 768px) {
  .mainlisting-wrap .plp-category {
    filter: drop-shadow(0.5px 1.598px 2.5px rgba(0, 0, 0, 0.21));
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    margin-right: 1%;
  }

  .modal-dialog.modal-90w.bank {
    width: 510px !important;
  }

  .modal-dialog {
    padding: 14px 21px 22px;
    width: 340px;
  }

  .modal-body .react-share__ShareButton {
    margin: 0 7px;
  }

  .modal-header {
    margin-bottom: 30px;
  }

  .modal-body {
    margin-bottom: 31px;
  }

  .modal-footer button {
    margin-left: 21px;
  }
}

@media only screen and (min-width: 1500px) {
  .accordian-panel {
    margin-bottom: 3px;
    margin-left: 10px;
  }
}
.price-sec.filter-border .accordian-header{
  background-color: transparent;
}