.menu-item-container {
  display: flex;
  padding: 50px 20px 0;
  max-width: 985px;
  margin: 0 auto;
}

.review-btn,
.next-btn {
  min-width: 255px;
  height: 55px;
  border: 1px solid #f86203;
  background: #f86203;
  font-weight: 500;
  font-size: 21px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  text-transform: capitalize;
  font-family: "Roboto";
}

.review-btn:hover,
.next-btn:hover {
  background: none;
  color: #d16d38;
  border-color: #d16d38;
}

.navigation-panel {
  width: 32%;
  margin-right: 3%;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

.navigation-screen {
  width: 68%;
  flex-grow: 1;
  padding: 25px 15px;
  background: #fff;
  border-radius: 7px;
  /* box-shadow: 0 4px 10px 6px rgba(0, 0, 0, 0.03); */
  box-shadow: 0px 4px 5px 5px rgb(0 0 0 / 6%);
  margin-bottom: 37px;
}

.navigation-container {
  display: flex;
  justify-content: space-between;
}

.navigation-continue {
  display: flex;
  justify-content: flex-end;
}

.completed-tab h5 {
  color: pink;
}

.pending-tab {
  color: black;
}

.input-url {
  height: 37px;
  font-size: 16px;
  padding: 0 15px;
  font-family: "Roboto";
  color: rgb(76, 76, 76);
}

.input-url::placeholder {
  color: rgb(76, 76, 76);
  font-family: "Roboto";
}

.pac-target-input {
  color: rgb(76, 76, 76);
  font-family: "Roboto";
}

.pac-target-input::placeholder {
  color: rgb(76, 76, 76);
  font-family: "Roboto";
}

.uploading-file-wrap {
  position: relative;
  margin-bottom: 10px;
  margin-top: 30px;
  flex: 1 1 0%;
  display: flex;
  cursor: pointer;
}

.uploading-file-wrap button {
  position: absolute;
  bottom: 0;
  left: 10px;
  cursor: default !important;
}

.uploading-file-wrap button.remove-img_btn {
  right: 10px;
  left: auto;
}

.uploading-file-wrap button.remove-img_btn .change-reset-file {
  margin-right: 0;
}

.custom-file-upload {
  border: 1px dotted #adadad;
  padding: 24px 16px 7px;
  /* margin: 10px; */
  position: relative;
  display: block;
  /* text-align: center; */
  border-radius: 20px;
  /* width: 240px; */
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.custom-file-render {
  border: 1px dotted #adadad;
  padding: 6px 12px;
  margin: 10px;
  position: relative;
  display: block;
}

.rend-box {
  width: 250px;
  height: 300px;
  display: flex;
}

.rend-box h6 {
  text-transform: capitalize;
  text-align: center;
  color: #989792;
}

.tooltip-text {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 10px;
  white-space: nowrap;
  color: #fff;
  background-color: #4a4a4a;
  padding: 3px 7px;
  border-radius: 15px;
  transform: translate(-50%, -50%);
}

.custom-file-upload:hover .tooltip-text {
  display: block;
}

.change-reset-file {
  margin-top: 20px;
  width: 100px;
  height: 50px;
  background-color: #d16d38;
  margin-right: 30px;
  cursor: pointer;
  padding: 10px 25px;
  color: #fff;
}

.article-head .change-reset-file span {
  color: #fff;
}

.add-more-file {
  background-color: #d16d38;
  padding: 10px;
  color: #fff;
  height: 39px;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

.display-image {
  width: 250px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
  object-fit: cover;
}

.image-container {
  margin-top: 20px;
}

.input-description {
  resize: none;
  width: 100%;
  height: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.country-code-mobile-no {
  display: flex;
}

.country-code-box {
  width: 45%;
}

.mobile-number-box {
  width: 45%;
}

.ship-btn {
  width: 80px;
  height: 30px;
  border: 1px solid;
  cursor: pointer;
  transition-duration: 0.4s;
}

/* .active:hover{
    background-color: white;
} */
/* .inactive:hover{
    background-color: grey;
} */
/* .active {
  background-color: grey;
} */

.inactive {
  background-color: white;
}

.slider {
  -webkit-appearance: none;
  width: 50%;
  height: 7px;
  background: #dca9a9;
  opacity: 0.7;
}

.slider:hover {
  opacity: 1;
}

.slider::-moz-range-thumb {
  width: 45px;
  height: 15px;
  background: #dca9a9;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #dca9a9;
  cursor: pointer;
}

/* Discount Banner start */
.discount-banner {
  padding: 50px 0;
  background: #f7d9b7;
}

.discount-banner .discount {
  width: 330px;
  height: 330px;
  background: #f9e8dd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 7%;
}

.discount .discount-title {
  font-size: 55px;
  line-height: 1;
  color: #673e37;
  font-family: "Ogg";
  font-weight: normal;
  margin-bottom: 30px;
}

.discount .discount-desc {
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #673e37;
  line-height: 1.5;
}

.discount-banner .discount-wrap {
  display: flex;
  align-items: center;
}

.discount-banner .info {
  width: 48%;
  text-align: center;
}

.discount-banner .info-title {
  color: #d2703c;
  font-family: "Ogg";
  font-size: 64px;
  line-height: 1.3;
  font-weight: normal;
  margin-bottom: 30px;
}

.discount-banner .info-desc {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 500;
  color: #d16d38;
  font-family: "Poppins", sans-serif;
}

/* Discount Banner end */
.selldetails-wrap {
  padding: 32px 15px 250px 20px;
}

.navigation-screen h5,
.selldetails-wrap h5 {
  padding: 15px 0;
  color: #adadad;
  font-weight: 500;
  font-size: 22px;
  text-transform: capitalize;
}

.navigation-screen h5 {
  border: 0;
  padding: 0;
  color: #4a4a4a;
  margin-bottom: 23px;
}

.selldetails-wrap h5 {
  position: relative;
  padding: 0 29px 0 29px;
}

.selldetails-wrap .completed-tab h5:after {
  content: url(/assets/images/tick-icn.svg);
  width: 27px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.selldetails-wrap .active-tab h5 {
  color: #4a4a4a;
}

.selldetails-wrap>div:last-child h5 {
  border: 0;
}

.navigation-screen .label-txt {
  font-family: "Roboto";
  color: #4a4a4a;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 14px;
  display: block;
}

/* .slct-optns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
} */

.slct-optns .optns {
  width: calc(50% - 15px);
  margin-right: 15px;
  margin-bottom: 20px;
  position: relative;
}

.slct-optns .input-url {
  border: 1px solid #cdced2;
  border-radius: 4px;
}

.slct-optns .slt-url {
  width: 40%;
}

.slct-optns .input-url {
  width: 100%;
  height: 47px;
  font-size: 22px;
  font-weight: 400;
}

.select-opt__menu {
  margin: 0 !important;
}

.select-opt__menu .select-opt__option {
  padding: 15px;
  font-size: 14px;
  font-weight: 500;
  z-index: 9;
}

/* How it work section start */
.how-it-work .szn-img {
  width: 362px;
  height: 362px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f5f5;
  border-radius: 50%;
  margin: 0 auto;
}

.how-it-work .diaries-block {
  padding: 60px 16% 111px;
}

.how-it-work .btn-diaries {
  display: none;
}

/* How it work section End */
/* Upload image section Start */
.upload-detials label,
.uploadimg-desc {
  font-size: 22px;
  font-family: "Roboto";
  line-height: 1.7;
  color: #4a4a4a;
}

.upload-detials>label {
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

.back-btn {
  color: #767676;
  font-size: 20px;
  font-weight: 500;
  border: 1px solid #767676;
  padding: 10px 45px;
  border-radius: 5px;
  font-family: "Roboto";
}

.back-btn svg {
  width: 15px;
  height: 18px;
  margin-right: 10px;
}

.navigation-container.both-btn {
  padding-top: 70px;
  margin-bottom: 20px;
}

/* Upload image section End */
/* Description section Start */
.description-container .desc-field {
  margin-bottom: 40px;
}

.btns-status {
  margin-bottom: 15px;
  font-family: "Roboto";
}

.btns-status button {
  font-family: "Roboto";
}

.btns-status .ship-btn {
  width: 75px;
  height: 35px;
  border: 1px solid #525252;
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  margin-right: 14px;
  border-radius: 5px;
  line-height: 1.2;
}

.btns-status .ship-btn.active {
  border: 3px solid #f86203;
  background: #f86203;
  color: #fff;
}

.bidding-slider {
  margin-bottom: 500px;
}

.bidding-slider .slider {
  height: 2px;
  background: #707070;
}

/* Description section Start */
/* Seller info sec start */
.seller-infoblog .mob-desc,
.seller-infoblog .slct-optns {
  margin-bottom: 50px;
}

.seller-infoblog .input-url {
  border: 1px solid #959595;
}

/* Seller info sec End */
/* Shipping detail sec start */

/* .shipping-details {
      margin-bottom: 300px;
  } */

/* Shipping detail sec start */
/* Review detail Start */
.review-detials .review-box {
  width: 424px;
  height: 424px;
  background: #f7f5f5;
  margin-bottom: 200px;
}

.review-detials .review-control {
  text-align: right;
  margin-bottom: 70px;
}

.review-control .review-btn {
  padding: 0 40px;
}

.shipping-details .slct-optns .optns {
  position: relative;
}

.sell-item {
  /* background: #eaeaea; */
  font-family: "Roboto";
}

.custom-file-upload .upload-icn span {
  display: block;
  color: #4c4c4c;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 10px;
}

.custom-file-upload .upload-icn span.drag {
  font-weight: 300;
  font-size: 15px;
}

.sell-item-header span {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

/* Responsive CSS Start */
@media only screen and (max-width: 1699px) {

  .navigation-screen h5,
  .selldetails-wrap h5 {
    font-size: 18px;
  }

  .selldetails-wrap h5 {
    font-size: 18px;
  }

  .discount-banner .info-title {
    font-size: 54px;
  }

  .navigation-screen .label-txt,
  .discount-banner .info-desc {
    font-size: 18px;
  }

  .slct-optns .select-opt__control {
    padding: 2px 0 0 15px;
    font-size: 18px;
    height: auto;
  }

  .slct-optns .input-url,
  .navigation-screen .attr-elements .slct-optns .slt-url select {
    height: 42px;
  }

  .slct-optns .input-url,
  .select-opt__menu .select-opt__option {
    font-size: 18px;
  }

  .review-btn,
  .next-btn {
    min-width: 162px;
    height: 54px;
    font-size: 20px;
  }

  .how-it-work .szn-img {
    width: 300px;
    height: 300px;
    padding: 30px;
  }

  .how-it-work .diaries-block {
    padding: 60px 14% 111px;
  }

  .upload-detials label,
  .uploadimg-desc {
    font-size: 18px;
  }

  .back-btn {
    font-size: 20px;
  }

  .back-btn svg {
    width: 9px;
    height: 14px;
  }

  .btns-status .ship-btn {
    width: 75px;
    height: 38px;
    font-size: 18px;
  }

  .review-detials .review-box {
    width: 320px;
    height: 320px;
  }
}

@media only screen and (max-width: 1499px) {
  .how-it-work .diaries-block {
    padding: 60px 10% 111px;
  }

  .how-it-work .diaries-caption p {
    min-height: 60px;
  }

  .review-btn,
  .next-btn,
  .navigation-screen h5,
  .selldetails-wrap h5 {
    font-size: 16px;
  }

  .slct-optns .input-url,
  .select-opt__menu .select-opt__option,
  .slct-optns .select-opt__control,
  .navigation-screen .label-txt,
  .discount-banner .info-desc {
    font-size: 14px;
  }

  .discount-banner .info-title {
    font-size: 44px;
  }

  .selldetails-wrap {
    padding: 32px 15px 250px 20px;
  }

  .how-it-work .szn-img {
    width: 280px;
    height: 280px;
  }

  .upload-detials label,
  .uploadimg-desc {
    font-size: 16px;
  }

  .back-btn {
    font-size: 18px;
  }

  .custom-file-upload .upload-icn {
    width: 70px;
    height: 65px;
    text-align: center;
  }

  .custom-file-upload .upload-icn img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto 15px;
  }

  .btns-status .ship-btn {
    width: 71px;
    height: 36px;
    font-size: 16px;
  }

  .review-detials .review-box {
    width: 280px;
    height: 280px;
  }
}

@media only screen and (max-width: 1199px) {
  .navigation-screen {
    padding: 25px 15px;
  }

  .how-it-work .szn-img {
    width: 200px;
    height: 200px;
  }

  .review-btn,
  .next-btn,
  .navigation-screen h5,
  .selldetails-wrap h5 {
    font-size: 14px;
  }

  .slct-optns .input-url,
  .select-opt__menu .select-opt__option,
  .slct-optns .select-opt__control,
  .navigation-screen .label-txt,
  .discount-banner .info-desc {
    font-size: 14px;
  }

  .discount-banner .info-title {
    font-size: 40px;
  }

  .discount-banner .discount {
    width: 240px;
    height: 240px;
  }

  .discount .discount-title {
    font-size: 40px;
  }

  .discount .discount-desc {
    font-size: 14px;
  }

  .upload-detials label,
  .uploadimg-desc {
    font-size: 14px;
  }

  .back-btn {
    font-size: 18px;
  }

  .btns-status .ship-btn {
    font-size: 16px;
  }

  .review-detials .review-box {
    width: 240px;
    height: 240px;
  }
}

@media only screen and (max-width: 991px) {
  .discount-banner .info-title {
    font-size: 32px;
  }

  .slct-optns .optns {
    width: calc(50% - 15px);
  }

  .slct-optns .slt-url {
    width: 46%;
  }

  .how-it-work .szn-img {
    width: 190px;
    height: 190px;
  }

  .review-detials .review-box {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .btns-status .ship-btn {
    height: 34px;
    font-size: 13px;
    border-radius: 5px;
  }

  .slct-optns {
    margin-bottom: 0;
  }

  .next-btn {
    height: 41px;
    font-size: 15px;
  }

  .how-it-work .btn-diaries {
    display: block;
  }

  .discount-banner .discount-wrap {
    display: block;
  }

  .discount-banner .discount {
    margin: 0 auto 30px;
  }

  .discount-banner .info {
    width: 100%;
  }

  .menu-item-container {
    display: block;
  }

  .navigation-panel {
    width: 100%;
    border: 0;
    margin-bottom: 20px;
  }

  .navigation-panel .selldetails-wrap span {
    height: 18px;
    width: 18px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 1px 0;
  }

  .selldetails-wrap {
    padding: 13px 15px;
  }

  .navigation-screen {
    padding: 25px 15px;
    width: 100%;
  }

  .bidding-slider .slider {
    width: 100%;
  }

  .bidding-slider {
    margin-bottom: 50px;
  }

  .shipping-details {
    margin-bottom: 0;
  }

  .review-detials .review-box {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .slct-optns .optns {
    width: 100%;
    margin: 0 0 20px;
  }

  .slct-optns {
    display: block;
  }

  .mobile-number-box,
  .slct-optns .slt-url {
    width: 100%;
  }

  .review-btn,
  .next-btn {
    min-width: 160px;
  }
}

.condition-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: var(--border-4);
  padding: 13px;
  text-align: center;
  border-radius: 7px;
  border: 1px solid #9a9a9a;
  cursor: pointer;

}

.attr-card-button.selected .desc {
  color: #fff;
}

.condition-card.selected {
  color: #fff;
  border: 1px solid #f86203;
  background-color: #f86203;
}

.attr-card-button {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-radius: var(--border-4);
  padding: var(--space-4) var(--space-2);
  text-align: center;
}

.attr-card-button.selected {
  color: #fff;
  border: 1px solid #5e6df2;
  background-color: #5e6df2;
  border-radius: 7px;
}

.slct-optns .attr-optns {
  width: auto;
  margin-right: 3%;
}

.slct-optns.price-row .input-url {}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.price-brief>span.red {
  color: red;
}

.selldetails-wrap .completed-tab span {
  background: #f86203 !important;
}

.selldetails-wrap .completed-tab h5 {
  color: #7ab661;
}

.price-brief {
  font-family: "Roboto";
}

.price-brief .row {
  margin-bottom: 5px;
}

.slct-optns.price-section {
  align-items: flex-start;
}

.ckEditorError .cke_chrome {
  border: 1px solid red !important;
  border-color: red !important;
}