.primary-header {
    /* background: #FDEFC1; */
    padding: 0;
    position: relative;
}

.primary-header .container-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.primary-header .logo {
    margin: 0;
    padding: 15px 0;
    display: block;
    width: 90px;
}

.primary-header .logo img {
    width: 100%;
    display: block;
}

.primary-header .navbar-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    background: #fff;
    z-index: 9;
    width: 100%;
    text-align: center;
    padding: 10px 20px;
}

.navbar-wrapper.active {
    display: block;
}

.header-content-wrap.active .navbar-wrapper {
    display: block;
    padding: 15px 0;
}

.container-main {
    max-width: 1384px;
    padding: 0 20px;
    margin: 0 auto;
}

/* 
.navbar {
    margin-bottom: 20px;
} */

.nav-menu a {
    font-family: 'Inter',
        sans-serif;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    padding: 15px;
    display: block;
    transition: all 0.5s;
}

.nav-menu .active a {
    color: #EF7F1A;
}

.nav-menu a:hover {
    color: #EF7F1A;
}

.nav-menu a:active {
    color: #EF7F1A;
}

.nav-menu a:active {
    color: #EF7F1A;
}

.latest-news {
    display: flex;
    align-items: center;
    background-color: #000;
}

.latest-news .ltl-news {
    padding: 8px 20px;
}

.ticker-wrapper {
    padding: 11px 20px;
    height: 41px;
    width: 100%;
}

.header-msg .container-main {
    padding: 0;
}

.latest-news h3 {
    font-size: 14px;
    color: #fff;
    line-height: 0;
}



.header-msg {
    background: #EEA12A;
    /* padding: 12px 0; */
}

.news-logo {
    margin-right: 10px;
    width: 28px;
}

.news-logo img {
    width: 100%;
    display: block;
}


.header-msg p {
    font-family: 'Inter', sans-serif;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
}

.search-wrapper {
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 6px;
    max-width: 318px;
    position: relative;
}

.search-wrapper .search-icon {
    background: url(/assets/images/search-icon.svg) no-repeat;
    display: block;
    height: 18px;
    width: 18px;
    background-size: 18px auto;
}

.search-wrapper .search-btn {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0 10px;
}

.astrisk-sign{
    color: #ff0000;
}
.error-msg-detail{
    color: #ff0000;
    display: block;
    margin: -5px 0 5px 0;
}

.search-wrapper .form-control {
    color: #666666;
    width: 100%;
    border: 0;
    background-color: transparent;
    padding: 12px 20px 12px 40px;
}

.signup-login-wrapper {
    display: flex;
    align-items: center;
}

.signup-login-wrapper .main-btn {
    color: #808080;
    font-weight: 500;
    font-size: 13px;
    font-family: 'Inter',
        sans-serif;
    text-transform: capitalize;
    padding: 0;
    transition: all 0.5s;
}

.signup-login-wrapper .main-btn:hover {
    opacity: 0.7;
}

.signup-login-wrapper .login {
    display: block;
}

.mob-menu {
    width: 20px;
    background: transparent;
    display: block;
    border: 0;
    padding: 0;
}

.mob-menu .menu-bar {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-bottom: 3px;
    transition: all 0.2s;
}

.mob-menu .menu-bar:last-child {
    margin-bottom: 0;
}

.mob-menu.active .menu-bar:first-child {
    transform: rotate(45deg) translateY(1px) translateX(4px);
}

.mob-menu.active .menu-bar:first-child {
    display: none;
}

.mob-menu.active .menu-bar:first-child {
    transform: rotate(-45deg) translateY(-2px) translateX(4px);
}

/* Banner */
.grow-business-wrap {
    padding: 30px 0;
    background-color: #fff;
}

.box-wrap {
    border: 2px solid #F9BE07;
    border-radius: 10px;
}

.grow-business-wrap .btn-wrapper {
    display: flex;
}

.grow-business-wrap .btn-wrapper .box-btn {
    background: #EF7F1A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    text-transform: capitalize;
    padding: 16px 20px;
    max-width: 160px;
    width: 100%;
    font-weight: 700;
    border: 1px solid transparent;
    transition: all 0.5s;
}

.grow-business-wrap .btn-wrapper .box-btn:hover {
    background-color: transparent;
    color: #EF7F1A;
    border: 1px solid #EF7F1A;
}

.grow-business-wrap .btn-wrapper .login-btn {
    margin-right: 15px;
}

.grow-business-wrap h2 {
    font-size: 22px;
    margin-bottom: 20px;
}

.grow-business-wrap p {
    font-size: 13px;
    margin-bottom: 20px;
}

.grow-business-wrap .box-wrap {
    padding: 30px 20px;
}

.grow-business-wrap .ltl-box {
    /* margin-bottom: 30px; */
    order: 2;
}

.grow-business-wrap .rtl-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    order: 1;
}

/* platform-wrapper */
.platform-wrapper {
    padding: 30px 0;
    background-color: #fff;
}

.platform-wrapper h3 {
    font-weight: 400;
    font-size: 25px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #333333;
}

.logo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.company-logo {
    height: 96px;
    padding: 20px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #F9BE07;
}

.company-logo img {
    width: 100%;
    object-fit: contain;
    object-position: top;
    height: 100%;
}

.company-logo .logo-text {
    color: #CC4B4C;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Inter',
        sans-serif;
}

/* working-process */
.working-process {
    padding: 30px 0;
    background-color: #fff;
}

.working-process h4 {
    font-weight: 500;
    font-size: 24px;
    font-family: 'Inter';
    font-style: normal;
    color: #333333;
}

/* working-process */
.working-process h3 {
    font-weight: 400;
    font-size: 25px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    color: #333333;
}

.working-process h4 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 25px;
    text-align: center;
}

.working-process .work-img-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    /* border: 2px solid #F9BE07; */
    border-radius: 12px;
    /* padding: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 125px;
    height: 125px; */
    /* margin: 0 auto 30px; */
    padding: 0 30px;
}

.working-process .work-img-wrp img {
    border-radius: 12px;
}

.working-process .ltl-work {
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 6px;
    padding: 30px 15px;
    margin-bottom: 30px;
}

.working-process .rtl-work {
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 6px;
    padding: 30px 15px;
}

.working-process .container-main {
    max-width: 1200px;
}

.working-process .img-wrap {
    /* width: 61px; */
}

.working-process .img-wrap img {
    width: 100%;
    display: block;
}

/* our-customer */
.our-customer .box-wrap {
    justify-content: center;
}

.our-customer h3 {
    font-size: 23px;
    font-weight: 500;
    font-family: 'Inter',
        sans-serif;
    margin-bottom: 40px;
    text-align: center;
}

.our-customer p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    color: #808080;
}

.customer-img {
    width: 96px;
    margin: 0 auto 18px;
}

.customer-img img {
    width: 100%;
    display: block;
}

/* product-offering */
.product-offering {
    padding: 30px 0;
}

.product-offering h3 {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Inter',
        sans-serif;
    margin-bottom: 8px;
}

.product-offering p {
    font-family: 'Inter',
        sans-serif;
    color: #808080;
    font-size: 14px;
}

.product-desc {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 6px;
    padding: 12px;
}

.product-desc:last-child {
    margin-bottom: 0;
}

.product-offering .work-img-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border: 2px solid #F9BE07;
    border-radius: 12px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 85px;
    margin: 0;
}

.product-offering .img-wrap {
    width: 45px;
    flex-shrink: 0;
}

.product-offering .product-rtl {
    width: calc(100% - 100px);
}

/* subscribe-wrapper */
.subscription .subscribe-wrapper {
    padding: 30px 20px;
    border: 2px solid #F9BE07;
    border-radius: 10px;
}

.subscribe-wrapper .img-wrap {
    display: flex;
    justify-content: center;
    max-width: 280px;
    margin: 0 auto 35px;
}

.subscribe-wrapper .img-wrap img {
    border-radius: 12px;
    width: 100%;
}

.subscription h3 {
    font-size: 25px;
    font-weight: 500;
    font-family: 'Inter',
        sans-serif;
    margin-bottom: 18px;
    text-align: center;
}

.subscription p {
    font-family: 'Inter',
        sans-serif;
    color: #808080;
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
}

.search-form .form-control {
    padding: 14px 24px;
    border: 0;
    background: linear-gradient(0deg, rgba(128, 128, 128, 0.2), rgba(128, 128, 128, 0.2)),
        #FFFFFF;
    border: 2px solid rgba(128, 128, 128, 0.25);
    border-radius: 12px;
    color: #000;
    font-size: 14px;
    width: 100%;
    text-transform: capitalize;
    font-family: 'Inter',
        sans-serif;
}

.search-form .form-control::placeholder {
    color: rgba(51, 51, 51, 0.5);
}

.subscription .subscription-search-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.subscription .submit-btn {
    background: #EF7F1A;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    text-transform: capitalize;
    padding: 16px 20px;
    width: 100%;
    font-weight: 700;
    border: 1px solid transparent;
    transition: all 0.5s;
}

.subscription .submit-btn:hover {
    background-color: transparent;
    color: #EF7F1A;
    border: 1px solid #EF7F1A;
}

.main-form-wrap {
    max-width: 600px;
    margin: 0 auto;
}


.news-content-wrap {
    display: flex;
    align-items: center;
    width: 100%;
}

.latest-news .ltl-news {
    display: flex;
    align-items: center;
    width: 100%;
}

.box-wrap {
    display: flex;
    flex-direction: column;
}

.grow-business-wrap .rtl-box img {
    cursor: pointer;
    width: 100%;
    border-radius: 12px;
    display: block;
}


.grow-business-wrap.our-customer .slick-next {
    background-color: #EEA12A !important;
}

.grow-business-wrap.our-customer .slick-prev {
    background-color: #EEA12A !important;
}

/* .grow-business-wrap.our-customer .slick-prev:hover,
.slick-next:hover {
    background-color: #f86202 !important;
} */

.grow-business-wrap.our-customer .customer-img {
    width: 160px;
    margin: 0 auto 18px;
    height: 160px;
}

.grow-business-wrap.our-customer .customer-img img {
    width: 100%;
    display: block;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}

.our-customer h3 {
    font-size: 23px;
    font-weight: 500;
    font-family: 'Inter',
        sans-serif;
    margin-bottom: 40px;
    text-align: center;
    line-height: 1.0;
    text-transform: capitalize;
}

.popular-arrival .slick-arrow.slick-next {
    border: 2px solid rgba(128, 128, 128, 0.5);
    background: #fff url(/assets/images/arrival-slider-arrow.svg) no-repeat center !important;
    height: 20px !important;
    width: 20px !important;
    background-size: 16px auto !important;
    padding: 20px;
}

.popular-arrival .slick-arrow.slick-next:hover {
    border: 2px solid #ED9829;
}

.popular-arrival .slick-arrow.slick-prev {
    border: 2px solid rgba(128, 128, 128, 0.5);
    background: #fff url(/assets/images/arrival-slider-arrow.svg) no-repeat center !important;
    height: 20px !important;
    width: 20px !important;
    background-size: 16px auto !important;
    padding: 20px;
    transform: translateY(-50%) rotate(180deg);
}

.popular-arrival .slick-arrow.slick-prev:hover {
    border: 2px solid #ED9829;
}

/* .grow-business-wrap h2{
    
} */

@media all and (min-width:768px) {
    /* .grow-business-wrap .rtl-box .img-metal {
        width: 200px;
        margin: 0 auto;
    } */

    .logo-wrapper {
        justify-content: flex-start;
    }

    .primary-header .container-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navbar {
        margin-bottom: 0;
    }

    .news-header {
        display: flex;
    }

    .nav-menu a {
        padding: 0;
    }

    .primary-header .nav-menu {
        display: flex;
        margin-bottom: 0;
    }

    .mob-menu {
        display: none;
    }

    .nav-menu a {
        font-size: 13px;
    }

    .signup-login-wrapper .main-btn {
        font-size: 13px;
    }

    .header-msg p {
        font-size: 13px;
    }

    .latest-news h3 {
        font-size: 16px;
    }

    .primary-header .nav-menu li {
        margin-right: 12px;
    }

    .primary-header .logo {
        width: 120px;
    }

    .primary-header .navbar-wrapper {
        display: flex;
        align-items: center;
        position: static;
        background-color: transparent;
        border: 0;
        width: auto;
        padding: 0;
    }

    .grow-business-wrap .box-wrap {
        padding: 40px 30px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .box-wrap {
        border-radius: 24px;
    }

    .grow-business-wrap h2 {
        font-size: 30px;
    }

    .grow-business-wrap .ltl-box {
        width: 50%;
        padding-right: 30px;
        margin-bottom: 0;
        order: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .work-wrapper p {
        text-align: center;
    }

    .grow-business-wrap .rtl-box {
        width: 50%;
        order: 2;
        margin-bottom: 0;
        max-width: 400px;
        margin: 0 auto;
        padding-left: 30px;

    }

    .grow-business-wrap .btn-wrapper .box-btn {
        font-size: 15px;
    }

    .grow-business-wrap {
        padding: 50px 0;
    }

    /* platform-wrapper */
    .platform-wrapper h3 {
        font-size: 30px;
        margin-bottom: 40px;
    }

    .logo-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 2rem;
    }

    .company-logo .logo-text {
        font-size: 15px;
    }

    /*work-wrapper */
    .work-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .working-process .ltl-work {
        border-radius: 12px;
        width: 48%;
        max-width: 464px;
        margin-bottom: 0;
    }

    .working-process .rtl-work {
        border-radius: 12px;
        width: 48%;
        max-width: 464px;
    }

    .working-process h3 {
        margin-bottom: 50px;
    }

    /* our-customer */
    .our-customer .box-wrap {
        justify-content: center;
        padding: 30px;
        flex-direction: column;
    }

    .our-customer h3 {
        margin-bottom: 20px;
    }

    /* product-offering */
    .product-offering .product-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .product-desc {
        width: 49%;
    }

    /* subscribe-wrapper */
    .subscription .subscribe-wrapper {
        border-radius: 20px;
        padding: 50px;
    }

    .subscription .subscription-search-btn {
        display: block;
        margin-top: 0;
        max-width: 160px;
        width: 100%;
    }

    .subscription .search-form {
        display: flex;
        justify-content: center;
        max-width: 600px;
        width: 100%;
    }

    .subscription .form-block {
        margin-right: 24px;
        max-width: 380px;
        width: 100%;
    }

    /* .latest-news {
        position: relative;
        width: 24%;
    } */

    .news-header {
        position: relative;
    }

    .latest-news {
        width: 30%;
        height: 41px;
        /* position: relative; */
        /* z-index: 1;
        top: 0;
        left: 0; */
    }

    /* .latest-news ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
    } */


    .header-msg {
        height: 41px;
    }

    .news-content-wrap {
        justify-content: flex-end;
    }

    .latest-news .ltl-news {
        justify-content: flex-end;
        position: relative;
    }

    .latest-news .ltl-news::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-left: 7px solid #000;
        border-bottom: 7px solid transparent;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    .header-msg .container-main {
        display: flex;
        width: 100%;
    }

    .ticker-wrapper {
        padding-left: 30px;
    }

    .header-msg {
        position: relative;
    }

    .header-msg::after {
        content: "";
        position: absolute;
        background-color: #000;
        left: 0;
        width: 100vw;
    }

    .grow-business-wrap p {
        max-width: 550px;
        margin-bottom: 30px;
    }

    .grow-business-wrap.our-customer p {
        max-width: none;
    }

    .working-process .work-img-wrp {
        padding: 0 50px;
    }
}

@media all and (max-width:767px) {
    .grow-business-wrap h2 {
        text-align: center;
    }

    .grow-business-wrap p {
        text-align: center;
    }

    .grow-business-wrap .btn-wrapper {
        justify-content: center;
    }
}

@media all and (min-width:1024px) {
    .our-customer h3 {
        font-size: 25px;
    }

    .work-wrapper {
        padding: 0 70px;
    }

    .working-process .ltl-work {
        padding: 20px 40px;
    }

    .working-process .rtl-work {
        padding: 25px 40px;
    }

    .working-process h3 {
        /* margin-bottom: 100px; */
        font-size: 30px;
    }

    .product-desc {
        border-radius: 12px;
    }

    /* .grow-business-wrap .box-wrap {
        padding: 70px 30px;
    } */
}

@media all and (min-width:1200px) {
    .logo-wrapper {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 2rem;
    }

    /* 
    .company-logo:nth-child(8n) {
        margin-right: 0;
    } */

    .latest-news {
        width: calc(50% - 512px);
        min-width: 200px;
    }


    .nav-menu a {
        font-size: 18px;
    }

    /* .primary-header .navbar-wrapper {
        margin-left: 150px;
    } */

    .signup-login-wrapper .main-btn {
        font-size: 18px;
    }

    .header-msg p {
        font-size: 14px;
    }

    /* .header-msg {
        padding: 12px 5px 12px 5px;
    } */

    .primary-header .logo {
        width: 171px;
    }

    .navbar {
        margin-right: 30px;
    }

    .grow-business-wrap h2 {
        font-size: 36px;
    }

    .grow-business-wrap .btn-wrapper .box-btn {
        font-size: 15px;
    }

    .grow-business-wrap {
        padding: 50px 0;
    }

    /* 
    .grow-business-wrap .box-wrap {
        padding: 103px 74px;
    } */

    .company-logo {
        padding: 30px 20px;
    }

    .company-logo .logo-text {
        font-size: 18px;
    }

    .platform-wrapper h3 {
        font-size: 36px;
    }

    /* working-process */
    /* .working-process .work-img-wrp {
        width: 148px;
        height: 148px;
    } */

    .work-wrapper {
        padding: 0;
    }

    /* our-customer */
    .our-customer .box-wrap {
        justify-content: center;
        padding: 50px;
    }

    .our-customer h3 {
        margin-bottom: 30px;
    }


    .our-customer p {
        font-size: 20px;
    }

    /* product-offering */
    .product-desc {
        width: 32%;
        margin-bottom: 0;
    }

    .product-offering .work-img-wrp {
        width: 145px;
        height: 145px;
    }

    .product-offering .img-wrap {
        width: 61px;
        flex-shrink: 0;
    }

    .product-offering .product-rtl {
        width: calc(100% - 160px);
    }

    /* subscribe-wrapper */
    .subscription .subscribe-wrapper {
        border-radius: 24px;
    }

    .subscription p {
        font-size: 18px;
        margin-bottom: 48px;
    }

    /* subscribe-wrapper */
    /* .subscription .subscribe-wrapper {
        padding: 70px;
    } */

    /* .working-process h3 {
        font-size: 33px;
    } */
}

@media all and (min-width:1400px) {
    /* .primary-header .navbar-wrapper {
        margin-left: 200px;
    } */

    .our-customer h3 {
        margin-bottom: 40px;
    }

}


.platform-wrapper .company-logo {
    height: 80px;
    padding: 20px 15px;
}

.platform-wrapper .company-logo img {
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: 100%;
    border-radius: 6px;
}

.grow-business-wrap.our-customer p {
    text-align: center;
    margin: 0 auto;
}

@media all and (max-width:767px) {
    .nav-menu a {
        text-align: left;
        padding: 15px 0;
        border-bottom: 1px solid #80808033;
    }

    .nav-menu li:last-child a {
        border-bottom: 0;
    }

    .primary-header .navbar-wrapper {
        padding: 15px;
    }
}