.item-seller-info {
  display: flex;
  padding: 13px 85px 50px 75px;
  align-items: flex-start;
  max-width: 1920px;
  margin: 0 auto;
}

.option-container {
  display: flex;
  width: 95px;
  margin-right: 1.3%;
  /* height: 400px; */
  height: 402px;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

.option-container .slick-list {
  height: 408px !important;
}

.product-view {
  width: 100%;
  padding: 0;
  border: 1px solid #e6e6e6;
  position: relative;
}

/* .product-view .main-item div , .product-view .main-item{
  height: 100% !important;
} */
/* .product-view .main-item img{
  max-height: 485px;
} */
.sales-details {
  width: 100%;
  margin-right: 2.5%;
  font-family: "Roboto";
}

/* .option-container > .div {
} */
.option-container-item {
  margin-bottom: 0;
  width: 115px;
  height: 160px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
}

.option-container-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.main-item {
  width: 100%;
  height: auto;
}

.main-item>div>div>img {
  max-width: unset;
}

.item-name-detail {
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  color: #4a4a4a;
  margin-bottom: 15px;
  margin-top: 21px;
}

.item-name-detail .it-dtlsopen-collapse {
  margin-bottom: 11px;
  display: block;
}

.main-item img {
  width: 100%;
}

.product-name {
  font-size: 32px;
  color: #4a4a4a;
  font-weight: 300;
  margin-bottom: 10px;
}

.buy-offer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  flex-direction: column;
  padding: 15px 0;
  margin-bottom: 15px;
}

.buy-offer span {
  color: #d16d38;
  display: block;
  margin: 15px 2px;
  font-weight: 600;
  font-size: 18px;
}

.buy-now-btn {
  border: 2px solid #000000;
  background: #ebddfe;
  border-radius: 40px;
  top: 0;
  left: 0;
  transition: all 0.15s linear 0s;
  position: relative;
  display: inline-block;
  color: #000000;
  box-shadow: 6px 6px 0 #707070;
  font-size: 15px;
  height: 54px;
}

.buy-now-btn:after,
.make-offer-btn:after {
  content: "";
  position: absolute;
  right: -9px;
  bottom: -10px;
  border: 2px solid #000000;
  padding: 23px 50.5% 29px;
  border-radius: 40px;
  z-index: -1;
}

.buy-now-btn:hover,
.make-offer-btn:hover {
  top: 3px;
  left: -3px;
  box-shadow: 0px 0px 0 #707070;
}

.buy-now-btn:hover:after,
.make-offer-btn:hover:after {
  display: none;
}

/* .buy-now-btn.disabled{
  opacity: 0.5;
  cursor: not-allowed;
} */
.make-offer-btn {
  width: 49%;
  height: 71px;
  border: 2px solid #000000;
  background: #ebddfe;
  border-radius: 40px;
  top: 0;
  left: 0;
  transition: all 0.15s linear 0s;
  position: relative;
  display: inline-block;
  color: #000000;
  box-shadow: 6px 6px 0 #707070;
  font-size: 15px;
  height: 54px;
}

/* .make-offer-btn.disabled{
  opacity: 0.5;
  cursor: not-allowed;
}
.make-offer-btn:hover {
  background: none;
  color: #d16d38;
  border-color: #d16d38;
} */
.product-verification-btn {
  margin-right: 10px;
  font-size: 18px;
  text-transform: capitalize;
  color: #4a4a4a;
  font-family: "Open Sans", sans-serif;
}

.reference-link {
  text-align: left;
  text-decoration: underline;
  font-weight: 500;
  letter-spacing: 0px;
  color: #a292b3;
  font-size: 20px;
  font-family: "poppins", sans-serif;
  text-transform: uppercase;
}

.seller-info .info-title h4 {
  padding-bottom: 15px;
}

.seller-info .vrify-icn {
  display: inline-block;
  vertical-align: top;
  margin-right: 7px;
}

.seller-info .trend-gallery {
  margin: 0 auto 15px;
}

.seller-info .info-wrap {
  padding-top: 1px;
  padding-bottom: 20px;
}

.seller-text {
  color: #828282;
  font-size: 22px;
  margin-bottom: 15px;
  display: inline-block;
  position: relative;
}

.seller-text span {
  display: none;
  position: absolute;
  top: -18px;
  left: 0;
  font-size: 10px;
  white-space: nowrap;
  color: #fff;
  background-color: #4a4a4a;
  padding: 3px 7px;
  border-radius: 15px;
}

.seller-text:hover span {
  display: block;
}

.seller-text2 {
  color: #4a4a4a;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

.rating-list {
  display: flex;
}

.rating-list li {
  margin-right: 6px;
}

.icon-star {
  width: 36px;
  height: 36px;
  background-position: -55px -248px;
}

.icon-star.active {
  background-position: -10px -248px;
  background-color: transparent;
}

.verify-title,
.similar-product-caption h2 {
  font-size: 64px;
  font-family: "Ogg";
  color: #d2703c;
  font-weight: 500;
  line-height: 1.2;
}

.details-dropdown {
  margin-top: 15px;
  border-top: 1px solid #cecece;
}

.last-chance .similar-product-caption {
  width: 22%;
}

/* verify-product start */
.verify-product {
  padding: 46px 0;
  border-top: 1px solid #cecece;
  background: #fff5f5;
}

.verify-title {
  margin-bottom: 50px;
  line-height: 1.3;
}

.verify-product .info {
  width: 49%;
  padding: 90px 82px;
  text-align: center;
}

.verify-product .flip-box {
  background: #707070;
  border: 2px solid #000000;
  padding-bottom: 15px;
  border-radius: 20px;
  position: relative;
  min-height: 400px;
  max-width: 375px;
  margin: 0 auto 20px auto;
  transition: 0.8s;
}

.flip-box-inner {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 98%;
  height: 99%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  height: 99%;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}

.flip-box-back {
  background-color: #ebddfe;
  color: white;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  border-radius: 18px;
  overflow: auto;
}

.flip-back-wrap .post-ques-btn {
  margin-bottom: 20px;
  padding: 8px 12px;
  font-size: 13px;
}

.flip-back-wrap .search-box {
  font-size: 13px;
}

.flip-back-wrap .error-msg {
  color: #141414;
  font-weight: 600;
}

.flip-back-wrap .ques-cards {
  color: #141414;
  margin-bottom: 10px;
}

.flip-back-wrap .cards-wrap {
  padding-right: 0;
  text-align: left;
}

.verify-product .flip-box-front {
  background: #ebddfe;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  border: 2px solid #000000;
  border-radius: 20px;
}

.flip-box-front h2 {
  color: #e57235;
  font-size: 36px;
  text-align: center;
  font-family: "Ogg";
  font-weight: "roman";
  text-decoration: underline;
}

.question-info:first-child:after {
  content: "";
  width: 2px;
  height: 45%;
  position: absolute;
  bottom: 0;
  top: -28px;
  left: 0;
  right: 0;
  margin: auto;
  background: #d16d38;
}

.verify-product .info figure {
  background: url(/assets/images/ipad-frame.svg) no-repeat;
  background-size: 100% 147%;
  background-position: center center;
  width: 700px;
  height: 473px;
  padding: 30px 67px 30px 81px;
  margin: 0 auto;
}

.verify-product .info:first-child {
  background: #f7d9b7;
}

.verify-product .info:last-child {
  background: #f7f5f5;
  padding: 60px 82px;
}

.verify-product .verify-wrap {
  display: flex;
  justify-content: space-between;
}

.verify-product .explore-membership {
  position: static;
}

.verify-product h3 {
  font-size: 40px;
  color: #4a4a4a;
  font-family: "Ogg";
  margin: 0 0 30px;
  font-weight: 300;
}

/* verify-product end */
/* Breadcrumb start */
.breadcrumb-wrap {
  padding: 20px 0 20px;
  /* border-top: 1px solid #cecece; */
  max-width: 1920px;
  margin: 0 auto;
}

.breadcrumb-wrap li,
.breadcrumb-wrap a {
  font-size: 16px;
  color: #999999;
  text-decoration: none;
  font-family: "Roboto";
}

.breadcrumb-wrap li {
  color: #4c4c4c;
}

.breadcrumb-wrap a:hover {
  color: #d16d38;
}

.breadcrumb-wrap li {
  display: inline-block;
  padding: 0 10px;
  position: relative;
  text-decoration: underline;
}

.breadcrumb-wrap li:after {
  position: absolute;
  right: -3px;
  content: "/";
  color: #4a4a4a;
  top: 0;
}

.breadcrumb-wrap li:last-child:after {
  display: none;
}

.breadcrumb-wrap .back-arrow {
  quotes: 8px;
  height: 14px;
}

/* Breadcrumb end */
.product-clr {
  color: #a3a5a7;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: "Roboto";
  margin-bottom: 10px;
}

.product-clr .product-verification-btn {
  margin: 0 0 0 10px;
  width: auto;
  min-width: 52px;
  color: grey;
  font-weight: 600;
}

.product-clr .product-verification-btn.active {
  border-color: #4a4a4a;
}

.item-seller-info .verify-txt {
  color: #828282;
  font-size: 20px;
  font-weight: 500;
  font-family: "poppins", sans-serif;
  margin-bottom: 20px;
}

.verification-btn-wrap {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #cecece;
}

.details-dropdown .droplink {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  font-family: "poppins", sans-serif;
  color: #707070;
  border-bottom: 1px solid #cecece;
  padding: 25px 0;
}

.details-dropdown i {
  position: relative;
  transform: rotate(-90deg);
  height: 14px;
  width: 14px;
}

.details-dropdown .open-collapse i {
  transform: rotate(90deg);
}

.ReactCollapse--content {
  padding: 25px 0;
  font-size: 16px;
  font-family: "poppins", sans-serif;
  line-height: 1.4;
  color: #707070;
  border-bottom: 1px solid #cecece;
}

.size-wrap .popup-wrap {
  position: absolute;
}

.size-popup .popup-sec {
  height: 100%;
}

.size-popup .popup-block {
  height: auto;
  overflow: visible;
}

.size-chart h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.size-chart img {
  width: 100%;
}

/* Make an offer popup start */

.make-offer-popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 1;
  background: rgba(0, 0, 0, 0.32);
  justify-content: center;
  align-items: center;
  display: flex;
  overflow-x: auto;
}

.make-offer-popup .offer-content {
  width: 100%;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 9px;
  padding: 15px;
  position: relative;
  text-align: center;
}

.make-offer-popup .offer-content .explore-membership {
  position: static;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  min-width: 220px;
  display: block;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

.make-offer-popup.custom-popup .offer-content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.make-offer-popup .offer-content .explore-membership:hover {
  background: transparent;
  color: #d16d39;
  border: 1px solid #d16d39;
}

.modal-closeBtn {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background-color: transparent;
  color: #c2c1c1;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
  outline: transparent solid 1px;
  cursor: pointer;
  z-index: 800;
  font-size: 25px;
  width: 25px;
  height: 33px;
}

.make-offer-popup.report-popup .modal-closeBtn {
  top: 7px;
}

.closeBtn-crossLeft,
.closeBtn-crossRight {
  display: block;
  position: absolute;
  left: 50%;
  top: 43%;
  width: 1px;
  height: 17px;
  border: none;
  background-color: #ababab;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
}

.closeBtn-crossLeft {
  transform: rotate(45deg) translate3d(-50%, -50%, 0);
  left: calc(50% - 3px);
  top: 36%;
}

.closeBtn-crossRight {
  transform: rotate(-45deg) translate3d(-50%, -50%, 0);
  left: calc(53% + 8px);
}

.make-offer-popup.seller-product-wrapper .closeBtn-crossRight {
  /* transform: rotate(-45deg) translate3d(-50%, -50%, 0); */
  left: calc(53% + 9px);
  top: 34%;
}

.make-offer-popup .offer-title {
  display: block;
  padding: 0 0 15px;
  margin-bottom: 15px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #333333;
}

.offer-title span {
  display: block;
  margin-bottom: 20px;
}

.offer-title .character-text {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 7px;
  margin-top: -12px;
  text-align: left;
}

.make-offer-popup .offer-desc {
  line-height: 21px;
  margin-bottom: 40px;
  font-size: 14px;
  font-family: "poppins", sans-serif;
  font-weight: 500;
}

.make-offer-popup .offer-price>span {
  color: #f47900;
  letter-spacing: 1.3px;
}

.make-offer-popup .offer-info {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.make-offer-popup .offer-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 193px;
  height: 193px;
  padding: 20px;
  margin-right: 26px;
  /* background: #f5f6f8; */
  object-fit: cover;
  padding: 0;
  border-radius: 13px;
}

.make-offer-popup .offer-txt {
  color: #282828;
  margin-left: calc(180px + 26px);
  font-size: 15px;
  font-family: "poppins", sans-serif;
  line-height: 1.4;
  text-align: left;
  font-weight: 500;
}

.make-offer-popup .offer-txts {
  font-weight: 700;
  font-size: 20px;
}

.make-offer-popup .offer-price {
  margin-left: calc(180px + 26px);
  overflow: hidden;
  position: relative;
  padding-top: 5px;
}

.offer-input-box label {
  width: 100%;
  text-transform: capitalize;
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
  font-family: "poppins", sans-serif;
}

.make-offer-popup .offer-price:before {
  display: block;
  /* content: ""; */
  position: absolute;
  height: 1px;
  background: #ebebeb;
  top: 0;
  left: 0;
  width: 110px;
  right: auto;
}

.shipping-inner-wrap {
  margin-top: 23px;
}

.shipping-inner-wrap>span {
  font-family: "poppins", sans-serif;
  font-size: 17px;
  display: block;
  margin-bottom: 20px;
}

.make-offer-popup .offer-input {
  margin-bottom: 7px;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  border: 1.5px solid rgba(128, 128, 128, 0.5);
  border-radius: 4px;
  max-width: 150px;
  padding: 0 10px;
}

.make-offer-popup .range-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ED9829;
}

.make-offer-popup .input-group {
  padding: 10px 0;
  display: block;
  position: relative;
  flex: auto;
  text-align: left;
  padding-left: 10px;
}

.make-offer-popup .input-group input {
  background: 0 0;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #666666;
}

.make-offer-popup .offer-sign {
  white-space: nowrap;
  position: relative;
  vertical-align: middle;
  font-family: 'Inter';
  font-style: normal;
  /* font-weight: 700; */
  font-size: 20px;
  color: #666666;
}

.offer-inserted-btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  white-space: nowrap;
  transition: all 0.3s ease;
  font-family: "poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
  /* background: #ebebeb;
  border-color: #ebebeb;
  color: #ababab; */
  width: 100%;
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  border: 1px solid #f47900;
  background: #f47900;
  color: #fff;
  transition: all 0.5s ease-in-out;
  border-radius: 8px;
  margin-top: 25px;
}

/* .offer-inserted-btn:hover {
  border: 1px solid #d16d38;
  background: transparent;
  color: #d16d38;
} */

.offer-btn-diable {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  white-space: nowrap;
  transition: all 0.3s ease;
  font-family: "poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: transparent;
  color: #f47900;
  /* border-color: #f47900; */
  border: 1px solid #f47900;
  width: 100%;
  height: 48px;
  padding: 0 24px;
  cursor: default;
  margin-top: 25px;
}

/* .offer-btn-diable:hover {
  background: #f47900;
  border: 1px solid #f47900;
  color: #fff;
} */

.seller-space {
  margin-bottom: 30px;
}

.seller-spacewrap {
  padding: 0 20px;
}

.verify-product .container {
  padding: 0 45px 0 45px;
}

.product-sellerwrap .last-chance .container {
  padding: 0 45px 0 45px;
}

.product-view .main-item div {
  margin: 0 auto;
  text-align: center;
}

/* .partition-line {
  border-top: 2px solid #e6e6e6;
  display: block;
  margin-bottom: 5px;
} */
/* .productfit-imagewrap{
  min-width: 505px!important;
  min-height: 745px!important;
  margin: 0 auto!important;
  max-width: 510px!important;
  width: auto!important;
  height: auto!important;
}
.productfit-imagewrap img{
  width: 100%!important;
  height: 100%!important;
  object-fit: contain;
} */
.availability-info {
  position: absolute;
  top: 0;
  left: 0;
  text-transform: uppercase;
  font-size: 20px;
  padding: 15px 35px;
  color: #a292b3;
  font-weight: 700;
  background: #f7f5f5;
}

.season-wrap {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.season-info {
  display: block;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #707070;
}

.icon-season {
  background-position: -10px -305px;
  background-size: 450px 350px;
  width: 39px;
  height: 9px;
}

.season-wrap i {
  width: 39px;
  height: 9px;
  margin-left: 10px;
  transform: rotate(0deg);
}

.product-details-name .free-shipping {
  margin-left: 5px;
}

.rating-date {
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
}

.item-seller-info .seller {
  width: 25%;
}

.item-seller-info .seller-reviews {
  width: 75%;
  display: flex;
  flex-direction: row;
}

.item-seller-info .seller-reviews .seller-review-card {
  border: 1px solid #b6b6d7;
  display: "flex";
  flex-direction: "column";
  width: 28%;
}

.product-name {
  font-weight: 600;
  font-size: 29px;
  font-family: "Roboto";
}

.item-name-detail .desc {
  font-size: 16px;
  font-family: "Roboto";
}

.item-name-detail .desc.desc-open {
  height: auto;
}

.item-name-detail i {
  position: relative;
  transform: rotate(-90deg);
  height: 14px;
  width: 14px;
}

.item-name-detail .open-collapse i {
  transform: rotate(90deg);
  position: relative;
  height: 14px;
  width: 14px;
}

.item-name-detail .droplink {
  cursor: pointer;
}

.seller-prof-img {
  width: 90px;
  height: 90px;
  border-radius: 90px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.16);
}

.complete-purchase.disablebutton {
  border: 0;
}

.product-sellerwrap .costume-box .costume-info {
  padding: 17px 10px;
}

.product-sellerwrap .costume-box .costumes {
  height: 157px;
}

.fontSmall {
  font-size: 14px;
}

.share-icon-group i {
  color: #c8c6c6;
}

.updated-bar {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.updated-time,
.reports {
  color: #999999;
  font-size: 18px;
  text-transform: capitalize;
  font-family: "Roboto";
}

.reports {
  display: flex;
  align-items: center;
}

.report-btns {
  display: flex;
  align-items: center;
}

.report-btns img {
  max-width: 19px;
}

.report {
  margin-top: 3px;
  margin-left: 3px;
}

.slide-box-wrap {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-right: 3.5%;
  position: sticky;
  top: 10px;
  z-index: 1;
}

.des-wrap {
  display: flex;
  width: 51%;
  padding-right: 60px;
  position: relative;
}

.seller-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  position: relative;
}

.about-seller h4 {
  margin-bottom: 30px;
  font-family: "Roboto";
  font-weight: 500;
}

.about-seller h4.info-title {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 5px;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.5s;
}

.about-seller h4.info-title :hover {
  color: #f86202;
}

.tittle-wrap {
  padding-left: 15px;
}

.media-seller li {
  cursor: pointer;
  display: flex;
  background: #9983a0;
  height: 30px;
  width: 30px;
  border-radius: 40px;
  padding: 5px 0;
  text-align: center;
  color: #fff;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
}

/* By Sujay(1-Sept-2021) 
Purpose - Change in Hover Color of Seller Facebook , 
Mail and Contact Icons*/
.media-seller li:hover {
  background: #d60e96;
}

/* .fab {
color: #fff;
}
.fas {
  color: #fff;
  } */
/*End*/
.confirmed {
  /* display: flex; */
  display: none;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.confirmed span {
  font-size: 18px;
}

.sell-items span,
.rating-review-wrap span {
  font-size: 16px;
  font-family: "Roboto";
}

.sell-items strong {
  font-weight: 600;
}

.media-seller {
  display: flex;
}

.rating-review-wrap {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  align-items: center;
}

.view-seller-wrap .rating-review-wrap {
  justify-content: flex-start;
}

.ratings span {
  font-size: 16px;
  cursor: pointer;
}

.rating-review-wrap span b {
  font-weight: 600;
}

.rating-review-wrap span {
  margin-left: 10px;
}

.rating-review-wrap span:first-of-type {
  margin-left: 0 !important;
}

.price {
  display: flex;
}

.it-dtls {
  font-weight: bold;
  font-size: 21px;
  margin-top: 13px;
  line-height: 46px;
  display: block;
  margin-bottom: 4px;
}

.buy-now {
  display: flex;
  align-items: center;
  background: #efefef;
  padding: 5px 20px;
  border-radius: 5px;
  margin-bottom: 25px;
  justify-content: center;
}

.buy-now span {
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
}

.shop-now {
  font-family: "Roboto";
  letter-spacing: 0.4px;
}

.buy-now a {
  margin-left: 10px;
}

.like-item {
  padding-left: 50px;
  position: relative;
  font-family: "Roboto";
}

.like-item i {
  position: absolute;
  top: 4px;
  left: 0;
}

.like-item strong,
.like-item span {
  font-size: 15px;
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
}

.like-item span {
  font-size: 14px;
  font-weight: 300;
}

/* Make an offer popup End */
.location-text {
  display: flex;
  flex-wrap: wrap;
}

.header-address .deliver-text {
  width: 100%;
  font-size: 10px;
}

.location-mark i {
  font-weight: 500;
  margin-right: 5px;
}

@media all and (min-width: 1700px) {
  .buy-now-btn:after {
    padding: 23px 50% 29px;
  }

  .option-container-item {
    height: 96px;
    width: 95px;
  }
}

@media only screen and (max-width: 1699px) {
  .product-name {
    font-size: 26px;
  }

  .details-dropdown .droplink,
  .item-seller-info .verify-txt,
  .product-clr,
  .item-name-detail {
    font-size: 16px;
  }

  .make-offer-btn,
  .buy-now-btn {
    font-size: 15px;
    height: 54px;
  }

  .product-verification-btn {
    font-size: 16px;
    border-width: 2px;
  }

  .reference-link {
    font-size: 14px;
  }

  .seller-info .info-title h4 {
    font-size: 20px;
  }

  .seller-text {
    font-size: 18px;
    text-align: left;
    line-height: 1.4;
  }

  .seller-spacewrap {
    padding: 0 15px;
  }

  .verify-title,
  .similar-product-caption h2 {
    font-size: 40px;
  }

  .verify-title {
    font-size: 42px;
  }

  .verify-product .info figure {
    width: 600px;
    height: 407px;
    padding: 25px 57px 22px 67px;
  }

  .rating-list li {
    margin-right: 1px;
  }

  /* .productfit-imagewrap{
    min-height:545px!important;
    max-width: 410px!important;
    min-width: 410px!important;
  } */
}

@media only screen and (max-width: 1499px) {
  /* .breadcrumb-wrap {
    padding: 20px 4% 20px 2%;
  } */

  .product-name,
  .it-dtls,
  .about-seller h4 {
    font-size: 18px;
  }

  .confirmed span,
  .product-clr,
  .product-verification-btn,
  .item-name-detail .desc,
  .updated-time,
  .reports {
    font-size: 14px;
  }

  .about-seller h4.info-title {
    font-size: 16px;
  }

  .verify-product .info:last-child {
    background: #f7f5f5;
    padding: 60px 30px;
  }

  .verify-product .info figure {
    width: 513px;
    height: 348px;
    padding: 20px 48px 20px 56px;
  }

  /* 10 nov */


  .sales-details {
    width: 100%;
  }

  .seller-info {
    /* width: 22%; */
    width: auto;
  }

  .rating-list li {
    margin-right: 6px;
  }

  .product-sellerwrap .last-chance {
    padding-bottom: 50px;
  }

  .verify-product .info {
    padding: 90px 40px;
  }

  /* .productfit-imagewrap {
    min-height: 305px!important;
    max-width: 295px!important;
    min-width: 295px!important;
  } */
  .option-container-item {
    width: 101% !important;
  }

  .popup-block h3 {
    margin-bottom: 25px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 1365px) {

  /* .breadcrumb-wrap {
    padding: 20px 35px 20px 35px;
  } */
  .item-seller-info {
    padding: 13px 4% 60px 4%;
  }
}

@media only screen and (max-width: 1240px) {

  /* .breadcrumb-wrap {
    padding: 20px 25px 20px 25px;
  } */
  .item-seller-info {
    padding: 13px 4% 50px 4%;
  }
}

@media only screen and (max-width: 1199px) {
  .seller-info .info-title h4 {
    font-size: 18px;
  }

  .seller-text {
    font-size: 16px;
  }

  .seller-info .ratting-img {
    max-width: 90%;
  }

  .seller-text2 {
    font-size: 16px;
  }

  .verify-product .info:last-child,
  .verify-product .info {
    padding: 60px 5%;
  }

  .verify-title,
  .similar-product-caption h2 {
    font-size: 32px;
  }

  .verify-product h3 {
    font-size: 32px;
  }

  .ReactCollapse--content {
    font-size: 14px;
  }

  .verify-product .info figure {
    width: 388px;
    height: 264px;
    padding: 15px 37px 15px 40px;
  }

  /* 10 nov */
  .item-seller-info {
    padding: 13px 4% 25px;
  }

  /* .breadcrumb-wrap {
    padding: 20px 85px 20px 75px;
  } */

  .productfit-imagewrap {
    min-width: 175px !important;
  }

  .rating-list li {
    margin-right: 2px;
  }

  .verify-product .container,
  .ques-ans-wrapper,
  .product-sellerwrap .last-chance .container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .product-fitimage {
    width: 305px !important;
    min-width: 305px !important;
    object-fit: contain !important;
  }
}

@media only screen and (max-width: 991px) {
  .item-seller-info {
    flex-flow: wrap;
  }

  .product-view {
    width: 75%;
    margin-bottom: 80px;
    margin-right: 0;
  }

  .verify-title,
  .similar-product-caption h2 {
    font-size: 26px;
  }

  .verify-product .info figure {
    width: 300px;
    height: 205px;
    padding: 15px 28px 15px 34px;
  }

  .productfit-imagewrap {
    min-height: 210px !important;
  }

  .seller-info {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .make-offer-popup .offer-input {
    max-width: none;
  }

  .mobile-header-address.header-address {
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 110px;
    border-radius: 0;
    padding: 8px 17px;
    background: #622bc5;
    color: #fff;
    position: static;
    margin-top: 49px;
  }

  .location-mark {
    min-height: auto;
    width: 100%;
  }

  .location-text {
    width: 100%;
    flex-wrap: initial;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1.1px;
  }

  .header-address .deliver-text {
    width: auto;
    margin-right: 3px;
    font-size: 13px;
  }

  .location-mark i {
    font-size: 20px;
    margin-right: 9px;
  }

  .header-address .delivery-text {
    max-width: none;
  }

  .deliver-zip {
    margin-left: 3px;
  }

  .option-container .slick-list {
    height: auto !important;
  }

  .multiple-items .summary-multi-bill .bill-price-list {
    text-align: center;
    margin-bottom: 15px;
  }

  .multiple-items .order-summary-multi h3 {
    text-align: left;
  }

  .multiple-items .order-summary-multi h6 {
    text-align: left;
  }

  .multiple-items .summary-multi-bill .bill-heading {
    width: 60%;
    font-size: 14px;
    text-align: left;
  }

  .multiple-items .items-table-wrap {
    padding: 0;
  }

  .multiple-items .summary-multi-bill .bill-price {
    margin-top: 7px;
    width: 40%;
    text-align: center;
    font-size: 14px;
  }

  .slide-box-wrap {
    position: static;
    z-index: unset;
  }

  .option-container-item {
    width: 70px;
    height: 100px;
  }

  .sales-details,
  .seller-info {
    width: 100%;
  }

  .verify-product .verify-wrap {
    display: block;
  }

  .verify-product .info {
    width: 100%;
    margin-bottom: 20px;
  }

  .last-chance .similar-product-caption {
    width: 100%;
    text-align: center;
  }

  .make-offer-popup .offer-content {
    height: 100%;
    min-height: auto;
    height: auto;
    padding: 20px 16px;
    width: 100%;
  }

  .right-prof-sec .make-offer-popup .offer-content {
    width: 94%;
  }


  .register-popup form {
    padding-bottom: 28px;
  }

  /* .make-offer-popup .offer-title {
    padding: 29px 0 34px;
  } */
  .make-offer-popup .offer-desc {
    line-height: 16px;
    margin-bottom: 16px;
    font-size: 13px;
  }

  .make-offer-popup .offer-img {
    width: 60px;
    height: 60px;
    margin-right: 18px;
    background: transparent;
    border-radius: 7px;
  }



  .offer-input-box {
    margin-top: 8px;
  }

  .make-offer-popup .offer-txt {
    font-size: 13px;
    line-height: 20px;
    margin-left: calc(66px + 7px);
  }

  .make-offer-popup .offer-price {
    margin-left: 0;
  }

  .make-offer-popup .offer-price:before {
    width: 100%;
  }

  .option-container {
    margin-right: 0;
  }

  .product-view {
    width: 70%;
    margin-bottom: 0;
  }

  .slide-box-wrap,
  .des-wrap {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
  }

  .about-seller,
  .post-ques-btn {
    margin-bottom: 20px;
  }

  .seller-wrap {
    flex-direction: column;
  }

  .profile-wrapper .wishlist-section .plp-product-screen {
    max-width: 305px;
    margin: 0 auto;
  }

  .des-wrap {
    padding-top: 4px;
  }

  .navigation-screen .attr-elements .slct-optns {
    width: 100% !important;
    margin-right: 0 !important;
  }
}

@media all and (min-width: 575px) {
  .make-offer-btn:after {
    right: -9px;
    padding: 23px 50% 29px;
  }
}

@media only screen and (max-width: 575px) {
  .verify-product .info figure {
    width: 260px;
    height: 180px;
    padding: 11px 24px 11px 30px;
  }

  .wrap-delivery {
    flex-flow: wrap;
  }

  .offered-popup-wrap.make-offer-popup .offer-title:after {
    width: 110%;
  }
}



/* more-btn */
.more-btn-wrap {
  text-align: center;
  padding: 15px 0;
}

.more-btn-wrap .more-btn {
  color: #d16d38;
  padding: 10px 25px;
  border-radius: 3px;
  transition: all 0.5s ease-in-out;
  font-size: 16px;
}

/* -- question-answer -- */
.ques-ans-wrapper {
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  padding: 50px 45px 50px 45px;
  margin-bottom: 40px;
}

.ques-ask-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*display: none;*/
}

.ques-ans-wrapper h3 {
  font-family: "Ogg";
  color: #d2703c;
  font-size: 40px;
  font-weight: normal;
  margin-bottom: 0;
}

.post-ques-btn {
  padding: 10px 20px;
  color: #f86202;
  font-size: 15px;
  font-weight: 500;
  font-family: "poppins", sans-serif;
  border: 1px solid #f86202;
  transition: all 0.25s ease-in-out;
  border-radius: 5px;
}

.post-ques-btn:hover {
  background-color: #f86202;
  color: #ffffff;
}

.post-ques-btn i {
  vertical-align: middle;
  margin-right: 8px;
}

/* .question-wrapper{
  border-bottom: 1px solid #cecece;
} */
.question-wrapper h3 {
  padding: 0px 0 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #cecece;
}

.ques-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.search-option {
  width: calc(100% - 325px);
  border: 1px solid #cecece;
  border-radius: 5px;
  position: relative;
}

.search-box {
  width: 100%;
  border: 0;
  padding: 15px 20px 15px 50px;
  border-radius: 5px;
  color: #4a4a4a;
  font-weight: 300;
  border: 0;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}

.search-option .icon-search {
  position: absolute;
  left: 15px;
  top: 15px;
}

.error-msg {
  width: 100%;
  padding: 25px;
  /*display: none;*/
}

.ques-cards {
  border: 1px solid #cecece;
}

.cards-wrap {
  padding: 15px;
  border-bottom: 1px solid #cecece;
  position: relative;
  padding-right: 150px;
}

.cards-wrap:last-child {
  border-bottom: none;
}

.cards-wrap .question {
  font-weight: 600;
}

.cards-wrap p {
  margin-bottom: 5px;
}

.cards-wrap p span {
  margin-right: 5px;
  display: inline-block;
  font-weight: 600;
}

.cards-wrap .user-name {
  color: #968f8f;
  font-weight: 500;
  font-size: 13px;
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 15px;
}

.other-ans {
  color: #d16d38;
  display: block;
  font-weight: 700;
}

.post-ans {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #d16d38;
  text-decoration: underline;
  font-weight: 700;
  transition: all 0.5s ease-in-out;
}

.post-ans:hover {
  transform: scale(1.1);
}

/* popup */
.popup-wrap {
  height: 100%;
  width: 100%;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 30;
  background: rgba(0, 0, 0, 0.75);
  overflow-y: auto;
}

.popup-sec {
  max-width: 600px;
  padding: 45px;
  margin: 70px auto 50px;
  background: #fff;
  position: relative;
  border: 1px solid #707070;
  overflow: auto;
}

.popup-cards {
  height: 100%;
  overflow: auto;
  border: 1px solid #cecece;
}

.popup-block {
  height: 100%;
  overflow: auto;
  border: none;
  padding: 0;
}

.field-wrap {
  width: 100%;
  height: 130px;
  border: 1px solid #cecece;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;
}

.field-input {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 15px 20px;
  border-radius: 5px;
  color: #4a4a4a;
  font-weight: 300;
  border: 0;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}

.post-btn-wrap {
  padding: 10px 20px;
  background-color: #d16d38;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "poppins", sans-serif;
  border: 1px solid #d16d38;
  transition: all 0.25s ease-in-out;
  float: right;
  clear: both;
  border-radius: 40px;
}

.post-btn-wrap:hover {
  background-color: transparent;
  color: #d16d38;
}

.popup-sec .close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.popup-sec .close-btn i {
  font-size: 26px;
}

.popup-sec .close-btn .icon-close {
  position: static;
}



#more-ans-popup {
  display: block;
}



/* responsive */
@media only screen and (max-width: 1199px) {
  .ques-ans-wrapper h3 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 991px) {
  .ques-ans-wrapper h3 {
    font-size: 26px;
    margin-bottom: 15px;
  }

  .ques-ask-wrap {
    display: block;
  }

  .search-option .icon-search {
    left: 10px;
  }

  .search-option {
    width: 100%;
    margin-bottom: 15px;
  }

  .ques-block {
    display: block;
  }

  .popup-sec {
    width: 100%;
  }

  .popup-block {
    padding: 0;
    padding-top: 15px;
  }

  .popup-sec .close-btn {
    top: 0;
    right: 20px;
  }

  .popup-sec .close-btn .icon-close {
    background-position: -148px -46px;
    background-size: 511px;
  }
}

@media (max-width: 575px) {
  .ques-ans-wrapper {
    padding: 50px 25px 50px 25px;
  }

  .ques-ans-wrapper h3 {
    line-height: 1.2;
  }

  .flip-back-wrap h3 {
    font-size: 20px;
  }
}

@media all and (min-width: 475px) {
  .season-wrap {
    padding: 35px 0;
  }

  .season-wrap i {
    margin-left: 35px;
  }

  .season-wrap:hover {
    transform: rotate(0) scale(1.1) translateZ(0);
  }

  .flip-back-wrap h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .flip-back-wrap .search-option {
    width: 100%;
    margin-bottom: 20px;
  }

  .flip-back-wrap .ques-block {
    justify-content: center;
  }
}

@media all and (min-width: 1024px) {
  .dual-order .dual-content-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .verify-product .flip-box {
    max-width: 672px;
    width: 40%;
  }
}

@media all and (min-width: 768px) {
  .header-address .deliver-text {
    width: 100%;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .updated-bar {
    display: flex;
  }

  .flip-box {
    max-width: 672px;
    width: 46%;
  }

  .product-view {
    width: calc(100% - 110px);
  }

  .slide-box-wrap {
    /* overflow: hidden; */
    width: 100%;
    display: block;
    margin-right: 0;
  }

  .pro-slide-wrap {
    width: 45%;
    order: 1;
    float: left;
    margin-bottom: 0;
    position: sticky;
    z-index: 9999;
  }


  .updt-info-wrp {
    width: 100%;
    order: 3;
    float: left;
  }

  .slide-box-wrap .des-wrap {
    order: 2;
    float: right;
    padding-right: 0;
  }

  .product-details-name .product-name {
    padding-right: 17px;
  }

  .slide-box-wrap .complete-purchase.btn-shop {
    display: inline-block;
    width: auto;
    background: transparent;
    border: 0;
  }

  .slide-box-wrap .complete-purchase.btn-shop button {
    border-left: 1px solid #f86202 !important;
    border-right: 1px solid #f86202 !important;
  }

  .slide-box-wrap .btn-wrap button.complete-purchase {
    width: 161px;
  }
}

@media all and (min-width: 1024px) {


  .product-details-name .product-name {
    padding-right: 0;
  }

  .slide-box-wrap .des-wrap {
    padding-right: 60px;
  }

  .updt-info-wrp {
    width: 45%;
    margin-top: 30px;
  }
}

@media all and (min-width: 1920px) {
  .season-wrap i {
    background-size: 900px 700px;
    background-position: -21px -611px;
    width: 77px;
    height: 17px;
  }

  .flip-box-inner h2 {
    font-size: 80px;
  }

  .verify-product .flip-box {
    min-height: 998px;
  }

  .verify-product .flip-box-inner {
    padding: 87px;
    height: 98.5%;
  }

  .question-info:first-child:after {
    height: 51%;
  }
}

@media all and (min-width: 992px) {
  .product-view .main-item div {
    text-align: left;
  }
}

/* Changes */
.dates-selector {
  flex-wrap: wrap;
}

.dates-selector .dates-wraps {
  margin-bottom: 20px;
  width: 100%;
}

.dates-wraps {
  position: relative;
  display: flex;
  align-items: center;
}

.dates-wraps input {
  flex-grow: 1;
}

.dates-wraps label {
  width: 64px;
}

.dates-wraps span {
  width: 64px;
}

.dates-wraps .toggle-password {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 13px;
}

.popup-body.offer-making {
  padding: 27px 0 30px;
}

.popup-body.offer-making h3 {
  margin-bottom: 45px;
  font-size: 25px;
}

.popCalendar.calendar-popup {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  transform: translateY(10px);
}

.popCalendar.calendar-popup .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
  white-space: nowrap;
}

.popCalendar.calendar-popup .react-calendar {
  width: 310px;
}



.deliver-zip {
  margin-left: 5px;
}

.wrap-delivery {
  display: flex;
}



@media all and (min-width: 768px) {
  .header-address .delivery-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .dates-selector {
    flex-wrap: nowrap;
  }

  .dates-selector .dates-wraps {
    margin-bottom: 0;
  }

  .dates-wraps span {
    width: auto;
  }

  .popup-body.offer-making {
    padding: 16px 0 30px;
  }

  .popup-body.offer-making h3 {
    text-align: left;
    margin-bottom: 67px;
    font-size: 20px;
    width: 100%;
    padding-right: 40px;
    position: relative;
  }

  .popup-body.offer-making h3::after {
    position: absolute;
    bottom: -20px;
    left: -15px;
    width: calc(100% + 30px);
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
    content: "";
  }
}

.smart-price {
  background: #ececec;
  padding: 13px 15px 17px;
  border-radius: 5px;
  margin-top: 13px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.26);
}

.smart-price span {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.smart-price p {
  color: rgb(76, 76, 76);
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.rec-input {
  width: 100%;
  margin-top: 16px;
}

.smart-price .smart-input {
  background: #fff;
  width: 100%;
  border: 1px solid #cdced2;
  padding: 11px 15px;
  font-size: 15px;
  border-radius: 4px;
  color: #4c4c4c;
  max-width: 168px;
}

.smart-price .smart-input:disabled {
  background: #d2d2d2;
}

.smart-price .btns-status .ship-btn {
  margin-right: 0;
}

.smart-price .btns-status .ship-btn.active {
  border: 1px solid #f86202;
}

.smart-price .btns-status .ship-btn:hover {
  color: #f86202;
  background: #fff;
}

.navigation-container.both-btn .navigation-back .back-btn {
  transition: all 0.5s;
}

.navigation-container.both-btn .navigation-back .back-btn:hover {
  opacity: 0.7;
}

.dashboard .slick-initialized .slick-slide {
  height: inherit;
}

.dashboard .slick-initialized .slick-slide.slick-active.slick-current {
  z-index: 1 !important;
}

.dashboard .slick-initialized .slick-slide>div {
  height: 100%;
}

.dashboard .slick-initialized .slick-slide .costume-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff !important;
  background-color: #fff !important;
}

.dashboard .slick-initialized .slick-slide .costume-box a {
  flex-grow: 1;
}

.dashboard .slick-initialized .slick-slide .costume-info {
  height: 100%;
}

.dashboard .slick-track {
  display: flex;
}

.heart-like .fa-heart {
  color: #f47900;
  font-size: 20px;
}

.attr .label-txt.attr-select {
  font-size: 15px;
}

.checkout-cardwrap .qty-input {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.checkout-cardwrap .qty-input input {
  text-align: center;
  border-top: none;
  border-bottom: none;
  border-radius: 0;
  padding: 0;
  font-size: 11px;
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkout-cardwrap .qty-input button {
  background-image: -webkit-linear-gradient(#fbfbfb, #e4e4e4);
  background-image: -moz-linear-gradient(#fbfbfb, #e4e4e4);
  background-image: -o-linear-gradient(#fbfbfb, #e4e4e4);
  background-image: linear-gradient(#fbfbfb, #e4e4e4);
  padding: 5px 3px;
  font-size: 10px;
}

.checkout-cardwrap .qty-input button:hover {
  background-image: -webkit-linear-gradient(#e4e4e4, #fbfbfb);
  background-image: -moz-linear-gradient(#e4e4e4, #fbfbfb);
  background-image: -o-linear-gradient(#e4e4e4, #fbfbfb);
  background-image: linear-gradient(#e4e4e4, #fbfbfb);
}

.checkout-cardwrap .qty-input button[disabled]:hover {
  background-image: -webkit-linear-gradient(#fbfbfb, #e4e4e4);
  background-image: -moz-linear-gradient(#fbfbfb, #e4e4e4);
  background-image: -o-linear-gradient(#fbfbfb, #e4e4e4);
  background-image: linear-gradient(#fbfbfb, #e4e4e4);
  cursor: unset;
}

.checkout-cardwrap .qty-input .qty-decrement {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.checkout-cardwrap .qty-input .qty-increment {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.applied-msg {
  margin-top: 5px;
  font-family: "Roboto";
}

.recommended-value {
  margin-top: 7px;
  font-size: 14px;
  color: #f86202;
  font-weight: 500;
}

.smart-price span {
  font-size: 12px;
}

.navigation-screen .attr-elements {
  display: flex;
  flex-flow: wrap;
}

.navigation-screen .attr-elements .select-attr,
.navigation-screen .attr-elements .label-txt {
  width: 100%;
}

.navigation-screen .attr-elements .slct-optns {
  width: 48%;
  margin-right: 2%;
}

.navigation-screen .attr-elements .slct-optns .slt-url {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navigation-screen .attr-elements .slct-optns .slt-url>div {
  width: 68%;
}

.navigation-screen .attr-elements .slct-optns .slt-url select {
  cursor: pointer;
  -webkit-appearance: none;
  border: 1px solid #cdced2;
  border-radius: 4px;
  height: 47px;
  margin-left: 8px;
  width: 31%;
  background-image: linear-gradient(45deg, transparent 50%, #bababa 50%),
    linear-gradient(135deg, #bababa 50%, transparent 50%);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) calc(0.8em + 6px),
    calc(100% - 15px) calc(0.8em + 6px), calc(100% - 2.5em) 0.5em;
  background-size: 7px 6px, 6px 7px, 0 0;
}

.navigation-screen .attr-elements .label-txt {
  font-size: 16px;
  text-transform: capitalize;
}

.navigation-screen .attr-elements .select-attr .label-txt {
  font-size: 14px;
  margin-bottom: 8px;
}

.navigation-screen .attr-elements .attr-optns .label-txt {
  margin-bottom: 16px;
}

.attr-elements .select-attr:nth-last-child(2) .slct-optns .attr-optns {
  width: auto;
}

.attr-elements .select-attr:last-child .slct-optns .attr-optns {
  width: auto;
}

.price-input-wrap {
  position: relative;
}

.rupees-icon {
  position: absolute;
  top: 14px;
  left: 15px;
}

.price-input-wrap .input-url {
  padding-left: 24px;
}

.product-review-images {
  display: block;
  width: 70px;
  height: 70px;
}

.prof-img .product-review-images img {
  width: 100%;
  margin-right: 0;
  height: 100%;
  object-fit: contain;
}

.status-details span {
  font-size: 13px;
  line-height: 1.2;
}

.del-details span {
  font-size: 13px;
}

.prod-title.prod-toggle .del-details p {
  font-size: 13px;
}

.status-details {
  font-size: 13px;
}

.del-details strong {
  font-size: 13px;
}

.make-offer-popup.cancel-return {
  font-family: "Roboto";
}

.make-offer-popup.cancel-return .offer-title.heading {
  padding: 14px 28px 0 0;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
}

.make-offer-popup.cancel-return .text-reason {
  border: 1px solid #cdced2;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 18px;
}

.make-offer-popup.cancel-return .full-width {
  border: 1px solid #cdced2;
  font-family: "Roboto";
  border-radius: 4px;
  color: rgb(51, 51, 51);
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 0 35px 0 15px;
  cursor: pointer;
}

.make-offer-popup.cancel-return .reason-selector {
  margin-bottom: 18px;
  position: relative;
}

.make-offer-popup.cancel-return .reason-selector i {
  position: absolute;
  pointer-events: none;
  top: 50%;
  right: 14px;
  font-size: 14px;
  transform: translateY(-50%);
}

.make-offer-popup.cancel-return .full-width::-ms-expand {
  display: none;
}

.make-offer-popup.cancel-return .header-sell {
  height: auto;
}

.make-offer-popup.cancel-return .text-reason textarea {
  width: 100% !important;
  border: none;
  font-family: "Roboto";
  color: rgb(51, 51, 51);
  display: block;
}

.make-offer-popup.cancel-return .text-reason textarea::placeholder {
  color: rgb(51, 51, 51);
}

.make-offer-popup.cancel-return .text-reason textarea:focus {
  outline: none;
}

.make-offer-popup.cancel-return .offer-title {
  padding-bottom: 15px;
}

.make-offer-popup.cancel-return .header-sell {
  font-size: 15px;
}

.footer-social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social .fb-link {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.footer-social .fb-link:hover {
  border: 1px solid #3b5998;
}

.footer-social .twitter-link {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.footer-social .twitter-link:hover {
  border: 1px solid #1da1f2;
}

.footer-social .insta-link {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 1px solid #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.footer-social .insta-link:hover {
  border: 1px solid #bc2a8d;
}

.withdraw-history-link {
  margin-top: 10px;
  display: block;
}

.green a {
  transition: all 0.5s;
}

.green a:hover {
  opacity: 0.7;
}

.footer-social i {
  font-size: 19px;
  color: #999999;
  transition: all 0.5s;
  margin-right: 20px;
  border: 1px solid #999999;
  ;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  text-rendering: optimizeLegibility;
}

.footer-social i:hover {
  color: #f47900;
  border-color: #f47900;
}

.footer-social .fb-link:hover i {
  color: #3b5998;
}

.footer-social .twitter-link:hover i {
  color: #1da1f2;
}

.footer-social .insta-link:hover i {
  color: #bc2a8d;
}

.withdraw-panel {
  font-family: "Roboto";
}

.withdraw-panel h4 {
  font-size: 23px;
  margin-bottom: 11px;
  color: #1d1d23;
}

.withdraw-panel p {
  color: #000000;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 1.3;
}

.withdraw-panel .italic-text {
  font-style: italic;
}

.balance-block {
  margin-bottom: 50px;
}

.balance-block .balance-number {
  color: #622bc5;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 5px;
}

.balance-block h6 {
  text-align: center;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 15px;
  padding: 0;
  margin: 0;
}

.withdraw-inputs {
  margin-bottom: 35px;
}

.withdraw-inputs .amount-block {
  margin-bottom: 20px;
}

.withdraw-inputs .label-text {
  color: #1d1d23;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 5px 3px;
  display: block;
}



.withdraw-inputs .input-amount {
  width: 100%;
  border: 1px solid #cecdcd;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
  color: #1d1d23;
  border-radius: 4px;
  padding: 20px 15px;
}

.withdraw-inputs .input-amount::placeholder {
  color: rgba(29, 29, 35, 0.42);
}

.withdraw-inputs h5 {
  margin: 8px 0 0 0;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
}



.withdraw-inputs .withdraw-select {
  width: 100%;
  border: 1px solid #cecdcd;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
  color: #999999;
  border-radius: 4px;
  padding: 19px 37px 19px 15px;
  height: auto;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.withdraw-inputs select::-ms-expand {
  display: none;
}

.withdraw-inputs .withdrwa-select-wrap {
  position: relative;
}

.withdraw-inputs .withdrwa-select-wrap i {
  position: absolute;
  top: 50%;
  right: 15px;
  pointer-events: none;
  transform: translateY(-50%);
}

.withdraw-inputs .add-bank-link {
  color: #f86202;
  text-decoration: underline;
  font-weight: 500;
  display: inline-block;
  margin-top: 10px;
  transition: all 0.5s;
}

.withdraw-inputs .add-bank-link:hover {
  color: #622bc5;
}

.bank-column {
  border-top: 1px solid #cecdcd;
  padding: 35px 0;
}

.bank-column .bank-balance {
  background: #eaeaea;
  display: flex;
  align-items: flex-start;
  padding: 15px 20px 15px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.bank-column h4 {
  margin-bottom: 34px;
}

.bank-column .bank-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bank-column .bank-icon {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #bababa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bank-column .bank-icon img {
  display: block;
}

.bank-column .bank-detail {
  margin-left: 12px;
  font-size: 17px;
}

.bank-column .bank-name {
  color: #1d1d23;
  font-weight: 500;
  margin-bottom: 4px;
}

.bank-column .bank-number {
  color: #bdbece;
  font-weight: 500;
  margin-bottom: 12px;
}

.bank-column .change-btn {
  color: #bdbece;
  text-decoration: underline;
  transition: all 0.5s;
  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto";
  margin-right: 15px;
}

.bank-column .change-btn i {
  margin-left: 4px;
  font-size: 12px;
}

.bank-column .change-btn:hover {
  color: rgb(58, 85, 169, 0.8);
}

.transaction-listing {
  max-width: 435px;
}

.transaction-listing li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.transaction-listing li:last-child {
  margin-bottom: 0;
}

.transaction-listing .list-heading {
  width: 61%;
  padding-right: 20px;
  font-size: 14px;
}

.transaction-listing .list-value {
  color: #1d1d23;
  font-size: 15px;
  font-weight: 700;
}

.transaction-listing .list-value.purple-value {
  color: #622bc5;
}

.withdraw-amounts h4 {
  margin-bottom: 27px;
}

.withdraw-amounts {
  margin-bottom: 35px;
}

.withdraw-btns {
  /* width: calc(100% + 60px); */
  /* margin-left: -30px; */
  /* border-top: 1px solid #d8d8d8; */
  width: 100%;
  /* padding: 27px 30px 0; */
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.withdraw-btns .withdraw-cancel {
  border: 1px solid #747477;
  font-weight: 500;
  color: #1d1d23;
  padding: 7px 12px;
  font-size: 17px;
  border-radius: 5px;
  transition: all 0.5s;
}

.withdraw-btns .withdraw-cancel:hover {
  color: #fff;
  background: #1d1d23;
}

.withdraw-btns .withdraw-continue {
  border: 1px solid #f86202;
  font-weight: 500;
  color: #fff;
  padding: 7px 20px;
  font-size: 17px;
  border-radius: 5px;
  background: #f86202;
  transition: all 0.5s;
}

.Sales-page.transaction {
  margin-bottom: 0;
}

.withdraw-btns .withdraw-continue:hover {
  color: #f86202;
  background: transparent;
}

.seller-input .MuiInputLabel-formControl {
  top: 50%;
  transition: all 0.3s;
}

.seller-input .MuiInputLabel-outlined {
  transform: translate(14px, -50%) scale(1);
  font-size: 16px;
  font-weight: 500;
  color: #1d1d23;
}

.signup-form .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  display: none;
}

.signup-form .MuiInputLabel-outlined.MuiInputLabel-shrink {
  padding: 0 7px;
  background: #fff;
}

.review-popup.make-offer-popup.review-popup .offer-title {
  text-align: center;
}

.signup-form .detail-from .MuiFormControl-root input {
  border: none;
  font-size: 16px;
}

.signup-form .detail-from .MuiFormControl-root input::placeholder {
  color: #000;
  opacity: 0.6;
}

.signup-form .detail-from .MuiFormControl-root input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
  opacity: 0.6;
}

.signup-form .detail-from .MuiFormControl-root input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
  opacity: 0.6;
}

.update-value .update-status {
  margin-top: 5px;
}

.seller-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: 1px;
}

.seller-input .MuiFormControl-root {
  width: 100%;
  height: 100%;
}

.seller-input .MuiInputBase-root {
  height: 100%;
}

.signup-form .MuiFormControl-root {
  width: 100%;
}

.seller-input .MuiInputBase-root input {
  border: none;
}

.seller-input .MuiSelect-select:focus {
  background: transparent;
  text-align: left;
}

.seller-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: #fff;
  padding: 0 5px;
}

.seller-input .MuiSelect-outlined.MuiSelect-outlined {
  text-align: left;
  min-height: auto;
  height: auto;
  padding: 18px 50px 18px 16px;
  line-height: 1.3;
  font-size: 16px;
  font-weight: 500;
}

legend span {
  margin-right: -8px;
}

.modal-dialog.modal-90w.bank .add-links .btn-purple {
  height: auto;
  width: auto;
  padding: 15px 61px;
  font-size: 19px;
}

.matrix-info {
  width: 14px;
  height: 14px;
  display: inline-block;
  cursor: pointer;
  background: url(/assets/images/icn-info-light.svg) no-repeat center;
  margin: 0 0 -2px 5px;
  position: relative;
}

.matrix-pop {
  position: absolute;
  top: 14px;
  left: 0;
  display: none;
  width: 161px;
  padding: 7px 0;
  z-index: 1;
}

.matrix-info-popup {
  width: 100%;
  display: block;
  background: rgba(0, 0, 0);
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
}

.matrix-info:hover .matrix-pop {
  display: block;
}

.matrix-info:hover {
  background: url(/assets/images/icn-info-light-hover.svg) no-repeat center;
}

.about-team {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
  background: #994fb1;
  z-index: 10;
  overflow: auto;
  height: 100%;
}

.chat-fld-wrap .full-item-detail .item-img img {
  max-height: 250px;
  object-fit: contain;
}

.about-container {
  max-width: 1430px;
  margin: 0 auto;
  padding: 0 15px;
}

.team-popup-head {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 16px 0 10px 0;
  margin-bottom: 16px;
}

.team-close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 11px;
}

.team-close i {
  transition: all 0.5s;
  font-size: 33px;
  color: #fff;
}

.team-close i:hover {
  color: #f86202;
}

.team-images {
  padding: 15px;
  background: #fff;
}

.team-images img {
  width: 100%;
  display: block;
}

.team-status h4 {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 3px;
}

.team-status h6 {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: #e0b6ee;
}

.para-team {
  background: #fff;
  padding: 15px;
}

.para-team p {
  margin-bottom: 20px;
  color: #000;
  line-height: 1.5;
}

.para-team p:last-child {
  margin-bottom: 0;
}

.general-terms {
  font-family: "Roboto" !important;
}

.privacy-details h2 {
  font-family: "Roboto" !important;
}

.privacy-details p {
  font-family: "Roboto" !important;
}

.register-popup.seller-register .label-text {
  color: rgb(76, 76, 76);
  font-weight: 600;
  font-family: "Roboto";
  display: block;
  width: 100%;
  margin-bottom: 3px;
  text-align: left;
}

.new-add-form .form-input span.highlighted {
  margin-bottom: 0;
}

.transaction-tables {
  width: 100%;
  overflow: auto;
}

.withdraw-table {
  font-family: "Roboto";
  background: #fff;
  border-radius: 4px;
  padding: 0 6px 26px 3px;
  min-width: 809px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.transaction-tab h4 {
  font-size: 20px;
  font-family: "Roboto";
  margin-bottom: 12px;
}

.withdraw-table .withdraw-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(76, 76, 76);
  font-size: 15px;
  font-weight: 500;
  padding: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.withdraw-table .withdraw-values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}



.withdraw-main {
  width: 16%;
  flex-shrink: 0;
  padding: 0 5px 0 0;
}

.withdraw-id {
  width: 16%;
  flex-shrink: 0;
  padding: 0 5px;
}

.withdraw-date {
  width: 12%;
  flex-shrink: 0;
  padding: 0 5px;
}

.req-date {
  width: 16%;
  flex-shrink: 0;
  padding: 0 5px;
}

.withdraw-date.req-date {
  width: 16%;
}

.withdraw-amount {
  width: 9%;
  flex-shrink: 0;
  padding: 0 5px;
}

.withdraw-left {
  width: 18%;
  flex-shrink: 0;
  padding: 0 5px;
}

.withdraw-status {
  width: 10%;
  flex-shrink: 0;
  padding: 0 0 0 5px;
}

.transaction-tab .balance-block {
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 30px;
}

.withdraw-values.pending .withdraw-main .transaction-logo i {
  color: #f67900;
}

.withdraw-table .withdraw-values.pending .withdraw-status {
  border: 1px solid #f67900;
  background: rgba(246, 121, 0, 0.09);
}

.withdraw-values.reject .withdraw-main .transaction-logo i {
  color: #ff0000;
}

.withdraw-table .withdraw-values.reject .withdraw-status {
  border: 1px solid #ff0000;
  background: rgba(255, 0, 0, 0.09);
}

.sign-form legend>span:last-child {
  /* display: none; */
  margin-right: -19px;
}

.transaction-tab .withdraw-panel {
  margin-bottom: 30px;
}

.withdraw-values .withdraw-main {
  display: flex;
  align-items: center;
}

.withdraw-main .transaction-logo i {
  display: block;
  font-size: 29px;
  color: #0a990a;
}

.withdraw-main .transaction-logo {
  margin-right: 10px;
}



.withdraw-table .withdraw-values .withdraw-status {
  text-align: center;
  border: 1px solid #0a990a;
  border-radius: 20px;
  padding: 6px 5px;
  background: rgba(10, 153, 10, 0.09);
}

.header-address {
  position: absolute;
  top: 63px;
  z-index: 12;
  left: 15%;
  /* width: 100%; */
  width: 113px;
  display: flex;
  justify-content: center;
  color: #f86202;
  border-radius: 6px;
  font-size: 10px;
  /* font-size: 12px; */
  /* font-weight: 500; */
  font-family: "Roboto";
  background: #e8e8e8;
  /* padding: 4px 6px; */
  /* padding: 7px 15px 3px; */
  z-index: 1;
  display: none;
}

.mobile-header-address.header-address {
  display: block;
}

.product-location-wraps {
  padding: 4px;
  cursor: pointer;
  transition: all 0.5s;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 32px;
}

.product-location-wraps:hover {
  color: #622bc5;
}

.header-address .product-location {
  display: flex;
  flex-wrap: wrap;
}

.distance-mark i {
  margin-left: 5px;
}

.location-mark {
  /* min-height: 20px; */
  display: flex;
  align-items: center;
}

.transaction-center {
  width: 100%;
  text-align: center;
  margin-top: 6px;
}

.bank-info {
  margin-top: 5px;
}

.transaction-btn {
  display: inline-block;
  color: #f67900;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.5s;
}

.transaction-btn:hover {
  opacity: 0.7;
}

.transaction-tab {
  margin: 0 -12px;
  background-color: #fff;
  padding: 18px 13px;
}

.make-offer-popup.review-popup {
  font-family: "Roboto";
}

.make-offer-popup.review-popup .offer-title {
  font-weight: 500;
  padding: 13px 31px 13px 0;
  text-align: left;
  margin-bottom: 16px;
}

.make-offer-popup.review-popup .form-input span {
  font-size: 18px;
  font-weight: 500;
}

.make-offer-popup.review-popup .rating__item {
  cursor: pointer;
}

.make-offer-popup.review-popup .form-input textarea {
  width: 100% !important;
  border: 1px solid #cdced2;
  border-radius: 4px;
  font-size: 15px;
  height: 157px;
}

.make-offer-popup.review-popup .form-input textarea:focus {
  outline: none;
}

.make-offer-popup.review-popup .detail-from .form-input {
  margin-bottom: 17px;
}

.state-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  font-weight: 400;
  border: 1px solid #cdced2;
  border-radius: 4px;
  font-family: "Roboto";
  color: #4c4c4c;
  height: 42px;
  font-size: 14px;
  padding-right: 38px;
  cursor: pointer;
}

.state-select.add {
  height: 45px;
}

.state-select::-ms-expand {
  display: none;
}

.select-state i {
  pointer-events: none;
  display: block;
  font-size: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: rgb(76, 76, 76);
}

.select-state {
  position: relative;
}

.select-state .select-opt__control {
  height: 42px;
}

.select-state .select-opt__value-container {
  height: 42px;
}

.new-add-form .detail-from button {
  padding: 10px 50px;
  font-size: 16px;
}

.new-add-form .select-state .select-opt__control {
  height: 45px;
}

.new-add-form .select-state .select-opt__value-container {
  height: 45px;
}



.local-banner {
  padding-bottom: 48px;
  background: #622bc5;
  font-family: "Roboto";
}

.rotate-image {
  margin: 0 -15px 27px;
  width: calc(100% + 30px);
  overflow: hidden;
}

.local-bazaar .local-banner-image {
  margin: 0 -27.5% 0 0;
}

.local-bazaar .local-banner-image img {
  width: 100%;
  display: block;
}

.local-bazaar .banner-texts {
  margin-top: 6px;
}

.local-banner h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 17px;
}

.local-banner p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 52px;
}

.local-banner .get-start {
  border: 1px solid #f86202;
  background: #f86202;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  padding: 12px 38px;
  transition: all 0.5s;
}

.local-banner .get-start:hover {
  color: #f86202;
  background: #fff;
}

.selling-local {
  padding: 20px 0;
}

.selling-local h2 {
  font-size: 26px;
  text-align: center;
  font-weight: 600;
  color: #622bc5;
  padding: 0 10px;
  margin-bottom: 40px;
}

.reason-image {
  width: 90px;
  margin-bottom: 14px;
}

.reasons-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

.reasons-wrap:last-child {
  margin-bottom: 0;
}

.reasons-wrap h4 {
  margin-bottom: 14px;
}

.reasons-wrap p {
  font-size: 14px;
  line-height: 1.5;
}

.reason-image img {
  display: block;
  width: 100%;
}

.order-details .show-review-rating {
  margin-bottom: 10px;
}

.powered-delivery {
  padding: 30px 0;
  max-width: 458px;
  margin: 0 auto;
}

.local-bazaar .container {
  max-width: 1360px;
  padding: 0 15px;
}

.selling-locals {
  background: rgba(115, 51, 129, 0.1);
  padding: 0 0 25px 0;
}

.local-selling-image {
  margin-left: -21%;
  padding-right: 21%;
}

.local-selling-image img {
  display: block;
  width: 100%;
}

.selling-locals h3 {
  font-size: 26px;
  margin-bottom: 16px;
}

.selling-locals .selling-boxes-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.selling-locals h4 {
  font-size: 26px;
  margin-bottom: 18px;
}

.selling-locals p {
  font-size: 16px;
}

.selling-locals .text-wrap-selling {
  width: calc(50% - 10px);
}

.selling-locals .selling-boxes {
  display: flex;
  justify-content: space-between;
}

.selling-locals .selling-box {
  width: calc(50% - 10px);
  text-align: center;
}

.selling-locals .option-boxes {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.selling-locals .option-box {
  padding: 20px 5px;
  margin-bottom: 8px;
  background: #622bc5;
  color: #fff;
  font-size: 16px;
}

.selling-locals .option-box:last-child {
  margin-bottom: 0;
}

.selling-locals .option-box-dotted {
  border: 2px dashed #622bc5;
  text-align: center;
  color: #fff;
  padding: 20px 5px;
  color: rgb(76, 76, 76);
  font-size: 16px;
}

.selling-locals .long {
  min-height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selling-locals .option-box.white {
  background: #fff;
  color: rgb(76, 76, 76);
}

.selling-locals .long.longer {
  min-height: 229px;
  flex-grow: 1;
}



/* .selling-locals{
  
}
.selling-locals{
  
}
.selling-locals{
  
} */
.toggle-password {
  cursor: pointer;
}

.shipping-itemwrap.shipping-remade .desc-txt .edit-wrapper .select-state input {
  border: none !important;
  margin-bottom: 0 !important;
  cursor: pointer;
  padding-left: 15px !important;
}

.shipping-itemwrap.shipping-remade .desc-txt .edit-wrapper .select-state .select-opt__single-value {
  transform: translateY(-50%) !important;
  top: 50% !important;
  margin: 0;
  left: 15px;
  color: #4c4c4c;
  font-weight: 400;
}

.shipping-itemwrap.shipping-remade .desc-txt .edit-wrapper .select-state .select-opt__single-value~div {
  margin: 0;
}

.new-add-form .select-state input {
  border: none !important;
  margin-bottom: 0 !important;
  cursor: pointer;
  padding-left: 15px !important;
}

.new-add-form .select-state .select-opt__value-container {
  transform: translateY(-50%) !important;
  top: 50% !important;
  margin: 0;
  left: 15px;
  color: #4c4c4c;
  font-weight: 400;
  font-size: 14px;
  font-family: "Roboto";
}

.new-add-form .select-state .select-opt__single-value {
  transform: translateY(-50%) !important;
  top: 50% !important;
  margin: 0;
  left: 0;
  color: #4c4c4c;
  font-weight: 400;
  font-size: 14px;
  font-family: "Roboto";
}

.new-add-form .select-state .select-opt__placeholder {
  font-size: 14px !important;
  top: 8px;
  transform: translateY(-50%) !important;
}

.new-add-form .select-opt__input {
  height: 34px;
}

.new-add-form .select-opt__input input {
  padding: 0 !important;
  height: 100%;
}

.shipping-itemwrap.shipping-remade .desc-txt.edit-address .edit-wrapper .select-opt__input input {
  height: 100% !important;
}

.edit-address .select-opt__input {
  height: 38px;
}

.new-add-form .select-state .select-opt__value-container~div {
  margin: 0;
  height: 100%;
}

.new-add-form .select-state .select-opt__value-container~div .select-opt__indicator-separator {
  display: none;
}

.buy-prod.dual-order {
  padding: 0;
}

.dual-order {
  font-family: "Roboto";
  width: 100%;
  padding-bottom: 25px;
}

.dual-order .dual-order-head {
  background: rgba(208, 208, 208, 0.28);
  padding: 20px 10px;
}

.dual-order .dual-content {
  margin-bottom: 20px;
}

.dual-order .dual-content:last-child {
  margin-bottom: 0;
}

.dual-order .dual-content h4 {
  font-size: 16px;
  margin-bottom: 3px;
  color: #4c4c4c;
  text-transform: uppercase;
}

.dual-order .dual-content h5 {
  font-size: 17px;
  margin-bottom: 0;
  color: #4c4c4c;
  text-transform: uppercase;
}



.dual-order .heading-menu {
  color: #f86202;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  transition: all 0.5s;
}

.dual-order .heading-menu:hover {
  color: #622bc5;
}

.dual-order .heading-menu .menu-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dual-order .heading-menu i {
  margin-left: 10px;
  color: #4c4c4c;
}

.dual-order .dual-menu .view-order {
  color: #f86202;
  font-size: 17px;
  display: block;
  margin: 8px 0;
  transition: all 0.5s;
}

.dual-order .dual-menu .view-order:hover {
  color: #622bc5;
}

.dual-order .ordered-products {
  padding: 20px 10px;
}

.dual-order .ordered-products h3 {
  font-size: 17px;
  margin-bottom: 20px;
}



.dual-order .dual-product-image {
  margin: 0 auto 30px;
}



.multiple-items .accordion-wrapper {
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 20px;
  background: #fff;
}

.multiple-items .accordian-panel {
  /* border-radius: 7px; */
  /* padding: 15px; */
  /* margin-bottom: 20px; */
  overflow: auto;
}

.dual-order .dual-product-image img {
  display: block;
  width: 100%;
  height: 98px;
  object-fit: contain;
  margin: 0 auto;
}

.dual-order .items-text-number {
  font-weight: 700;
  font-size: 20px;
  color: #4c4c4c;
}

.need-help-box {
  margin-bottom: 20px;
  border-radius: 7px;
  background: #fff;
}

.weight-value .cancel-btn {
  font-family: "Roboto";
  border: 1px solid #f86202;
  color: #f86202;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 11px;
  transition: all 0.5s;
  margin-right: 7px;
}

.weight-value .cancel-btn:last-child {
  margin-right: 0;
}

.weight-value .cancel-btn:hover {
  background: #f86202;
  color: #fff;
}





.dual-order .view-dual-order {
  flex-shrink: 0;
}

.dual-order .view-dual-order .view-link {
  cursor: pointer;
  border: 1px solid transparent;
  color: #fff;
  background: #EF7F1A;
  border-radius: 4px;
  padding: 10px 34px;
  font-size: 16px;
  transition: all 0.5s;
}

.dual-order .view-dual-order .view-link:hover {
  background: transparent;
  border: 1px solid #EF7F1A;
  color: #EF7F1A;
}

.multiple-items {
  /* margin: 0 10px; */
  margin-top: 20px;
  padding-bottom: 20px;
  font-family: "Roboto";
}

.multiple-items .ordered-products {
  background: #fff;
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 20px;
}

.multiple-items .products-images {
  display: flex;
  flex-wrap: wrap;
}

.multiple-items .dual-product-image {
  width: 80px;
  margin: 0 20px 20px 0;
}

.multiple-items .dual-product-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}



.multiple-items .order-items h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.multiple-items .view-all-items {
  color: #f86202;
  font-size: 13px;
  transition: all 0.5s;
  cursor: pointer;
  margin-right: 5px;
}

.multiple-items .view-all-items:hover {
  color: #622bc5;
}

.multiple-items .order-summary-multi {
  background: #fff;
  border-radius: 7px;
  padding: 15px 15px 30px;
}

.multiple-items .order-summary-multi h6 {
  padding: 0;
  margin: 0;
  text-transform: none;
  font-weight: 400;
}

.multiple-items .order-summary-multi h3 {
  font-size: 16px;
  margin-bottom: 13px;
  font-weight: 500;
}

.multiple-items .order-summary-multi h4 {
  font-size: 19px;
  margin-bottom: 20px;
}

.multiple-items .order-summary-multi h5 {
  text-transform: none;
  color: #4c4c4c;
  margin-bottom: 5px;
  line-height: 1.5;
  font-weight: 500;
}

.multiple-items .summary-multi-bill {
  margin: 19px 0;
  padding-bottom: 19px;
  border-bottom: 1px solid #dadada;
}

.multiple-items .bill-price-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
}

.multiple-items .bill-price-list:last-child {
  margin-bottom: 0;
}

.multiple-items .bill-price-list.main {
  margin-top: 25px;
  font-weight: 500;
}

.multiple-items .bill-heading {
  width: 60%;
}

.multiple-items .bill-price {
  text-align: right;
  width: 35%;
}

.multiple-items .order-summary-multi h6 {
  font-size: 16px;
}

.items-table-wrap {
  width: 100%;
  overflow: auto;
  padding: 0 10px;
  background: #fff;
  font-family: "Roboto";
  border-radius: 7px;
}

.items-table-wrap .items-table {
  min-width: 650px;
  padding: 0 0 10px 0;
}

.items-table-wrap .table-head-headings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
  position: relative;
}

.items-table-wrap .table-head-headings::after {
  content: "";
  border-bottom: 1px solid #e2e2e2;
  position: absolute;
  width: 130%;
  left: 0;
  bottom: 0;
}

.items-table-wrap .image-heading {
  width: 40px;
  flex-shrink: 0;
}

.items-table-wrap .name-heading {
  flex-shrink: 0;
  width: calc(40% - 40px);
  padding: 0 15px 0 25px;
  font-weight: 500;
}

.items-table-wrap .quantity-heading {
  flex-shrink: 0;
  width: 12%;
  text-align: center;
  padding: 0 10px;
}

.items-table-wrap .weight-heading {
  flex-shrink: 0;
  text-align: center;
  width: 63%;
  padding: 0 10px;
}

.items-table-wrap .total-heading {
  flex-shrink: 0;
  width: 17%;
  padding: 0 10px;
  text-align: center;
}



.items-table-wrap .table-head-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding: 15px 10px;
}

.items-table-wrap .table-head-values:last-child {
  border: none;
}

.items-table-wrap .image-value img {
  display: block;
  width: 100%;
}

.items-table-wrap .image-value {
  width: 40px;
  flex-shrink: 0;
}

.items-table-wrap .name-value {
  flex-shrink: 0;
  width: calc(40% - 40px);
  font-size: 15px;
  padding: 0 15px 0 25px;
  font-size: 16px;
}

.items-table-wrap .quantity-value {
  flex-shrink: 0;
  width: 12%;
  text-align: center;
  font-size: 17px;
  padding: 0 10px;
}

.items-table-wrap .weight-value {
  flex-shrink: 0;
  width: 63%;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  padding: 0 10px;
  display: flex;
  justify-content: center;
}

.items-table-wrap .total-value {
  flex-shrink: 0;
  width: 17%;
  font-size: 17px;
  padding: 0 10px;
  text-align: center;
}

.items-table-wrap .weight-value span {
  font-weight: 400;
}

.items-all-list {
  margin-bottom: 20px;
  background: #fff;
  width: 100%;
  border-radius: 7px;
  /* overflow: auto; */
  /* padding-top: 17px; */
}

.multiple-items .accordion-wrapper h1 {
  color: #4c4c4c;
  font-family: "Roboto";
  margin: 0 0;
  /* padding: 0 10px; */
  font-size: 19px;
  font-weight: 500;
  text-align: left;
}

.more-msg {
  margin: auto 0;
  font-size: 15px;
  text-decoration: underline;
  transition: all 0.5s;
  display: block;
}

.more-msg:hover {
  opacity: 0.7;
}

.seller-input.dual-input .MuiSelect-outlined.MuiSelect-outlined {
  padding: 15px 50px 15px 16px;
}

.local-working {
  padding: 33px 0;
  font-family: "Roboto";
}



.local-working .working-flow h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 24px;
  color: #141414;
}



.local-working .working-block {
  margin-bottom: 40px;
}

.local-working .working-image {
  margin-bottom: 15px;
}

.local-working .working-image img {
  width: 100%;
  display: block;
}



.local-working .working-text h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 13px;
  color: #141414;
}

.local-working .working-text p {
  color: #141414;
  font-size: 13px;
  margin-bottom: 14px;
}

.local-working .working-cost {
  color: #f86202;
  display: flex;
  align-items: center;
  line-height: 1.7;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.5s;
}

.local-working .working-cost:hover {
  color: #622bc5;
}

.local-working .working-cost i {
  font-size: 20px;
  opacity: 0.7;
  margin-left: 5px;
}

.register-facebook.mail-button span {
  display: flex;
  justify-content: center;
  text-transform: none;
}

.register-google.mail-button {
  text-transform: none;
}

.video-section {
  padding: 18px 0;
}



.video-section .video-box h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 17px;
}



.video-section .video-thumbnail {
  height: 300px;
  position: relative;
}



.video-section .video-icon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.32);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 1s;
}

.video-section .video-icon:hover {
  background: rgba(0, 0, 0, 0.05);
}

.video-section .video-icon i {
  font-size: 100px;
  color: rgba(255, 255, 255, 0.7);
}

.video-section .video-thumbnail img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}

.seller-inputs .error {
  display: block;
  font-size: 12px;
  margin: -16px 0 15px 6px;
  text-align: left;
  font-family: "Roboto";
}

.need-ideas {
  font-family: "Roboto";
  padding: 38px 0;
  background: rgba(0, 0, 0, 0.05);
}



.need-ideas .ideas-wrap h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 17px;
}

.need-ideas .ideas-wrap p {
  font-size: 17px;
  text-align: center;
  color: #141414;
  margin: 0 10px 30px;
}

.need-ideas .ideas-blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.need-ideas .idea-inner {
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  background: #fff;
  padding: 19px 11px;
  border-radius: 14px;
}

.need-ideas .idea-block {
  width: 50%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.need-ideas .idea-block h5 {
  margin: 12px 0 0 0;
  font-weight: 500;
  text-transform: none;
  font-size: 18px;
}

.need-ideas .idea-product {
  width: 100%;
  margin: auto;
}

.need-ideas .idea-product img {
  width: 100%;
  display: block;
}

.need-ideas .list-btn-wrap {
  text-align: center;
  cursor: pointer;
  margin-top: 40px;
}

.need-ideas .list-btn {
  color: #fff;
  background: #f86202;
  padding: 15px 40px;
  border-radius: 7px;
  display: inline-block;
  font-size: 20px;
  border: 1px solid #f86202;
  transition: all 0.5s;
}

.need-ideas .list-btn:hover {
  color: #f86202;
  background: transparent;
}

.available-nationwide {
  padding: 30px 0;
  font-family: "Roboto";
}



.available-nationwide .nations-name h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 37px;
}

.available-nationwide .nation-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.available-nationwide .nation-list li {
  font-weight: 500;
  width: calc(50% - 8px);
  text-align: center;
  color: #141414;
  font-size: 17px;
  margin-bottom: 25px;
}

.available-nationwide .cities-btn {
  text-align: center;
}

.available-nationwide .cities-btn .list-cities {
  padding: 9px 30px;
  border-radius: 20px;
  font-size: 17px;
  border: 1px solid #f86202;
  color: #f86202;
  transition: all 0.5s;
  display: inline-block;
}

.available-nationwide .cities-btn .list-cities:hover {
  color: #fff;
  background: #f86202;
}

.local-reviews {
  padding: 40px 0;
  font-family: "Roboto";
}

.local-reviews .reviews-local h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 17px;
}

.local-reviews .reviews-local h5 {
  text-align: center;
  text-transform: none;
  font-size: 17px;
  margin-bottom: 47px;
  font-weight: 400;
}



.local-reviews .review-box {
  background: rgb(115, 51, 129, 0.17);
  margin-bottom: 20px;
  padding: 30px 20px 70px;
}

.local-reviews .quotes {
  margin-bottom: 10px;
}

.local-reviews .quotes i {
  color: rgb(115, 51, 129, 1);
  font-size: 24px;
}

.local-reviews .review-box h4 {
  font-size: 16px;
  margin: 0 0 30px 15px;
  font-weight: 500;
}

.local-reviews .review-box p {
  font-size: 15px;
  margin-left: 15px;
}

.questions-local {
  font-family: "Roboto";
  padding: 30px 0;
}

.questions-local .local-question {
  position: relative;
  padding-bottom: 90px;
}

.questions-local .local-question h3 {
  text-align: center;
  font-size: 27px;
  margin-bottom: 17px;
}

.questions-local .local-question h5 {
  text-align: center;
  text-transform: none;
  font-size: 17px;
  margin-bottom: 47px;
  font-weight: 400;
}

.questions-local .view-faq {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}

.questions-local .faq-btn {
  border: 2px solid #f86202;
  border-radius: 39px;
  display: inline-block;
  padding: 14px 32px;
  color: #f86202;
  font-size: 17px;
  transition: all 0.5s;
}

.questions-local .faq-btn:hover {
  background: #f86202;
  color: #fff;
}

.register-popup.seller-register .gst-file-name {
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  pointer-events: none;
  color: #4c4c4c;
}

.dates-wraps input::placeholder {
  font-family: "Roboto";
}



.local-accordion .accordion__button {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  outline: none;
  font-weight: 500;
  font-size: 17px;
  transition: all 0.5s;
}

.local-accordion .accordion__button:hover {
  background: transparent;
  outline: none;
}

.local-accordion .accordion__button:before {
  border-bottom: 0;
  border-right: 0;
  background: url(/public/assets/images/accordion-plus.svg) no-repeat center;
  width: 20px;
  height: 20px;
  background-size: 20px auto;
}

.local-accordion .accordion__panel {
  padding: 0 20px 10px;
}

.local-accordion .accordion {
  border: none;
}

.local-accordion .accordion__item+.accordion__item {
  border: none;
}

.local-accordion p {
  font-size: 14px;
  margin-bottom: 10px;
}

.local-accordion p {
  /* font-size: 17px; */
  width: 90%;
  padding-right: 25px;
}

.local-accordion .accordion__button[aria-expanded="true"]::before {
  margin-bottom: 5px;
}

.local-accordion .accordion__button[aria-selected="true"]::before {
  margin-bottom: 5px;
}

.local-accordion .accordion__item {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 5px;
}

.detail-from.otp-screen {
  width: 100%;
  font-family: "Roboto";
}

.detail-from.otp-screen .otp-btns {
  display: flex;
  justify-content: center;
}

.detail-from.otp-screen .form-title {
  margin-bottom: 12px;
  font-size: 16px;
}

.detail-from.otp-screen .otp-timer {
  /* margin-bottom: 12px; */
  font-size: 16px;
}

.detail-from.otp-screen .btns-wrap {
  display: flex;
  flex-direction: column;
}

.signup-form.otp-open {
  max-width: 400px;
  top: 50%;
  transform: translateY(-50%);
}

.detail-from.otp-screen .otp-btn {
  color: #f86202;
  background: transparent;
  border: 1px solid #f86202;
  border-radius: 12px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: "Roboto";
  font-size: 15px;
  margin-bottom: 12px;
  padding: 12px 42px;
  transition: all 0.5s;
}

.detail-from.otp-screen .otp-btn:hover {
  color: #fff !important;
  background-color: #f86202;
}

.detail-from.otp-screen .otp-btn:disabled {
  color: #808080;
  border: 1px solid #808080;
  pointer-events: none;
}

.detail-from.otp-screen .otp-btn.submit {
  color: #fff;
  background: #622bc5;
  border: 1px solid transparent;
}

.detail-from.otp-screen .otp-btn.submit:hover {
  color: #fff;
  background: #622bc5;
  border: 1px solid transparent;
}

.detail-from.otp-screen .otp-btn.submit:disabled {
  color: #fff;
  background: #f86202;
  border: 1px solid transparent;
}

.sign-form .error {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  font-size: 13px;
  margin-top: 4px;
  text-align: left;
  font-family: 'Inter';
}

.complete-purchase.btn-shop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.complete-purchase.make-offer {
  margin-bottom: 10px;
}

.pro-btn-wrap.make-offer {
  padding-top: 0;
}



.complete-purchase.make-offer {
  background: #622bc5;
  border-color: #622bc5;
}

.complete-purchase.msg-seller {
  border: 1px solid #622bc5;
  background: #fff;
  color: #622bc5;
}

.complete-purchase.msg-seller:hover {
  color: #622bc5;
  box-shadow: 0 0 16px 0 rgba(115, 50, 129, 0.26);
}

.complete-purchase.make-offer:hover {
  color: #622bc5;
}

.complete-purchase.make-offer.disablebutton,
.complete-purchase.msg-seller.disablebutton,
.complete-purchase.disablebutton {
  opacity: 0.3;
}

.complete-purchase.disablebutton:hover {
  background-color: #f86202;
  color: #fff;
}

.complete-purchase.make-offer.disablebutton:hover {
  background-color: #622bc5;
  color: #fff;
}

.complete-purchase.msg-seller.disablebutton:hover {
  background-color: transparent;
  color: #622bc5;
}

.complete-purchase.prod-share-btn {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 90px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a9a9a9;
  border: 0;
  background: none;
}

.prod-share-btn i {
  margin-left: 10px;
}

.dual-order .dual-product-image {
  text-align: center;
}

@media only screen and (min-width: 375px) {
  .transaction-listing .list-heading {
    font-size: 15px;
  }

  .transaction-listing .list-value {
    font-size: 16px;
  }

  .header-address {
    width: 120px;
  }
}

@media only screen and (min-width: 576px) {

  .team-details {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    flex-grow: 1;
  }

  .about-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .team-images {
    width: 209px;
    flex-shrink: 0;
    margin-top: 14px;
  }

  .para-team {
    height: 100%;
    padding: 29px 15px;
  }

  .para-team p {
    margin-bottom: 30px;
  }

  .team-close {
    margin-bottom: 0;
  }

  .team-popup-head {
    flex-direction: row;
    align-items: flex-start;
  }

  .team-status {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 950px) {
  .search-brand {
    margin: 0 6px 0 17px;
  }

  .header-text i {
    font-size: 19px;
  }

  .download-app {
    padding-right: 14px;
  }

  .cart-wrap i.fa-shopping-bag {
    font-size: 19px;
  }

  .userName-container {
    margin-right: 12px;
  }

  .signin-register .profile-name {
    font-size: 18px;
    margin: 0 6px 0 0;
    white-space: nowrap;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1023px) {
  .profile-wrapper .tags-wrap .container .tags {
    margin: 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-header-address.header-address {
    display: none;
  }

  .header-address {
    display: block;
  }

  .footer-social i {
    font-size: 16px;
  }

  .footer-social .fb-link {
    width: 33px;
    height: 33px;
  }

  .dates-wraps label {
    width: auto;
  }

  .video-section {
    padding: 18px 0 50px;
  }

  .need-ideas .idea-block {
    width: 33.33%;
    margin-bottom: 40px;
  }

  .seller-input.dual-input .MuiSelect-outlined.MuiSelect-outlined {
    padding: 12px 50px 12px 16px;
  }

  .register-popup.seller-register .seller-input .MuiInputBase-root {
    height: 44px;
  }

  .seller-input .MuiInputBase-root input {
    height: auto;
  }

  .multiple-items .order-items {
    display: flex;
    align-items: center;
  }

  .multiple-items .order-items h3 {
    margin: 0 8px 0 0;
  }

  .multiple-items .view-all-items {
    font-size: 17px;
  }

  .dual-order .ordered-products {
    text-align: center;
  }

  .rotate-image {
    margin: 0;
    width: 446px;
    overflow: initial;
  }

  .local-bazaar .container {
    padding: 0 28px;
  }

  .local-bazaar .local-banner-image {
    margin: 0;
  }

  .selling-locals .rotate-image {
    margin: 0 auto 27px;
    padding: 0;
  }

  .powered-delivery {
    padding: 50px 0;
    max-width: 1110px;
  }

  .selling-local h2 {
    font-size: 30px;
  }

  .local-bazaar .banner-content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .local-banner {
    padding-bottom: 0;
  }

  .make-offer-popup.review-popup .offer-title {
    padding: 0 31px 13px 0;
  }

  .header-address {
    position: relative;
    flex-shrink: 0;
    margin: 0 13px;
    font-size: 11px;
    width: 138px;
    z-index: 1;
  }

  .team-images {
    width: 289px;
    margin-top: 34px;
  }

  .para-team {
    padding: 47px 30px;
  }

  .para-team p {
    font-size: 16px;
  }

  .seller-input .MuiSelect-outlined.MuiSelect-outlined {
    padding: 18px 30px 18px 12px;
  }

  .withdraw-panel p {
    margin-bottom: 60px;
  }

  .footer-social {
    justify-content: flex-end;
  }

  .make-offer-popup.cancel-return .offer-content {
    padding: 15px 24px;
  }

  .make-offer-popup.cancel-return .offer-title.heading {
    padding: 0 40px 0 0;
  }

  .checkout-cardwrap .qty-input button {
    padding: 5px 8px;
  }

  .checkout-cardwrap .qty-input {
    width: 85px;
  }

  .checkout-cardwrap .qty-input input {
    font-size: 13px;
  }

  .bank-column .bank-icon {
    width: 55px;
    height: 55px;
  }

  .bank-column .bank-detail {
    margin-left: 20px;
  }

  .bank-column .bank-balance {
    padding: 15px 36px 15px 15px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .bank-column .bank-boxes {
    justify-content: initial;
  }

  .local-bazaar .banner-texts {
    margin-right: 15px;
  }

  .dual-order .dual-order-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .dual-order .dual-content {
    width: calc(50% - 10px);
  }

  .dual-order .dual-content.order {
    width: 100%;
  }

  .dual-order .products-images {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    /* align-items: center; */
  }

  .dual-order .dual-product-image {
    margin: 0 49px 25px 0;
    width: 92px;
  }

  .dual-order .view-dual-order {
    padding: 0 10px;
    text-align: center;
  }

  .dual-order .dual-menu {
    display: flex;
    align-items: center;
    margin-top: 3px;
  }

  .dual-order .dual-menu .view-order {
    margin: 0 15px 0 0;
    padding-right: 15px;
    /* border-right: 1px solid #d6d0d0; */
  }

  .local-working {
    padding: 45px 0;
  }

  .local-working .working-flow h3 {
    font-size: 39px;
    margin-bottom: 35px;
  }

  .local-working .working-text h4 {
    font-size: 29px;
    margin-bottom: 15px;
  }

  .local-working .working-text p {
    font-size: 16px;
  }

  .local-working .working-cost {
    font-size: 16px;
  }

  .local-working .working-cost i {
    font-size: 25px;
  }

  .local-working .working-block {
    margin-bottom: 50px;
  }

  .video-section .video-box {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    background: rgb(115, 51, 129, 0.24);
  }

  .video-section .video-name {
    width: 50%;
    padding: 0 30px;
  }

  .video-section .video-thumbnail {
    width: 50%;
  }

  .video-section .video-box h3 {
    margin-bottom: 0;
  }

  .available-nationwide {
    padding: 40px 0;
  }

  .available-nationwide .nation-list li {
    width: calc(33.3% - 10px);
    margin: 0 5px 30px;
  }

  .available-nationwide .nation-list {
    justify-content: flex-start;
  }

  .local-reviews .review-boxes {
    display: flex;
    flex-wrap: wrap;
  }

  .local-reviews .review-box {
    width: calc(33.3% - 10px);
    margin: 0 5px 20px;
  }

  .local-accordion .accordion__button {
    font-size: 20px;
  }

  .local-accordion p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .prod-title.prod-toggle .del-details p {
    text-align: right;
  }

  .selling-locals .rotate-image {
    margin-bottom: 27px;
  }

  .header-address .deliver-text {
    font-size: 12px;
  }

  .questions-local .view-faq {
    position: static;
    text-align: left;
  }

  .questions-local .local-question {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 0 0 50px;
  }

  .questions-local .question-text {
    width: 30%;
  }

  .local-accordion {
    width: 100%;
  }

  .questions-local {
    padding: 30px 0 50px;
  }

  .questions-local .local-question h3 {
    font-size: 36px;
    text-align: left;
  }

  .questions-local .local-question h5 {
    text-align: left;
    font-size: 19px;
  }

  .local-accordion .accordion__button {
    font-size: 23px;
  }

  .local-accordion p {
    font-size: 17px;
  }

  .questions-local .local-question {
    max-width: 970px;
    margin: 0 auto;
    padding-left: 14px;
  }

  .local-reviews .review-box {
    width: calc(33.3% - 26px);
    margin: 0 13px 20px;
    padding: 30px 20px 50px;
  }

  .local-reviews .review-boxes {
    max-width: 970px;
    margin: 0 auto;
  }

  .local-reviews .review-box h4 {
    font-size: 21px;
  }

  .local-reviews .review-box p {
    font-size: 19px;
  }

  .local-reviews .reviews-local h3 {
    font-size: 36px;
  }

  .local-reviews .reviews-local h5 {
    font-size: 24px;
  }

  .local-reviews {
    padding: 50px 0;
  }

  .need-ideas .idea-block {
    width: 16%;
    margin-bottom: 0;
  }

  .need-ideas .ideas-wrap h3 {
    font-size: 36px;
  }

  .available-nationwide .nations-name h3 {
    font-size: 36px;
  }

  .need-ideas .ideas-wrap p {
    font-size: 22px;
    margin-bottom: 50px;
  }

  .need-ideas {
    padding: 59px 0;
  }

  .video-section .video-box h3 {
    font-size: 37px;
  }

  .video-section .video-thumbnail {
    height: 360px;
  }

  .local-working .working-block {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0;
    position: relative;
  }

  .local-working .working-block::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: #141414;
    width: 1px;
    height: 50px;
    transform: translateY(50%);
  }

  .local-working .working-block:last-child:after {
    display: none;
  }

  .local-working .working-block:first-child {
    align-items: flex-start;
  }

  .local-working .working-block:first-child .working-text {
    padding-top: 0;
  }

  .local-working .working-image {
    margin-bottom: 0;
    width: calc(60% - 10px);
  }

  .local-working .working-flow h3 {
    text-align: left;
    margin-bottom: 47px;
    font-size: 41px;
  }

  .local-working .working-blocks {
    padding: 0 31px;
  }

  .local-working {
    padding: 60px 0;
  }

  .local-working .working-text {
    width: calc(40% - 10px);
    padding: 24px 6px 31px 0;
  }

  .multiple-items .order-summary-multi h3 {
    font-size: 18px;
  }

  .selling-locals-wrap {
    display: flex;
  }

  .local-selling-content {
    padding-top: 40px;
  }

  .local-selling-image {
    margin-left: -14%;
    padding-right: 14%;
    max-width: 600px;
  }

  .dual-order .ordered-products {
    text-align: left;
  }

  .dual-order .view-dual-order {
    text-align: left;
  }

  .dual-order .products-images {
    justify-content: flex-start;
    max-width: 487px;
  }

  .selling-locals h3 {
    font-size: 35px;
    margin-bottom: 42px;
  }

  .selling-locals .selling-content {
    display: flex;
  }

  .selling-locals .selling-boxes-text {
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 20px 0 0;
  }

  .selling-locals .selling-boxes {
    flex-direction: column;
    justify-content: flex-start;
  }

  .selling-locals .text-wrap-selling {
    width: 100%;
    min-height: 166px;
    width: 173px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .selling-locals .text-wrap-selling:last-child {
    margin-bottom: 0;
  }

  .selling-locals .selling-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .selling-locals .selling-box:last-child {
    margin-bottom: 0;
  }

  .selling-locals .option-boxes {
    flex-direction: row;
  }

  .selling-locals .option-box {
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
    width: 83px;
    text-align: center;
    justify-content: center;
  }

  .selling-locals .long {
    flex-grow: 1;
  }

  .selling-locals .long.longer {
    margin: 0;
  }

  .selling-locals .long.longer {
    min-height: 166px;
  }

  .reasons-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .reasons-wrap {
    margin-bottom: 0;
    width: 32.3%;
    padding: 0 20px;
    max-width: 380px;
    align-items: flex-start;
    text-align: left;
  }

  .reasons-wrap p {
    font-size: 16px;
  }

  .local-banner h2 {
    font-size: 43px;
  }

  .local-banner p {
    font-size: 20px;
  }

  .local-bazaar .local-banner-image {
    height: auto;
  }

  .rotate-image {
    width: 50%;
  }

  .header-address {
    top: auto;
    left: auto;
    width: auto;
    font-size: 15px;
    /* padding: 2px 12px 2px 6px; */
  }

  .product-location-wraps {
    padding: 6px 18px 6px 15px;
  }

  .team-status h4 {
    font-size: 25px;
  }

  .team-status h6 {
    font-size: 17px;
  }

  .para-team p {
    font-size: 17px;
  }

  .team-images {
    width: 359px;
  }

  .about-container {
    padding: 0 25px;
  }

  .para-team {
    padding: 48px 51px;
  }

  .team-popup-head {
    padding: 26px 0 10px 0;
    margin-bottom: 31px;
  }

  .withdraw-btns .withdraw-cancel {
    padding: 11px 24px;
    font-size: 18px;
  }

  .withdraw-btns .withdraw-continue {
    padding: 11px 44px;
    font-size: 18px;
  }

  .balance-block {
    margin-bottom: 68px;
  }

  .withdraw-inputs {
    display: flex;
    align-items: flex-start;
  }

  .withdraw-inputs .amount-block {
    width: calc(50% - 10px);
    margin: 0;
  }

  .withdraw-inputs .bank-selector {
    width: calc(50% - 32px);
    margin-left: 40px;
  }

  .withdraw-inputs .input-amount-wrap {
    display: flex;
    align-items: center;
  }

  .withdraw-inputs h5 {
    white-space: nowrap;
    margin: 0 0 0 10px;
  }

  .withdraw-panel h4 {
    font-size: 22px;
  }

  .withdraw-panel p {
    font-size: 17px;
  }

  .balance-block .balance-number {
    font-size: 32px;
  }

  .balance-block h6 {
    font-size: 17px;
  }

  .transaction-listing .list-heading {
    font-size: 16px;
  }

  .transaction-listing .list-value {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) {
  .items-table-wrap .table-head-headings::after {
    display: none;
  }

  .user-offer-table .items-table-wrap .table-head-headings::after {
    display: block;
  }

  .items-table-wrap .table-head-headings {
    border-bottom: 1px solid #e2e2e2;
  }

  .user-offer-table .items-table-wrap .table-head-headings {
    border: 0;
  }

  .weight-value .cancel-btn {
    font-size: 15px;
    padding: 7px 11px;
  }

  .local-accordion .accordion__button {
    font-size: 24px;
  }

  .questions-local .faq-btn {
    font-size: 20px;
    padding: 16px 42px;
  }

  .available-nationwide {
    padding: 50px 0;
  }

  .available-nationwide .nations-name h3 {
    margin-bottom: 47px;
  }

  .available-nationwide .nation-list li {
    text-align: left;
    width: calc(33.3% - 50px);
    margin: 0 25px 30px;
    font-size: 20px;
  }

  .available-nationwide .cities-btn .list-cities {
    padding: 11px 35px;
    border-radius: 35px;
    font-size: 19px;
  }

  .available-nationwide .nation-list {
    margin: 0 auto 40px;
    max-width: 900px;
  }

  .local-working .working-image {
    width: 50%;
  }

  .local-working .working-text {
    width: 50%;
    padding: 24px 103px 41px 0;
  }

  .local-working .working-text p {
    font-size: 17px;
  }

  .local-working .working-cost {
    font-size: 17px;
  }

  .local-working .working-block::after {
    height: 56px;
  }

  .video-section .video-box h3 {
    font-size: 44px;
  }

  .video-section .video-name {
    padding: 0 60px;
  }

  .video-section .video-thumbnail {
    height: 380px;
  }

  .need-ideas .idea-inner {
    width: 166px;
    padding: 29px 17px;
  }

  .need-ideas .list-btn-wrap {
    margin-top: 70px;
  }

  .items-table-wrap .name-value {
    font-size: 17px;
    padding: 0 15px 0 30px;
    width: calc(40% - 132px);
  }

  .items-table-wrap .name-heading {
    padding: 0 15px 0 30px;
    width: calc(40% - 132px);
  }

  .items-table-wrap .table-head-headings {
    padding: 13px 10px;
    font-size: 18px;
  }

  .items-table-wrap .weight-value,
  .items-table-wrap .weight-heading {
    /* font-size: 18px; */
    width: 47%;
    padding: 0;
  }

  .multiple-items .summary-multi-bill {
    max-width: 555px;
  }

  .dual-order .view-dual-order {
    padding: 0;
  }

  .dual-order .dual-content {
    width: calc(20% - 10px);
  }

  .dual-order .dual-content-order {
    padding: 25px 10px;
  }

  .dual-order {
    padding-bottom: 0;
  }

  .dual-order .ordered-products {
    padding: 0;
    margin-top: 5px;
  }

  .local-selling-content {
    padding-top: 79px;
  }

  .local-bazaar .container {
    padding: 0 48px;
  }

  .local-selling-image {
    margin-left: -17px;
    padding-right: 50px;
    max-width: 600px;
  }

  .blogs-content {
    justify-content: flex-start;
    margin-right: -24px;
  }

  .blogcard-quad {
    margin-right: 24px;
  }

  .blogcard-dual {
    margin-right: 24px;
    width: calc(50% - 24px);
  }

  .powered-delivery {
    padding: 50px 0 60px;
  }

  .selling-local h2 {
    margin-bottom: 60px;
  }

  .selling-local {
    padding: 40px 0;
  }

  .selling-local h2 {
    font-size: 33px;
  }

  .local-banner h2 {
    font-size: 54px;
  }

  .local-banner p {
    font-size: 21px;
  }

  .header-address {
    margin: 0 17px 0 0;
    width: auto;
    /* font-size: 16px; */
    /* padding: 3px 6px 3px 8px; */
  }

  .header-address .product-location {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .product-location-wraps {
    padding: 7px 18px 7px 14px;
  }

  .location-mark i {
    margin: -1px 8px 0 0;
  }

  .location-mark {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .header-address .distance-mark {
    margin-left: 5px;
    white-space: nowrap;
  }

  .withdraw-table {
    min-width: auto;
  }

  .transaction-tab h4 {
    font-size: 22px;
  }

  .team-popup-head {
    padding: 37px 0 10px 0;
    margin-bottom: 35px;
  }

  .about-container {
    padding: 0 57px;
  }

  .para-team {
    position: relative;
  }

  .para-team::after {
    top: 34px;
    right: 100%;
    width: 15px;
    height: calc(100% - 34px);
    content: "";
    background: #ad72c1;
    position: absolute;
  }

  .withdraw-amounts {
    margin-bottom: 55px;
  }

  .transaction-listing li {
    margin-bottom: 20px;
  }

  .transaction-listing .list-heading {
    font-size: 17px;
  }

  .transaction-listing .list-value {
    font-size: 18px;
  }

  .withdraw-inputs .add-bank-link {
    font-size: 18px;
    margin-top: 13px;
  }

  .withdraw-inputs .label-text {
    font-size: 18px;
  }

  .withdraw-inputs .input-amount {
    font-size: 18px;
  }

  .withdraw-inputs .input-amount::placeholder {
    font-size: 18px;
  }

  .withdraw-inputs h5 {
    font-size: 15px;
  }

  .bank-column .bank-balance {
    padding: 20px 49px 20px 20px;
  }

  .bank-column {
    padding: 40px 0 44px;
  }

  .bank-column h4 {
    margin-bottom: 40px;
  }

  .withdraw-inputs {
    margin-bottom: 40px;
  }

  .bank-column .change-btn {
    font-size: 16px;
  }

  .bank-column .bank-detail {
    font-size: 18px;
  }



  .make-offer-popup.cancel-return .full-width {
    font-size: 14px;
  }

  .make-offer-popup.cancel-return .text-reason textarea {
    font-size: 14px;
  }

  .prod-toggle {
    max-width: 281px;
  }

  .status-details span {
    font-size: 14px;
  }

  .del-details span {
    font-size: 14px;
  }

  .prod-title.prod-toggle .del-details p {
    font-size: 14px;
  }

  .status-details {
    font-size: 14px;
  }

  .del-details strong {
    font-size: 14px;
  }

  .checkout-cardwrap .qty-input {
    width: 93px;
  }

  .checkout-cardwrap .qty-input input {
    font-size: 15px;
  }

  .checkout-cardwrap .qty-input button {
    padding: 7px 8px;
    font-size: 12px;
  }

  .attr .label-txt.attr-select {
    font-size: 16px;
  }

  .navigation-screen .attr-elements .select-attr .label-txt {
    font-size: 14px;
  }

  .team-images {
    width: 373px;
  }
}

@media only screen and (min-width: 1280px) {
  .dual-order .dual-content.order {
    width: calc(28% - 10px);
    max-width: 235px;
  }

  .dual-order .ordered-products h3 {
    font-size: 17px;
  }

  .dual-order .items-text-number {
    font-size: 18px;
  }

  .dual-order .dual-content {
    width: calc(15% - 10px);
    margin-bottom: 0;
  }

  .dual-order .dual-content h4 {
    font-size: 14px;
  }

  .dual-order .dual-content h5 {
    font-size: 15px;
  }

  .dual-order .heading-menu {
    font-size: 15px;
  }

  .dual-order .dual-menu .view-order {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1300px) {
  .dual-order .products-images {
    max-width: 690px;
  }

  /* .blogcard-dual{
    margin-right: 17px;
  } */
  .items-table-wrap .weight-value,
  .items-table-wrap .weight-heading {
    width: 43%;
  }
}

@media only screen and (min-width: 1350px) {
  .items-table-wrap {
    padding: 0 18px;
  }



  .multiple-items .order-summary-multi {
    padding: 15px 18px 30px;
  }

  .available-nationwide .nation-list li {
    width: calc(33.3% - 20px);
    margin: 0 10px 35px;
    font-size: 20px;
    font-size: 23px;
  }

  .available-nationwide .nation-list {
    max-width: 1000px;
  }

  .local-working .working-text {
    padding: 24px 103px 50px 0;
  }

  .local-working .working-block::after {
    height: 72px;
  }

  .local-working .working-text {
    padding: 24px 103px 58px 0;
  }

  .local-working .working-block::after {
    height: 85px;
  }

  .dual-order .dual-order-head {
    padding: 20px;
  }

  .dual-order .dual-content-order {
    padding: 9px 20px;
  }



  .team-popup-head {
    padding: 45px 0 10px 0;
  }

  .about-container {
    padding: 0 65px;
  }

  .team-images {
    width: 389px;
  }

  .team-close i {
    font-size: 38px;
  }
}

@media only screen and (min-width: 1367px) {
  .product-review-images {
    width: 90px;
    height: 90px;
  }
}

@media only screen and (min-width: 1500px) {
  .slide-box-wrap .btn-wrap button.complete-purchase {
    width: 216px;
  }

  .footer-social i {
    font-size: 19px;
  }

  .footer-social .fb-link {
    width: 36px;
    height: 36px;
  }

  .product-location-wraps {
    padding: 8px 18px 8px 14px;
  }

  .complete-purchase.make-offer {
    width: calc(50% - 5px);
  }

  .signup-form.otp-open {
    max-width: 500px;
  }

  .detail-from.otp-screen .otp-btn {
    padding: 13px 42px;
    font-size: 17px;
  }

  .header-address {
    margin: 0 22px 0 0;
  }

  .dual-order .ordered-products h3 {
    font-size: 17px;
  }

  .dual-order .items-text-number {
    font-size: 20px;
  }

  .dual-order .dual-content h4 {
    font-size: 16px;
  }

  .dual-order .dual-content h5 {
    font-size: 15px;
  }

  .dual-order .heading-menu {
    font-size: 17px;
  }

  .dual-order .dual-menu .view-order {
    font-size: 17px;
  }

  .local-working {
    padding: 80px 0;
  }

  .local-working .working-flow h3 {
    margin-bottom: 54px;
  }

  .need-ideas .idea-inner {
    width: 180px;
  }

  .need-ideas .list-btn-wrap {
    margin-top: 90px;
  }

  .dual-order .dual-content {
    width: calc(17% - 10px);
    margin-bottom: 0;
  }

  .dual-order .view-dual-order .view-link {
    font-size: 20px;
  }

  .dual-order .dual-content.order {
    width: 29%;
    max-width: 272px;
  }

  .selling-local {
    padding: 60px 0;
  }

  .sign-form .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -8px) scale(0.75);
  }

  .selling-local h2 {
    font-size: 40px;
  }

  .local-banner h2 {
    font-size: 56px;
  }

  .local-banner p {
    font-size: 23px;
  }

  .signup-form .MuiInputLabel-outlined {
    top: 4px;
  }

  .para-team::after {
    height: calc(100% - 49px);
    top: 49px;
    width: 20px;
  }

  .team-status h4 {
    font-size: 28px;
  }

  .team-status h6 {
    font-size: 20px;
  }

  .para-team p {
    font-size: 20px;
  }

  .team-images {
    width: 462px;
    margin-top: 49px;
  }

  .para-team {
    padding: 68px 79px;
  }

  .withdraw-btns .withdraw-cancel {
    padding: 14px 50px;
    font-size: 22px;
  }

  .withdraw-btns .withdraw-continue {
    padding: 14px 54px;
    font-size: 22px;
  }

  .balance-block .balance-number {
    font-size: 33px;
  }

  .balance-block h6 {
    font-size: 18px;
  }

  .attr .label-txt.attr-select {
    font-size: 18px;
  }

  .navigation-screen .attr-elements .label-txt {
    font-size: 18px;
  }

  .navigation-screen .attr-elements .select-attr .label-txt {
    font-size: 14px;
  }

  .description-container .label-txt {
    font-size: 16px !important;
  }

  /* .discount {
    display: block;
  } */
}

@media only screen and (min-width: 1700px) {

  .items-table-wrap .weight-value,
  .items-table-wrap .weight-heading {
    width: 35%;
  }

  .attr .label-txt.attr-select {
    font-size: 20px;
  }

  .rupees-icon {
    top: 16px;
  }



  .team-close i {
    font-size: 50px;
  }

  .blog-article-one.blog-articles .article-head.blog-expandimage figure img {
    max-height: 656px;
  }
}

/* @media only screen and (min-width: 1800px) {
  .blogcard-dual {
    margin-right: 20px;
}
} */
@media only screen and (min-width: 1900px) {


  .local-banner h2 {
    font-size: 58px;
  }

  .local-banner p {
    font-size: 25px;
  }

  .local-banner .get-start {
    font-size: 21px;
  }
}

@media only screen and (max-width: 991px) {
  .local-selling-image {
    margin: 0;
    padding: 0 12px;
  }
}

/* styling */
@media all and (max-width:767px) {
  .make-offer-popup .offer-content {
    width: 100%;
  }

  .make-offer-popup.offered-popup-wrap.offer-popup-wrap .offer-title {
    font-size: 20px;
    margin: 0 -15px 10px;
    padding-bottom: 10px;
  }

  /* .item-seller-info .offered-popup-wrap.make-offer-popup .offer-title:after {
    width: 100%;
    left: 0;
  } */

  .make-offer-popup .offer-info {
    margin-bottom: 5px;
  }

  .offer-input-box label {
    font-size: 14px;
  }

  .offer-price h3 {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    margin: 15px 0 12px;
  }

  .offer-btn-diable {
    margin-top: 15px;
    height: 40px;
  }

  .offer-inserted-btn {
    margin-top: 15px;
    height: 40px;
  }
}

@media all and (min-width:430px) {
  .make-offer-popup .offer-content {
    width: 75%;
  }
}