*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
img{
    max-width: 100%;
    height: auto;
}
.coupon-wrapper{
    max-width: 90%;
    margin: 0 auto;
    padding: 60px 15px;
}
.coupon-tab{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 40px;
}
.coupon-tab li{
    margin-right:18px;
    color: #4a4a4a;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.16);
    background: #fff;
    font-weight: 700;
    font-size: 16px;
    padding: 18px 25px;
    border-radius: 26px;
    line-height: 1.3;
    text-align: center;
    cursor: pointer;
}
.coupon-tab li:hover{
    background-color:rgba(209,109,56,0.8);
    color:#fff;
}
.coupon-tab li.active{
    background-color:rgba(209,109,56,0.8);
    color:#fff;
}
.coupon-tab li:last-child{
    margin: 0;
}
.coupon-tabcontent{
    padding: 20px;
}
.coupon-infobtn{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-bottom:40px;
    border-bottom: 1px solid #cfcfcf;
}
.coupon-infobtn li{
    width: 30%;
}
.coupon-infobtn label{
    display: block;
    margin-bottom: 12px;
    font-size: 17px;
    color: #4a4a4a;
    font-weight: 600;
}
.coupon-infobtn input{
    width: 100%;
    border-radius: 8px;
    padding: 15px;
    border: 1px solid #cfcfcf;
}
.input-wrap{
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}
.coupon-infobtn .input-wrap input{
    padding-right: 50px;
}
.discount-label{
    position: absolute;
    top:0;
    right: 0;
    color:#fff;
    font-size: 16px;
    height: 100%;
    width:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(209,109,56,1);
    pointer-events: none;
}
.coupon-products{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cfcfcf;
}

.coupon-products li{
    color: #4a4a4a;
    font-size: 16px;
    font-weight: 600;
    padding: 20px 0;
}
.coupon-products .product-image{
    width: 150px;
    max-height: 150px;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.coupon-products .product-name{
    width: 60%;
}
.coupon-products .product-price{
    width: 15%;
}
.coupon-products .product-image img{
    width:75px;
    height: 75px;
    object-fit:contain;
}
.coupon-products .product-checkmark{
    width:50px;
}
.coupon-btn{
    padding: 10px 25px;
    border-radius: 8px;
    background-color:rgba(209,109,56,1);
    color:#fff;
    font-weight: 700;
    max-width: 195px;
    margin: 30px 0 0 auto;
    display: block;
    font-size: 17px;
    border:1px solid transparent;
    transition: all 0.5s ease;
}
.coupon-btn:hover{
    background-color:#fff;
    color:#4a4a4a;
    border:1px solid rgba(209,109,56,0.7);
}
.checkbox-wrap{
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size:16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.checkbox-wrap input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: -8px;
    left: 0;
    height:18px;
    width: 18px;
    border: 1px solid #cfcfcf;
  }
  
  .checkbox-wrap input:checked ~ .checkmark {
    background-color:#d16d38;
    border: 1px solid transparent;
  }  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .checkbox-wrap .checkmark:after {
    left: 5px;
    top: 0px;
    width: 7px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
  .checkbox-wrap input:checked ~ .checkmark:after {
    display: block;
  }
  .coupon-listcontent{
      display: block;
  }
  .product-searchwrap{
      display: flex;
      justify-content: flex-end;
      padding-bottom: 30px;
      border-bottom: 1px solid #cfcfcf;
}
  .global-search{
        position: relative;
  }
  .global-search input{
        border: 1px solid #cfcfcf;
        border-radius: 4px;
        width: 100%;
        padding: 12px 18px;
        font-size: 15px;
        color:#4a4a4a;
  }
  .global-search .icon-search{
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      background-size: 450px 350px;
      width: 14px;
      height: 14px;
      background-position: -104px -7px;
  }
  .remove-product{
      font-size:30px;
      color:#4a4a4a;
      cursor: pointer;
      transition: all 0.5s ease;
  }
  .remove-product:hover{
    color: #d16d38;
  }
    .coupons-wrapper{
        max-width: 100%;
    }
    .coupons-wrapper .checkmark{
        left: 50%;
        transform: translateX(-50%);
    }
    
  /* responsive */
  @media(max-width:1199px){
    .coupon-wrapper{
        max-width: 100%;
    }
  }
  @media(max-width:991px){
    
    .coupon-heading .product-description,.coupon-products .product-name{
        width: 55%;
    }
    .coupon-heading li{
        font-size: 15px;
    }
    .couponlist-heading .product-description{
        width: 16%;
    }
    .coupon-listing li{
        font-size: 15px;
    }
    .coupon-listing .product-name{
        width: 16%;
        margin-bottom: 0;
    }
  }
  @media(max-width:767px){
    .coupon-wrapper{
        max-width: 100%;
        padding: 40px 15px;
    }
    .coupon-tabcontent {
        padding: 15px 0;
    }
    .coupon-listing li{
        font-size: 13px;
    }
    
    .coupon-heading .product-description {
        width:40%;
    }
    .coupon-products .product-name {
        width: 40%;
        margin: 0;
    }
    .couponlist-heading .product-description{
        width:24%;
    }
    .coupon-listing .product-name {
        width:95px;
    }
    .coupon-heading li{
        font-size: 13px;
    }
    .couponlist-heading li{
        font-size: 12px;
    }
    .couponlist-heading .product-image{
        width: 95px;
    }
    .couponlist-heading .product-description{
        width: 95px;
        line-height: 1.2;
    }
    .product-price,.product-expiry{
        line-height: 1.4;
    }
    .coupon-listing .product-image{
        width: 95px;
    }
    
  }
  @media(max-width:475px){
    .coupon-tab li{
        padding: 10px;
        font-size: 14px;
    }
    .coupon-tabcontent{
        padding: 0;
    }
    .coupon-infobtn label{
        font-size: 14px;
    }
    .coupon-tab{
        justify-content: space-between;
    }
    .coupon-infobtn{
        flex-wrap: wrap;
    }
    .coupon-infobtn li{
        width: 48%;
        margin-bottom: 15px;
    }
    .coupon-infobtn li:last-child{
        margin-bottom: 0;
    }
    .coupon-infobtn input{
        padding: 10px;
    }
    .coupon-products li{
        font-size: 15px;
    }
    .checkbox-wrap{
        font-size: 15px;
    }
    .coupon-listing{
        flex-wrap: wrap;
        position: relative;
    }
    .coupon-listing li{
        width: 100%;
    }
    .coupon-listing .product-image{
        max-width: none;
    }
    
    
  }