.useLocation {
  background: #622bc5;
  color: #fff;
  padding: 13px 10px;
  font-size: 18px;
  margin-bottom: 20px;
  transition: all 0.5s;
  border-radius: 7px;
  border: 1px solid #622bc5;
}

.useLocation:hover {
  color: #622bc5;
  background: transparent;
}

.popup-body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  align-content: space-around;
  padding: 60px 0 30px;
}

.cont-shopping {
  background: rgb(248, 98, 2);
  color: #fff;
  padding: 13px 10px;
  font-size: 18px;
  transition: all 0.5s;
  border-radius: 7px;
  border: 1px solid rgb(248, 98, 2);
}

.zip-code {
  height: 49px;
  font-size: 15px;
  margin-bottom: 20px;
  padding: 0 15px;
  border-radius: 7px;
  text-align: center;
  border-width: 1px;
  font-family: "Roboto";
}

.make-offer-popup {
  font-family: "Roboto";
}

.popup-body h3 {
  font-family: "Roboto";
}

.useLocation {
  font-family: "Roboto";
}

.cont-shopping {
  font-family: "Roboto";
}

.popup-body .loc-img {
  margin: 0 0 24px 0;
}

.cont-shopping:hover {
  background: transparent;
  color: rgb(248, 98, 2);
}


/* Slider.css */

.slider {
  margin-bottom: 40px;
}

.slider label {
  color: #888;
  font-size: 0.8rem;
  margin-bottom: 20px;
  display: block;
  text-transform: capitalize;
}

.input-range__slider {
  background: #e27a3f;
  border: none;
}

.input-range__track {
  background: #ddd;
}

.input-range__track--active {
  background: #e27a3f;
}

.input-range__label--value .input-range__label-container {
  background: #e27a3f;
  color: white;
  font-size: 0.65rem;
  padding: 2px 5px;
  border-radius: 2px;
}

.input-range__label--min .input-range__label-container,
.input-range__label--max .input-range__label-container {
  font-size: 0.65rem;
  color: #888;
  left: 0;
}

.input-range__label--max .input-range__label-container {
  left: 25%;
}